import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { Avatar, Col, Row, Tag, Typography } from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import {
  LoadingOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
// import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import NoDataFound from "../../../components/EmptyTable/NoDataFound";
import {
  useLazyGetDownloadMobPROReportsQuery,
  useLazyGetMobPROReportsQuery,
} from "../../../apis/reports";
import { Link } from "react-router-dom";
import {
  dateFormat,
  getNameInitials,
  handlePdfDownload,
  statusColorMapping,
} from "../../../commonFuntions/CommonUtilFunctions";
// import { useLazyGetLineofCreditExcelQuery } from "../../../apis/forms";
// import { handlePdfDownload } from "../../../commonFuntions/CommonUtilFunctions";

const { Text } = Typography;

const MobPROReport = () => {
  const [searchedValue, setSearchedValue] = useState({});
  const [sorterState, setSorterState] = useState({
    name: "",
    phone_number: "",
    date_joined: "",
  });
  const [dateFilter, setDateFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [
    getLineofCreditApi,
    { data: lineOfCreditData, isFetching: isLoading },
  ] = useLazyGetMobPROReportsQuery();
  const [excelApi, { isFetching: exlLoading }] =
    useLazyGetDownloadMobPROReportsQuery();

  useEffect(() => {
    getLineofCreditApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        getLineofCreditApi({
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.name && { sort_by: sorterState.name }),
          ...(sorterState.phone_number && {
            sort_by: sorterState.phone_number,
          }),
          ...(sorterState.date_joined && { sort_by: sorterState.date_joined }),

          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        getLineofCreditApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.name && { sort_by: sorterState.name }),
          ...(sorterState.phone_number && {
            sort_by: sorterState.phone_number,
          }),
          ...(sorterState.date_joined && { sort_by: sorterState.date_joined }),

          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }

      setCurrentPage(1);
    }
    setDateFilter(value);
  };

  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates?.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      getLineofCreditApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        ...(searchedValue?.search && { search: searchedValue?.search }),
        ...(sorterState.name && { sort_by: sorterState.name }),
        ...(sorterState.phone_number && {
          sort_by: sorterState.phone_number,
        }),
        ...(sorterState.date_joined && { sort_by: sorterState.date_joined }),

        page: 1,
      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
      setCurrentPage(1);
    }
  };
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(sorterState.name && { sort_by: sorterState.name }),
      ...(sorterState.phone_number && {
        sort_by: sorterState.phone_number,
      }),
      ...(sorterState.date_joined && { sort_by: sorterState.date_joined }),
    };
    setCurrentPage(currPage);
    await getLineofCreditApi(params);
  };
  const exportOptions = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];
  const statusFilterOptions = [
    {
      key: 1,
      value: false,
      label: "PRO Inactive",
    },
    {
      key: 1,
      value: true,
      label: "PRO Active",
    },
    {
      key: 3,
      value: "clear_status",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear Status</Col>
      ),
    },
  ];
  const handleExportApi = async () => {
    let response = await excelApi({
      ...(searchedValue.search && { search: searchedValue.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(sorterState.name && { sort_by: sorterState.name }),
      ...(sorterState.phone_number && {
        sort_by: sorterState.phone_number,
      }),
      ...(sorterState.date_joined && { sort_by: sorterState.date_joined }),
    });

    if (response?.status === "fulfilled") {
      handlePdfDownload(response?.data?.data?.url);
    }

    // excelApi();
  };
  const handleFilterApi = (item) => {
    getLineofCreditApi({
      ...(searchedValue.search && { search: searchedValue.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(sorterState.name && { sort_by: sorterState.name }),
      ...(sorterState.phone_number && {
        sort_by: sorterState.phone_number,
      }),
      ...(sorterState.date_joined && { sort_by: sorterState.date_joined }),
      ...(item !== "clear_status" && { is_professional: item }),
    });
  };
  const sorterChange = (item) => {
    let key = item;
    setSorterState({
      // ...sorterState,
      [key.replace("-", "")]: item,
    });
    getLineofCreditApi({
      sort_by: item,
      ...(searchedValue?.search && { search: searchedValue?.search }),
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
      page: 1,
    });
  };
  const options = [
    {
      key: 1,
      value: "Today",
      label: "Today",
    },
    {
      key: 2,
      value: "Yesterday",
      label: "Yesterday",
    },
    {
      key: 3,
      value: "last_7_day",
      label: "Last 7 day",
    },
    {
      key: 4,
      value: "last_30_day",
      label: "Last 30 day",
    },
    {
      key: 5,
      value: "last_60_day",
      label: "Last 60 day",
    },
    {
      key: 6,
      value: "Custom",
      label: "Custom",
    },
    {
      key: 7,
      value: "clear_date",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear Date</Col>
      ),
    },
  ];
  const columns = [
    {
      width: "300px",
      fixed: "left",
      flex: 2,
      // title: "Name",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Name</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.name === "name" ? "" : "grey",
                }}
                onClick={() => sorterChange("name")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color: sorterState?.name === "-name" ? "" : "grey",
                }}
                onClick={() => sorterChange("-name")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      key: 1,
      dataIndex: "full_name",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={record?.profile_image}
                  alt="Avatar"
                  // icon={<DefaultIcon width={20} height={20} />}
                >
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                    }}
                  >
                    <Text
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {record?.full_name
                        ? getNameInitials(record?.full_name)
                        : `N/A`}
                    </Text>
                  </Col>
                </Avatar>
              </Col>
              <Col style={{ fontWeight: 500 }}>{record?.full_name}</Col>
            </Col>
          </>
        );
      },
    },
    {
      flex: 2,
      width: "200px",
      // title: "Upgrade date & time",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Account created date</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color:
                    sorterState?.date_joined === "date_joined" ? "" : "grey",
                }}
                onClick={() => sorterChange("date_joined")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color:
                    sorterState?.date_joined === "-date_joined" ? "" : "grey",
                }}
                onClick={() => sorterChange("-date_joined")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      key: 3,
      dataIndex: "date_joined",
      render: (params) => {
        return <Col>{dateFormat(params)}</Col>;
      },
    },
    {
      flex: 2,
      width: "200px",
      // title: "Upgrade date & time",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Upgrade date & time</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color:
                    sorterState?.professional_date === "professional_date"
                      ? ""
                      : "grey",
                }}
                onClick={() => sorterChange("professional_date")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color:
                    sorterState?.professional_date === "-professional_date"
                      ? ""
                      : "grey",
                }}
                onClick={() => sorterChange("-professional_date")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      key: 4,
      dataIndex: "professional_date",
      render: (params) => {
        return <Col>{dateFormat(params)}</Col>;
      },
    },
    {
      // fixed: "left",
      flex: 1,
      // title: "Business Mobile (For OTP)",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Business Mobile (For OTP)</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color:
                    sorterState?.phone_number === "phone_number" ? "" : "grey",
                }}
                onClick={() => sorterChange("phone_number")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color:
                    sorterState?.phone_number === "-phone_number" ? "" : "grey",
                }}
                onClick={() => sorterChange("-phone_number")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      width: "250px",
      key: 5,
      dataIndex: "phone_number",
      render: (params, record) => {
        return (
          <Link
            to={`/admin/customer/customerDetails/${record?.phone_number}`}
            target="_blank"
            style={{ fontWeight: 500, color: "#2973f0", cursor: "pointer" }}
          >
            {params}
          </Link>
        );
      },
    },
    // {
    //   flex: 2,
    //   width: "200px",
    //   title: "Email",
    //   key: 3,
    //   dataIndex: "email",
    // },
    // {
    //   // fixed: "left",
    //   flex: 1,
    //   title: "Mob Star Points",
    //   width: "250px",
    //   key: 3,
    //   dataIndex: "mob_star_points",
    // },
    {
      // fixed: "left",
      flex: 1,
      title: "Status",
      // title: "Tag (PRO ACTIVE/ PRO INACTIVE)",
      width: "200px",
      key: 6,
      dataIndex: "is_professional",
      render: (params) => {
        return (
          <Tag
            style={{ padding: "8px" }}
            className={`tag-font-style ${
              statusColorMapping[params ? "PRO Active" : "PRO Inactive"]
            }`}
          >
            {params ? "PRO Active" : "PRO Inactive"}
          </Tag>
        );
      },
    },
    // {
    //   // fixed: "left",
    //   flex: 1,
    //   title: "Current Limit",
    //   width: "200px",
    //   key: 5,
    //   dataIndex: "current_limit",
    //   render: (params, record) => {
    //     return (
    //       <>
    //         <Text>{(record?.rupifiDetails?.current_limit || 0).toFixed(2)}</Text>
    //       </>
    //     );
    //   },
    // },
    // {
    //   // fixed: "left",
    //   flex: 1,
    //   title: "Balance",
    //   width: "200px",

    //   key: 6,
    //   dataIndex: "balance",
    //   render: (params, record) => {
    //     return (
    //       <>
    //         <Text>{(record?.rupifiDetails?.balance || 0).toFixed(2)}</Text>
    //       </>
    //     );
    //   },
    // },
    // {
    //   // fixed: "left",
    //   flex: 1,
    //   title: "Repayment amount",
    //   width: "200px",
    //   key: 7,
    //   dataIndex: "repayment_amount",
    //   render: (params, record) => {
    //     return (
    //       <>
    //         <Text>{(record?.repayment_amount || 0).toFixed(2)}</Text>
    //       </>
    //     );
    //   },
    // },
    // {
    //   // fixed: "left",
    //   flex: 1,
    //   title: "Repayment link",
    //   width: "250px",
    //   key: 8,
    //   dataIndex: "repayment_link",
    //   render: (params, record) => {
    //     return (
    //       <Link
    //         // to={`/admin/customer/customerDetails/${record?.phone_number}`}
    //         // target="_blank"
    //         style={{ fontWeight: 500, color: "#2973f0", cursor: "pointer" }}
    //       >
    //         {params || "N/A"}
    //       </Link>
    //     );
    //   },
    // },
    {
      width: "200px",
      // fixed: "left",
      flex: 2,
      title: "Business Name",
      key: 1,
      dataIndex: "business_name",
      render: (params, record) => {
        return (
          <>
            <Col style={{ fontWeight: 500 }}>{record?.business_name}</Col>
          </>
        );
      },
    },
    {
      // fixed: "left",
      flex: 1,
      title: "GSTIN",
      width: "200px",
      key: 8,
      dataIndex: "gst_number",
    },
  ];

  return (
    <>
      <Col style={{ padding: "2rem" }}>
        <Row>
          <Col className="header-container">
            <Text className="heading">Mob PRO Upgrade Reports</Text>
          </Col>
        </Row>
        <Row className="action-container">
          <Col className="action-sub-container">
            <SearchInput
              size={"large"}
              placeholder={"Search by customer name, mobile no, email"}
              getLibraryApi={getLineofCreditApi}
              width={"400px"}
              isLoading={isLoading}
              handleSearchedValue={handleSearchedValue}
            />

            <CustomSelect
              placeholder={"Date"}
              width={"150px"}
              options={options}
              handleChange={handleChange}
              value={dateFilter}
            />
            {dateFilter === "Custom" && (
              <DateRangePicker
                onChange={handleChangeRangePicker}
                format={"DD-MMM-YYYY"}
              />
            )}
            <CustomSelect
              placeholder={"Export"}
              width={"150px"}
              options={exportOptions}
              handleChange={handleExportApi}
              value={exlLoading ? <LoadingOutlined /> : "Export"}
              disabled={exlLoading ? true : false}
              loading={exlLoading}
            />
            <CustomSelect
              placeholder={"Status Filter"}
              width={"150px"}
              options={statusFilterOptions}
              handleChange={handleFilterApi}
              // value={isLoading ? <LoadingOutlined /> : "Status Filter"}
              disabled={isLoading ? true : false}
              loading={isLoading}
            />
          </Col>
        </Row>
        <Col>
          <CustomTableWithPagination
            className="order-list-table"
            tableDataSource={lineOfCreditData?.data?.results || []}
            tableColumns={columns}
            locale={{
              emptyText: isLoading ? (
                <TableSkeleton length={10} />
              ) : (
                <NoDataFound text={"No line of credit found!"} />
              ),
            }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={30}
            totalEntries={
              !isLoading && lineOfCreditData?.data?.pagination?.total_entries
            }
            isLoading={lineOfCreditData?.data?.results?.length && isLoading}
          />
        </Col>
      </Col>
    </>
  );
};

export default MobPROReport;

import React, { useEffect, useState } from "react";
import "./ExistingOrders.css";
import { UploadOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { Button, Col, Row, Typography, Avatar } from "antd";
import SearchInput from "../../components/Input/SearchInputs/SearchInput";
import "./ExistingOrders.css";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import { useDispatch, useSelector } from "react-redux";
// import { dateFormat } from "../../commonFuntions/CommonUtilFunctions";
import CustomSelect from "../../components/SelectableDropdown/CustomSelect/CustomSelect";
import { ReactComponent as DefaultIcon } from "../../assets/icons/default.svg";
import CustomTableWithPagination from "../../components/CustomTableWithPagination/CustomTableWithPagination";
import UploadPaymentModel from "../../components/Modal/SettlementModal/UploadPaymentModel";
import { useLazyGetSettlementTableQuery } from "../../apis/settlement";
import { settlementTableListSelector } from "../../redux/slices/settlement/selector";
import {
  getSettlementDataByID,
  getSettlementTableList,
} from "../../redux/slices/settlement/action";
import { baseUrl } from "../../commonUtils/commonUtils";

const { Text } = Typography;
const handleTableData = (data) => {
  let tableData = [];
  data?.results?.map((item) => {
    tableData.push({
      id: item?.bmp_id,
      key: item?.bmp_id,
      amount_pending: item?.amount_pending,
      bmp_id: item?.bmp_id,
      bmp_image: item?.bmp_image,
      city: item?.city,
      state: item?.state,
      to_be_resolved: item?.to_be_resolved,
      vendor_name: item?.vendor_name,
      settlement_date: item?.settlement_date,
    });
    return null;
  });
  return tableData;
};

const PendingSettlement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectorData = useSelector(settlementTableListSelector);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [searchedValue, setSearchedValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [
    settlementTableListAPI,
    { data: settlementTableListData, isFetching: isLoading },
  ] = useLazyGetSettlementTableQuery();

  const [tableDataSource, setTableDataSource] = useState([]);

  useEffect(() => {
    settlementTableListAPI();
    dispatch(getSettlementDataByID({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (settlementTableListData?.status) {
      dispatch(getSettlementTableList(settlementTableListData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settlementTableListData]);
  useEffect(() => {
    if (selectorData) {
      const data = selectorData ? handleTableData(selectorData) : "";
      setTableDataSource(data);
    }
  }, [selectorData]);
  useEffect(() => {
    if (selectorData) {
      // const data = selectorData ? handleTableData(selectorData) : "";
      //   setTableDataSource(data);
    }
  }, [selectorData]);
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const goToSellerPage = (record) => {
    navigate(`/admin/settlement/${record?.bmp_id}`);
  };
  const handleChange = (value) => {};

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
    };
    setCurrentPage(currPage);
    await settlementTableListAPI(params);
  };

  const handleOk = () => {
    setOpenUploadModal(false);
  };

  const onCancelModal = () => {
    setOpenUploadModal(false);
  };
  const options = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
    {
      key: 2,
      value: "CSV",
      label: "CSV",
    },
    {
      key: 3,
      value: "PDF",
      label: "PDF",
    },
    {
      key: 4,
      value: "Print",
      label: "Print",
    },
  ];
  const columns = [
    {
      width: "250px",
      fixed: "left",
      title: "Name",
      key: 1,
      dataIndex: "vendor_name",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record?.parentKey ? "60px" : "0px",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={`${baseUrl}${record?.seller_display_image}`}
                  alt="Avatar"
                  icon={<DefaultIcon width={20} height={20} />}
                />
              </Col>
              <Col className="table_text" style={{ fontWeight: 400 }}>
                {params}
              </Col>
            </Col>
          </>
        );
      },
    },
    {
      // fixed: "left",
      flex: 2,
      title: "BMP ID",
      key: 2,
      dataIndex: "bmp_id",
      className: "table_text",
    },
    {
      // fixed: "left",
      flex: 1,
      title: "City",
      key: 3,
      dataIndex: "city",
      className: "table_text",
    },
    {
      // fixed: "left",
      flex: 1,
      title: "Settlement date",
      key: 4,
      dataIndex: "settlement_date",
      className: "table_text",
    },
    {
      flex: 1,
      title: "To be resolve",
      key: 5,
      dataIndex: "to_be_resolved",
      render: (params) => {
        return (
          <Text className="table_text">
            ₹ {params ? (params || 0).toFixed(2) : "0"}
            {/* <RightOutlined /> */}
          </Text>
        );
      },
    },
    {
      flex: 1,
      title: "Amount pending",
      dataIndex: "amount_pending",
      key: 6,
      render: (params, record) => {
        return (
          <div
            className="table_text"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Text style={{ width: "150px" }}>
              ₹ {params ? (params || 0).toFixed(2) : "0"}
            </Text>
            <RightOutlined onClick={() => goToSellerPage(record)} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Col style={{ padding: "2rem" }}>
        <Row>
          <Col className="header-container">
            <Text className="heading">Seller (BMP) Pending Settlement</Text>
          </Col>
        </Row>
        <Row className="action-container">
          <Col className="action-sub-container">
            <SearchInput
              size={"large"}
              placeholder={"Search by seller / BMP"}
              getLibraryApi={settlementTableListAPI}
              width={"400px"}
              isLoading={isLoading}
              handleSearchedValue={handleSearchedValue}
            />
            <CustomSelect
              placeholder={"Download"}
              width={"150px"}
              height={"46px"}
              options={options}
              handleChange={handleChange}
              value={"Download"}
            />
          </Col>
          <Col className="action-sub-container">
            <Button
              icon={
                <UploadOutlined
                  style={{ strokeWidth: "60", stroke: "white" }}
                />
              }
              size="large"
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                fontWeight: 500,
                height: "48px",
              }}
              onClick={() => setOpenUploadModal(true)}
            >
              Upload Payments
            </Button>
          </Col>
        </Row>
        <Col>
          <CustomTableWithPagination
            className="order-list-table"
            tableDataSource={tableDataSource || []}
            tableColumns={columns}
            locale={{
              emptyText: isLoading ? <TableSkeleton length={30} /> : "",
            }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={30}
            totalEntries={
              !isLoading &&
              settlementTableListData?.data?.pagination?.total_entries
            }
            isLoading={tableDataSource.length && isLoading}
          />
        </Col>
      </Col>
      <UploadPaymentModel
        open={openUploadModal}
        onCancleModel={onCancelModal}
        handleOk={handleOk}
      />
    </>
  );
};

export default PendingSettlement;

import React, { useState } from "react";
import "./SettlementReport.css";
import {
  //  Avatar,
  Col,
  //  Dropdown,
  // Menu,
  Typography,
} from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
// import { DashOutlined } from "@ant-design/icons";
// import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";

// import dayjs from "dayjs";
const { Text } = Typography;
const options = [
  {
    key: 1,
    value: "Excel",
    label: "Excel",
  },
  {
    key: 2,
    value: "CSV",
    label: "CSV",
  },
  {
    key: 3,
    value: "PDF",
    label: "PDF",
  },
  {
    key: 4,
    value: "Print",
    label: "Print",
  },
];

const dateOptions = [
  {
    key: 1,
    value: "Today",
    label: "Today",
  },
  {
    key: 2,
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    key: 3,
    value: "last_7_day",
    label: "Last 7 day",
  },
  {
    key: 4,
    value: "last_30_day",
    label: "Last 30 day",
  },
  {
    key: 5,
    value: "last_60_day",
    label: "Last 60 day",
  },
  {
    key: 6,
    value: "Custom",
    label: "Custom",
  },
];
const tableDataSource = [
  {
    key: 1,
    BMP: "BENG 1",
    order_no: 1234567890,
    order_date: "23 Jun 2022, 09:13am",
    order_amount: "₹ 120799",
    pg_charges: "₹ 120799",
    payment_mode: "Online",
    pg_status: "Done",
    settlement_status: "Done",
    id: 1,
    entity: 524,
    currency: "Dollar",
    invoice_id: 3214454,
    international: "No",
  },
  {
    key: 2,
    BMP: "BENG 1",
    order_no: 1234567890,
    order_date: "23 Jun 2022, 09:13am",
    order_amount: "₹ 120799",
    pg_charges: "₹ 120799",
    payment_mode: "Online",
    pg_status: "Done",
    settlement_status: "Done",
    id: 2,
    entity: 524,
    currency: "Dollar",
    invoice_id: 3214454,
    international: "No",
  },
  {
    key: 3,
    BMP: "BENG 1",
    order_no: 1234567890,
    order_date: "23 Jun 2022, 09:13am",
    order_amount: "₹ 120799",
    pg_charges: "₹ 120799",
    payment_mode: "Online",
    pg_status: "Done",
    settlement_status: "Done",
    id: 3,
    entity: 524,
    currency: "Dollar",
    invoice_id: 3214454,
    international: "No",
  },
];
const SettlementReport = () => {
  const [dateFilter, setDateFilter] = useState();

  const handleDateChange = (value) => {
    // let formattedDate = null;

    // switch (value) {
    //   case "Today":
    //     formattedDate = dayjs().format("YYYY-MM-DD");
    //     break;
    //   case "Yesterday":
    //     formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    //     break;
    //   case "last_7_day":
    //     formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
    //     break;
    //   case "last_30_day":
    //     formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
    //     break;
    //   case "last_60_day":
    //     formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
    //     break;
    //   case "Custom":
    //     formattedDate = "Custom";
    //     break;
    //   default:
    //     break;
    // }

    // const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      //   getWalletTransferDetailsApi({
      //     start_date: formattedDate,
      //     end_date: currentDate,
      //   });
    }
    setDateFilter(value);
  };
  const columns = [
    {
      title: "BMP",
      dataIndex: "BMP",
      key: "BMP",
      width: "150px",
      fixed: "left",
      render: (params, record) => {
        return (
          <Col
            // onClick={() => handleUserDetailsNavigation(record)}
            style={{ fontWeight: 500, cursor: "pointer" }}
          >
            {params}
          </Col>
        );
      },
    },
    {
      title: "Order No",
      dataIndex: "order_no",
      key: "order_no",
      width: "200px",
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      width: "250px",
    },

    {
      title: "Order Amount",
      dataIndex: "order_amount",
      key: "order_amount",
      width: "300px",
    },
    {
      title: "PG Charges",
      dataIndex: "pg_charges",
      key: "pg_charges",
      width: "250px",
    },
    {
      title: "Payment Mode",
      dataIndex: "payment_mode",
      key: "payment_mode",
      width: "200px",
    },
    {
      title: "PG Status",
      dataIndex: "pg_status",
      key: "pg_status",
      width: "200px",
    },
    {
      title: "Settlement Status",
      dataIndex: "settlement_status",
      key: "settlement_status",
      width: "200px",
    },

    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "150px",
    },
    {
      title: "Entity",
      dataIndex: "entity",
      key: "entity",
      width: "150px",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      width: "150px",
    },
    {
      title: "Invoice Id",
      dataIndex: "invoice_id",
      key: "invoice_id",
      width: "150px",
    },
    {
      title: "International",
      dataIndex: "international",
      key: "international",
      width: "150px",
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      width: "150px",
    },
    {
      title: "Card Id",
      dataIndex: "card_id",
      key: "card_id",
      width: "150px",
    },
    {
      title: "Email Id",
      dataIndex: "email_id",
      key: "email_id",
      width: "150px",
    },
    {
      title: "Business mobile",
      dataIndex: "mobile_no",
      key: "mobile_no",
      width: "150px",
    },
    {
      title: "Notes",
      dataIndex: "note",
      key: "note",
      width: "150px",
    },
    {
      title: "Error Code",
      dataIndex: "error_code",
      key: "error_code",
      width: "150px",
    },
    {
      title: "Error Desc",
      dataIndex: "error_desc",
      key: "error_desc",
      width: "150px",
    },
    {
      title: "Error Source",
      dataIndex: "error_source",
      key: "error_source",
      width: "150px",
    },
    {
      title: "Error Step",
      dataIndex: "error_step",
      key: "error_step",
      width: "150px",
    },
    {
      title: "Error Reason",
      dataIndex: "error_reason",
      key: "error_reason",
      width: "150px",
    },
    {
      title: "Auth Code",
      dataIndex: "auth_code",
      key: "auth_code",
      width: "150px",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      width: "150px",
    },
    {
      title: "Response Status",
      dataIndex: "response_status",
      key: "response_status",
      width: "150px",
    },
    {
      title: "Wallet",
      dataIndex: "wallet",
      key: "wallet",
      width: "150px",
    },
    {
      title: "VPA",
      dataIndex: "VPA",
      key: "VPA",
      width: "150px",
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
      width: "250px",
    },
  ];
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      // const startDateFormatted = dates[0].format("YYYY-MM-DD");
      // const endDateFormatted = dates[1].format("YYYY-MM-DD");
      //   getWalletTransferDetailsApi({
      //     start_date: startDateFormatted,
      //     end_date: endDateFormatted,
      //   });
    }
  };
  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">Settlement Report</Text>
      <Col style={{ display: "flex", gap: "1rem" }}>
        <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
          //   getLibraryApi={getWalletTransferDetailsApi}
          width={"400px"}
          //   isLoading={isFetching}
        />
        <Col className="action-sub-container">
          <CustomSelect
            placeholder={"Date"}
            width={"150px"}
            options={dateOptions}
            handleChange={handleDateChange}
            value={dateFilter}
          />
          {dateFilter === "Custom" && (
            <DateRangePicker
              onChange={handleChangeRangePicker}
              format={"DD-MMM-YYYY"}
            />
          )}
        </Col>
        <CustomSelect
          placeholder={"Download"}
          width={"100px"}
          height={"46px"}
          options={options}
          //   handleChange={handleChange}
          value={"EXPORT"}
        />
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        //   tableExpandable={defaultExpandable}
        tableDataSource={tableDataSource || []}
        // tableDataSource={getWalletData?.data?.results}
        tableColumns={columns}
        // locale={{
        //   emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        // }}
        // isLoading={getWalletData?.data?.results.length && isFetching}
        scroll={{ x: "100%" }}
        // currentPage={currentPage}
        // handlePageChange={handlePageChange}
        itemsPerPage={30}
        // totalEntries={
        //   !isFetching && getWalletData?.data?.pagination?.total_entries
        // }
      />
    </Col>
  );
};

export default SettlementReport;

import { Col, Typography } from "antd";
import React from "react";

const StatusBar = ({ status }) => {
  const { Text } = Typography;
  return (
    <>
      <Col
        style={{
          display: "flex",
          justifyContent: "space-between",
          // maxWidth: status ? "792px" : "700px",
          width: "100%",
          height: "52px",
          padding: "14px 16px",
          borderRadius: "8px",
          backgroundColor: status ? "#f8e6b6" : "#e9fdf2",
          color: status ? "#2973f0" : "#0a243f",

          fontWeight: 500,
        }}
      >
        {status ? (
          <Text>
            👀 Please publish the quote to make it visible to the customer
          </Text>
        ) : (
          "" || (
            <Text style={{ fontSize: "12px", fontWeight: 500 }}>
              🎉 Order has been created for this request. Please click on view
              details to check the order status or edit
            </Text>
          )
        )}
      </Col>
    </>
  );
};

export default StatusBar;

import React from "react";
import {
  Avatar,
  Col,
  Dropdown,
  Menu,
  Table,
  Typography,
  // Checkbox,
  // Popconfirm,
  // Button,
} from "antd";
import {ReactComponent as UploadImage} from "../../assets/icons/uploadImage.svg";
import {ReactComponent as CancelInfoIcon} from "../../assets/icons/CancelInfoIcon.svg";
import EmptyTable from "../Request/RFQ/CreateQuote/CreateQuoteTable/EmptyTable";
import {baseUrl} from "../../commonUtils/commonUtils";
import TextArea from "antd/es/input/TextArea";
import CanceledUnitNameCell from "./CanceledUnitNameCell/CanceledUnitNameCell";
import CanceledGstCell from "./CanceledGstCell/CanceledGstCell";
import {totalPrice} from "../../commonFuntions/CommonUtilFunctions";
import CanceledTableHeader from "./CanceledTableHeader/CanceledTableHeader";
import CanceledTableFooter from "./CanceledTableFooter/CanceledTableFooter";
import "./cancelledstyle.css";
// import {ReactComponent as DeleteIcon} from "../../assets/icons/DeleteIconTable.svg";
// import {CaretUpOutlined, CaretDownOutlined} from "@ant-design/icons";
const CanceledQuoteTable = (props) => {
  const {Text} = Typography;
  const {
    table,
    tableIndex,
    selectedSeller,
    editCondition,
    dataSource,
    setDataSource,
    setSelectedSeller,
    sellerList,
    getAllVendors,
    bmpPrice,
    initialValue,
    getBmpPrice,
    billingAddressSelector,
  } = props;
  const disableAll = true;
  let defaultColumns = [];
  const columsHeader = (table, tableIndex, currentSeller, editCondition) => {
    defaultColumns = [
      {
        title: `${table.length} Items`,
        key: "icon",
        dataIndex: "input",
        minWidth: "300px",
        maxWidth: "400px",
        render: (params, record, index) => {
          return (
            <>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}>
                {/* <Checkbox
                  disabled={disableAll}
                  //   onChange={(e) => handleCheckboxChange(e, record, tableIndex)}
                  //   checked={selectedRows.some(
                  //     (selectedRow) =>
                  //       tableIndex === selectedRow.tableIndex &&
                  //       selectedRow.key === record.key
                  //   )}
                /> */}
                <Avatar
                  shape="square"
                  size={60}
                  style={{backgroundColor: "#e5e5e5", cursor: "not-allowed"}}
                  icon={<UploadImage />}
                  //   onClick={() => showModal(record, tableIndex)}
                  src={`${baseUrl}${record?.imageURL}`}
                  disabled={disableAll}
                />
                <Dropdown
                  disabled={disableAll}
                  overlay={
                    <Menu
                      style={{maxHeight: "250px", overflow: "auto"}}
                      //   onClick={(e) => handleSaveProduct(e, tableIndex, record)}
                    >
                      {/* {dropDownList?.map((item, index) => (
                        <>
                          <Menu.Item
                            key={item.key}
                            value={item}
                            style={{ padding: "7px 12px" }}
                          >
                            {item.product_name}
                          </Menu.Item>
                          {index !== dropDownList.length - 1 && (
                            <Divider style={{ margin: "5px" }} />
                          )}
                        </>
                      ))} */}
                    </Menu>
                  }
                  style={{fontWeight: 500, color: "#0a243f"}}
                  trigger={["click"]}>
                  <TextArea
                    disabled={disableAll}
                    className="custom-textarea"
                    rows={3}
                    placeholder="Enter Item Name"
                    value={params}
                    style={{
                      width: "180px",
                      border: "1px solid #fff",
                      borderRadius: "8px",
                      overflowY: "hidden",
                    }}
                    // onChange={(e) =>
                    //   handleSearchText(e, tableIndex, record, currentSeller)
                    // }
                  />
                </Dropdown>
              </Col>
            </>
          );
        },
      },
      {
        minWidth: "300px",
        maxWidth: "400px",
        title: (
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Text style={{fontFamily: "Inter", fontWeight: 500}}>
              Unit name
            </Text>
            <Text style={{fontFamily: "Inter", fontWeight: 500}}>
              Price (Before tax)
            </Text>
          </Col>
        ),
        key: 1,
        dataIndex: "unit_name",
        height: "60px",
        render: (params, record) => {
          return (
            <>
              <CanceledUnitNameCell
                params={params}
                record={record}
                dataSource={dataSource}
                tableIndex={tableIndex}
                setDataSource={setDataSource}
                disableAll={disableAll}
              />
            </>
          );
        },
        unit_name: "unit_name",
        before_tax: "before_tax",
        after_tax: "after_tax",
        hsn_sac: "hsn_sac",
        title1: "Unit Name",
        title2: "Before Tax",
        tableIndex: tableIndex,
        total: "total",
        bmp_price: "bmp_price",
      },
      {
        flex: 4,
        title: (
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Text style={{fontFamily: "Inter", fontWeight: 500}}>GST%</Text>
            <Text style={{fontFamily: "Inter", fontWeight: 500}}>
              Price (After tax)
            </Text>
            <Text style={{fontFamily: "Inter", fontWeight: 500}}>Discount</Text>
            <Text style={{fontFamily: "Inter", fontWeight: 500}}>Quantity</Text>
          </Col>
        ),
        key: 2,
        dataIndex: "gst",
        render: (params, record) => {
          return (
            <CanceledGstCell
              params={params}
              record={record}
              dataSource={dataSource}
              tableIndex={tableIndex}
              setDataSource={setDataSource}
              editCondition={editCondition}
              disableAll={disableAll}
            />
          );
        },
        gst: "gst",
        after_tax: "after_tax",
        discount: "discount",
        quantity: "quantity",
        mob_margin: "mob_margin",
        amount_in: "amount_in",
        amount: "amount",
        bmp_price: "bmp_price",
        total: "total",
        title1: "Column 1 Title",
        title2: "Column 2 Title",
        tableIndex: tableIndex,
      },
      {
        flex: 1,
        width: "150px",
        title: "Total",
        dataIndex: "total",
        align: "center",
        render: (_, record) => {
          return (
            <>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                  marginBottom: "20px",
                }}>
                <Text
                  level={5}
                  style={{
                    fontWeight: 500,
                    cursor: "not-allowed",
                  }}>
                  ₹ {totalPrice(record).toFixed(2)}
                </Text>
                {/* <Popconfirm
                  key={record.key}
                  title={
                    <Text
                      style={{
                        display: "block",
                      }}
                    >
                      Are you sure ?
                    </Text>
                  }
                  placement="left"
                  trigger="click"
                  style={{ width: "150px" }}
                  disabled={disableAll}
                    onConfirm={() =>
                      handleDeleteRow(record.key, tableIndex, record)
                    }
                >
                  <Col
                    className="col-delete-icon"
                    style={{
                      height: "30px",
                      width: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteIcon />
                  </Col>
                </Popconfirm> */}
              </Col>
              {/* <Col
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button.Group size="small">
                  <Button
                    value="large"
                    // disabled={
                    //   record?.key ===
                    //   dataSource[tableIndex][dataSource[tableIndex].length - 1]
                    //     ?.key
                    // }
                    disabled={disableAll}
                    style={{
                      backgroundColor: "#f3f2ef",
                      borderRadius: "8px 0 0 8px",
                      height: "27px",
                    }}
                  >
                    <CaretDownOutlined
                    //   onClick={() =>
                    //     handleMoveRow(record.key, "down", tableIndex)
                    //   }
                    />
                  </Button>
                  <Button
                    value="small"
                    // disabled={record.key === dataSource[tableIndex][0]?.key}
                    disabled={disableAll}
                    style={{
                      backgroundColor: "#f3f2ef",
                      borderRadius: "0 8px 8px 0",
                      height: "27px",
                    }}
                  >
                    <CaretUpOutlined
                    //   onClick={() =>
                    //     handleMoveRow(record.key, "up", tableIndex)
                    //   }
                    />
                  </Button>
                </Button.Group>
              </Col> */}
            </>
          );
        },
      },
    ];
    return defaultColumns;
  };

  const tableColumns = columsHeader(
    table,
    tableIndex,
    selectedSeller[tableIndex],
    editCondition
  );
  return (
    <>
      <Col className="cancelled-main-container">
        <Col className="cancelled-main-banner">
          <CancelInfoIcon />{" "}
          <Text>Order cancelled. You can no longer edit it.</Text>
        </Col>
        <Table
          key={`${tableIndex}`}
          className={"cancelled-rfq-table"}
          style={{
            borderRadius: "0 0 10px 10px",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
            border: "solid 1px #eaeaea",
            marginBottom: "48px",
            marginTop: "58px",
            backgroundColor: "rgb(240 72 62 / 45%)",
            cursor: "not-allowed",
          }}
          columns={
            selectedSeller[tableIndex] || tableIndex !== 0 ? tableColumns : []
          }
          dataSource={
            selectedSeller[tableIndex] || tableIndex !== 0 ? table : []
          }
          title={() => (
            <CanceledTableHeader
              // handleAddRow={handleAddRow}
              tableIndex={tableIndex}
              // handleDeleteTable={handleDeleteTable}
              selectedSeller={selectedSeller}
              setSelectedSeller={setSelectedSeller}
              sellerList={sellerList}
              getAllVendors={getAllVendors}
              editCondition={editCondition}
              key={tableIndex}
              bmpPrice={bmpPrice}
              getBmpPrice={getBmpPrice}
              initialValue={initialValue.venderList}
              disableAll={disableAll}
            />
          )}
          footer={() => (
            <CanceledTableFooter
              // handleAddRow={handleAddRow}
              tableIndex={tableIndex}
              selectedSeller={selectedSeller}
              dataSource={dataSource}
              key={tableIndex}
              editCondition={editCondition}
              bmpPrice={bmpPrice}
              getBmpPrice={getBmpPrice}
              billingAddressSelector={billingAddressSelector}
              disableAll={disableAll}
            />
          )}
          locale={{
            emptyText: selectedSeller[tableIndex]?.vendor_name ? (
              ""
            ) : (
              <EmptyTable
                mainText={"Please select the seller to add items to the RFQ."}
              />
            ),
          }}
          pagination={false}
        />
      </Col>
    </>
  );
};

export default CanceledQuoteTable;

import React, { useEffect, useState } from "react";
// import "../";
import { Button, Col, Divider, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ReactComponent as MobCreditWhiteIcon } from "../../../assets/icons/mobCreditWhiteIcon.svg";

import {
  CardContent,
  ContentText,
} from "../../Request/RFQ/CreateQuote/CreateQuoteProfileSection/ProfileStyled";
import { useNavigate, useParams } from "react-router-dom";
// import {
//   CardContent,
//   ContentText,
// } from "../../../../Request/RFQ/CreateQuote/CreateQuoteProfileSection/ProfileStyled";
// import AddNewSellerModal from "../../../../../components/Modal/AddNewSellerModal/AddNewSellerModal";
// import {
//   useDeleteSellerAddressMutation,
//   useLazyGetAllSellerAddressQuery,
// } from "../../../../../apis/sellerCatalogue";
import { useDispatch, useSelector } from "react-redux";
import {
  allAddressSellerSelector,
  editAddressSellerSelector,
} from "../../../redux/slices/sellerCatalogue/selector";
// import {
//   useDeleteSellerAddressMutation,
//   // useLazyGetAllSellerAddressQuery,
// } from "../../../apis/sellerCatalogue";
import {
  getAllSellerAddress,
  getEditSellerAddress,
} from "../../../redux/slices/sellerCatalogue/action";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import AddNewSellerModal from "../../../components/Modal/AddNewSellerModal/AddNewSellerModal";
import { useLazyGetCustomersAddressQuery } from "../../../apis/customer";
import {
  useAddNewAddressMutation,
  useDeleteAddressMutation,
  useUpdateAddressMutation,
} from "../../../apis/createQuote";
import ManageDeleteModal from "../../../components/Modal/DeleteModal/ManageDeleteModal/ManageDeleteModal";
import { getDeleteAddressContainer } from "../../../redux/slices/createQuote/action";
import { deleteAddressSelector } from "../../../redux/slices/createQuote/selector";

export const CustomerAddress = (props) => {
  const navigate = useNavigate();
  const { seller_id } = useParams();
  const { Text } = Typography;
  const dispatch = useDispatch();
  const allAddressSelector = useSelector(allAddressSellerSelector);
  const editAddressSelector = useSelector(editAddressSellerSelector);
  const delete_Address = useSelector(deleteAddressSelector);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [getAllSellerAddressAPI, { data: allAddressesData }] =
    useLazyGetCustomersAddressQuery();
  const [deleteAddressAPI] = useDeleteAddressMutation();
  const [createNewAddressApi, { isLoading: isCreateLoading }] =
    useAddNewAddressMutation();
  const [updateAddressApi, { isLoading: isUpdateLoading }] =
    useUpdateAddressMutation();
  const handleCancelDelete = () => {
    dispatch(getDeleteAddressContainer({}));
    setDeleteModal(false);
  };
  const handleDeleteOpenModal = (data, address_type) => {
    dispatch(getDeleteAddressContainer(data));
    setDeleteModal(true);
  };
  useEffect(() => {
    if (seller_id) {
      getAllSellerAddressAPI({ phone_number: seller_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (allAddressesData?.status) {
      dispatch(getAllSellerAddress(allAddressesData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAddressesData]);
  const handleDeleteAddress = async (id) => {
    const response = await deleteAddressAPI({ address_id: delete_Address?.id });

    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      getAllSellerAddressAPI({ phone_number: seller_id });
    } else {
      showErrorToast(response?.error?.data?.message);
    }
    setDeleteModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    dispatch(getEditSellerAddress({}));
  };
  const handleEditaddress = (data) => {
    dispatch(getEditSellerAddress(data));
    setOpenModal(true);
  };
  const handleBackNavigate = () => {
    navigate(-1);
  };

  return (
    <>
      <Col style={{ padding: "1rem" }}>
        <Button
          className="btn"
          style={{ margin: "1rem 0" }}
          icon={<ArrowLeftOutlined />}
          onClick={handleBackNavigate}
        >
          BACK
        </Button>
        <Col className="pickup-header">
          <Text className="pickup-header-text">Customer Addresses</Text>
          <Button
            className="pickup-header-button"
            onClick={() => handleOpenModal()}
          >
            Add Address
          </Button>
        </Col>
        <Col className="pickup-header-body">
          {allAddressSelector?.map((item, index) => {
            return (
              <>
                <Col className="pickup-card">
                  <Col style={{ padding: "20px" }}>
                    <Col className="pickup-card-heading">
                      {item?.name} {item?.mob_credit && <MobCreditWhiteIcon />}
                    </Col>

                    <CardContent style={{ width: "100%" }}>
                      <ContentText>
                        {item?.address} , {item?.address_line_2}
                      </ContentText>
                      <ContentText>
                        {item?.state} , {item?.city} , {item?.pincode}
                      </ContentText>
                      <ContentText>+91 {item?.phone_number} </ContentText>

                      {item?.map_url && (
                        <Col className="map-url-container">
                          <Text className="map-url-heading">Map Url</Text>
                          <Text className="map-url-text">
                            {item?.map_url || "no value"}
                          </Text>
                        </Col>
                      )}
                    </CardContent>
                  </Col>
                  {item?.mob_credit ? (
                    <Col className="pickup-card-actions edit-go-container">
                      To edit, go to Create Quote & Create Order
                    </Col>
                  ) : (
                    <Col className="pickup-card-actions">
                      <Button
                        className="actions-buttons"
                        onClick={() => handleEditaddress(item)}
                      >
                        {" "}
                        Edit
                      </Button>
                      <Divider
                        type="vertical"
                        className="pickup-divider"
                      ></Divider>
                      <Button
                        className="actions-buttons"
                        onClick={() => handleDeleteOpenModal(item)}
                      >
                        Delete
                      </Button>
                    </Col>
                  )}
                </Col>
              </>
            );
          })}
          <AddNewSellerModal
            deliveryModal={openModal}
            setIsModalOpen={setOpenModal}
            setDeliveryModal={setOpenModal}
            editData={editAddressSelector}
            getAllAddressAPI={getAllSellerAddressAPI}
            dispatchEditData={getEditSellerAddress}
            isCreateLoading={isCreateLoading}
            isUpdateLoading={isUpdateLoading}
            createAddressAPI={createNewAddressApi}
            updateAddressAPI={updateAddressApi}
            fetchingID={"phone_number"}
            // initialFormData={initialFormData}
            // formData={formData}
            // setFormData={setFormData}
            // addressListModal={showModal}
          />
          <ManageDeleteModal
            open={deleteModal}
            handleOk={handleCancelDelete}
            handleSave={handleDeleteAddress}
            data="this address"
          />
        </Col>
      </Col>
    </>
  );
};

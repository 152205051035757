import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  // Radio,
  Row,
  Select,
  // Tag,
  Typography,
} from "antd";
import { ReactComponent as LeftArrowIcon } from "../../../../../assets/icons/chevron-left.svg";
import {
  // useAddNewAddressMutation,
  useUpdateAddressMutation,
} from "../../../../../apis/createQuote";
import { useDispatch, useSelector } from "react-redux";
import {
  createQuoteUserSelector,
  deliveryAddressSelector,
} from "../../../../../redux/slices/createQuote/selector";
import {
  // getDeliveryAddress,
  updateAddressFlag,
} from "../../../../../redux/slices/createQuote/action";
import // showErrorToast,
// showSuccessToast,
"../../../../../NotificationToast/NotificationToast";
import { Option } from "antd/es/mentions";

const DeliveryAddressModal = ({
  deliveryModal,
  setDeliveryModal,
  setIsModalOpen,
  editData,
  initialFormData,
  formData,
  setFormData,
  addressListModal,
}) => {
  const dispatch = useDispatch();
  const deliveryAddressRedux = useSelector(deliveryAddressSelector);
  console.log(
    "🚀 ~ file: DeliveryAddressModal.js:46 ~ deliveryAddressRedux:",
    deliveryAddressRedux
  );

  const { Text, Title } = Typography;
  const mob_user = useSelector(createQuoteUserSelector);
  console.log("🚀 ~ file: DeliveryAddressModal.js:53 ~ mob_user:", mob_user);
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    if (editData) {
      setFormData({
        nameCompany: editData?.name || "",
        mobileNo: editData?.phone_number || "",
        gst: editData?.gst_number || "",
        addressI: editData?.address_line_1 || "",
        addressII: editData?.address_line_2 || "",
        google_map_link: editData?.address_line_2 || "",
        pincode: editData?.pincode || "",
        city: editData?.city || "",
        state: editData?.state || "",
        email: editData?.email || "",
        address_tag: editData?.address_tag || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);
  const validateForm = () => {
    const requiredFields = [
      "nameCompany",
      "mobileNo",
      "addressI",
      "email",
      "city",
      "state",
      "pincode",
    ];

    const isValid = requiredFields.every((field) => !!formData[field]);
    setIsFormValid(isValid);
  };

  const [updateAddressApi, { isSuccess: updateAddressSuccess }] =
    useUpdateAddressMutation();
  console.log(
    "🚀 ~ file: DeliveryAddressModal.js:88 ~ updateAddressApi:",
    updateAddressApi
  );

  useEffect(() => {
    if (updateAddressSuccess) {
      dispatch(updateAddressFlag(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAddressSuccess]);

  const handleSaveAddress = async () => {
    // setFormData(initialFormData);
    setDeliveryModal(false);
    // const params = {
    //   mob_user: mob_user.id,
    //   address_type: "Delivery",
    //   name: formData.nameCompany,
    //   phone_number: formData.mobileNo,
    //   email: formData.email,
    //   gst_number: formData.gst,
    //   address_line_1: formData.addressI,
    //   address_line_2: formData.addressII,
    //   google_map_link: formData.addressII,
    //   city: formData.city,
    //   state: formData.state,
    //   pincode: formData.pincode,
    //   address_tag: formData.address_tag,
    // };
    // if (editData?.name) {
    //   params.address_id = editData.id;
    //   const response = await updateAddressApi(params);
    //   if (response?.data?.status === true) {
    //     showSuccessToast(response.data.message);
    //     if (response?.data?.data.id === deliveryAddressRedux?.id) {
    //       dispatch(getDeliveryAddress(response?.data?.data));
    //     }
    //   } else {
    //     if (response.error.data.data.email[0]) {
    //       showErrorToast(response.error.data.data.email[0]);
    //     }
    //   }
    // } else {
    //   const response = await createNewAddressApi(params);
    //   if (response?.data?.status === true) {
    //     showSuccessToast(response.data.message);
    //     addressListModal();
    //   } else {
    //     if (response?.error?.data?.data?.email[0]) {
    //       showErrorToast(response?.error?.data?.data?.email[0]);
    //     }
    //   }
    // }
  };
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    validateForm();
  };

  const handleBack = () => {
    setFormData(initialFormData);
    setDeliveryModal(false);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setFormData(initialFormData);
    setDeliveryModal(false);
  };
  const tagData = editData?.address_tag;
  console.log("🚀 ~ file: DeliveryAddressModal.js:165 ~ tagData:", tagData);
  return (
    <>
      <Modal
        title={
          <>
            <Row style={{ width: "100%", alignItems: "center" }}>
              <Col style={{ paddingTop: "6px", width: "35%" }}>
                <LeftArrowIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleBack}
                />
              </Col>
              <Title level={4} style={{ width: "65%", margin: 0 }}>
                Delivery Address
              </Title>
            </Row>
          </>
        }
        open={deliveryModal}
        onCancel={handleCancel}
        closeIcon={false}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "28px",
              borderTop: "1px solid #dedede",
            }}
          >
            <Button
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                maxWidth: "120px",
                width: "100%",
              }}
            >
              CANCEL
            </Button>
            <Button
              style={{
                height: "48px",
                // maxWidth: "200px",
                // width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                border: "none",
                color: "white",
                backgroundColor: "#0354a3",
              }}
              onClick={handleSaveAddress}
              disabled={!isFormValid}
            >
              {editData?.name ? "SAVE & UPDATE ADDRESS" : "SAVE THIS ADDRESS"}
            </Button>
          </Col>,
        ]}
      >
        <Form
          name="delivery_address"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            height: "360px",
            width: "100%",
            overflow: "auto",
          }}
          autoComplete="off"
        >
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "2px 18px 18px 18px",
              gap: 12,
            }}
          >
            <Form.Item
              label={
                <Col>
                  Name / Company<Text style={{ color: "#FF0000" }}> *</Text>
                </Col>
              }
              style={{ margin: 0 }}
            >
              <Input
                style={{ height: 45 }}
                name="nameCompany"
                value={formData.nameCompany}
                onChange={(e) =>
                  handleInputChange("nameCompany", e.target.value)
                }
              />
            </Form.Item>
            <Col style={{ display: "flex", gap: "1rem" }}>
              <Form.Item
                label={
                  <Col>
                    Business Mobile(for OTP)
                    <Text style={{ color: "#FF0000" }}> *</Text>
                  </Col>
                }
                style={{ margin: 0 }}
              >
                <Input
                  style={{ height: 45 }}
                  name="mobileNo"
                  maxLength={10}
                  onKeyDown={(e) => {
                    if (
                      (e.key < "0" || e.key > "9") &&
                      e.key !== "Backspace" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      e.key !== "Tab"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  value={formData.mobileNo}
                  onChange={(e) =>
                    handleInputChange("mobileNo", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                label="GSTIN (Optional)"
                // name="gst"
                style={{ margin: 0 }}
                // value={formData.gst}
              >
                <Input
                  style={{ height: 45 }}
                  value={formData.gst}
                  name="gst"
                  onChange={(e) => handleInputChange("gst", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Col>
          <Divider style={{ margin: 0, borderTopWidth: "12px" }} />
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 18px 18px 18px",
              gap: 12,
            }}
          >
            <Form.Item
              label={
                <Col>
                  Email <Text style={{ color: "#FF0000" }}> *</Text>
                </Col>
              }
              style={{ margin: 0 }}
            >
              <Input
                value={formData.email}
                name="email"
                style={{ height: 45 }}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={
                <Col>
                  House no/ Building name (Address line 1)
                  <Text style={{ color: "#FF0000" }}> *</Text>
                </Col>
              }
              style={{ margin: 0 }}
            >
              <Input
                value={formData.addressI}
                name="addressI"
                style={{ height: 45 }}
                onChange={(e) => handleInputChange("addressI", e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Road/ Area/ Colony or google maps link"
              style={{ margin: 0 }}
            >
              <Input
                style={{ height: 45 }}
                name="addressII"
                value={formData.addressII}
                onChange={(e) => handleInputChange("addressII", e.target.value)}
              />
            </Form.Item>
            <Col style={{ display: "flex", gap: "1rem" }}>
              <Form.Item
                label={
                  <Col>
                    Pincode <Text style={{ color: "#FF0000" }}> *</Text>{" "}
                  </Col>
                }
                style={{ margin: 0 }}
              >
                <Input
                  style={{ height: 45 }}
                  name="pincode"
                  maxLength={6}
                  onKeyDown={(e) => {
                    if (
                      (e.key < "0" || e.key > "9") &&
                      e.key !== "Backspace" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      e.key !== "Tab"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  value={formData.pincode}
                  onChange={(e) => handleInputChange("pincode", e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label={
                  <Col>
                    City <Text style={{ color: "#FF0000" }}> *</Text>{" "}
                  </Col>
                }
                style={{ margin: 0 }}
              >
                <Input
                  style={{ height: 45 }}
                  name="city"
                  value={formData.city}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Form.Item
              label={
                <Col>
                  State <Text style={{ color: "#FF0000" }}> *</Text>{" "}
                </Col>
              }
              style={{ margin: 0 }}
            >
              <Select
                showSearch
                placeholder="--Select or Search  state--"
                onChange={(e) => handleInputChange("state", e)}
                value={formData.state}
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "6px",
                  height: "46px",
                }}
              >
                <Option disabled value="">
                  --Select or Search state--
                </Option>
                <Option value="Karnataka">Karnataka</Option>
                <Option value="Tamil Nadu">Tamil Nadu</Option>
              </Select>
              {/* <Input
                style={{ height: 45 }}
                name="state"
                value={formData.state}
                onChange={(e) => handleInputChange("state", e.target.value)}
              /> */}
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(DeliveryAddressModal);

import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Layout} from "antd";
import RFQList from "./modules/Request/RFQ/RFQList.js";
import "./App.css";
import Dashboard from "./modules/Dashboard/Dashboard";
import {store} from "./store";
import {Provider} from "react-redux";
import {ThemeProvider} from "styled-components";
import RFQDetails from "./modules/Request/RFQ/RFQDetails";
import UploadImage from "./modules/ManageMobLibrary/UpLoadImage";
import UploadSKU from "./modules/ManageMobLibrary/UploadSKU";
import LogIn from "./components/LogIn/LogIn";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import CreateQuote from "./modules/Request/RFQ/CreateQuote/CreateQuote";
import NotificationToast from "./NotificationToast/NotificationToast.js";
import ManageMobLib from "./modules/ManageMobLibrary/ManageMobLib.js";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";
import UpdateSKU from "./modules/ManageMobLibrary/UpdateSKU.js";
import DeleteSKU from "./modules/ManageMobLibrary/DeleteSKU.js";
import EditMobLibProduct from "./modules/ManageMobLibrary/EditMobLib/EditMobLibProduct.js";
import UpdatePricing from "./modules/ManageMobSeller/UpdatePricing/UpdatePricing.js";
import ExistingSeller from "./modules/ManageMobSeller/ExistingSeller/ExistingSeller.js";
import EditMobSeller from "./modules/ManageMobSeller/EditMobSeller/EditMobSeller.js";
import SellerInventoryAndPrice from "./modules/ManageMobSeller/SellerInventoryAndPrice/SellerInventoryAndPrice.js";
import SellerMobLib from "./modules/ManageMobSeller/SellerMobLib/SellerMobLib.js";
import ExistingOrders from "./modules/ManageOrders/ExistingOrders/ExistingOrders.js";
import OrderDetails from "./modules/ManageOrders/OrderDetails/OrderDetails.js";
import ViewDetails from "./modules/ManageOrders/OrderDetails/ViewDetails/ViewDetails.js";
import CreateOrder from "./modules/ManageOrders/CreateOrder/CreateOrder.js";
import Settlement from "./modules/Settlement/Settlement.js";
import PendingSettlement from "./modules/Settlement/PendingSettlement.js";
import Sellers from "./modules/ManageMobSeller/Sellers/Sellers.js";
import SellersView from "./modules/ManageMobSeller/SellersView/SellersView.js";
import {AddNewSeller} from "./modules/ManageMobSeller/Sellers/AddNewSeller/AddNewSeller.js";
import Customer from "./modules/Customer/Customer.js";
import CustomerDetails from "./modules/Customer/CustomerDetails/CustomerDetails.js";
import CustomerWallet from "./modules/Customer/CustomerWallet/CustomerWallet.js";
import WalletBankTransfer from "./modules/Customer/WalletBankTransfer/WalletBankTransfer.js";
import WalletbankTransferView from "./modules/Customer/WalletbankTransferView/WalletbankTransferView.js";
import Page404 from "./modules/NotFoundPage/Page404.js";
// import CustomerOrders from "./modules/Customer/CustomerOrders/CustomerOrders.js";
import CategorySalesReport from "./modules/Reports/CategorySalesReport/CategorySalesReport.js";
import InvoiceReport from "./modules/Reports/InvoiceReport/InvoiceReport.js";
import SalesReport from "./modules/Reports/SalesReport/SalesReport.js";
import SellerSalesReport from "./modules/Reports/SellerSalesReport/SellerSalesReport.js";
import ReportCustomerDetails from "./modules/Reports/CustomerDetails/ReportCustomerDetails.js";
import SettlementReport from "./modules/Reports/SettlementReport/SettlementReport.js";
import OrderReport from "./modules/Reports/OrderReports/OrderReport.js";
import CancelledReport from "./modules/Reports/CancelledReport/CancelledReport.js";
import Categories from "./modules/Categories/Categories.js";
import SubCategories from "./modules/Categories/SubCategories/SubCategories.js";
import SubSubCategories from "./modules/Categories/SubSubCategories/SubSubCategories.js";
import CustomerOrders from "./modules/Customer/CustomerOrders/CustomerOrders.js";
import {CustomerAddress} from "./modules/Customer/CustomerAddress/CustomerAddress.js";
import CustomerQuotation from "./modules/Customer/CustomerQuotation/CustomerQuotation.js";
import PersonalInfo from "./modules/Customer/PersonalInfo/PersonalInfo.js";
import ManageState from "./modules/Manage/ManageState/ManageState.js";
import PasswordChange from "./modules/passwordChange/PasswordChange.js";
import BecomeSeller from "./modules/forms/BecomeSeller/BecomeSeller.js";
import LineOfCredits from "./modules/forms/LineOfCredits/LineOfCredits.js";
import CustomerSubOrderDetails from "./modules/Customer/CustomerSubOrderDetails/CustomerSubOrderDetails.js";
import MobCreditView from "./modules/Customer/MobCreditView/MobCreditView.js";
import MobCreditReport from "./modules/Reports/MobCreditReport/MobCreditReport.js";
import MobPROReport from "./modules/Reports/MobPROReport/MobPROReport.js";

import ServiceRequestReport from "./modules/Reports/ServiceRequestReport/ServiceRequestReport.js";
import ServiceRequest from "./modules/Customer/ServiceRequest/ServiceRequest.js";
import MobCreditSubscriptionReport from "./modules/Reports/mobCREDITsubscriptionReport/MobCreditSubscriptionReport.js";

const generateBreakpoint = (min, max) =>
  `@media screen and (min-width: ${min}px) and (max-width: ${max}px)`;

const theme = {
  breakpoints: {
    xs: generateBreakpoint(0, 550),
    sm: generateBreakpoint(551, 850),
    md: generateBreakpoint(851, 1150),
    lg: generateBreakpoint(1151, 1500),
  },
  light: {
    background: "#ffffff",
    textColor: "#333333",
    buttonColor: "#ff9900",
    fontFamily: "Arial, sans-serif",
    fontWeight: "normal",
  },
  dark: {
    background: "#333333",
    textColor: "#ffffff",
    buttonColor: "#007bff",
    fontFamily: "Arial, sans-serif",
    fontWeight: "normal",
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <NotificationToast />
        <Router>
          <ScrollToTop />
          {/* Generic route for 404 page */}
          <Layout style={{minHeight: "100vh"}}>
            <Routes>
              <Route path="*" element={<Page404 />} />
              {/* Public Routes */}
              {["/", "login"].map((path) => (
                <Route exact path={path} element={<LogIn />} />
              ))}
              
              {/* Private Routes */}

              {/* DASHBOARD ROUTES */}
              <Route
                path="/admin/dashboard"
                exact
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />

              {/* RFQ ROUTES */}
              <Route
                exact
                element={
                  <PrivateRoute>
                    <RFQList />
                  </PrivateRoute>
                }
                path="/admin/rfq"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <RFQDetails />
                  </PrivateRoute>
                }
                path="/admin/rfq/quotes/:id?"
              />

              {/* <Route
                exact
                element={
                  <PrivateRoute>
                    <RFQDetails />
                  </PrivateRoute>
                }
                path="/admin/rfq/quotes"
              /> */}

              {[
                "/admin/rfq/quotes/create-quote/:rfq_id?",
                "/admin/rfq/quotes/update-quote/:rfq_id?/:quote_id?",
              ].map((path) => (
                <Route
                  exact
                  path={path}
                  element={
                    <PrivateRoute>
                      <CreateQuote />
                    </PrivateRoute>
                  }
                />
              ))}
              {/* <Route
                path="/admin/rfq/quotes/createQuote"
                exact
                element={
                  <PrivateRoute>
                    <CreateQuote />
                  </PrivateRoute>
                }
              /> */}

              {/* LIBRARY ROUTES */}
              <Route
                exact
                element={
                  <PrivateRoute>
                    <ManageMobLib />
                  </PrivateRoute>
                }
                path="/admin/products"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <EditMobLibProduct />
                  </PrivateRoute>
                }
                path="/admin/products/update-product/:id?"
              />
              {/* <Route
                  exact
                  element={
                    <PrivateRoute>
                      <EditMobLibProduct />
                    </PrivateRoute>
                  }
                  path="/admin/products/edit"
                /> */}

              <Route
                exact
                element={
                  <PrivateRoute>
                    <UploadImage />
                  </PrivateRoute>
                }
                path="/admin/products/upload-images"
              />

              <Route
                exact
                element={
                  <PrivateRoute>
                    <UploadSKU />
                  </PrivateRoute>
                }
                path="/admin/products/upload-sku"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <UpdateSKU />
                  </PrivateRoute>
                }
                path="/admin/products/update-sku"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <DeleteSKU />
                  </PrivateRoute>
                }
                path="/admin/products/delete-sku"
              />

              {/* Seller routes */}
              <Route
                path="/admin/sellers"
                exact
                element={
                  <PrivateRoute>
                    <Sellers />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/sellers/seller-view/:seller_id?"
                exact
                element={
                  <PrivateRoute>
                    <SellersView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/sellers/add-seller/:seller_id?"
                exact
                element={
                  <PrivateRoute>
                    <AddNewSeller />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <UpdatePricing />
                  </PrivateRoute>
                }
                path="/admin/sellers/update-pricing/:seller_id?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <ExistingSeller />
                  </PrivateRoute>
                }
                path="/admin/sellers/catalogue/:seller_id?"
              />

              <Route
                exact
                element={
                  <PrivateRoute>
                    <SellerInventoryAndPrice />
                  </PrivateRoute>
                }
                path="/admin/sellers/inventory-and-price/:seller_id?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <EditMobSeller />
                  </PrivateRoute>
                }
                path="/admin/sellers/update-seller/:id?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <SellerMobLib />
                  </PrivateRoute>
                }
                path="/admin/sellers/mob-library/:seller_id?"
              />

              {/* Order routes */}
              <Route
                exact
                element={
                  <PrivateRoute>
                    <ExistingOrders />
                  </PrivateRoute>
                }
                path="/admin/orders"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <OrderDetails />
                  </PrivateRoute>
                }
                path="/admin/orders/order-details/:order_id?"
              />
              {/* <Route
                exact
                element={
                  <PrivateRoute>
                    <OrderDetails />
                  </PrivateRoute>
                }
                path="/admin/orders/orderDetails"
              /> */}
              <Route
                exact
                element={
                  <PrivateRoute>
                    <ViewDetails />
                  </PrivateRoute>
                }
                path="/admin/orders/view-details/:id?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <Customer />
                  </PrivateRoute>
                }
                path="/admin/customer/customer-crm"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <CustomerDetails />
                  </PrivateRoute>
                }
                path="/admin/customer/customerDetails/:id?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <CustomerAddress />
                  </PrivateRoute>
                }
                path="/admin/customer/customerDetails/CustomerAddress/:seller_id?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <CustomerQuotation />
                  </PrivateRoute>
                }
                path="/admin/customer/customerDetails/customerQuotation/:phone_number?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <PersonalInfo />
                  </PrivateRoute>
                }
                path="/admin/customer/customerDetails/personalInfo"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <CustomerWallet />
                  </PrivateRoute>
                }
                path="/admin/customer/customerDetails/wallet/:id?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <ServiceRequest />
                  </PrivateRoute>
                }
                path="/admin/customer/customerDetails/ServiceRequest/:phone_number?"
              />

              {/* REPORTS */}
              <Route
                exact
                element={
                  <PrivateRoute>
                    <CategorySalesReport />
                  </PrivateRoute>
                }
                path="/admin/report/categorySalesReport"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <MobCreditReport />
                  </PrivateRoute>
                }
                path="/admin/report/mobCreditsReport"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <MobPROReport />
                  </PrivateRoute>
                }
                path="/admin/report/mobPROReport"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <InvoiceReport />
                  </PrivateRoute>
                }
                path="/admin/report/invoiceReport"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <SalesReport />
                  </PrivateRoute>
                }
                path="/admin/report/salesReport"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <SellerSalesReport />
                  </PrivateRoute>
                }
                path="/admin/report/sellerSalesReport"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <ReportCustomerDetails />
                  </PrivateRoute>
                }
                path="/admin/report/customerDetails"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <SettlementReport />
                  </PrivateRoute>
                }
                path="/admin/report/settlementReport"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <OrderReport />
                  </PrivateRoute>
                }
                path="/admin/report/orderReport"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <CancelledReport />
                  </PrivateRoute>
                }
                path="/admin/report/cancelledReport"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <Categories />
                  </PrivateRoute>
                }
                path="/admin/categories"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <SubCategories />
                  </PrivateRoute>
                }
                path="/admin/categories/:id?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <SubSubCategories />
                  </PrivateRoute>
                }
                path="/admin/categories/:id?/:idI?"
              />

              <Route
                exact
                element={
                  <PrivateRoute>
                    <PendingSettlement />
                  </PrivateRoute>
                }
                path="/admin/pending-settlement"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <WalletBankTransfer />
                  </PrivateRoute>
                }
                path="/admin/customer/walletbankTransfer"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <MobCreditView />
                  </PrivateRoute>
                }
                path="/admin/customer/mobCredit/:phone_number?"
              />

              <Route
                exact
                element={<PrivateRoute>{<CustomerOrders />}</PrivateRoute>}
                path="/admin/customer/customerDetails/customerOrders/:phone_number?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>{<CustomerSubOrderDetails />}</PrivateRoute>
                }
                path="/admin/customer/customer-details/customer-orders/customer-sub-order-details/:order_id?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <Settlement />
                  </PrivateRoute>
                }
                path="/admin/settlement/:bmp_id?"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <WalletbankTransferView />
                  </PrivateRoute>
                }
                path="/admin/customer/walletbankTransferView/:id?"
              />
              {[
                "/admin/orders/create-order",
                "/admin/orders/update-order/:order_id?/:quote_id?",
              ].map((path) => (
                <Route
                  exact
                  path={path}
                  element={
                    <PrivateRoute>
                      <CreateOrder />
                    </PrivateRoute>
                  }
                />
              ))}
              {/* <Route
                exact
                element={
                  <PrivateRoute>
                    <CreateOrder />
                  </PrivateRoute>
                }
                path="/admin/orders/create-order"
              /> */}
              <Route
                exact
                element={
                  <PrivateRoute>
                    <ManageState />
                  </PrivateRoute>
                }
                path="/admin/manageState"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <PasswordChange />
                  </PrivateRoute>
                }
                path="/admin/changePassword"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <BecomeSeller />
                  </PrivateRoute>
                }
                path="/admin/forms/becomeSeller"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                    <LineOfCredits />
                  </PrivateRoute>
                }
                path="/admin/forms/lineOfCredits"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                   <ServiceRequestReport />
                  </PrivateRoute>
                }
                path="/admin/report/service-request-report"
              />
              <Route
                exact
                element={
                  <PrivateRoute>
                   <MobCreditSubscriptionReport />
                  </PrivateRoute>
                }
                path="/admin/report/mobCREDITsubscription"
              />
             
            </Routes>
          </Layout>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;

import React from 'react'
import { ReactComponent as VehicleIcon } from "../../../../../assets/icons/noVehicle.svg";
import "./NoVehicleAssigned.css";
import { Button, Col, Typography } from 'antd';
const {Text}=Typography
const NoVehicleAssigned = ({handleAddVehicleModal}) => {
  return (
    <Col className="add-vehicle">
    <Col className="noVehicle-align">
      <VehicleIcon />
      <Text className="noVehicle-text">No vehicle assigned</Text>
      <Button className="detail-btn" onClick={handleAddVehicleModal}>ADD VEHICLE</Button>
    </Col>
  </Col>
  )
}

export default NoVehicleAssigned

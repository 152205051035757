import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";

export const userManagementAPI = createApi({
  reducerPath: "userManagementAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    changePassword: builder.mutation({
      query: (params) => ({
        url: "/api/accounts/dashboard/change_password/",
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const { useChangePasswordMutation } = userManagementAPI;

export default userManagementAPI;

import React from "react";
import "../contentcard.css";
import { Col, Typography } from "antd";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-right.svg";

export const RejectedBySeller = (props) => {
  const { data } = props;
  const { Text } = Typography;

  return (
    <>
      <Col className="rejected-card-container">
        {data?.map((val, idx) => (
          <Col className="rejected-card-data-container" key={idx}>
            <Col className="rejected-card-data-sub-container">
              <Col className="rejected-card-data-sub-id-container">
                <Text className="rejected-card-data-sub-id-text">{`Sub ID: ${val.id}`}</Text>
                <Text className="rejected-card-data-sub-name-text">
                  {val.name}
                </Text>
              </Col>
              <Col className="rejected-card-amount-container">
                <Col className="rejected-card-amount-sub-container">
                  <Text className="rejected-card-amount-sub-text">{`₹ ${val.amount}`}</Text>
                  <Text className="rejected-card-amount-sub-text-item">{`${val.item} items`}</Text>
                </Col>
                <Col className="rejected-card-status-container">
                  <Col className="rejected-card-status-sub-container">
                    {val.status}
                  </Col>
                  <ArrowIcon />
                </Col>
              </Col>
            </Col>
          </Col>
        ))}
      </Col>
    </>
  );
};

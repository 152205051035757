import React, {useEffect, useState} from "react";
import "./CustomerDetails.css";
import {Button, Col, Row, Typography} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useParams} from "react-router-dom";
// import { ReactComponent as CartIcon } from "../../../assets/icons/shopping-cart.svg";
import {ReactComponent as OrderIcon} from "../../../assets/icons/orders.svg";
import {ReactComponent as ProjectIcon} from "../../../assets/icons/project.svg";
// import { ReactComponent as WishlistIcon } from "../../../assets/icons/wishlist.svg";
import {ReactComponent as UserIcon} from "../../../assets/icons/user.svg";
import {ReactComponent as AddressIcon} from "../../../assets/icons/address.svg";
import {ReactComponent as WalletIcon} from "../../../assets/icons/wallet.svg";
import {ReactComponent as ReferIcon} from "../../../assets/icons/refer.svg";
import {ReactComponent as ServiceRequestIcon} from "../../../assets/icons/servicerequest.svg";
import CustomProfileSection from "../../../components/CustomContainer/CustomProFileSection/CustomProfileSection";
import CustomerCard from "./CustomerCard/CustomerCard";
import {
  useLazyGetCustomerDetailQuery,
  useUpdateCustomerMutation,
  useUpdateProfessionalCustomerMutation,
} from "../../../apis/customer";
import {useWalletToBankTransferMutation} from "../../../apis/wallet";
import WalletToBankModal from "../WalletToBankModal/WalletToBankModal";
import ProCustomerActiveInactiveModal from "../../../components/Modal/ProCustomerActiveInactive/ProCustomerActiveInactive";
import {setSelectedStatus} from "../../../redux/slices/componentState/action";
import {useDispatch} from "react-redux";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
const {Text} = Typography;
const CustomerDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActiveOpen, setIsActiveModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [customerDetailApi, {data: customerDetails}] =
    useLazyGetCustomerDetailQuery();
  const [updateCustomerApi, {isSuccess: updateCustomerIsSuccess}] =
    useUpdateCustomerMutation();
  const [updateProProfessionalApi, {isSuccess: isSuccessUpdatePro}] =
    useUpdateProfessionalCustomerMutation();
  const [walletToBankTransferApi] = useWalletToBankTransferMutation();
  const handleBackNavigate = () => {
    if (location?.state?.id) {
      navigate(location?.state?.id);
    } else {
      navigate(`/admin/customer/customer-crm`);
    }
  };
  const handleUpdateProApi = (data, index) => {
    setIsActiveModalOpen(true);
    setIsActive(data === "PRO Active" ? true : false);
  };
  const handleActiveInactive = async () => {
    const response = await updateProProfessionalApi({
      customer_id: customerDetails?.data?.id,
      is_professional: isActive,
    });
    if (response?.data?.status) {
      dispatch(setSelectedStatus(isActive ? "PRO Active" : "PRO Inactive"));
      setIsActiveModalOpen(false);
      showSuccessToast(response?.data?.message);
    } else {
      showErrorToast(response?.error?.data?.message);
      setIsActiveModalOpen(false);
    }
  };
  // const WalletClick = () => {
  //   navigate(`/admin/customer/customerDetails/wallet`);
  // };
  const handleWallet = () => {
    setIsModalOpen(true);
  };
  const handleCloseActiveModal = () => {
    setIsActiveModalOpen(false);
  };
  useEffect(() => {
    if (param) {
      customerDetailApi(param?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param, isSuccessUpdatePro, updateCustomerIsSuccess]);

  return (
    <Col className="main-background">
      <Col className="customerDetail-backGround"></Col>
      <Col className="customerDetail-container">
        <Button
          className="btn"
          icon={<ArrowLeftOutlined />}
          onClick={handleBackNavigate}>
          BACK
        </Button>
        <CustomProfileSection
          flag={true}
          isLoading={""}
          //   editCondition={editSubOrderData?.user_details?.full_name}
          data={customerDetails?.data}
          check="customerDetail"
          topVipBanner={true}
          vipMemberCheck={false}
          proCheck={true}
          handleProApi={handleUpdateProApi}
          createCustomerApi={updateCustomerApi}
        />

        <Col
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Text className="customerDetails-styledText" onClick={handleWallet}>
            Add wallet money to customer bank account
          </Text>
          {/* <Col style={{ display: "flex", gap: "1rem" }}>
            <Button
              className="btn"
              style={{ display: "flex", alignItems: "center", fontWeight: 500 }}
              icon={<CartIcon />}
            >
              11
            </Button>
            <Button className="customerDetails-card-btn" type="primary">
              ADD TO CART
            </Button>
          </Col> */}
        </Col>
        <Row style={{display: "flex", gap: "18px"}}>
          <CustomerCard
            icon={<OrderIcon />}
            title={"Orders"}
            totalOrders={customerDetails?.data?.total_orders}
            handleCardClick={`/admin/customer/customerDetails/customerOrders/${param?.id}`}
          />
          {/* <CustomerCard icon={<ProjectIcon />} title={"Projects"} /> */}
          <CustomerCard
            icon={<ProjectIcon />}
            title={"Quotation request"}
            handleCardClick={`/admin/customer/customerDetails/customerQuotation/${param?.id}`}
          />
          {/* <CustomerCard icon={<WishlistIcon />} title={"Wishlist"} /> */}
          <CustomerCard
            icon={<UserIcon />}
            title={"Personal info"}
            handleCardClick={`/admin/customer/customerDetails/personalInfo`}
          />
          <CustomerCard
            icon={<AddressIcon />}
            title={"Address"}
            handleCardClick={`/admin/customer/customerDetails/customerAddress/${param?.id}`}
          />
          <CustomerCard
            icon={<WalletIcon />}
            title={"mobWALLET"}
            // handleClick={WalletClick}
            handleCardClick={`/admin/customer/customerDetails/wallet/${param?.id}`}
          />
          {/* <CustomerCard icon={<WalletIcon />} title={"GST details"} /> */}
          {/* <CustomerCard icon={<ReferIcon />} title={"Refer and earn"} /> */}
          {["ACTIVE", "INACTIVE"].includes(
            customerDetails?.data?.ruipi?.account_status
          ) && (
            <CustomerCard
              icon={<ReferIcon />}
              title={"mobCREDIT"}
              handleCardClick={`/admin/customer/mobCredit/${param?.id}`}
              state={customerDetails?.data?.ruipi}
            />

            
          )}
           <CustomerCard className="INACTIVE"
            icon={<ServiceRequestIcon />}
            title={"Service request"}
            // handleClick={WalletClick}
            handleCardClick={`/admin/customer/customerDetails/ServiceRequest/${param?.id}`}
          />
        </Row>
      </Col>
      <WalletToBankModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        walletToBankTransferApi={walletToBankTransferApi}
        allDetails={customerDetails?.data}
        id={customerDetails?.data?.id}
      />
      <ProCustomerActiveInactiveModal
        open={isActiveOpen}
        handleOk={handleCloseActiveModal}
        isActive={isActive}
        handleSave={handleActiveInactive}
      />
    </Col>
  );
};

export default CustomerDetails;

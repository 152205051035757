import React, { useEffect, useState } from "react";
import { Col, Table, Tag, Typography } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
import { DownArrow } from "../../components/Icons/DownArrow";
import { UpArrow } from "../../components/Icons/UpArrow";
import "./DashboardTable.css";
import { SubOrderComponent } from "./SubOrderComponent";
import {
  dateFormat,
  statusColorMapping,
} from "../../commonFuntions/CommonUtilFunctions";
import { Link, useNavigate } from "react-router-dom";
import NoDataFound from "../../components/EmptyTable/NoDataFound";

const handleTableData = (data) => {
  let tableData = [];
  data?.map((item) => {
    tableData.push({
      id: item?.id,
      key: item?.order_id,
      order_id: item?.order_id,
      created_at: item?.created_at,
      name: item?.name,
      mobile_number: item?.mobile_number,
      order_status: item?.order_status,
      total: item?.total,
      email: item?.email || "",
      rfq_id: item?.rfq_details?.rfq_id,
      is_delayed: item?.is_delayed,
      sub_orders: item?.sub_orders?.map((element) => {
        const obj = {
          subId: element?.suborder_id,
          itemName: element?.vendor_name,
          subStatus: element?.status,
          bmp_id: element?.bmp_id,
          subTotal: Number(element?.total).toFixed(2),
          is_delayed: element?.is_delayed,
        };
        return obj;
      }),
    });
    return null;
  });
  //tableData.sort((a, b) => (a.is_delayed === b.is_delayed) ? 0 : (a.is_delayed ? -1 : 1));
  tableData.sort((a, b) => {
    if (a.is_delayed && !b.is_delayed) return -1;
    if (!a.is_delayed && b.is_delayed) return 1;
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateA.getTime() - dateB.getTime();
  });
  return tableData;
};
const DashBoardTable = ({ orderData, isFetching }) => {
  const { Text } = Typography;
  const [tableDataSource, setTableDataSource] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (orderData) {
      const data = orderData ? handleTableData(orderData) : [];
      setTableDataSource(data);
    }
    // if (orderData) {
  
    //   const data = orderData.map((row) => {        
    //     const rowStyle = {};
    //     if (row?.is_delayed) {
    //       rowStyle.background = "red"; // or any other color
    //     }
    //     return {
    //       ...row,
    //       className: rowStyle.background ? "highlight-row" : "",
    //     };
    //   });
    //   console.log("data",data)
    //   setTableDataSource(data);
    // }
  }, [orderData]);

  const handleOrderNavigate = () => {
    navigate("/admin/orders");
  };

  const defaultExpandable = {
    expandedRowRender: (record) => {
      return record?.sub_orders?.map((obj) => (
        <SubOrderComponent record={obj} />
      ));
    },
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <UpArrow className={"cursor"} onClick={(e) => onExpand(record, e)} />
      ) : (
        <DownArrow className={"cursor"} onClick={(e) => onExpand(record, e)} />
      ),
  };
  // const handleDetailNav = (record) => {
  //   navigate(`/admin/orders/order-details/${record?.id}`, {
  //     state: { id: record?.id },
  //   });
  // };
  const columns = [
    {
      fixed: "left",
      flex: 1,
      title: "Order ID",
      key: 1,
      dataIndex: "order_id",
      sorter: (a, b) => a.order_id.localeCompare(b.order_id),
      width: "250px",
      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleDetailNav(record)}
            to={`/admin/orders/order-details/${record?.id}`}
            target="_blank"
            className="dashboard-table-orderid"
          >
            {params}
          </Link>
        );
      },
    },
    {
      flex: 1,
      title: "Date",
      key: 2,
      dataIndex: "created_at",
      width: "200px",
      render: (params, record) => {
        return <Text className="">{dateFormat(params)}</Text>;
      },
    },
    {
      flex: 1,
      title: "Name",
      key: 3,
      dataIndex: "name",
      width: "200px",
    },
    {
      flex: 1,
      title: "Business mobile",
      key: 4,
      dataIndex: "mobile_number",
      width: "150px",
    },
    // {
    //   flex: 1,
    //   title: "Email",
    //   key: 5,
    //   dataIndex: "email",
    //   width: "300px",
    // },
    {
      flex: 1,
      title: "Status",
      dataIndex: "order_status",
      key: 6,
      width: "120px",
      render: (params, record) => {
        return (
          <>
            {params && (
              <Tag className={`subDataStatus  ${statusColorMapping[params]}`}>
                {params}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      flex: 1,
      key: 7,
      width: "150px",
      title: (
        <>
          <Text>{"Total"}</Text>
          {/* <PlusOutlined
            style={{
              backgroundColor: "#fff",
              borderRadius: "50%",
              padding: "3px",
              marginLeft: "1rem",
            }}
          /> */}
        </>
      ),
      dataIndex: "total",
      align: "center",
      render: (params) => {
        return <Text>{`₹ ${params}`}</Text>;
      },
    },
  ];

  return (
    <Col className="dashboard-table-container">
      <Table
        showSorterTooltip={false}
        className="dashboard-table"
        expandable={defaultExpandable}
        columns={columns}
        dataSource={tableDataSource}
        title={() => (
          <Col className="dashboard-table-title-container">
            <Text className="dashboard-table-title-text-container">{`${tableDataSource?.length} New Orders`}</Text>
            <Text
              className="dashboard-table-title-text-view-container"
              onClick={handleOrderNavigate}
            >{`View All`}</Text>
          </Col>
        )}
        locale={{
          emptyText: <NoDataFound text={"No Order Found!"} />,
        }}
        pagination={false}
        scroll={{ y: 300, x: 100 }}
        rowClassName={(record, index) => {
         
          return record.is_delayed? 'highlight-row' : '';
        }}
      />
    </Col>
  );
};

export default DashBoardTable;

import React from "react";
import { Drawer, Steps, Typography, Col, Avatar, Collapse } from "antd";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/x (3).svg";
import { ReactComponent as DownArrowIcon } from "../../../../assets/icons/DownArrow.svg";
import { ReactComponent as UpArrowIcon } from "../../../../assets/icons/UpArrow.svg";
import "./ActivityLogSidebar.css";
import {
  dateFormat,
  getNameInitials,
} from "../../../../commonFuntions/CommonUtilFunctions";
const { Title, Text } = Typography;
const { Step } = Steps;
const { Panel } = Collapse;

const PanelHeader = (prop) => {
  const { data } = prop;
  return (
    <>
      <Col className="panel-header-container">
        <Avatar src={data?.profile_image || ""} size={36}>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "36px",
            }}
          >
            <Text
              style={{
                color: "black",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {data?.user ? getNameInitials(data?.user) : `N/A`}
            </Text>
          </Col>
        </Avatar>
        <Col>
          <Text className="panel-date">
            {data?.created_at && dateFormat(data?.created_at)}
          </Text>
          <Col className="panel-name-container">
            <Text className="panel-name">{data?.user || "N/A"}</Text>
            <Text className="panel-edited">edited items</Text>
          </Col>
        </Col>
      </Col>
    </>
  );
};
const PanelContent = (props) => {
  const { data } = props;
  return (
    <>
      <Col className="panel-content-container">
        {data?.map((element) => {
          return (
            <>
              <Col>
                <Text className="panel-content-text">{element?.activity}</Text>
              </Col>
            </>
          );
        })}
      </Col>
    </>
  );
};
const ActivityLogSidebar = ({ drawerVisible, setDrawerVisible, data }) => {
  const closeDrawer = () => {
    setDrawerVisible(false);
  };
  const CustomDot = () => (
    <Col
      style={{
        width: 8,
        height: 8,
        borderRadius: "50%",
        background: "#01a685",
        display: "block",
      }}
    />
  );
  return (
    <>
      <Drawer
        className="activity-drawer-container"
        title={
          <Col style={{ display: "flex", alignItems: "center", gap: "20%" }}>
            <Col
              onClick={closeDrawer}
              style={{
                width: "48px",
                height: "48px",
                padding: "16px",
                borderRadius: " 24px",
                backgroundColor: " #e8e8e8",
                cursor: "pointer",
              }}
            >
              <CrossIcon />
            </Col>
            <Title level={4} style={{ margin: 0 }}>
              Activity Log
            </Title>
          </Col>
        }
        placement="right"
        width={500}
        closable={false}
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        <Steps
          className="steps-wrapper"
          direction="vertical"
          current={data?.length}
          progressDot={<CustomDot />}
        >
          {data?.map((ele, idx) => (
            <Step
              style={{ position: "relative" }}
              key={idx}
              className="steps-sub-wrapper"
              title={
                <>
                  <Collapse
                    className="activity-collapse"
                    ghost
                    expandIconPosition={"end"}
                    expandIcon={({ isActive }) =>
                      isActive ? <UpArrowIcon /> : <DownArrowIcon />
                    }
                  >
                    <Panel header={<PanelHeader data={ele} />} key={idx}>
                      <PanelContent data={ele?.activites} />
                    </Panel>
                  </Collapse>
                </>
              }
            />
          ))}
        </Steps>
      </Drawer>
    </>
  );
};
export default ActivityLogSidebar;

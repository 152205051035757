import { Button, Card, Checkbox, Col, Row, Tag, Typography } from "antd";
import styled from "styled-components";

const { Text, Title } = Typography;

export const ProfileBackground = styled(Col)`
  background-image: linear-gradient(to bottom, #bddbdf, rgba(209, 230, 231, 0));
  padding: 24px;
`;
export const ProfileContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-bottom: 48px;
`;

export const QuoteHeader = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActivityContainer = styled(Text)`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

export const ActivityText = styled(Title)`
  margin: 0 !important;
  color: #0a243f;
  text-decoration: underline;
`;

export const CancelButton = styled(Button)`
  height: 48px;
  padding: 13px 40px;
  font-weight: 500;
  color: #0a243f;
`;

export const QuoteButton = styled(Button)`
  height: 48px;
  padding: 13px 40px;
  font-weight: 500;
  background-color: #0a243f;
  &:hover {
    background-color: #0a243f !important;
    color: #fff !important;
  }
`;

export const QuotationContainer = styled(Col)`
  width: 100%;
  height: 102px;
  padding: 24px;
  border-radius: 16px;
  background-color: #f8e6b6;
  display: flex;
  justify-content: space-between;
`;

export const QuotationText = styled(Col)`
  width: 394px;
  height: 54px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: #0a243f;
`;

export const QuotationButton = styled(Button)`
  width: 260px;
  height: 48px;
  padding: 10px 59px 10px 58px;
  background-color: ${(props) =>
    props.disabled ? "#9da7b2 !important" : "#01a685 !important"};
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    color: #fff !important;
  }
`;

export const UserCardContainer = styled(Card)`
  width: 100%;
  position: relative;
`;

export const DiamondMemberText = styled(Text)`
  font-weight: 500;
  background-color: #9feafe;
  padding: 3px 8px;
  border-radius: 8px;
  position: absolute;
  bottom: -3px;
  left: -24px;
  color: #0a243f;
`;

export const UserDetailContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const UserNameText = styled(Title)`
  margin: 0 !important;
  color: #0a243f !important;
`;

export const UserDetails = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const UserDetailHeading = styled(Text)`
  color: #6c7c8c;
`;

export const UserDetailText = styled(Text)`
  font-weight: 500;
  color: #0a243f;
`;

export const AddressContainer = styled(Col)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const AddressCard = styled(Card)`
  width: 49%;
`;

export const CardContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CardHeader = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardHeaderText = styled(Title)`
  margin: 0 !important;
  color: #0a243f !important;
`;

export const CardEdit = styled(Text)`
  font-weight: 500;
  color: #2973f0;
  cursor: pointer;
`;

export const CardContent = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const ContentText = styled(Text)`
  display: block;
  line-height: 1.5;
  font-size: 16px;
  color: #0a243f;
`;

export const GST_TEXT = styled(Text)`
  font-weight: 500;
  background-color: #133b62;
  padding: 8px 16px;
  border-radius: 8px;
  color: #fff;
`;

export const CardCheckbox = styled(Checkbox)`
  color: #0a243f;
  font-size: 14px;
  font-weight: normal;
`;

export const RowContainer = styled(Row)`
  flex-direction: column;
  gap: 30px;
`;

export const RFQHeading = styled(Title)`
  margin: 0 !important;
  color: #0a243f !important;
`;

export const RFQMainContainer = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RFQDetailContainer = styled(Col)`
  display: flex;
  gap: 30px;
`;

export const RFQID = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: #0a243f;
`;

export const RFQDate = styled(Text)`
  font-size: 16px;
  color: #0a243f;
`;

export const RFQFileContainer = styled(Col)`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const RFQFileText = styled(Text)`
  color: #2973f0;
  font-size: 16px;
`;

export const RFQTag = styled(Tag)`
  // width: 105px;
  height: 32px;
  padding: 4px 17px;
  font-weight: 500;
  font-size: 14px;
  margin: 0.7rem 0 0 1.5rem;
`;

import {format, parseISO} from "date-fns";
import dayjs from "dayjs";
import "../modules/Request/RFQ/RFQList.css";
import {baseUrl} from "../commonUtils/commonUtils";
export const colorMapping = {
  "Quote sent": "quotesent",
  "Quote accepted": "colorSuccess",
  "Quote Accepted": "colorSuccess",
  closed: "disabled",
  "Order created": "colorSuccess",
  "Order Created": "colorSuccess",
  "Order Converted": "colorSuccess",
  "SKU Request": "requested",
  Requested: "requested",
  Unpublished: "requested",
  Published: "colorSuccess",
  locally: "locally",
  "MOB library": "MOB_library",
  "TO BE SETTLED": "to_be_settled",
  SETTLED: "SETTLED",
  "In Progress": "ready_for_pickup",
  Resolved: "colorSuccess",
  Redundant: "cancelled",
};
export const statusColorMapping = {
  "Ready for pickup": "ready_for_pickup",
  "Ready for Pickup": "ready_for_pickup",
  Waiting: "waiting",
  Delivered: "delivered",
  Cancelled: "cancelled",
  "Rejected by seller": "rejected_by_seller",
  "Return request": "return_request",
  "Out for delivery": "out_for_delivery",
  "Order Created": "colorSuccess",
  "PRO Active": "colorSuccess",
  "PRO Inactive": "rejected_by_seller",
};
export const amountTypeColorMapping = {
  Debit: "debit_amount_style",
  Credit: "credit_amount_style",
};
export const dateFormat = (inputDate) => {
  console.log("🚀 ~ dateFormat ~ inputDate:", inputDate);
  //	2023-09-18T03:48:58Z use this format to convert dates
  if (!inputDate || inputDate === "None") return "Date is Invalid";
  const parsedDate1 = parseISO(inputDate);
  const formattedDate1 = format(parsedDate1, "dd MMM yyyy, hh:mmaaa");
  return formattedDate1;
};
export const onlyDateFormat = (inputDate) => {
  //	2023-09-18T03:48:58Z use this format to convert dates
  if (!inputDate || inputDate === "None") return "Date is Invalid";
  const parsedDate1 = parseISO(inputDate);
  const formattedDate1 = format(parsedDate1, "dd MMM yyyy");
  return formattedDate1;
};

export const bytesToSize = (bytes) => {
  const kilobytes = bytes / 1024;
  if (kilobytes < 1) {
    return bytes + "b";
  } else if (kilobytes < 1024) {
    return kilobytes.toFixed(2) + "kb";
  } else {
    const megabytes = kilobytes / 1024;
    return megabytes.toFixed(2) + "mb";
  }
};

export const dateFormatDDMMM = (inputDate) => {
  if (!inputDate) return "Date is Invalid";
  const formattedDate = dayjs(inputDate).format("DD MMM");
  return formattedDate;
};

export const getNameInitials = (fullName) => {
  if (fullName === "" || !fullName) return "No Name";
  const names = fullName?.split(" ");
  const firstNameInitial = names[0] ? names[0][0]?.toUpperCase() : "";
  const lastNameInitial = names[1] ? names[1][0]?.toUpperCase() : "";
  return `${firstNameInitial}${lastNameInitial}`;
};

export const totalPrice = (params) => {
  const afterTaxPrice = priceAfterTax(params);
  const {quantity, discount} = params;
  const totalValue =
    afterTaxPrice * (number(quantity) || 0) -
    number(discount || 0) * number(quantity || 0);
  return parseFloat(params.after_tax) < discount ? 0 : totalValue;
};

export const priceAfterTax = (params) => {
  const {gst, before_tax} = params;
  const newGst = number(gst || 0) / 100;
  const price = number(before_tax || 0) || 0;
  const newPrice = number(price * newGst);
  return number(price + newPrice);
};
export const number = (value) => parseFloat(value);

export const handlePdfDownload = (pdfURL, check) => {
  const pdfUrl = `${baseUrl}${pdfURL}`;
  if (check) {
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF: ", error);
      });
  } else {
    window.open(pdfUrl, "_blank");
  }
};

export const handlePublicFolderDownload = (url) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  // downloadLink.download = "Pricing-Sheet-Example.xlsx";

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const string_dot = (string, length = 0) => {
  if (string?.length <= length) {
    return string;
  } else {
    return `${string?.substring(0, length)}...`;
  }
};
export function formatDatePicker(dateString) {
  return dayjs(dateString).format("DD-MMM-YYYY") || "";
}
export function removeLastWord(string) {
  if (!string) {
    return "";
  }
  let words = string.split(" ");

  if (words.length > 1) {
    words.pop();
  }

  return words.join(" ");
}
export function isDisabledBillingAddress(addressList, currentAddress) {
  for (let i = 0; i < addressList.length; i++) {
    const address = addressList[i];
    if (
      address.address_type === "Billing" &&
      address.id !== currentAddress.id
    ) {
      return true;
    }
  }
  return false;
}
export function testFileUrl(url = "") {
  const containsFileExtension = /\.(pdf|xls|doc|docx|xlsx)$/.test(url);
  // const containsFileExtension = /\.(pdf|xls|doc)$/.test(url);
  return containsFileExtension;
}
export function extractFileFormat(url = "") {
  let fileFormat = "";
  let name = "";
  if (url) {
    const parts = url?.split("/");
    const fileName = parts?.[parts?.length - 1];
    const extensionParts = fileName?.split(".");
    fileFormat = extensionParts?.[extensionParts?.length - 1];
    name =
      extensionParts?.[extensionParts?.length - 2] +
      "." +
      extensionParts?.[extensionParts?.length - 1];
  }

  return {fileFormat: fileFormat, name: name};
}

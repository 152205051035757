import React, { useState } from "react";
// import "./Categories.css";
import { Avatar, Button, Col, Dropdown, Menu, Switch, Typography } from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {
  DashOutlined,
  // CloseOutlined,
  // CheckOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
// import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-right.svg";
import StatusModal from "../../../components/Modal/StatusModal/StatusModal";
import AddCategoriesModal from "../AddCategoriesModal/AddCategoriesModal";
import {
  //  Navigate,
  useNavigate,
  useParams,
} from "react-router";

// import dayjs from "dayjs";
const { Text } = Typography;
const options = [
  {
    key: 1,
    value: "Excel",
    label: "Excel",
  },
  {
    key: 2,
    value: "CSV",
    label: "CSV",
  },
  {
    key: 3,
    value: "PDF",
    label: "PDF",
  },
  {
    key: 4,
    value: "Print",
    label: "Print",
  },
];

// const dateOptions = [
//   {
//     key: 1,
//     value: "Today",
//     label: "Today",
//   },
//   {
//     key: 2,
//     value: "Yesterday",
//     label: "Yesterday",
//   },
//   {
//     key: 3,
//     value: "last_7_day",
//     label: "Last 7 day",
//   },
//   {
//     key: 4,
//     value: "last_30_day",
//     label: "Last 30 day",
//   },
//   {
//     key: 5,
//     value: "last_60_day",
//     label: "Last 60 day",
//   },
//   {
//     key: 6,
//     value: "Custom",
//     label: "Custom",
//   },
// ];
const tableDataSource = [
  {
    key: 1,
    category_name: "Deep primer and adhesive primer",
  },
  {
    key: 2,
    category_name: "Silicone and acrylic",
  },
  {
    key: 3,
    category_name: "Concrete and screed",
  },
];
const SubSubCategories = () => {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);
  const navigate = useNavigate();
  const param = useParams();
  const onChange = (checked) => {
    setShowStatusModal(true);
  };
  // const categoryModalOpen = () => {
  //   setOpenAddFile(true);
  // };
  const handleBack = () => {
    navigate(-1);
  };
  const columns = [
    {
      key: "icon",
      dataIndex: "icon",
      fixed: "left",
      width: "50px",
      render: (params, record) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  <>
                    <Menu.Item
                      //   onClick={() => onEdit(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">Edit</Text>
                      </Col>
                    </Menu.Item>
                    <Menu.Item
                      //   onClick={() => handleDeleteModalOpen(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">Delete</Text>
                      </Col>
                    </Menu.Item>
                  </>
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined
              //   onClick={() => _onRowClick(record)}
              />
            </Dropdown>
          </>
        );
      },
      // height: "60px",
    },
    {
      title: "Category name",
      dataIndex: "category_name",
      key: "category_name",
      fixed: "left",
      width: "250px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record.parentKey ? "60px" : "0px",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  //   src={`${apiBaseUrl}${record.profile_picture}`}
                  alt="Avatar"
                  icon={<DefaultIcon width={20} height={20} />}
                />
              </Col>
              <Col
                // onClick={() => handleUserDetailsNavigation(record)}
                style={{ fontWeight: 500, cursor: "pointer" }}
              >
                {record.category_name}
              </Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "Category Description",
      dataIndex: "category_description",
      key: "category_description",
      width: "250px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "150px",
      render: (params, record) => {
        return (
          <Col style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Text style={{ fontWeight: 500, fontSize: "16px" }}>Active</Text>
            <Switch defaultChecked onChange={onChange} />
          </Col>
        );
      },
    },
  ];

  return (
    <Col className="SalesReport-wrapper">
      <Button
        className="btn"
        style={{ maxWidth: "235px" }}
        icon={<ArrowLeftOutlined />}
        onClick={handleBack}
      >
        BACK TO SUBCATEGORY
      </Button>
      <Col>
        <Text className="heading">{param?.id}</Text>
        <Text
          style={{ fontWeight: 500 }}
        >{`${param?.id} > ${param?.idI} > Sub subcategory`}</Text>
      </Col>
      <Col className="categories-heading_wrapper">
        <Col style={{ display: "flex", gap: "1rem" }}>
          <SearchInput
            size={"large"}
            placeholder={"Search by customer name, mobile no, email"}
            //   getLibraryApi={getWalletTransferDetailsApi}
            width={"400px"}
            //   isLoading={isFetching}
          />

          <CustomSelect
            placeholder={"Download"}
            width={"100px"}
            height={"46px"}
            options={options}
            //   handleChange={handleChange}
            value={"EXPORT"}
          />
        </Col>
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        //   tableExpandable={defaultExpandable}
        tableDataSource={tableDataSource || []}
        // tableDataSource={getWalletData?.data?.results}
        tableColumns={columns}
        // locale={{
        //   emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        // }}
        // isLoading={getWalletData?.data?.results.length && isFetching}
        scroll={{ x: "100%" }}
        // currentPage={currentPage}
        // handlePageChange={handlePageChange}
        itemsPerPage={30}
        // totalEntries={
        //   !isFetching && getWalletData?.data?.pagination?.total_entries
        // }
      />
      <StatusModal
        open={showStatusModal}
        setOpen={setShowStatusModal}
        // handleOk={onActivate}
        // data={productTableData}
      />
      <AddCategoriesModal
        openAddFile={openAddFile}
        setOpenAddFile={setOpenAddFile}
        // handleUploadFile={handleUploadFile}
        title={"Add new sub subcategory"}
      />
    </Col>
  );
};

export default SubSubCategories;

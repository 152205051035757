import React from "react";
import CreateQuoteTable from "./CreateQuoteTable/CreateQuoteTable";
import { ProfileBackground } from "./CreateQuoteProfileSection/ProfileStyled";

const CreateQuote = () => {
  return (
    <>
      <ProfileBackground>
        <CreateQuoteTable />
      </ProfileBackground>
    </>
  );
};

export default CreateQuote;

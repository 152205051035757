import { Col, Typography } from "antd";
import React from "react";
import "./DashboardTable.css";

const StatusCard = ({ heading, value, status }) => {
  const { Text } = Typography;
  return (
    <Col className="status-card-container">
      <Text className="status-card-container-text">{heading}</Text>
      <Text className="status-card-container-text">{value}</Text>
      <Text className="status-card-container-text-color">{status}</Text>
    </Col>
  );
};

export default StatusCard;

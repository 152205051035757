import React, { useEffect, useState } from "react";
import "./ExistingOrders.css";
import { Button, Col, Row, Typography } from "antd";
import "./ExistingOrders.css";
// import TableSkeleton from "../../components/skeleton/TableSkeleton";
// import { useDispatch, useSelector } from "react-redux";
// import { getmanageOrdersTableData } from "../../redux/slices/manageOrders/action";
// import { manageOrdersDataSelector } from "../../redux/slices/manageOrders/selector";
import {
  dateFormat,
  handlePdfDownload,
  // handlePdfDownload,
} from "../../commonFuntions/CommonUtilFunctions";
import CustomSelect from "../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../components/CustomTableWithPagination/CustomTableWithPagination";
// import UploadPaymentModel from "../../components/Modal/SettlementModal/UploadPaymentModel";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import AddPaymentModal from "../../components/Modal/SettlementModal/AddPaymentModal/AddPaymentModal";
import ConfirmPaymentModal from "../../components/Modal/SettlementModal/AddPaymentModal/ConfirmPaymentModal";
import {
  useAddSettlementPaymentMutation,
  useLazyGetDownloadPdfPaymentQuery,
  useUpdateSettlementPaymentMutation,
} from "../../apis/settlement";
import {
  showErrorToast,
  showSuccessToast,
} from "../../NotificationToast/NotificationToast";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { baseUrl } from "../../commonUtils/commonUtils";
const { Text } = Typography;
const handleTableData = (data) => {
  let tableData = [];
  data?.map((item) => {
    tableData.push({
      ...item,
      id: item?.id,
      key: item?.id,
      date: item?.created_at,
      payment_ref_no: item?.payment_ref_no,
      settlement_amount: item?.settlement_amount,
    });
    return null;
  });
  return tableData;
};

const RecentSettlement = (props) => {
  const {
    data = [],
   
    vendor_id,
    bmp_id,
    getSettlementByIDAPI,
    seller_name,
    handleRefetch,
  } = props;
  
  // const dispatch = useDispatch();
  // const selectorData = useSelector(manageOrdersDataSelector);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateFilter, setDateFilter] = useState();
  const [editPayment, setEditPayment] = useState({
    state: false,
    check: true,
  });
  const [tableDataSource, setTableDataSource] = useState([]);
  const [addPaymentApi] = useAddSettlementPaymentMutation();
  const [updatePaymentApi] = useUpdateSettlementPaymentMutation();
  const [
    getDownloadPdfPaymentAPi,
    //  { data: pdfData }
  ] = useLazyGetDownloadPdfPaymentQuery();
  const [formData, setFormData] = useState({});
  const [datePickerEmpty, setDatePickerEmpty] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [paymentMode, setPaymentMode] = useState();
  const [inputDate, setInputDate] = useState("");

  useEffect(() => {
  
    if (data?.results?.length >= 0) {
     
      const tableData = data?.results ? handleTableData(data?.results) : "";
      setTableDataSource(tableData);
    }
    if (seller_name) {
      setFormData({ ...formData, seller_name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value === "clear_date") {
      setDateSearch({
        start_date: "",
        end_date: "",
        bmp_id: bmp_id,
      });
    } else {
      setDateSearch({
        start_date: formattedDate,
        end_date: currentDate,
        bmp_id: bmp_id,
      });
    }
    setDateFilter(value);
  };
  const handleSearchOnDate = () => {
    if (dateFilter === "clear_date") {
      getSettlementByIDAPI({ bmp_id: dateSearch?.bmp_id });
    } else {
      getSettlementByIDAPI(dateSearch);
    }
  };

  const handleBack = () => {
    setConfirmModal(false);
    setOpenUploadModal(true);
  };
  const handleViewSummary = (record) => {
    dayjs.extend(customParseFormat);
    const newDateValue = dayjs(record?.payment_date);
    // handleDateChange(newDateValue, inputDate);
    setFormData({
      seller_name: seller_name,
      settlement_amount: record?.settlement_amount,
      payment_mode: record?.payment_mode,
      date: record?.payment_date || "",
      ref_no: record?.payment_ref_no,
      remarks: record?.remarks,
      upload_receipt: [record?.receipt] || [],
      payment_id: record?.id,
    });
    setEditPayment({ check: true, state: true });
    setFileList([]);
    setPaymentMode(record?.payment_mode);
    setInputDate(newDateValue);
    setOpenUploadModal(true);
  };
  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      bmp_id: bmp_id      
    };
    setCurrentPage(currPage);
    await getSettlementByIDAPI(params);
  };
  // const handlePageChange = async (currPage) => {
  //   const params = {
  //     page: currPage,
  //   };
    
  //   setCurrentPage(currPage);
  // };

  const handleOk = () => {
    setOpenUploadModal(false);
    setConfirmModal(false);
    setPaymentMode("");
    setInputDate("");
    setFileList([]);
    // setDatePickerEmpty(false);
    setFormData({ seller_name });
  };

  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        bmp_id: bmp_id,
      });
    }
  };

  const handleAddPayment = async () => {
    let response = "";
    if (editPayment?.state) {
      response = await updatePaymentApi({
        // mob_vendor: vendor_id,
        payment_id: formData?.payment_id,
        payment_mode: formData?.payment_mode,
        payment_date: formData?.date || "",
        payment_ref_no: formData?.ref_no,
        receipt: formData?.upload_receipt?.length
          ? formData?.upload_receipt[0]
          : [],
        remarks: formData?.remarks || "",
        settlement_amount: formData?.settlement_amount,
      });
    } else {
      response = await addPaymentApi({
        mob_vendor: vendor_id,
        payment_mode: formData?.payment_mode,
        payment_date: formData?.date || "",
        payment_ref_no: formData?.ref_no,
        receipt: formData?.upload_receipt?.length
          ? formData?.upload_receipt[0]
          : [],
        remarks: formData?.remarks || "",
        settlement_amount: formData?.settlement_amount,
      });
    }

    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setConfirmModal(false);
      setOpenUploadModal(false);
      setPaymentMode("");
      setInputDate("");
      setFileList([]);
      setFormData({});
      // setDatePickerEmpty(false);
      handleRefetch({ bmp_id });
    } else {
      showErrorToast(response?.error?.data?.message || response?.data?.message);
    }
  };
  const options = [
    {
      key: 1,
      value: "Today",
      label: "Today",
    },
    {
      key: 2,
      value: "Yesterday",
      label: "Yesterday",
    },
    {
      key: 3,
      value: "last_7_day",
      label: "Last 7 day",
    },
    {
      key: 4,
      value: "last_30_day",
      label: "Last 30 day",
    },
    {
      key: 5,
      value: "last_60_day",
      label: "Last 60 day",
    },
    {
      key: 6,
      value: "Custom",
      label: "Custom",
    },
    {
      key: 7,
      value: "clear_date",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear Date</Col>
      ),
    },
  ];
  const handleDownload = async () => {
    const response = await getDownloadPdfPaymentAPi({
      bmp_id: bmp_id,
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
    });
    if (response?.status === "fulfilled") {
      window.open(`${baseUrl}${response?.data?.data?.url}`, "_self");
    }
  };
  const downloadOptions = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];
  const columns = [
    {
      // width: "150px",
      // fixed: "left",
      flex: 1,
      title: "Settlement Date",
      key: 1,
      dataIndex: "payment_date",
      render: (params, record) => {
        return (
          <Text className="table_text">
            {params ? dateFormat(params) : "No Date"}
          </Text>
        );
      },
    },
    {
      // width: "150px",
      // fixed: "left",
      flex: 1,
      title: "Created At",
      key: 1,
      dataIndex: "date",
      render: (params, record) => {
        return (
          <Text className="table_text">
            {params ? dateFormat(params) : "No Date"}
          </Text>
        );
      },
    },
    {
      // fixed: "left",
      flex: 2,
      // width: "150px",
      title: "Payment ref no.",
      key: 2,
      dataIndex: "payment_ref_no",
      className: "table_text",
    },
    {
      // fixed: "left",
      flex: 3,
      title: "Settlement Amount",
      // width: "250px",
      key: 3,
      dataIndex: "settlement_amount",
      render: (params, record) => {
        return (
          <Row>
            <Col
              span={8}
              className="table_text"
              style={{ textAlign: "center" }}
            >
              ₹ {params}
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <Text
                style={{
                  color: "#0354a3",
                  cursor: "pointer",
                  width: "110px",
                  display: "block",
                }}
                onClick={() => handleViewSummary(record)}
              >
                View summary
              </Text>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              {record?.receipt && (
                <Button
                  style={{
                    background: "#f5f5f5",
                    color: "#0a243f",
                    fontWeight: 500,
                    fontSize: "14px",
                    border: "none",
                  }}
                  onClick={() => handlePdfDownload(record?.receipt)}
                >
                  Download
                </Button>
              )}
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Col>
        <Row className="action-container">
          <Col className="action-sub-container">
            <CustomSelect
              placeholder={"Date"}
              width={"150px"}
              options={options}
              handleChange={handleChange}
              value={dateFilter}
            />
            {dateFilter === "Custom" && (
              <DateRangePicker
                onChange={handleChangeRangePicker}
                format={"DD-MMM-YYYY"}
              />
            )}
            <Button
              size="large"
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                fontWeight: 500,
                height: "48px",
              }}
              onClick={() => handleSearchOnDate()}
            >
              Search
            </Button>
          </Col>
          <Col className="action-sub-container">
            <CustomSelect
              placeholder={"Download"}
              width={"150px"}
              height={"46px"}
              options={downloadOptions}
              handleChange={handleDownload}
              value={"Download"}
            />
            <Button
              size="large"
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                fontWeight: 500,
                height: "48px",
              }}
              onClick={() => {
                setOpenUploadModal(true);
                setDatePickerEmpty(true);
                setEditPayment({ state: false, check: true });
              }}
            >
              ADD PAYMENT
            </Button>
          </Col>
        </Row>
        <Col>
          <CustomTableWithPagination
            className="order-list-table"
            tableDataSource={tableDataSource || []}
            tableColumns={columns}
            // locale={{
            //   emptyText: isLoading ? <TableSkeleton length={30} /> : "",
            // }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
           // itemsPerPage={data?.pagination?.end_index}
           itemsPerPage={20}
            totalEntries={data?.pagination?.total_entries
            }
          />
        </Col>
      </Col>
      <AddPaymentModal
        openModal={openUploadModal}
        onCancleModel={setOpenUploadModal}
        handleOk={handleOk}
        formData={formData}
        setFormData={setFormData}
        datePickerEmpty={datePickerEmpty}
        setDatePickerEmpty={setDatePickerEmpty}
        fileList={fileList}
        setFileList={setFileList}
        paymentMode={paymentMode}
        setPaymentMode={setPaymentMode}
        seller_name={seller_name}
        editPayment={editPayment}
        setEditPayment={setEditPayment}
        setConfirmModal={setConfirmModal}
        setOpenUploadModal={setOpenUploadModal}
        setInputDate={setInputDate}
        inputDate={inputDate}
      />
      <ConfirmPaymentModal
        open={confirmModal}
        data={formData}
        onBack={handleBack}
        handleOk={handleOk}
        handleSave={handleAddPayment}
      />
    </>
  );
};

export default RecentSettlement;

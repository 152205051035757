import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "./baseQuery";

export const walletAPI = createApi({
  reducerPath: "walletAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    walletToBankTransfer: builder.mutation({
      query: (data) => ({
        url: "api/accounts/wallet/wallet_to_bank_transfer/",
        method: "POST",
        body: data,
      }),
    }),
    getAddMoneyToWallet: builder.mutation({
      query: (data) => ({
        url: "/api/customer/add_money_to_wallet/",
        method: "POST",
        body: data,
      }),
    }),
    getWalletToBankTransferDetails: builder.query({
      query: (params) => {
        return {
          url: `api/accounts/wallet/get_wallet_to_bank_transfers/`,
          params,
        };
      },
    }),
    getMobCreditTransactionDetails: builder.query({
      query: (params) => {
        return {
          url: `/api/customer/${params?.phone_number}/get_customer_credit_history/`,
          // params,
        };
      },
    }),
    getWalletToBankTransferDetailsById: builder.query({
      query: (id) => {
        return {
          url: `api/accounts/wallet/${id}/get_wallet_to_bank_transfer_details/`,
        };
      },
    }),
    // getWalletToBankTransferDetailsById: builder.query({
    //   query: (id) => {
    //     return {
    //       url: `api/accounts/wallet/${id}/get_wallet_to_bank_transfer_details/`,
    //     };
    //   },
    // }),
    getWalletHistory: builder.query({
      query: (id) => {
        return {
          url: `api/customer/${id}/get_wallet_history/`,
        };
      },
    }),

    updateWalletToBankTransfer: builder.mutation({
      query: (params, file) => {
        const formData = new FormData();

        formData.append("transaction_number", params.transaction_number);
        formData.append("transaction_date", params.transaction_date);
        formData.append("file", params.file);
        formData.append("remarks", params.remarks);
        return {
          url: `api/accounts/wallet/${params.id}/update_wallet_to_bank_transfer/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useWalletToBankTransferMutation,
  useLazyGetWalletToBankTransferDetailsQuery,
  useLazyGetWalletToBankTransferDetailsByIdQuery,
  useUpdateWalletToBankTransferMutation,
  useLazyGetWalletHistoryQuery,
  useGetAddMoneyToWalletMutation,
  useLazyGetMobCreditTransactionDetailsQuery,
} = walletAPI;

export default walletAPI;

import { Col, Typography } from "antd";
import React from "react";
const { Text } = Typography;
const UserDetail = (props) => {
  const { data } = props;
  return (
    <Col className="userWrapper">
      <Col className="userContentAlign">
        <Text className="SettleContentTextI">Name of account holder</Text>
        <Text className="SettleContentText">
          {data?.name_of_account_holder}
        </Text>
      </Col>
      <Col className="userContentAlign">
        <Text className="SettleContentTextI">Bank account number</Text>
        <Text className="SettleContentText">{data?.bank_account_number}</Text>
      </Col>
      <Col className="userContentAlign">
        <Text className="SettleContentTextI">Bank IFSC code</Text>
        <Text className="SettleContentText">{data?.bank_ifsc}</Text>
      </Col>
      <Col className="userContentAlign">
        <Text className="SettleContentTextI">Bank name and branch</Text>
        <Text className="SettleContentText">{data?.bank_name}</Text>
      </Col>
      <Col className="userContentAlign">
        <Text className="SettleContentTextI">Bank account type</Text>
        <Text className="SettleContentText">{data?.bank_account_type}</Text>
      </Col>
    </Col>
  );
};

export default UserDetail;

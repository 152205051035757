import React, {useState} from "react";
import {
  Button,
  Col,
  Modal,
  Input,
  Form,
  Typography,
  DatePicker,
  TimePicker,
} from "antd";
import "./WalletToBankModal.css";
import dayjs from "dayjs";
import ConfirmModal from "./ConfirmModal";
const {Text} = Typography;
const WalletToBankModal = ({
  isModalOpen,
  setIsModalOpen,
  walletToBankTransferApi,
  id,
  allDetails,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const handleOk = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const formattedDate = dayjs(values.date).format("YYYY-MM-DD");
      setFormData({
        amount: values.amount,
        date_requested: formattedDate,
        mob_user: id,
      });

      setConfirmModalOpen(true);
      //   form.resetFields();
      //   setIsModalOpen(false);
    } catch (error) {
      console.error("Save failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const defaultDate = dayjs();
  const handleAllAmount = () => {
    form.setFieldsValue({amount: allDetails?.wallet});
  };
  return (
    <Modal
      title="Wallet to bank transfer"
      open={isModalOpen}
      onCancel={handleCancel}
      style={{top: "10px"}}
      footer={[
        <Col
          key="footer"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "28px",
          }}>
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              height: "48px",
              fontWeight: 500,
              fontSize: "14px",
              width: "120px",
            }}>
            CANCEL
          </Button>
          <Button
            key="save"
            type="primary"
            loading={loading}
            onClick={handleOk}
            style={{
              height: "48px",
              fontWeight: 500,
              fontSize: "14px",
              //   width: "220px",
              backgroundColor: "#0354a3",
            }}>
            REQUEST TRANSFER
          </Button>
        </Col>,
      ]}>
      <Form form={form} layout="vertical" style={{padding: "8px 24px"}}>
        <Col className="available-money-notify">
          <Text>Wallet money available:</Text>
          <Text>{`₹ ${allDetails?.wallet}`}</Text>
        </Col>
        <Form.Item
          name="amount"
          label={
            <Col className="amount-transfer-label">
              <Text style={{fontWeight: 500}}>Amount to transfer</Text>
              <Text
                style={{fontWeight: 500, color: "#2973f0", cursor: "pointer"}}
                onClick={handleAllAmount}>
                All wallet amount
              </Text>
            </Col>
          }
          rules={[
            // { required: true, message: "Amount is required" },
            {
              validator: async (_, value) => {
                if (value <= 0 || !value) {
                  return Promise.reject("Amount must be greater than 0");
                }
                return Promise.resolve();
              },
            },
          ]}>
          <Input
            placeholder="Enter amount"
            style={{height: "46px"}}
            className="wallet-amount-input"
            onKeyDown={(e) => {
              if (
                (e.key < "0" || e.key > "9") &&
                e.key !== "Backspace" &&
                e.key !== "ArrowLeft" &&
                e.key !== "ArrowRight" &&
                e.key !== "Tab"
              ) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}>
          <Form.Item
            name="date"
            label={<Col style={{fontWeight: 500}}>Date</Col>}
            rules={[{required: true, message: "Date is required"}]}
            initialValue={defaultDate}>
            <DatePicker
              className="wallet-amount-input"
              style={{width: "225px", height: "46px"}}
            />
          </Form.Item>
          <Form.Item
            name="time"
            label={<Col style={{fontWeight: 500}}>Time</Col>}>
            <TimePicker
              className="wallet-amount-input"
              style={{width: "225px", height: "46px"}}
            />
          </Form.Item>
        </Col>
        <Form.Item
          name="note"
          label={<Col style={{fontWeight: 500}}>Note</Col>}>
          <Input.TextArea
            rows={4}
            placeholder="Enter note"
            className="wallet-amount-input"
          />
        </Form.Item>
      </Form>
      <ConfirmModal
        isModalOpen={confirmModalOpen}
        setIsModalOpen={setConfirmModalOpen}
        form={form}
        mainModal={setIsModalOpen}
        api={walletToBankTransferApi}
        formData={formData}
      />
    </Modal>
  );
};

export default WalletToBankModal;

import {
  Col,
  // Typography
} from "antd";
import React from "react";
import "./BasicDetails.css";
import EditContainerProduct from "./EditDetails/EditContainerProduct";
import { dropDownData } from "../../../../commonUtils/commonUtils";

const ComplianceAndBatteryInfo = (props) => {
  const { handleChildFieldChange, data, isFetching } = props;
  // const { Text } = Typography
  const complianceAndBatteryInformation = [
    {
      name: "are_batteries_included",
      label: "Are batteries included",
      type: "Select",
      value: data?.are_batteries_included || "",
      placeholder: "Enter Are batteries included",
      toolTip: false,
      options: [
        {
          key: "YES",
          label: "YES",
          value: "YES",
        },
        {
          key: "NO",
          label: "NO",
          value: "NO",
        },
      ],
    },
    {
      name: "is_the_product_a_battery_itself_battery_type",
      label: "Is the product a battery",
      type: "Select",
      value: data?.is_the_product_a_battery_itself_battery_type || "",
      placeholder: "Enter Is the product a battery",
      toolTip: false,
      options: [
        {
          key: "YES",
          label: "YES",
          value: "YES",
        },
        {
          key: "NO",
          label: "NO",
          value: "NO",
        },
      ],
    },
    {
      name: "battery_type",
      label: "Battery type",
      type: "Select",
      value: data?.battery_type || "",
      placeholder: "Enter Battery type",
      toolTip: false,
      options: dropDownData?.battery_type,
    },
    {
      name: "battery_weight",
      label: "Battery weight",
      type: "Input",
      placeholder: "Enter Battery weight",
      toolTip: false,
      value: data?.battery_weight || "",
      reg: true,
    },
    {
      name: "battery_weight_unit_of_measure",
      label: "Battery weight unit",
      type: "Select",
      value: data?.battery_weight_unit_of_measure || "",
      placeholder: "Enter Battery weight unit",
      toolTip: false,
      options: [
        {
          key: 1,
          label: "ONE",
          value: "ONE",
        },
        {
          key: 2,
          label: "TWO",
          value: "TWO",
        },
      ],
    },

    {
      name: "voltage",
      label: "Voltage",
      type: "Input",
      placeholder: "Enter Voltage",
      toolTip: false,
      value: data?.voltage || "",
    },
    {
      name: "safety_warning",
      label: "Safety warning",
      type: "TextArea",
      placeholder: "",
      toolTip: false,
      value: data?.safety_warning || "",
    },
    {
      name: "legal_disclaimer",
      label: "Legal disclaimer",
      type: "TextArea",
      placeholder: "",
      toolTip: false,
      value: data?.legal_disclaimer || "",
    },
  ];
  return (
    <Col>
      <EditContainerProduct
        tittle={"Compliance and battery information"}
        formData={complianceAndBatteryInformation}
        handleChildFieldChange={handleChildFieldChange}
        isFetching={isFetching}
      />
    </Col>
  );
};

export default ComplianceAndBatteryInfo;

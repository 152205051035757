import React, { useRef, useState } from "react";
import "./OrderSummary.css";
import { ReactComponent as AlertIcon } from "../../../../assets/icons/alert.svg";
import {
  //  Avatar,
  Col,
  Tooltip,
  Typography,
} from "antd";
import {
  // CheckCircleFilled,
  // CloseCircleFilled,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
// import { ReactComponent as StarIcon } from "../../../../assets/icons/star-icon.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/InfoIcon.svg";
import { ReactComponent as FileIcon } from "../../../../assets/icons/file-copy.svg";
// import { ReactComponent as MobCreditWhiteIcon } from "../../../../assets/icons/mobCreditWhiteIcon.svg";
import AddPaymentModal from "../OrderModals/AddPaymentModal/AddPaymentModal";
import PaymentSummaryModal from "../OrderModals/PaymentSummaryModal/PaymentSummaryModal";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../NotificationToast/NotificationToast";
import { onlyDateFormat } from "../../../../commonFuntions/CommonUtilFunctions";
const { Text } = Typography;

const OrderSummary = ({
  data,
  paymentSummary,
  orderPaymentApi,
  paymentModal,
  setPaymentModal,
  handlePayment,
  amountError,
  setAmountError,
  remarksError,
  setRemarksError,
  dateError,
  setDateError,
  isLoading,
  isFetching,
}) => {
  const paymentLinkRef = useRef(null);
  const [summaryModalOpen, setSummaryModalOpen] = useState(false);
  const showSummaryModal = () => {
    setSummaryModalOpen(true);
  };
  const columns = [
    {
      title: "Payment date",
      dataIndex: "payment_date",
      width: "150px",
      render: (params, record) => {
        console.log("🚀 ~ record:", record)
        return (
          <>
          
            <Col>{onlyDateFormat(params)}
            {(record?.added_by?.name &&
            <Tooltip title={`${record?.added_by?.name}, ${record?.added_by?.user}`} color={"#fff"} key={"#2973f0"}>
             {" "}
             <InfoIcon style={{marginTop:"10px"}} />
                
              
            </Tooltip>
            )}
            </Col>
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "100px",

      render: (params, record) => {
        return (
          <Col style={{ textAlign: "right", color: params < 0 ? 'red' : 'green' }}>
          {(params || 0).toFixed(2)}
        </Col>
        );
      },
    },
    {
      title: "Payment type",
      dataIndex: "payment_type",
      width: "150px",
    },
    {
      title: "Payment mode",
      dataIndex: "payment_mode",
      width: "150px",
    },
    {
      title: "Reference no",
      dataIndex: "reference_number",
      width: "150px",
    },
    {
      title: "Payment paid",
      dataIndex: "is_paid",
      width: "150px",
      render: (params, record) => {
        return (
          <>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              {params ? (
                <CheckOutlined style={{ color: "green" }} />
              ) : (
                <CloseOutlined style={{ color: "red" }} />
              )}
            </Col>
          </>
        );
      },
    },
    {
      title: "Payment Link",
      dataIndex: "payment_link",
      width: "150px",
      render: (params) => {
        return (
          params && (
            <Tooltip title="Copy url" color={"#fff"} key={"#2973f0"}>
              {" "}
              <Col
                className="link-wrapperI"
                onClick={() => copyLinkToClipboardRow(params)}
              >
                <FileIcon />
                <Text>Copy link</Text>
              </Col>
            </Tooltip>
          )
        );
      },
    },
  ];
  const copyLinkToClipboard = async () => {
    const linkText = paymentLinkRef.current.textContent;

    try {
      await navigator.clipboard.writeText(linkText);
      showSuccessToast("Link copied to clipboard successfully");
    } catch (err) {
      showErrorToast("Failed to copy link to clipboard", err);
    }
  };
  const copyLinkToClipboardRow = async (params) => {
    try {
      await navigator.clipboard.writeText(params);
      showSuccessToast("Link copied to clipboard successfully");
    } catch (err) {
      showErrorToast("Failed to copy link to clipboard", err);
    }
  };
  // const paymentNotPaidCheck = Object?.keys(data?.payment_not_paid || {});
  return (
    <Col className="sum-wrapper">
      <Text className="sum-text">Order summary</Text>
      <Col className="bill-wrapper">
        <Col className="amount-calc">
          <Text>Subtotal</Text>
          <Text>{`₹ ${Number(data?.sub_total).toFixed(2)}`}</Text>
        </Col>
        <Col className="amount-calc">
          <Text className="align-text">
            Shipping <InfoIcon />
          </Text>
          <Text>{`₹ ${Number(data?.delivery_fee).toFixed(2)}`}</Text>
        </Col>
        <Col className="amount-calc">
          <Text className="align-text">
            Tax
            <InfoIcon />
          </Text>
          <Text>{`₹ ${Number(data?.sgst * 2).toFixed(2)}`}</Text>
        </Col>
        <Col className="amount-calc">
          <Text>Discounts</Text>
          <Text style={{ color: "#01a685" }}>{` - ₹ ${Number(
            Math.abs(data?.discount || 0)
          ).toFixed(2)}`}</Text>
        </Col>
      </Col>
      <Col className="total-amount">
        <Col className="amount-calc">
          <Text style={{ fontSize: "18px" }}>Total</Text>
          <Text style={{ fontSize: "18px" }}>{`₹ ${Number(data?.total).toFixed(
            2
          )}`}</Text>
        </Col>

        <Col className="amount-calc">
          <Text>Paid</Text>
          <Text>{`₹ ${Number(data?.paid_amount).toFixed(2)}`}</Text>
        </Col>

        <Col className="amount-calc">
          <Text>Pending(+) / Excess(-)</Text>
          <Text style={{ color: "#bf2600" }}>{`₹ ${Number(
            data?.pending_amount
          ).toFixed(2)}`}</Text>
        </Col>
        {data?.pending_amount < 0 && (
          <Col className="detail-notify">
            <AlertIcon />
            <Col style={{marginTop:"3px"}}>
              {`₹ ${Math.abs(Number(data?.pending_amount).toFixed(2))}`} amount
              will be added to mobWALLET after delivery
            </Col>
          </Col>
        )}
      </Col>

      <Col className="payment-option">
        <Col className="payment-type">
          <Text className="pay-tI">This order contains multiple payments</Text>

          <Col className="pay-btn">
            <Text className="pay-tII" onClick={showSummaryModal}>
              View all payments
            </Text>
            {(data?.pending_amount > 0 || data?.pending_amount < 0) && (
              <Text className="pay-tII" onClick={handlePayment}>
                Add payment
              </Text>
            )}
          </Col>
        </Col>
        <Col className="payment-link">
          <Col className="link-wrapper">
            <Text>Payment link:</Text>
            <a
              ref={paymentLinkRef}
              href={data?.payment_not_paid?.payment_link || "N/A"}
              target="_blank"
              rel="noopener noreferrer"
              className="payment-links"
            >
              {data?.payment_not_paid?.payment_link || "N/A"}
            </a>
          </Col>
          <Tooltip title="Copy url" color={"#fff"} key={"#2973f0"}>
            <Col className="link-wrapperI" onClick={copyLinkToClipboard}>
              <FileIcon />
              <Text>Copy link</Text>
            </Col>
          </Tooltip>
        </Col>
      </Col>
      {/* {true && (
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 24px 24px 24px",
            gap: "15px",
          }}
        >
          <Text style={{ fontSize: "18px", fontWeight: 500 }}>
            Payment method
          </Text>
          <MobCreditWhiteIcon />
        </Col>
      )} */}

      {/* ) : (
         ""
         // <Col className="payment-option">
         //   <Text className="pay-text">Payment method</Text>
         //   <Col className="card">
         //     <Avatar />
         //     <Text>Visa card ending 9987</Text>
         //   </Col>
         // </Col>
       )} */}

      {/* {false && (
        <Col className="reward">
          <StarIcon /> <Text style={{ fontWeight: 500 }}>328 points</Text>
          <Text style={{ color: "#0a243f", fontWeight: 500, opacity: 0.6 }}>
            (It will be added)
          </Text>
          <InfoIcon />
        </Col>
      )} */}
      <AddPaymentModal
        openAddFile={paymentModal}
        setOpenAddFile={setPaymentModal}
        orderPaymentApi={orderPaymentApi}
        orderId={data?.id}
        totalAmount={data?.total}
        pendingAmount={data?.pending_amount}
        amountError={amountError}
        setAmountError={setAmountError}
        remarksError={remarksError}
        setRemarksError={setRemarksError}
        dateError={dateError}
        setDateError={setDateError}
        isLoading={isLoading}
        isFetching={isFetching}
        data={data}
      />
      <PaymentSummaryModal
        showModal={showSummaryModal}
        isModalOpen={summaryModalOpen}
        setIsModalOpen={setSummaryModalOpen}
        columns={columns}
        data={paymentSummary}
      />
    </Col>
  );
};

export default OrderSummary;

import {
  // Button,
  Col,
  Table,
  Typography,
} from "antd";
import React from "react";
import "./DeliveryTable.css";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/InfoIcon.svg";
const { Text } = Typography;
const DeliveryTable = ({ columns, data, deliveryStatus, editSubOrderData }) => {
  return (
    <>
      <Table
        className="deliveryBy-table"
        columns={columns}
        pagination={false}
        footer={() => (
          <Col>
            <Col className="deliveryBy-calc">
              {/* <Col className="deliveryBy-align-amount">
                <Text>Subtotal</Text>
                <Text>
                  ₹ {Number(editSubOrderData?.sub_total || 0).toFixed(2)}
                </Text>
              </Col> */}
              <Col className="deliveryBy-align-amount">
                <Text>Discount</Text>
                <Text>
                  ₹ {Number(editSubOrderData?.discount || 0).toFixed(2)}
                </Text>
              </Col>
              <Col className="deliveryBy-align-amount">
                <Text className="tax-info">
                  {" "}
                  Tax
                  <InfoIcon />
                </Text>
                <Text>
                  ₹{" "}
                  {Number(
                    editSubOrderData?.cgst + editSubOrderData?.sgst
                  ).toFixed(2)}
                </Text>
              </Col>
              <Col className="deliveryBy-align-amount">
                <Text>Delivery fee</Text>
                <Text>
                  ₹ {Number(editSubOrderData?.delivery_fee || 0).toFixed(2)}
                </Text>
              </Col>
            </Col>
            <Col className="deliveryBy-align-total">
              <Text className="deliveryBy-text">Total</Text>
              <Text className="deliveryBy-text">
                ₹ {Number(editSubOrderData?.sub_total || 0).toFixed(2)}
              </Text>
            </Col>
          </Col>
        )}
        dataSource={data?.map((itemData) => ({
          ...itemData,
          //           button:(
          //  deliveryStatus==="Delivered" && <Col className="btn-containerI">

          // <Button className="styled-btn">Return</Button>
          // <Button className="styled-btn">Exchange</Button>
          // </Col>

          //           )
        }))}
      />
    </>
  );
};

export default DeliveryTable;

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./CustomerOrders.css";
import {
  // PlusOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { useNavigate,useParams } from "react-router";
import { Button, Col, Row, Tag, Typography } from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
// import SearchDropdown from "../../../components/DropDown/SearchDropdown";

import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import {
  useLazyGetExistingOrdertTableQuery,
} from "../../../apis/ManageOrders";
import { useDispatch, useSelector } from "react-redux";
import { getmanageOrdersTableData } from "../../../redux/slices/manageOrders/action";
import { manageOrdersDataSelector } from "../../../redux/slices/manageOrders/selector";
import { DownArrow } from "../../../components/Icons/DownArrow";
import { UpArrow } from "../../../components/Icons/UpArrow";
import { SubOrderComponent } from "../../Dashboard/SubOrderComponent";
import {
  dateFormat,
  // handlePdfDownload,
} from "../../../commonFuntions/CommonUtilFunctions";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {
  getBillingAddress,
  getDeliveryAddress,
} from "../../../redux/slices/createQuote/action";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import NoDataFound from "../../../components/EmptyTable/NoDataFound";
const { Text } = Typography;

const handleTableData = (data) => {
  let tableData = [];
  data?.results?.map((item) => {
    tableData.push({
      id: item?.id,
      key: item?.order_id,
      order_id: item?.order_id,
      date: item?.created_at,
      name: item?.name,
      mobile_number: item?.mobile_number,
      status: item?.order_status,
      total: item?.total,
      email: item?.email || "",
      rfq_id: item?.rfq_details?.rfq_id,
      sub_orders: item?.sub_orders?.map((element) => {
        const obj = {
          subId: element?.suborder_id,
          itemName: element?.vendor_name,
          subStatus: element?.status,
          bmp_id: element?.bmp_id,
          subTotal: Number(element?.total).toFixed(2),
        };
        return obj;
      }),
    });
    return null;
  });
  return tableData;
};
const CustomerOrders = () => {
  const navigate = useNavigate();
  const queryparams = useParams();
  
  const dispatch = useDispatch();
 
  const selectorData = useSelector(manageOrdersDataSelector);
  const [searchedValue, setSearchedValue] = useState({});
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [sorterState, setSorterState] = useState({
    order_id: "",
    created_at: "",
  });
  const [dateFilter, setDateFilter] = useState();  
  const [currentPage, setCurrentPage] = useState(1);
  const [orderTableApi, { data: orderData, isFetching: isLoading }] =
    useLazyGetExistingOrdertTableQuery({...(queryparams?.phone_number && { phone_number: queryparams?.phone_number }), });
  // const [
  //   excelApi,
  //   {
  //     isFetching: exlLoading,
  //     // data: excelApiData
  //   },
  // ] = useLazyGetOrdersListExcelQuery();
  const [tableDataSource, setTableDataSource] = useState([]);
 
  useEffect(() => {
    orderTableApi({...(queryparams?.phone_number && { phone_number: queryparams?.phone_number }), });
    dispatch(getDeliveryAddress({}));
    dispatch(getBillingAddress({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderData?.status) {
      dispatch(getmanageOrdersTableData(orderData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  useEffect(() => {
    if (selectorData) {
      const data = selectorData ? handleTableData(selectorData) : "";
      setTableDataSource(data);
    }
  }, [selectorData]);
  // const handleDetailNav = (record) => {
  //   navigate(`/admin/orders/order-details/${record?.id}`, {
  //     state: { id: record?.id },
  //   });
  // };
  // const handleNavigateToRFQDetails = (id) => {
  //   navigate(`/admin/rfq/quotes/${id}`, { state: { id } });
  // };
  const defaultExpandable = {
    expandedRowRender: (record) => {
      return record?.sub_orders?.map((obj) => (
        <SubOrderComponent record={obj} />
      ));
    },
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <UpArrow className={"cursor"} onClick={(e) => onExpand(record, e)} />
      ) : (
        <DownArrow className={"cursor"} onClick={(e) => onExpand(record, e)} />
      ),
  };
  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        orderTableApi({
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.order_id && { sort_by: sorterState.order_id }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          ...(queryparams?.phone_number && { phone_number: queryparams?.phone_number }),
          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        orderTableApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.order_id && { sort_by: sorterState.order_id }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          ...(queryparams?.phone_number && { phone_number: queryparams?.phone_number }),
          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }

      setCurrentPage(1);
    }
    setDateFilter(value);
  };

  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates?.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      orderTableApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        ...(searchedValue?.search && { search: searchedValue?.search }),
        ...(sorterState.order_id && { sort_by: sorterState.order_id }),
        ...(sorterState.created_at && { sort_by: sorterState.created_at }),
        ...(queryparams?.phone_number && { phone_number: queryparams?.phone_number }),
        page: 1,
      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });

      setCurrentPage(1);
    }
  };
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
      ...(sorterState.order_id && { sort_by: sorterState.order_id }),
      ...(sorterState.created_at && { sort_by: sorterState.created_at }),
      ...(queryparams?.phone_number && { phone_number: queryparams?.phone_number })
    };
    setCurrentPage(currPage);
    await orderTableApi(params);
  };

  const options = [
    {
      key: 1,
      value: "Today",
      label: "Today",
    },
    {
      key: 2,
      value: "Yesterday",
      label: "Yesterday",
    },
    {
      key: 3,
      value: "last_7_day",
      label: "Last 7 day",
    },
    {
      key: 4,
      value: "last_30_day",
      label: "Last 30 day",
    },
    {
      key: 5,
      value: "last_60_day",
      label: "Last 60 day",
    },
    {
      key: 6,
      value: "Custom",
      label: "Custom",
    },
    {
      key: 7,
      value: "clear_date",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear Date</Col>
      ),
    },
  ];
  const sorterChange = (item) => {
    let key = item;
    setSorterState({
      // ...sorterState,
      [key.replace("-", "")]: item,
    });
    orderTableApi({
      sort_by: item,
      ...(searchedValue?.search && { search: searchedValue?.search }),
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
      ...(queryparams?.phone_number && { phone_number: queryparams?.phone_number }),
      page: 1,
    });
  };
  const columns = [
    {
      width: "200px",
      fixed: "left",
      flex: 2,
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Order ID</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.order_id === "order_id" ? "" : "grey",
                }}
                onClick={() => sorterChange("order_id")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color: sorterState?.order_id === "-order_id" ? "" : "grey",
                }}
                onClick={() => sorterChange("-order_id")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      // title: "Order ID",
      key: 1,
      dataIndex: "order_id",

      render: (params, record) => {
        return (
          <Link
            className="order-id-text"
            to={`/admin/orders/order-details/${record?.id}`}
            // onClick={() => handleDetailNav(record)}
            target="_blank"
          >
            {params}
          </Link>
        );
      },
    },
    {
      // fixed: "left",
      flex: 2,
      width: "200px",
      // title: "Date",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Date</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.created_at === "created_at" ? "" : "grey",
                }}
                onClick={() => sorterChange("created_at")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color:
                    sorterState?.created_at === "-created_at" ? "" : "grey",
                }}
                onClick={() => sorterChange("-created_at")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      key: 2,
      dataIndex: "date",
      render: (params, record) => {
        return <Text>{dateFormat(params)}</Text>;
      },
    },
    {
      // fixed: "left",
      flex: 1,
      title: "Name",
      width: "250px",
      key: 3,
      dataIndex: "name",
    },
    {
      // fixed: "left",
      flex: 1,
      title: "Business mobile",
      width: "150px",

      key: 4,
      dataIndex: "mobile_number",
    },
    {
      // fixed: "left",
      flex: 1,
      title: "Email",
      width: "350px",

      key: 5,
      dataIndex: "email",
    },
    {
      // fixed: "left",
      flex: 1,
      title: "Status",
      width: "150px",
      dataIndex: "status",
      key: 6,
      render: (params) => {
        return <Tag className={`tag-font-style}`}>{params}</Tag>;
      },
    },
    {
      // fixed: "left",
      flex: 1,
      title: "RFQ",
      width: "200px",

      dataIndex: "rfq_id",
      key: 7,
      render: (params) => {
        return (
          <Link
            to={`/admin/rfq/quotes/${params}`}
            className="order-id-text"
            target="_blank"
            // onClick={() => handleNavigateToRFQDetails(params)}
          >
            {params}
          </Link>
        );
      },
    },
    {
      fixed: "left",
      width: "150px",

      flex: 1,
      key: 8,
      title: (
        <>
          <Text>{"Total"}</Text>
          {/* <PlusOutlined className="plus-icon" /> */}
        </>
      ),
      dataIndex: "total",
      align: "center",
      render: (params, record) => {
        return <Text>₹ {Number(params).toFixed(2)}</Text>;
      },
    },
  ];
  
  // const handleNavigateRfqList = () => {
  //   navigate("/admin/rfq");
  // };
  // const exportOptions = [
  //   {
  //     key: 1,
  //     value: "Excel",
  //     label: "Excel",
  //   },
  // ];
  // const handleExportApi = async () => {
  //   let response = await excelApi({
  //     ...(searchedValue.search && { search: searchedValue.search }),
  //     ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
  //     ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
  //     ...(sorterState.order_id && { sort_by: sorterState.order_id }),
  //     ...(sorterState.created_at && { sort_by: sorterState.created_at }),
  //   });
  //   if (response?.status === "fulfilled") {
  //     // handlePdfDownload(response?.data?.data?.url, true);
  //     saveAs(response?.data?.data?.url);
  //   }

  //   // excelApi();
  // };
  const handleBackNavigate = () => {
    navigate(-1);
  };

  return (
    <Row
      className="customerOrderContainer"
      //   className={
      //     selectedKey == "myAccountOrder"
      //       ? "myAccountMainTableContentParentResponsive"
      //       : "myAccountMainTableContentParent"
      //   }
    >
      <Button
        className="btn"
        icon={<ArrowLeftOutlined />}
        onClick={handleBackNavigate}
      >
        BACK
      </Button>
      
    
      <Col>
        <Row>
          <Col className="header-container">
            <Text className="heading">Orders</Text>
          </Col>
        </Row>
        <Row className="action-container">
          <Col className="action-sub-container">
            <SearchInput
              size={"large"}
              placeholder={"Search by Order ID"}
              getLibraryApi={orderTableApi}
              width={"400px"}
              isLoading={isLoading}
              handleSearchedValue={handleSearchedValue}
              queryparams={queryparams}
            />
            {/* <SearchDropdown
              // list={tableSelector?.results?.categories}
              label={"Status filter"}
              placeholder={"Search categories"}
              // selectedItems={categories}
              // setSelectedItems={setCategories}
              // handleFilterLibrary={handleFilterLibrary}
              isCustomBorder={"8px"}
            /> */}
            <CustomSelect
              placeholder={"Date"}
              width={"150px"}
              options={options}
              handleChange={handleChange}
              value={dateFilter}
            />
            {dateFilter === "Custom" && (
              <DateRangePicker
                onChange={handleChangeRangePicker}
                format={"DD-MMM-YYYY"}
              />
            )}
            {/* <CustomSelect
              placeholder={"Export"}
              width={"150px"}
              options={exportOptions}
              handleChange={handleExportApi}
              value={exlLoading ? <LoadingOutlined /> : "Export"}
              disabled={exlLoading ? true : false}
              loading={exlLoading}
            /> */}
          </Col>
          
        </Row>
        <Col>
          {/* <Table
            className="dashboard-table"
            expandable={defaultExpandable}
            columns={columns}
            dataSource={tableDataSource}
            locale={{
              emptyText: isLoading ? <TableSkeleton /> : "",
            }}
            pagination={false}
            scroll={{ y: 1300 }}
          /> */}
          <CustomTableWithPagination
            className="order-list-table"
            tableExpandable={defaultExpandable}
            tableDataSource={tableDataSource || []}
            tableColumns={columns}
            locale={{
              emptyText: isLoading ? (
                <TableSkeleton length={10} />
              ) : (
                <NoDataFound text={"No orders found!"} />
              ),
            }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={20}
            totalEntries={
              !isLoading && orderData?.data?.pagination?.total_entries
            }
            isLoading={tableDataSource.length && isLoading}
            // sorterChange={sorterChange}
          />
        </Col>
      </Col>
      
    

      
    </Row>
  );
};

export default CustomerOrders;

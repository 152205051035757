import React, {useEffect, useState} from "react";
import {Col, Tag, Typography} from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {
  useLazyGetDownloadServiceRequestReportsQuery,
  useLazyGetServiceRequestDataQuery,
} from "../../../apis/reports";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import {LoadingOutlined} from "@ant-design/icons";
import {saveAs} from "file-saver";
import {
  colorMapping,
  dateFormat,
} from "../../../commonFuntions/CommonUtilFunctions";
import {Link} from "react-router-dom";

const {Text} = Typography;
const options = [
  {
    key: 1,
    value: "Export",
    label: "Export",
  },
];
const ServiceRequestReport = () => {
  const [searchedValue, setSearchedValue] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  const [
    getCustomerOrderReportApi,
    {data: customerOrderReportData, isFetching},
  ] = useLazyGetServiceRequestDataQuery();
  const [getDownloadCustomerDetailsReportapi, {isFetching: downloadReport}] =
    useLazyGetDownloadServiceRequestReportsQuery();
  useEffect(() => {
    getCustomerOrderReportApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Service request ID",
      dataIndex: "service_request_id",
      key: "service_request_id",
      width: "230px",
      fixed: "left",
      render: (params, record) => {
        return (
          <Link
            to={`/admin/orders/view-details/${record?.suborder?.id}`}
            target="_blank"
            style={{fontWeight: 500, color: "#2973f0", cursor: "pointer"}}>
            {params}
          </Link>
        );
      },
    },
    {
      title: "Suborder id",
      dataIndex: "suborder",
      key: "suborder",
      width: "200px",
      render: (params, record) => {
        return (
          <Link
            to={`/admin/orders/view-details/${record?.suborder?.id}`}
            target="_blank"
            style={{fontWeight: 500, color: "#2973f0", cursor: "pointer"}}>
            {record?.suborder?.suborder_id || "N/A"}
          </Link>
        );
      },
    },
    {
      title: "Date and time",
      dataIndex: "created_at",
      key: "created_at",
      width: "250px",
      render: (params, record) => {
        return (
          <Col style={{fontWeight: 400}}>
            <Text>{params ? dateFormat(params) : "N/A"}</Text>
          </Col>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "150px",
      render: (params, record) => {
        return (
          <Tag
            style={{padding: "8px"}}
            className={`tag-font-style ${colorMapping[params]}`}>
            {params}
          </Tag>
        );
      },
    },

    {
      title: "Issue type",
      dataIndex: "issue_type",
      key: "issue_type",
      width: "280px",
      render: (params, record) => {
        return (
          <Col>
            <Text>{params}</Text>
          </Col>
        );
      },
    },
    {
      title: "Name (customer)",
      dataIndex: "address",
      key: "address",
      width: "250px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{record?.user_data?.full_name}</Text>
          </Col>
        );
      },
    },
    {
      title: "Business mobile",
      dataIndex: "address_line_2",
      key: "address_line_2",
      width: "250px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{record?.user_data?.phone_number}</Text>
          </Col>
        );
      },
    },
  ];

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && {search: searchedValue?.search}),
    };
    setCurrentPage(currPage);
    await getCustomerOrderReportApi(params);
  };

  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const handleExportApi = async () => {
    console.log("dgdgh");
    let response = await getDownloadCustomerDetailsReportapi({
      ...(searchedValue.search && {search: searchedValue.search}),
      //   download: true,
    });
    if (response?.status === "fulfilled") {
      saveAs(response?.data?.data?.url);
    }
  };

  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">Service Request Report</Text>
      <Col style={{display: "flex", gap: "1rem"}}>
        <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
          width={"400px"}
          getLibraryApi={getCustomerOrderReportApi}
          handleSearchedValue={handleSearchedValue}
          isLoading={isFetching}
        />

        <CustomSelect
          placeholder={"Export"}
          width={"150px"}
          options={options}
          handleChange={handleExportApi}
          value={downloadReport ? <LoadingOutlined /> : "EXPORT"}
          disabled={downloadReport ? true : false}
          loading={downloadReport}
        />
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        tableDataSource={customerOrderReportData?.data?.results}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        }}
        isLoading={customerOrderReportData?.data?.results.length && isFetching}
        scroll={{x: "100%"}}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={
          !isFetching &&
          customerOrderReportData?.data?.pagination?.total_entries
        }
      />
    </Col>
  );
};

export default ServiceRequestReport;

import { Col } from "antd";
import React from "react";
import "./BasicDetails.css";
import EditContainerProduct from "./EditDetails/EditContainerProduct";
import { dropDownData } from "../../../../commonUtils/commonUtils";

const BasicDetails = (props) => {
  const { handleChildFieldChange, data, isFetching } = props;
  const itemDetails = [
    {
      name: "item_name_title",
      toolTip: false,
      label: "Item name",
      type: "Input",
      placeholder: "Enter Item Name",
      required: true,
      value: data?.item_name_title || "",
    },
    {
      name: "brand_name",
      label: "Brand/ manufacturer name",
      type: "Input",
      placeholder: "Enter Brand Name",
      toolTip: false,
      value: data?.brand_name || "",
      // disabled: true,
    },
    {
      name: "category",
      label: "Category",
      type: "Select",
      multiLevel: true,
      placeholder: "Enter Category Name",
      toolTip: false,
      required: true,
      options: data?.categories,
      value: [
        data?.category || "",
        data?.sub_category_id || "",
        data?.sub_category_id_2,
      ],
    },
    {
      name: "product_description",
      label: "Description",
      type: "TextArea",
      placeholder: "Enter Description",
      toolTip: false,
      value: data?.product_description || "",
    },
  ];
  const pricingDetails = [
    {
      name: "mob_sku",
      toolTip: true,
      label: "MOB SKU",
      type: "Input",
      placeholder: "Enter Item Name",
      value: data?.mob_sku || "",
      required: true,
      disabled: true,
    },
    {
      name: "hsn_code",
      label: "HSN",
      type: "Input",
      placeholder: "Enter HSN",
      toolTip: true,
      value: data?.hsn_code || "",
      // disabled: true,
    },
    {
      name: "fulfillment_latency",
      label: "Fulfillment latency",
      type: "Input",
      placeholder: "Enter Fulfillment latency",
      toolTip: true,
      value: data?.fulfillment_latency || "",
      // disabled: true,
    },
    {
      name: "product_tax_code",
      label: "Product tax code",
      type: "Select",
      placeholder: "Select Tax",
      toolTip: false,
      value: data?.product_tax_code || "",
      options: [
        {
          key: "TAX5_STD",
          label: "5% GST",
          value: "TAX5_STD",
        },
        {
          key: "TAX12_STD",
          label: "12% GST",
          value: "TAX12_STD",
        },
        {
          key: "TAX18_STD",
          label: "18% GST",
          value: "TAX18_STD",
        },
        {
          key: "TAX28_STD",
          label: "28% GST",
          value: "TAX28_STD",
        },
        {
          key: "TAX32_STD",
          label: "32% GST",
          value: "TAX32_STD",
        },
      ],
    },
    // {
    //   name: "unit",
    //   label: "Unit",
    //   type: "Select",
    //   placeholder: "Per Item",
    //   toolTip: false,
    //   value: data?.unit || "",
    //   options:dropDownData?.unit_count_type || [],
    // },
    {
      name: "mrp",
      label: "MRP",
      type: "Input",
      placeholder: "Enter MRP",
      toolTip: false,
      value: data?.mrp ? `${data?.mrp}` : "",
      prefix: "₹",
      reg: true,
    },
    {
      name: "your_selling_price",
      label: "Your selling price",
      type: "Input",
      placeholder: "Enter selling price",
      toolTip: false,
      value: data?.your_selling_price ? `${data?.your_selling_price}` : "",
      // disabled: true,
      prefix: "₹",
      reg: true,
    },
    {
      name: "commission_percent",
      label: "Comission percent",
      type: "Input",
      placeholder: "Enter Comission percent",
      toolTip: false,
      value: data?.commission_percent ? `${data?.commission_percent}%` : "",
      // disabled: true,
      suffix: "%",
      reg: true,
    },
  ];
  const additionalDetails = [
    {
      name: "sale_price",
      label: "Sale price",
      type: "Select",
      placeholder: "Select sale price",
      // value: "TWO",
      // disabled: true,
      toolTip: false,
      options: [
        {
          key: 1,
          label: "ONE",
          value: "ONE",
        },
        {
          key: 2,
          label: "TWO",
          value: "TWO",
        },
      ],
    },
    {
      name: "sale_start_date",
      label: "Sale start date",
      type: "DatePicker",
      placeholder: "Select",
      // disabled: true,
      value: data?.sale_start_date || "",
    },
    {
      name: "sale_end_date",
      label: "Sale end date",
      type: "DatePicker",
      placeholder: "Select",
      value: data?.sale_end_date || "",
    },
    {
      name: "release_date",
      label: "Release date",
      type: "DatePicker",
      placeholder: "Select",
      value: data?.release_date || "",
    },
    {
      name: "stop_selling_date",
      label: "Stop selling date",
      type: "DatePicker",
      placeholder: "Select",
      value: data?.stop_selling_date || "",
    },
    {
      name: "max_order_quantity",
      toolTip: false,
      label: "Max order qty",
      type: "Input",
      placeholder: "Enter Max order qty",
      value: data?.max_order_quantity || "",
      reg: true,
    },
    {
      name: "maximum_order_ship_quantity",
      label: "Max order ship qty",
      type: "Input",
      placeholder: "Enter Max order ship qty",
      toolTip: false,
      value: data?.maximum_order_ship_quantity || "",
      reg: true,
    },
    {
      name: "return_and_refund",
      label: "Return and refund",
      type: "Select",
      placeholder: "Select",
      toolTip: false,
      // disabled: true,
      value: data?.return_and_refund,
      options: dropDownData?.return_refund_criteria,
    },
    {
      name: "warranty_term",
      label: "Warranty terms",
      type: "Select",
      placeholder: "Select",
      value: data?.warranty_term || "",
      toolTip: false,
      options: dropDownData?.warranty_term,
    },
    {
      name: "product_bullet_points",
      label: "Product bullet points",
      type: "TextArea",
      placeholder: "",
      toolTip: false,
      value: data?.product_bullet_points || "",
    },
    {
      name: "manufacturer_warranty_description",
      label: "Manufacturer warranty description",
      type: "TextArea",
      placeholder: "",
      toolTip: false,
      value: data?.manufacturer_warranty_description || "",
    },
  ];
  return (
    <Col>
      {/* <Form> */}
      <EditContainerProduct
        title={"Item details"}
        formData={itemDetails}
        handleChildFieldChange={handleChildFieldChange}
        isFetching={isFetching}
      />
      <EditContainerProduct
        title={"Pricing"}
        formData={pricingDetails}
        handleChildFieldChange={handleChildFieldChange}
        isFetching={isFetching}
      />
      <EditContainerProduct
        title={"Additional details"}
        formData={additionalDetails}
        handleChildFieldChange={handleChildFieldChange}
        isFetching={isFetching}
      />
      {/* </Form> */}
    </Col>
  );
};

export default BasicDetails;

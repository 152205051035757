import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Input, Form, Select, Typography } from "antd";
import "./AddMoneyModal.css";
import { amountType } from "../../../commonUtils/commonUtils";

const AddMoneyModal = ({
  isModalOpen,
  setIsModalOpen,
  form,
  handleOk,
  loading,
  amountTypeColor,
  setAmountTypeColor,
}) => {
  const { Option } = Select;
  const { Text } = Typography;
  const [amount, setAmount] = useState("");
  const handleChangeAmount = (event) => {
    setAmount(event.target.value);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setAmountTypeColor("CREDIT");
    setAmount("");
    form.resetFields();
    form.setFieldsValue({ amount_type: "CREDIT" });
  };
  useEffect(() => {
    form.setFieldsValue({ amount_type: "CREDIT" });
    setAmount("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);
  return (
    <Modal
      title="Add money to wallet"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Col
          key="footer"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "28px",
            borderTop: "1px solid #dedede",
          }}
        >
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              height: "48px",
              fontWeight: 500,
              fontSize: "14px",
              width: "120px",
            }}
          >
            CANCEL
          </Button>
          <Button
            key="save"
            type="primary"
            loading={loading}
            onClick={handleOk}
            style={{
              height: "48px",
              fontWeight: 500,
              fontSize: "14px",
              width: "120px",
              backgroundColor: "#0354a3",
            }}
          >
            SAVE
          </Button>
        </Col>,
      ]}
    >
      <Form form={form} layout="vertical" style={{ padding: "8px 24px" }}>
        <Text className="amount_label_text">Amount</Text>
        <Col
          className={`amount_container ${
            amountTypeColor === "DEBIT" ? "amount_container_debit" : ""
          } ${amountTypeColor === "CREDIT" ? "amount_container_credit" : ""}`}
        >
          <Form.Item
            name="amount_type"
            // label="Amount"
            rules={[{ required: true, message: "Amount type is required" }]}
            style={{ margin: 0 }}
          >
            <Select
              placeholder="Select Type"
              onChange={(e) => {
                setAmountTypeColor(e);
              }}
              defaultValue={"CREDIT"}
              // value={formData.gender}
              name="amount_type"
              style={{
                border: "none",
                borderRadius: "6px",
                height: "46px",
                width: "130px",
              }}
            >
              {/* <Option disabled value="">
              Select Gender
            </Option> */}
              {amountType.map((option, index) => (
                <Option key={index} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="amount"
            // label={<Col style={{ fontWeight: 500 }}>Amount</Col>}
            rules={[{ required: true, message: "Amount is required" }]}
          >
            <Input
              name="amount"
              placeholder="Enter amount"
              style={{ height: "46px", border: "none", textAlign: "right" }}
              className="wallet-amount-input"
              onChange={handleChangeAmount}
              prefix={
                <Text
                  style={{
                    fontSize: "18px",
                    color:
                      form?.getFieldValue()?.amount_type === "CREDIT"
                        ? "#13863f"
                        : "#c13615",
                    fontWeight: 500,
                  }}
                >
                  {form?.getFieldValue()?.amount_type === "CREDIT" ? "+" : "-"}
                </Text>
              }
              onKeyDown={(e) => {
                const { value, selectionStart } = e.target;
                const decimalIndex = value.indexOf(".");
                const hasDecimal = decimalIndex !== -1;

                if (
                  (e.key < "0" || e.key > "9") &&
                  e.key !== "Backspace" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight" &&
                  e.key !== "Tab" &&
                  // e.key !== "+" &&
                  // e.key !== "-" &&
                  e.key !== "."
                ) {
                  e.preventDefault();
                }

                if (e.key === "." && hasDecimal) {
                  e.preventDefault();
                }

                if (
                  hasDecimal &&
                  selectionStart > decimalIndex &&
                  value.substring(decimalIndex + 1).length >= 2
                ) {
                  if (e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }
              }}
            />
          </Form.Item>
        </Col>
        <Form.Item
          name="note"
          label={<Col style={{ fontWeight: 500 }}>Note</Col>}
        >
          <Input.TextArea
            name="note"
            rows={4}
            placeholder="Enter note"
            className="wallet-amount-input"
          />
        </Form.Item>
        {amount && (
          <Col className="customer-wallet-notify">
            {form?.getFieldValue()?.amount_type === "CREDIT" ? (
              <>
                {" "}
                ₹ {amount || 0} will be added immediately to customer’s MOB
                wallet.
              </>
            ) : (
              <>
                ₹ {amount || 0} will be deducted immediately from
                customer’s MOB wallet.
              </>
            )}
          </Col>
        )}
      </Form>
    </Modal>
  );
};

export default AddMoneyModal;

import {
  // useCallback,
  useEffect,
  useState,
} from "react";
import {
  Avatar,
  Col,
  Dropdown,
  Space,
  Typography,
  // Input,
  // Button,
  // Menu,
  // Divider,
  // Popconfirm,
  // Radio,
} from "antd";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {ReactComponent as TableInfo1} from "../../../assets/icons/TableInfo2.svg";
import {ReactComponent as TableInfo} from "../../../assets/icons/TableInfo.svg";
import {ReactComponent as TableShopIcon} from "../../../assets/icons/TableShopicon.svg";
import {useDispatch} from "react-redux";
import "./CanceledTableHeader.css";
import {baseUrl} from "../../../commonUtils/commonUtils";
import {useLazyGetAllVendorsQuery} from "../../../apis/createQuote";
// import {debounce} from "lodash";
// import {ReactComponent as DeleteSubIcon} from "../../../assets/icons/DeleteSubOrder.svg";
// import {ReactComponent as AddIcon} from "../../../assets/icons/AddIcon.svg";
// import {ReactComponent as DotIcon} from "../../../assets/icons/DotIcon.svg";
// import {ReactComponent as SearchIcon} from "../../../assets/icons/search-3.svg";

const {Text} = Typography;
const CanceledTableHeader = ({
  // handleAddRow,
  // handleDeleteTable,
  tableIndex,
  sellerList,
  getAllVendors,
  selectedSeller,
  setSelectedSeller,
  editCondition,
  initialValue,
  // bmpPrice,
  // getBmpPrice,
  subOrderID = false,
  disableAll,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  // const [searchText, setSearchText] = useState("");
  const [
    getAllVendorsApi,
    {
      data: vendorList,
      // isFetching: isLoading
    },
  ] = useLazyGetAllVendorsQuery();

  // const dropDownList = sellerList?.map((element, index) => {
  //   return {...element, label: element.vendor_name, key: element.bmp_id};
  // });

  useEffect(() => {
    if (vendorList) {
      dispatch(getAllVendors(vendorList?.data?.results));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorList]);
  useEffect(() => {
    getAllVendorsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editCondition && sellerList?.length > 0 && vendorList?.data) {
      const list = initialValue?.map((item, index) => {
        const sellerData = sellerList
          ?.map((element, i) => {
            if (element.bmp_id === item) {
              const editObj = {
                ...element,
                label: element.vendor_name,
                vendor_name: element.vendor_name,
                key: element.bmp_id,
                bmp_id: element.bmp_id,
              };
              return editObj;
            }
            return null;
          })
          .filter(Boolean);
        return sellerData;
      });
      let finalList = [];
      list?.map((item) => {
        item.map((element) => {
          return finalList.push(element);
        });
        return null;
      });
      if (!(initialValue?.length <= selectedSeller?.length)) {
        setSelectedSeller(finalList || selectedSeller);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCondition, sellerList, vendorList]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };
  //   const handleTrigger = (params) => {
  //     // getAllVendorsApi(params);
  //     // handleSearchedValue(params);
  //   };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  //   const searchTextDebouncedTrigger = useCallback(
  //     debounce(handleTrigger, 1000),
  //     []
  //   );
  //   const handleSearch = (e) => {
  //     const value = e.target.value;
  //     setSearchText(value);
  //     if (value.length >= 3) {
  //       searchTextDebouncedTrigger({
  //         search: value,
  //       });
  //     }
  //   };

  //   const handleSelect = (e) => {
  //     const value = e.target.value;
  //     const list = [...selectedSeller];
  //     list[tableIndex] = value;
  //     setSelectedSeller(list);
  //     setSearchText("");
  //     setVisible(false);
  //   };

  // const filteredItems = dropDownList?.filter((item) =>
  //   item.label.toLowerCase().includes(searchText.toLowerCase())
  // );

  const getNameInitials = (fullName) => {
    const names = fullName.split(" ");
    const firstNameInitial = names[0] ? names[0][0].toUpperCase() : "";
    const lastNameInitial = names[1] ? names[1][0].toUpperCase() : "";
    return `${firstNameInitial}${lastNameInitial}`;
  };
  // const onChange = (date, dateString) => {
  // };
  // const subOrderMenu = [
  //   {
  //     label: "Delete suborder",
  //     key: "0",
  //   },
  // ];
  //   const subOrderMenu = (
  //     <Menu>
  //       <Popconfirm
  //         title={
  //           <Text
  //             style={{
  //               display: "block",
  //             }}
  //           >
  //             Are you sure ?
  //           </Text>
  //         }
  //         style={{ width: "150px" }}
  //         trigger="click"
  //         onConfirm={() => handleDeleteTable(tableIndex)}
  //       >
  //         <Menu.Item
  //           key="1"
  //           // onClick={() => handleDeleteTable(tableIndex)}
  //         >
  //           {" "}
  //           <Col
  //             style={{
  //               color: "#bf2600",
  //               display: "flex",
  //               gap: "10px",
  //               alignItems: "center",
  //             }}
  //             // onClick={handleOpenPopover}
  //           >
  //             <DeleteSubIcon />
  //             <Text
  //               style={{
  //                 color: "#bf2600",
  //                 fontSize: "14px",
  //                 fontWeight: 500,
  //               }}
  //             >
  //               Delete suborder
  //             </Text>
  //           </Col>
  //         </Menu.Item>
  //       </Popconfirm>
  //     </Menu>
  //   );

  //   const dropdownContent = (
  //     <Menu style={{ width: "400px", height: "300px", overflow: "auto" }}>
  //       <Menu.Item key="search">
  //         <Input
  //           placeholder="Search seller"
  //           value={searchText}
  //           onChange={handleSearch}
  //           suffix={<SearchIcon style={{ width: "16px" }} />}
  //           style={{ width: "100%", height: "48px" }}
  //         />
  //       </Menu.Item>
  //       <Radio.Group
  //         style={{
  //           width: "100%",
  //           paddingTop: "15px",
  //           color: "#0a243f",
  //           fontWeight: 500,
  //           fontSize: "12px",
  //         }}
  //         value={
  //           selectedSeller[tableIndex]?.label ||
  //           selectedSeller[tableIndex]?.vendor_name ||
  //           ""
  //         }
  //         onChange={handleSelect}
  //       >
  //         {isLoading ? (
  //           <>
  //             <TableSkeleton length={4} />
  //           </>
  //         ) : (
  //           <>
  //             {sellerList?.map((item, index) => (
  //               <>
  //                 <Radio
  //                   key={item?.bmp_id}
  //                   value={item}
  //                   style={{ padding: "0 0 6px 16px" }}
  //                 >
  //                   {item?.bmp_id || ""}{" "}
  //                   {item?.vendor_name ? `(${item?.vendor_name})` : ""}
  //                 </Radio>
  //                 {index !== filteredItems.length - 1 && (
  //                   <Divider style={{ margin: "10px 0" }} />
  //                 )}
  //               </>
  //             ))}
  //           </>
  //         )}
  //       </Radio.Group>
  //     </Menu>
  //   );
  return (
    <>
      {tableIndex !== 0 &&
        !(
          selectedSeller[tableIndex]?.label ||
          selectedSeller[tableIndex]?.vendor_name
        ) && (
          <Col
            style={{
              background: "#f0483e",
              position: "absolute",
              top: "-38px",
              left: "0px",
              height: "40px",
              width: "100%",
              display: "flex",
              gap: "25px",
              alignItems: "center",
              paddingLeft: "24px",
            }}>
            <TableInfo1 />
            <Text style={{color: "#fff", fontSize: "14px", fontWeight: 500}}>
              Please select the seller for this suborder
            </Text>
          </Col>
        )}
      <Col
        key={tableIndex}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Col style={{display: "flex", alignItems: "center", gap: "24px"}}>
          {/* {!selectedSeller ? <TableShopIcon /> : <TableIcon />} */}
          <Avatar
            shape="square"
            src={`${baseUrl}${
              selectedSeller[tableIndex]?.bmp_image ||
              selectedSeller[tableIndex]?.seller_display_image
            }`}
            style={{
              backgroundColor: "#f3f2ef",
              // verticalAlign: "middle",
              borderRadius: "16px",
            }}
            size={60}
            icon={
              !(
                selectedSeller[tableIndex]?.label ||
                selectedSeller[tableIndex]?.vendor_name
              ) ? (
                <TableShopIcon style={{marginTop: "12px"}} />
              ) : (
                ""
              )
            }
            // gap={gap}
          >
            <Text style={{color: "#000", fontSize: "16px", fontWeight: "bold"}}>
              {selectedSeller[tableIndex]?.label ||
              selectedSeller[tableIndex]?.vendor_name
                ? getNameInitials(selectedSeller[tableIndex]?.vendor_name)
                : ""}
            </Text>
          </Avatar>
          <Dropdown
            // overlay={dropdownContent}
            style={{fontWeight: 500, color: "#0a243f", cursor: "not-allowed"}}
            trigger={["click"]}
            onVisibleChange={handleVisibleChange}
            visible={visible}
            disabled={disableAll}>
            <Space
              style={{
                fontWeight: 500,
                color: "#0a243f",
                width: "250px",
                cursor: "not-allowed",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}>
              <Col style={{display: "flex", flexDirection: "column"}}>
                <Text>
                  {selectedSeller[tableIndex]?.label ||
                  selectedSeller[tableIndex]?.vendor_name
                    ? selectedSeller[tableIndex]?.label ||
                      selectedSeller[tableIndex]?.vendor_name
                    : "Select seller"}{" "}
                  {selectedSeller[tableIndex]?.bmp_id || ""}
                </Text>
                {editCondition && subOrderID && (
                  <Text
                    style={{
                      color: "#0a243f",
                      fontSize: "14px",
                      fontWeight: 400,
                      opacity: 0.8,
                    }}>
                    Sub ID: {selectedSeller[tableIndex]?.bmp_id || ""}
                  </Text>
                )}
              </Col>
              {!(
                selectedSeller[tableIndex]?.label ||
                selectedSeller[tableIndex]?.vendor_name
              ) && <TableInfo style={{paddingTop: "4px"}} />}
              {visible ? <UpOutlined /> : <DownOutlined />}
            </Space>
          </Dropdown>
          {/* {(selectedSeller[tableIndex]?.label || tableIndex !== 0) && (
            <Tag
              style={{
                width: "160px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                fontWeight: 500,
                fontSize: "14px",
              }}
              className={`tag-font-style ${colorMapping[""]}`}
            >
              <Document />
              View catalogue
            </Tag>
          )} */}
        </Col>
        {/* {(selectedSeller[tableIndex]?.label ||
          selectedSeller[tableIndex]?.vendor_name ||
          tableIndex !== 0) && (
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
            }}
          >
            <DeliveryDatePopover
              tableIndex={tableIndex}
              editCondition={editCondition}
              bmpPrice={bmpPrice}
              getBmpPrice={getBmpPrice}
            />
            <Button
              onClick={() => handleAddRow(tableIndex)}
              type="primary"
              style={{
                width: "140px",
                fontWeight: 500,
                height: "33px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              icon={<AddIcon style={{ alignItems: "center" }} />}
            >
              Add item
            </Button>
            {tableIndex !== 0 && (
              <Dropdown
                   overlay={subOrderMenu}
                trigger={["click"]}
              >
                <Col
                  style={{
                    height: "33px",
                    width: "33px",
                    borderRadius: "8px",
                    border: "solid 1px #eaeaea",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <DotIcon />
                </Col>
              </Dropdown>
            )}
          </Col>
        )} */}
      </Col>
    </>
  );
};

export default CanceledTableHeader;

import { Col, Typography, Form, Input, Button, List } from "antd";
import React from "react";
import "./PasswordChange.css";
import { showErrorToast, showSuccessToast } from "../../NotificationToast/NotificationToast";
import { useChangePasswordMutation } from "../../apis/userManagement";

const { Text } = Typography;
const passwordTips = [
  "Use at least 8 characters, a combination of numbers and letters is best.",
  "Do not use the same password you have used with us previously.",
  "Do not use dictionary words, your name, e-mail address, mobile phone number or other personal information that can be easily obtained.",
  "Do not use the same password for multiple online accounts.",
];
const PasswordChange = () => {
  const [changePasswordApi] = useChangePasswordMutation();
  const [form] = Form.useForm();

  const handleReset = () => {
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    if (values) {
      const response = await changePasswordApi({
        old_password: values.currentPassword,
        new_password: values.password2,
      });
      if(response?.error?.data?.status===false){
        showErrorToast(response?.error?.data?.message)
        form.resetFields();
        
      }else{
        
        showSuccessToast(response?.data?.message)
        form.resetFields();
        
      }
    }
  };

  return (
    <>
      <Col className="password-change-container">
        <Col className="password-change-wrapper">
          <Col className="password-change-text-wrapper">
            <Text className="password-change-header">Change Password</Text>
            <Text className="password-change-headerI">
              We'll ask for this password whenever you sign in.
            </Text>
          </Col>
          <Form
            form={form}
            name="passwordChange"
            autoComplete="off"
            style={{
              // maxWidth: 500,
            }}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Form.Item
              label={
                <Col className="change-password-text">Current Password</Col>
              }
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your current password",
                },
              ]}
            >
              <Input type="password" className="change-password-input" />
            </Form.Item>

            <Form.Item
              label={<Col className="change-password-text">New Password</Col>}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your new password",
                },
                {
                  min: 8,
                  message: "Password must be at least 8 characters",
                },
                {
                  pattern: /^(?=.*[A-Z])(?=.*\d)/,
                  message: "Password must contain at least 1 uppercase letter and 1 number",
                },
              ]}
            >
              <Input type="password" className="change-password-input" />
            </Form.Item>

            <Form.Item
              label={
                <Col className="change-password-text">Confirm Password</Col>
              }
              name="password2"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input type="password" className="change-password-input" />
            </Form.Item>

            <Form.Item className="change-password-btn-wrapper">
              <Button
                htmlType="button"
                onClick={handleReset}
                className="change-password-reset"
              >
                RESET
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="change-password-submit"
              >
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col style={{minWidth:"350px"}}>
        <Col className="change-password-tips-wrapper">
          <List
            header={
              <Col className="password-change-header">
                Secure password tips:
              </Col>
            }
            //   bordered
            dataSource={passwordTips}
            renderItem={(item) => (
              <List.Item className="change-password-text">
                <Text
                  className=""
                  style={{ marginRight: "8px", fontSize: "20px" }}
                  >
                  &#8226;
                </Text>
                {item}
              </List.Item>
            )}
            />
        </Col>
            </Col>
      </Col>
    </>
  );
};

export default PasswordChange;

import React, { useEffect, useState } from "react";
import "./PickupDetails.css";
import { Button, Col, Divider, Skeleton, Typography } from "antd";
import {
  CardContent,
  ContentText,
} from "../../../../Request/RFQ/CreateQuote/CreateQuoteProfileSection/ProfileStyled";
import AddNewSellerModal from "../../../../../components/Modal/AddNewSellerModal/AddNewSellerModal";
import {
  useCreateSellerAddressMutation,
  useDeleteSellerAddressMutation,
  useLazyGetAllSellerAddressQuery,
  useUpdateSellerAddressMutation,
} from "../../../../../apis/sellerCatalogue";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSellerAddress,
  getEditSellerAddress,
} from "../../../../../redux/slices/sellerCatalogue/action";
import {
  allAddressSellerSelector,
  editAddressSellerSelector,
} from "../../../../../redux/slices/sellerCatalogue/selector";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../../NotificationToast/NotificationToast";

export const PickupDetails = (props) => {
  const { seller_id } = props;
  const { Text } = Typography;
  const dispatch = useDispatch();
  const allAddressSelector = useSelector(allAddressSellerSelector);
  const editAddressSelector = useSelector(editAddressSellerSelector);
  const [openModal, setOpenModal] = useState(false);
  const [
    getAllSellerAddressAPI,
    { data: allAddressesData, isFetching: isFetchingAllAddressData },
  ] = useLazyGetAllSellerAddressQuery();
  const [deleteAddressAPI] = useDeleteSellerAddressMutation();
  const [createSellerAddressAPI, { isLoading: isCreateLoading }] =
    useCreateSellerAddressMutation();
  const [updateSellerAddressAPI, { isLoading: isUpdateLoading }] =
    useUpdateSellerAddressMutation();
  useEffect(() => {
    if (seller_id) {
      getAllSellerAddressAPI({ bmp_id: seller_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (allAddressesData?.status) {
      dispatch(getAllSellerAddress(allAddressesData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAddressesData]);
  const handleDeleteAddress = async (id) => {
    const response = await deleteAddressAPI({ address_id: id });

    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      getAllSellerAddressAPI({ bmp_id: seller_id });
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    dispatch(getEditSellerAddress({}));
  };
  const handleEditaddress = (data) => {
    dispatch(getEditSellerAddress(data));
    setOpenModal(true);
  };
  return (
    <>
      <Col>
        <Col className="pickup-header">
          <Text className="pickup-header-text">Pickup Addresses</Text>
          <Button
            className="pickup-header-button"
            onClick={() => handleOpenModal()}
          >
            Add Address
          </Button>
        </Col>
        {isFetchingAllAddressData ? (
          <>
            <Col style={{ display: "flex", gap: "30px" }}>
              <Skeleton active paragraph={{ rows: 5 }} />
              <Skeleton active paragraph={{ rows: 5 }} />
            </Col>
          </>
        ) : (
          <Col className="pickup-header-body">
            {allAddressSelector?.map((item, index) => {
              return (
                <>
                  <Col className="pickup-card">
                    <Col style={{ padding: "20px" }}>
                      <Col className="pickup-card-heading">{item?.name}</Col>
                      <CardContent style={{ width: "100%" }}>
                        <ContentText>
                          {item?.address} , {item?.address_line_2}
                        </ContentText>

                        <ContentText>
                          {item?.state} , {item?.city} , {item?.pincode}
                        </ContentText>
                        <ContentText>+91 {item?.phone_number}</ContentText>

                        {item?.map_url && (
                          <Col className="map-url-container">
                            <Text className="map-url-heading">Map Url</Text>
                            <Text className="map-url-text">
                              {item?.map_url || "no value"}
                            </Text>
                          </Col>
                        )}
                      </CardContent>
                    </Col>
                    <Col className="pickup-card-actions">
                      <Button
                        className="actions-buttons"
                        onClick={() => handleEditaddress(item)}
                      >
                        {" "}
                        Edit
                      </Button>
                      <Divider
                        type="vertical"
                        className="pickup-divider"
                      ></Divider>
                      <Button
                        className="actions-buttons"
                        onClick={() => handleDeleteAddress(item?.id)}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Col>
                </>
              );
            })}
            <AddNewSellerModal
              deliveryModal={openModal}
              setIsModalOpen={setOpenModal}
              setDeliveryModal={setOpenModal}
              editData={editAddressSelector}
              getAllAddressAPI={getAllSellerAddressAPI}
              dispatchEditData={getEditSellerAddress}
              isCreateLoading={isCreateLoading}
              isUpdateLoading={isUpdateLoading}
              createAddressAPI={createSellerAddressAPI}
              updateAddressAPI={updateSellerAddressAPI}
              fetchingID={"bmp_id"}
              // initialFormData={initialFormData}
              // formData={formData}
              // setFormData={setFormData}
              // addressListModal={showModal}
            />
          </Col>
        )}
      </Col>
    </>
  );
};

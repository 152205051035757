import React from "react";
import {
  Button,
  Col,
  Modal,
  // Input,
  // Form,
  Typography,
  // DatePicker,
  // TimePicker,
} from "antd";
import "./WalletToBankModal.css";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
// import dayjs from "dayjs";
const { Text } = Typography;
const ConfirmModal = ({
  isModalOpen,
  setIsModalOpen,
  form,
  mainModal,
  api,
  formData,
}) => {
  const values = form.getFieldsValue();

  const handleOk = async () => {
    setIsModalOpen(false);

    const response = await api(formData);

    if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    } else {
      mainModal(false);
      form.resetFields();
      const responseData = response?.data;
      showSuccessToast(responseData?.message);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      title="Are you sure?"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Col
          key="footer"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px",
            // borderTop: "1px solid #dedede",
          }}
        >
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              height: "48px",
              fontWeight: 500,
              fontSize: "14px",
              //   width: "120px",
            }}
          >
            CANCEL
          </Button>
          <Button
            key="save"
            type="primary"
            onClick={handleOk}
            style={{
              height: "48px",
              fontWeight: 500,
              fontSize: "14px",
              width: "120px",
              backgroundColor: "#0354a3",
            }}
          >
            CONFIRM
          </Button>
        </Col>,
      ]}
    >
      <Col style={{ padding: "18px", fontSize: "16px", fontWeight: 500 }}>
        <Text style={{ fontWeight: 500, fontSize: "18px" }}>
          ₹ {values.amount}
        </Text>{" "}
        wallet amount will be deducted immediately from customer account after
        requesting.
      </Col>
    </Modal>
  );
};

export default ConfirmModal;

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";

export const customersAPI = createApi({
  reducerPath: "customerAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: (params) => {
        return {
          url: `accounts/mob_customer/get_customers/`,
          params,
        };
      },
    }),
    getCustomerDetail: builder.query({
      query: (id) => {
        return {
          url: `accounts/mob_customer/${id}/get_customer_details/`,
        };
      },
    }),
    createCustomer: builder.mutation({
      query: (params) => ({
        url: "accounts/mob_user/create_user/",
        method: "POST",
        body: params,
      }),
    }),
    updateCustomer: builder.mutation({
      query: (params) => ({
        url: "accounts/mob_user/update_user/",
        method: "PATCH",
        body: params,
      }),
    }),
    updateProfessionalCustomer: builder.mutation({
      query: (params) => ({
        url: "/accounts/mob_customer/update_professional_customer/",
        method: "POST",
        body: params,
      }),
    }),
    deleteCustomer: builder.mutation({
      query: (params) => ({
        url: "accounts/mob_user/delete_user/",
        method: "DELETE",
        body: params,
      }),
    }),
    getDownloadCustomers: builder.query({
      query: (params) => {
        return {
          url: `accounts/mob_customer/download_customers/`,
          params,
        };
      },
    }),
    getCustomersOrders: builder.query({
      query: ({ id, params }) => {
        return {
          url: `api/customer/${id}/get_orders/`,
          params,
        };
      },
    }),
    getCustomersSubOrdersDetails: builder.query({
      query: (params) => {
        return {
          url: `api/customer/${params}/get_suborder_details/`,
        };
      },
    }),
    getCustomersAddress: builder.query({
      query: (params) => {
        return {
          url: `/accounts/mob_user/get_user_address/`,
          params,
        };
      },
    }),
  }),
});

export const {
  useLazyGetCustomersQuery,
  useLazyGetDownloadCustomersQuery,
  useLazyGetCustomerDetailQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useUpdateProfessionalCustomerMutation,
  useDeleteCustomerMutation,
  useLazyGetCustomersOrdersQuery,
  useLazyGetCustomersSubOrdersDetailsQuery,
  useLazyGetCustomersAddressQuery,
  use,
} = customersAPI;

export default customersAPI;

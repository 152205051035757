import {Col, Typography} from "antd";
import React from "react";
import "./CustomerCard.css";
import {ReactComponent as ArrowIcon} from "../../../../assets/icons/arrow-right.svg";
import {Link} from "react-router-dom";
const {Text} = Typography;
const CustomerCard = ({
  title,
  icon,
  handleClick,
  totalOrders,
  handleCardClick,
  state,
}) => {
  return (
    <Col className="customerCard-container">
      <Link to={handleCardClick} state={state}>
      <Col style={{display: "flex", alignItems: "center", gap: "1rem"}}>
        {icon}
        <Text style={{fontSize: "20px", fontWeight: 500}}>{title}</Text>
        {totalOrders >= 0 && (
          <Col
            style={{
              width: "40px",
              height: "32px",
              borderRadius: "8px",
              backgroundColor: "#e8e8e8",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 500,
              color:"#0a243f"
            }}>
            {totalOrders}
          </Col>
        )}
      </Col>
      </Link>
      <Link to={handleCardClick} state={state}>
        <ArrowIcon
          style={{cursor: "pointer"}}
          // onClick={() => handleCardClick()}
        />
      </Link>
    </Col>
  );
};

export default CustomerCard;

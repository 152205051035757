import React from "react";
import { Col, Modal, Table } from "antd";
import "./PaymentSummaryModal.css";
const PaymentSummaryModal = ({
  isModalOpen,
  setIsModalOpen,
  columns,
  data,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        title="Payment summary"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="styled-summary-modal"
      >
      <Col className="modal-table-wrapper">
      {  data?.length > 0 ?   <Table
            pagination={false}
            columns={columns}
            dataSource={data}
            className="styled-table custom-font"
            scroll={{ y: 240 }}
          />: <Col style={{textAlign:'center',fontSize:"16px",fontWeight:500}}>NO DATA FOUND !</Col>}
        </Col> 
      </Modal>
    </>
  );
};

export default PaymentSummaryModal;

import React from "react";
import {Col, Typography} from "antd";
import "./contentcard.css";
import {VehicleRequest} from "./DashboardCard/VehicleRequest";
import {RejectedBySeller} from "./DashboardCard/RejectedBySeller";
import {RFQRequests} from "./DashboardCard/RFQRequests";
import {RFQOrder} from "./DashboardCard/RFQOrder";
import {ItemIssues} from "./DashboardCard/ItemIssues";
import {ReturnExchange} from "./DashboardCard/ReturnExchange";
import {ServiceRequest} from "./DashboardCard/ServiceRequest";

const ContentCard = ({heading, length, data, handleNavigate}) => {
  const {Text} = Typography;

  const componentsMap = {
    "Vehicle request": <VehicleRequest data={data} />,
    "Rejected by seller": <RejectedBySeller data={data} />,
    "RFQ requests": <RFQRequests data={data} />,
    "Service request": <ServiceRequest data={data} />,
    "Accepted RFQ’s for order": <RFQOrder data={data} />,
    "Item issues": <ItemIssues data={data} />,
    "Return/ exchange": <ReturnExchange data={data} />,
    default: <Col key="default">Type Key has no value or Different value</Col>,
  };

  const selectedComponent = componentsMap[heading] || componentsMap.default;
  return (
    <>
      <Col className="dashboard-content-card">
        <Col className="dashboard-content-card-container">
          <Col className="dashboard-content-card-head-container">
            <Text className="dashboard-content-card-container-text-heading">
              {heading}
            </Text>
            <Text className="dashboard-content-card-container-text-heading-length">
              {length}
            </Text>
          </Col>

          <Text
            className="dashboard-content-card-container-text-view-all"
            onClick={handleNavigate}>
            View all
          </Text>
        </Col>
        {selectedComponent}
      </Col>
    </>
  );
};

export default ContentCard;

import React from "react";
import { Col, Typography } from "antd";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-right.svg";
import "../contentcard.css";

export const ItemIssues = (props) => {
  const { data } = props;
  const { Text } = Typography;
  return (
    <>
      <Col className="rejected-card-container">
        {data?.map((val, idx) => (
          <Col key={idx} className="rejected-card-data-container">
            <Col className="rejected-card-data-sub-container">
              <Col className="vehicle-sub-sub-card-status-container-image">
                <Col>{val.image}</Col>
                <Col className="rejected-card-data-sub-id-container">
                  <Text className="rejected-card-data-sub-id-text">
                    {val.id}
                  </Text>
                  <Text className="rejected-card-data-sub-name-text">
                    {val.date}
                  </Text>
                </Col>
              </Col>
              <Col className="item-issues-sub-name-container">
                <Col className="rejected-card-amount-sub-container">
                  <Text className="rejected-card-amount-sub-text">
                    {val.name}
                  </Text>
                  <Text className="rejected-card-amount-sub-text-item">
                    {val.amount}
                  </Text>
                </Col>
                <Col className="rejected-card-status-container">
                  <Col className="item-issues-status-container">
                    {val.status}
                  </Col>
                  <ArrowIcon />
                </Col>
              </Col>
            </Col>
          </Col>
        ))}
      </Col>
    </>
  );
};

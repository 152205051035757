import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./CustomerWallet.css";
import { Link } from "react-router-dom";
import { Button, Col, Form, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CashIcon } from "../../../assets/icons/cash-wallet.svg";
// import { ReactComponent as PointsIcon } from "../../../assets/icons/points-icon.svg";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import AddMoneyModal from "../AddMoneyModal/AddMoneyModal";
import {
  useGetAddMoneyToWalletMutation,
  useLazyGetWalletHistoryQuery,
} from "../../../apis/wallet";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import { dateFormat } from "../../../commonFuntions/CommonUtilFunctions";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import NoDataFound from "../../../components/EmptyTable/NoDataFound";
const { Text } = Typography;

const CustomerWallet = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amountTypeColor, setAmountTypeColor] = useState("CREDIT");
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [walletHistoryApi, { data: walletHistoryData, isLoading, isFetching }] =
    useLazyGetWalletHistoryQuery();
  const [addMoneyWalletApi] = useGetAddMoneyToWalletMutation();

  const handleOk = async () => {
    setLoading(true);

    const values = form.getFieldsValue();
    console.log("🚀 ~ handleOk ~ values:", values);

    let response;
    let errorOccurred = false;

    // Validate the form fields
    await form.validateFields().catch(() => {
      errorOccurred = true;
    });

    if (!errorOccurred) {
      response = await addMoneyWalletApi({
        amount: values?.amount,
        transaction_type: values?.amount_type,
        phone_number: params?.id,
        remarks: values?.note,
      }).catch((error) => {
        errorOccurred = true;
        showErrorToast(response?.error?.data?.message);
      });
    }

    if (!errorOccurred) {
      if (response?.data?.status) {
        form.resetFields();
        setIsModalOpen(false);
        showSuccessToast(response?.data?.message);
        walletHistoryApi(params?.id);
        setAmountTypeColor("CREDIT");
        form.setFieldsValue({ amount_type: "CREDIT" });
      } else {
        showErrorToast(response?.error?.data?.message);
      }
    }

    setLoading(false);
  };
  const handleBackNavigate = () => {
    navigate(-1);
  };
  const handleAddMoneyModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (params) {
      walletHistoryApi(params?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  const handlePageChange = async (currPage) => {
    // let brandArray = [];
    // let categoriesArray = [];
    // brands.map((item) => brandArray.push(item.label));
    // categories.map((item) => categoriesArray.push(item.label));
    const params = {
      page: currPage,
      // ...(searchedValue && { search: searchedValue?.search }),
      // ...(brandArray.length && { brands: brandArray }),
      // ...(categoriesArray.length && { category: categoriesArray }),
    };
    setCurrentPage(currPage);
    await walletHistoryApi(params?.id);
  };
  const columns = [
    {
      title: "TRANSACTIONS",
      dataIndex: "description",
      key: "description",
      fixed: "left",
      width: "100px",
      render: (params, record) => {
        return (
          <Col style={{ display: "flex", flexDirection: "column" }}>
            {record?.order && record?.order?.order_id !== undefined ? (
              <Text style={{ fontsize: "14px", fontWeight: 500 }}>
                {" "}
                {"Order ID: "}
                <Link
                  style={{ color: "#1677ff", fontWeight: 500 }}
                  className="order-id-text"
                  to={`/admin/orders/order-details/${record?.order?.id}`}
                  // onClick={() => handleDetailNav(record)}
                  target="_blank"
                >
                  {`${record?.order?.order_id}`}
                </Link>
              </Text>
            ) : (
              <Text style={{ fontsize: "14px", fontWeight: 500 }}>
                {" "}
                Remarks: {record?.remarks}
              </Text>
            )}
            <Text
              style={{ fontSize: "14px", fontWeight: 400, color: "#000000e0" }}
            >{`${record?.transaction_type} on ${dateFormat(
              record?.created_at
            )}`}</Text>
          </Col>
        );
      },
    },
    // {
    //   title: "POINTS",
    //   dataIndex: "points",
    //   key: "points",
    //   width: "50px",
    //   render: (params, record) => {
    //     return (
    //       <>
    //         <Text style={{ fontSize: "18px", opacity: 0.5, fontWeight: 500 }}>
    //           {record?.points}
    //         </Text>
    //       </>
    //     );
    //   },
    // },
    {
      title: "",
      dataIndex: "cash",
      key: "cash",
      width: "50px",
      render: (params, record) => {
        return (
          <>
            <Text
              style={{
                fontSize: "18px",
                color:
                  record?.transaction_type === "CREDIT" ? "#13863f" : "#c13615",
                fontWeight: 500,
                float: "right",
              }}
            >
              {record?.transaction_type === "CREDIT" ? "+" : "-"} ₹
              {(record?.amount || 0).toFixed(2)}
            </Text>
          </>
        );
      },
    },
    // {
    //   title: "BALANCE",
    //   dataIndex: "balance",
    //   key: "balance",
    //   width: "50px",
    //   render: (params, record) => {
    //     return (
    //       <>
    //         <Text style={{fontSize: "18px", fontWeight: 500}}>
    //           ₹ {(record?.current_balace || 0).toFixed(2)}
    //         </Text>
    //       </>
    //     );
    //   },
    // },
  ];
  return (
    <Col className="customer-wallet-container">
      <Button
        className="btn"
        icon={<ArrowLeftOutlined />}
        onClick={handleBackNavigate}
      >
        BACK
      </Button>
      <Text className="customer-wallet-heading">mobWALLET</Text>
      <Col style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="primary"
          className="add_customer_btn"
          onClick={handleAddMoneyModal}
          style={{ width: "203px" }}
        >
          Add money to wallet
        </Button>
      </Col>
      <Col className="customer-wallet-body">
        <Col className="customer-wallet-points">
          <Col className="wallet-points-align">
            <Col className="wallet-icon-wrapper">
              <CashIcon />
            </Col>
            <Col className="wallet-text-wrapper">
              <Text className="cash-text">
                {walletHistoryData?.data?.wallet
                  ? `₹ ${(walletHistoryData?.data?.wallet || 0).toFixed(2)}`
                  : "--"}
              </Text>
              <Text className="earned-text">Balance</Text>
            </Col>
          </Col>
          {/* <Col className="wallet-points-align"> */}
          {/* <Col className="wallet-icon-wrapper">
              <PointsIcon />
            </Col> */}
          {/* <Col className="wallet-text-wrapper">
              <Text className="cash-text" style={{ opacity: 0.5 }}>
                5000
              </Text>
              <Text className="earned-text" style={{ opacity: 0.5 }}>
                Points
              </Text>
            </Col> */}
          {/* </Col> */}
        </Col>
        {/* <Col className="customer-wallet-notify">
          MOB points are valid for 1 year from the date they are credited on
        </Col> */}
        <Col className="customer-wallet-notifyI">
          To add mobWALLET balance to your bank account please send an email to
          <Text style={{ fontWeight: 500 }}> ask@madoverbuildings.com</Text>
        </Col>
        <CustomTableWithPagination
          className="order-list-table"
          //   tableExpandable={defaultExpandable}
          //   tableDataSource={tableDataSource || []}
          tableDataSource={walletHistoryData?.data?.results || []}
          // tableDataSource={tableDataSource || []}
          tableColumns={columns}
          locale={{
            emptyText: isFetching ? (
              <TableSkeleton length={10} />
            ) : (
              <NoDataFound text={"No transaction found!"} />
            ),
          }}
          scroll={{ x: "100%" }}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          itemsPerPage={30}
          totalEntries={
            !isLoading && walletHistoryData?.data?.pagination?.total_entries
          }
        />
      </Col>
      <AddMoneyModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        form={form}
        handleOk={handleOk}
        loading={loading}
        setLoading={setLoading}
        amountTypeColor={amountTypeColor}
        setAmountTypeColor={setAmountTypeColor}
      />
    </Col>
  );
};

export default CustomerWallet;

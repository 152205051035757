export const MobIcon = (props) => {
  const style = {
    fill: "#00de9d",
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="Group 14453">
        <path
          data-name="Path 2506"
          d="M419.292 163a3.292 3.292 0 0 1 3.292 3.292 3.292 3.292 0 0 1-3.292 3.292 3.292 3.292 0 0 1-3.292-3.292 3.292 3.292 0 0 1 3.292-3.292z"
          transform="translate(-416 -145.756)"
          style={style}
        />
        <path
          data-name="Path 2507"
          d="M419.292 108a3.292 3.292 0 0 1 3.292 3.292 3.292 3.292 0 0 1-3.292 3.292 3.292 3.292 0 0 1-3.292-3.292 3.292 3.292 0 0 1 3.292-3.292z"
          transform="translate(-416 -99.378)"
          style={style}
        />
        <path
          data-name="Path 2508"
          d="M419.292 53a3.292 3.292 0 0 1 3.292 3.292 3.292 3.292 0 0 1-3.292 3.292A3.292 3.292 0 0 1 416 56.292 3.292 3.292 0 0 1 419.292 53z"
          transform="translate(-416 -53)"
          style={style}
        />
        <path
          data-name="Path 2509"
          d="M474.292 163a3.292 3.292 0 0 1 3.292 3.292 3.292 3.292 0 0 1-3.292 3.292 3.292 3.292 0 0 1-3.292-3.292 3.292 3.292 0 0 1 3.292-3.292z"
          transform="translate(-462.378 -145.756)"
          style={style}
        />
        <path
          data-name="Path 2510"
          d="M474.292 108a3.292 3.292 0 0 1 3.292 3.292 3.292 3.292 0 0 1-3.292 3.292 3.292 3.292 0 0 1-3.292-3.292 3.292 3.292 0 0 1 3.292-3.292z"
          transform="translate(-462.378 -99.378)"
          style={style}
        />
        <path
          data-name="Path 2511"
          d="M474.292 53a3.292 3.292 0 0 1 3.292 3.292 3.292 3.292 0 0 1-3.292 3.292A3.292 3.292 0 0 1 471 56.292 3.292 3.292 0 0 1 474.292 53z"
          transform="translate(-462.378 -53)"
          style={style}
        />
        <path
          data-name="Path 2512"
          d="M529.292 108a3.292 3.292 0 0 1 3.292 3.292 3.292 3.292 0 0 1-3.292 3.292 3.292 3.292 0 0 1-3.292-3.292 3.292 3.292 0 0 1 3.292-3.292z"
          transform="translate(-508.756 -99.378)"
          style={style}
        />
      </g>
    </svg>
  );
};

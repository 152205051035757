import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";

export const settlementAPI = createApi({
  reducerPath: "settlementAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSettlementTable: builder.query({
      query: (params) => {
        return {
          url: `api/settlements/settlement/get_vendors/`,
          params,
        };
      },
    }),
    getDownloadPdfPayment: builder.query({
      query: (params) => {
        return {
          url: `api/settlements/settlement/download_payments/`,
          params,
        };
      },
    }),
    getDownloadPdfMobPayment: builder.query({
      query: (params) => {
        return {
          url: `api/settlements/settlement/download_orders/`,
          params,
        };
      },
    }),
    getSettlementDataByID: builder.query({
      query: (params) => {
        return {
          url: `api/settlements/settlement/get_settlements/`,
          params,
        };
      },
    }),
    getSearchSettlementVendors: builder.query({
      query: (params) => {
        return {
          url: `api/settlements/settlement/get_mob_vendors/`,
          params,
        };
      },
    }),
    getMobOrders: builder.query({
      query: ({params, bmp_id}) => {
        return {
          url: `api/settlements/settlement/get_mob_orders/`,
          params: { ...params, bmp_id },
        };
      },
    }),
    addSettlementPayment: builder.mutation({
      query: (data) => {
        // const list = [];
        // data?.receipt?.map((element) => list.push(element.originFileObj));
        const formData = new FormData();
        formData.append("mob_vendor", data?.mob_vendor);
        formData.append("payment_mode", data?.payment_mode);
        formData.append("payment_date", data?.payment_date);
        formData.append("payment_ref_no", data?.payment_ref_no);
        formData.append("receipt", data?.receipt);
        // for (let i = 0; i <= list?.length - 1; i++) {
        //   formData.append("receipt", list[i]);
        // }
        formData.append("remarks", data?.remarks);
        formData.append("settlement_amount", data?.settlement_amount);
        return {
          url: `api/settlements/settlement/create_payment/`,
          method: "POST",
          body: formData,
        };
      },
    }),
    createSettlementPayment: builder.mutation({
      query: (data) => {
        return {
          url: `api/settlements/settlement/create_sellements/`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateSettlementPayment: builder.mutation({
      query: (data) => {
        // const list = [];
        // data?.receipt?.map((element) => list.push(element.originFileObj));
        const formData = new FormData();
        // formData.append("mob_vendor", data?.mob_vendor);
        formData.append("payment_id", data?.payment_id);
        formData.append("payment_mode", data?.payment_mode);
        formData.append("payment_date", data?.payment_date);
        formData.append("payment_ref_no", data?.payment_ref_no);
        formData.append("receipt", data?.receipt);
        // for (let i = 0; i <= list?.length - 1; i++) {
        //   formData.append("receipt", list[i]);
        // }
        formData.append("remarks", data?.remarks);
        formData.append("settlement_amount", data?.settlement_amount);
        return {
          url: `api/settlements/settlement/update_payment/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useLazyGetSettlementTableQuery,
  useLazyGetSettlementDataByIDQuery,
  useAddSettlementPaymentMutation,
  useLazyGetSearchSettlementVendorsQuery,
  useUpdateSettlementPaymentMutation,
  useLazyGetMobOrdersQuery,
  useCreateSettlementPaymentMutation,
  useLazyGetDownloadPdfPaymentQuery,
  useLazyGetDownloadPdfMobPaymentQuery,
} = settlementAPI;

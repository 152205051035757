import React, { useState } from "react";
import { Button, Col, Input, Row, Table, Typography, message } from "antd";
import copy from "copy-to-clipboard";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import "./MobLibraryCard.css";
const MobLibraryCard = ({
  productData,
  productRecords,
  showCards,
  updateSKU,
  text,
}) => {
  const { Text, Title } = Typography;
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const data = [];
  productData?.map((item, index) =>
    data.push({
      key: item?.index,
      index: item?.index,
      status: item?.status,
      description: item?.error,
    })
  );
  const filteredItems = data?.filter(
    (item) =>
      item.status
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item.description?.toLowerCase().includes(searchText?.toLowerCase())
  );

  const copyToClipboard = () => {
    const startString = `MOB | Admin Portal${"\n"} Sr.No Status description Status ${"\n"}`;
    const textToCopy = filteredItems
      .map((item) => `${item?.index} ${item?.description} ${item?.status}`)
      .join("\n");
    copy(startString + textToCopy);
    message.success("Text copied to clipboard");
  };

  const exportToCSV = () => {
    const mainHeading = "MOB | Admin Portal";
    const csvContent = [
      mainHeading,
      columns.map((column) => column.title).join(","),
      ...filteredItems.map((record) =>
        columns.map((column) => record[column.dataIndex]).join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "data.csv");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("MOB | Admin Portal", 10, 10);
    doc.autoTable({
      head: [columns.map((column) => column.title)],
      body: filteredItems.map((record) =>
        columns.map((column) => record[column.dataIndex])
      ),
    });
    doc.save("data.pdf");
  };

  const printData = () => {
    const printWindow = window.open("", "", "width=800,height=500");
    printWindow.document.open();

    const tableHTML = `
      <html>
        <head>
          <title>Print</title>
        </head>
        <body >
          <h1>MOB | Admin Portal</h1>
          <table style='border-collapse: collapse;
          width: 90%;
          border: 1px solid #dedede;'>
            <thead style="border: 1px solid #ddd;
            padding: 8px;
            text-align: left;">
              <tr style="border: 1px solid #ddd;
              padding: 8px;
              text-align: left;">
                ${columns
                  .map(
                    (column) => `<th style="border: 1px solid #ddd;
                padding: 8px;
                text-align: left;">${column.title}</th>`
                  )
                  .join("")}
              </tr>
            </thead>
            <tbody style="border: 1px solid #ddd;
            padding: 8px;
            text-align: left;">
              ${filteredItems
                .map(
                  (record) => `<tr style="border: 1px solid #ddd;
                padding: 8px;
                text-align: left;">
                  ${columns
                    .map(
                      (column) => `<td style="border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;">${record[column.dataIndex]}</td>`
                    )
                    .join("")}
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
        </body>
      </html>
    `;

    printWindow.document.write(tableHTML);
    printWindow.document.close();
    printWindow.print();
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    const mainHeading = "MOB | Admin Portal";
    const mainHeadingCell = { t: "s", v: mainHeading };
    mainHeadingCell.s = {
      font: { sz: 16, bold: true },
      alignment: { horizontal: "center" },
    };
    const dataColumn = columns.map((item) => item.title);
    const dataRows = filteredItems.map((item) => [
      item.index,
      item.description,
      item.status,
    ]);

    const dataWorksheet = XLSX.utils.aoa_to_sheet([
      [mainHeading],
      [],
      dataColumn,
      ...dataRows,
    ]);

    XLSX.utils.book_append_sheet(workbook, dataWorksheet, "Data");

    XLSX.writeFile(workbook, "data.xlsx");
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "index",
      key: "index",
      render: (params) => <Text>{params}</Text>,
    },
    {
      title: "Status Description",
      dataIndex: "description",
      key: "description",
      render: (params) => <Text>{params}</Text>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (params) => <Text>{params}</Text>,
    },
  ];
  return (
    <>
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "60%",
          border: "1px solid #dedede",
          borderRadius: "8px",
          boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
          marginTop: "20px",
        }}
      >
        <Row
          style={{
            width: "100%",
            padding: "24px",
            gap: "16px",
            justifyContent: "space-around",
          }}
        >
          {showCards && (
            <>
              <Col
                style={{
                  width: updateSKU ? "22%" : "30%",
                  height: "102px",
                  borderRadius: "8px",
                  backgroundColor: "#f5f5f5",
                  padding: "0.7rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Col>
                  <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                    Total records
                  </Text>
                </Col>
                <Col>
                  <Title level={2} style={{ margin: 0 }}>
                    {productRecords?.totalRecords}
                  </Title>
                </Col>
              </Col>
              <Col
                style={{
                  width: updateSKU ? "22%" : "30%",
                  height: "102px",
                  borderRadius: "8px",
                  backgroundColor: "#ebf7ef",
                  padding: "0.7rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Col>
                  <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                    {text ? text : updateSKU ? "Rewritten" : "Imported"}
                  </Text>
                </Col>
                <Col>
                  <Title level={2} style={{ margin: 0 }}>
                    {updateSKU
                      ? productRecords?.updated
                      : productRecords?.imported}
                  </Title>
                </Col>
              </Col>
              <Col
                style={{
                  width: updateSKU ? "22%" : "30%",
                  height: "102px",
                  borderRadius: "8px",
                  backgroundColor: "#ffebe6",
                  padding: "0.7rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Col>
                  <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                    Rejected
                  </Text>
                </Col>
                <Col>
                  <Text style={{ fontWeight: 900, fontSize: "28px" }}>
                    {productRecords?.rejected}
                  </Text>
                </Col>
              </Col>
              {updateSKU && (
                <Col
                  style={{
                    width: updateSKU ? "22%" : "30%",
                    height: "102px",
                    borderRadius: "8px",
                    backgroundColor: "#05f1052e",
                    padding: "0.7rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Col>
                    <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                      New Items Added
                    </Text>
                  </Col>
                  <Col>
                    <Text style={{ fontWeight: 900, fontSize: "28px" }}>
                      {productRecords?.newly_created}
                    </Text>
                  </Col>
                </Col>
              )}
            </>
          )}
          <Col style={{ width: "100%", display: "flex", gap: "8px" }}>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
            />

            <Button onClick={copyToClipboard}>Copy</Button>
            <Button onClick={exportToExcel}>Excel</Button>
            <Button onClick={exportToCSV}>CSV</Button>
            <Button onClick={exportToPDF}>PDF</Button>
            <Button onClick={printData}>Print</Button>
          </Col>
        </Row>
        {/* <Col
          style={{
            backgroundColor: "#e8e8e8",
            padding: "21px",
            width: "100%",
            display: "flex",
          }}
        >
          <Col style={{ width: "20%" }}>
            <Text style={{ fontSize: "16px", fontWeight: 500 }}>Status</Text>
          </Col>
          <Col style={{ width: "80%" }}>
            <Text style={{ fontSize: "16px", fontWeight: 500 }}>
              Status description
            </Text>
          </Col>
        </Col> */}
        <Table
          className="library-table"
          columns={columns}
          dataSource={filteredItems}
          pagination={{ pageSize: 50, showSizeChanger: false }}
        />

        {/* {apiData?.data?.errors?.errorList?.map((element, index) => {
          return (
            <Col
              style={{
                borderTop: "1px solid #e8e8e8",
                padding: "16px 24px",
                width: "100%",
                display: "flex",
              }}
            >
              <Col style={{ width: "20%" }}>
                <Text style={{ fontSize: "14px", fontWeight: 500 }}>
                  {element.index}
                </Text>
              </Col>
              <Col style={{ width: "80%" }}>
                <Text style={{ fontSize: "14px", fontWeight: 500 }}>
                  {element.error}
                </Text>
              </Col>
            </Col>
          );
        })} */}
      </Col>
    </>
  );
};

export default MobLibraryCard;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  //  Avatar, Dropdown, Menu,
  Col,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import { useLazyGetItemWiseSalesReportsQuery } from "../../../apis/reports";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";

import dayjs from "dayjs";
import { saveAs } from "file-saver";
const { Text } = Typography;
const options = [
  {
    key: 1,
    value: "Export",
    label: "Export",
  },
];

const dateOptions = [
  {
    key: 1,
    value: "Today",
    label: "Today",
  },
  {
    key: 2,
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    key: 3,
    value: "last_7_day",
    label: "Last 7 day",
  },
  {
    key: 4,
    value: "last_30_day",
    label: "Last 30 day",
  },
  {
    key: 5,
    value: "last_60_day",
    label: "Last 60 day",
  },
  {
    key: 6,
    value: "Custom",
    label: "Custom",
  },
];

const SalesReport = () => {
  const [dateFilter, setDateFilter] = useState();
  const [searchedValue, setSearchedValue] = useState({});
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [currentPage, setCurrentPage] = useState(1);

  const [
    getItemWiseSalesReportApi,
    { data: itemWiseSalesReportData, isFetching },
  ] = useLazyGetItemWiseSalesReportsQuery();
  useEffect(() => {
    getItemWiseSalesReportApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }
    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        getItemWiseSalesReportApi({
          ...(searchedValue?.search && { search: searchedValue?.search }),
          // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
          // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        getItemWiseSalesReportApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(searchedValue?.search && { search: searchedValue?.search }),
          // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
          // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }
      setCurrentPage(1);
    }
    setDateFilter(value);
  };
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      getItemWiseSalesReportApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
        // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };

  const handleExportApi = async () => {
    let response = await getItemWiseSalesReportApi({
      ...(searchedValue.search && { search: searchedValue.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      download: true,
    });
    if (response?.status === "fulfilled") {
      // handlePdfDownload(response?.data?.data?.url);
      saveAs(response?.data?.data?.url);
    }
  };

  const columns = [
    // {
    //   key: "icon",
    //   dataIndex: "icon",
    //   fixed: "left",
    //   width: "50px",
    //   render: (params, record) => {
    //     return (
    //       <>
    //         <Dropdown
    //           overlay={
    //             <Menu>
    //               <>
    //                 <Menu.Item
    //                   //   onClick={() => onEdit(record)}
    //                   style={{ padding: "7px 12px" }}
    //                 >
    //                   <Col className="container-list">
    //                     <Text className="select-text">Edit</Text>
    //                   </Col>
    //                 </Menu.Item>
    //                 <Menu.Item
    //                   //   onClick={() => handleDeleteModalOpen(record)}
    //                   style={{ padding: "7px 12px" }}
    //                 >
    //                   <Col className="container-list">
    //                     <Text className="select-text">Delete</Text>
    //                   </Col>
    //                 </Menu.Item>
    //               </>
    //             </Menu>
    //           }
    //           trigger={"click"}
    //         >
    //           <DashOutlined
    //           //   onClick={() => _onRowClick(record)}
    //           />
    //         </Dropdown>
    //       </>
    //     );
    //   },
    //   // height: "60px",
    // },
    {
      title: "Product name",
      dataIndex: "item_name_title",
      key: "item_name_title",
      fixed: "left",
      width: "400px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  //   src={`${apiBaseUrl}${record.profile_picture}`}
                  alt="Avatar"
                  icon={<DefaultIcon width={20} height={20} />}
                />
              </Col>
              <Col
                // onClick={() => handleUserDetailsNavigation(record)}
                style={{ fontWeight: 500, cursor: "pointer" }}
              >
                {params}
              </Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "MOB SKU",
      dataIndex: "mob_sku",
      key: "mob_sku",
      width: "150px",
      render: (params, record) => {
        return (
          <Col
          // onClick={() => handleUserDetailsNavigation(record)}
          >
            {params}
          </Col>
        );
      },
    },
    {
      title: "HSN code",
      dataIndex: "hsn_code",
      key: "hsn_code",
      width: "150px",
    },
    {
      title: "Seller SKU",
      dataIndex: "seller_SKU",
      key: "seller_SKU",
      width: "150px",
    },
    {
      title: "Material Category",
      dataIndex: "category",
      key: "category",
      width: "250px",
    },
    {
      title: "Sub Category Level 1",
      dataIndex: "sub_category",
      key: "sub_category",
      width: "250px",
    },
    {
      title: "Sub Category Level 2",
      dataIndex: "sub_sub_category",
      key: "sub_sub_category",
      width: "250px",
    },
    {
      title: "Brand name",
      dataIndex: "brand_name",
      key: "brand_name",
      width: "150px",
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: "150px",
      render: (params, record) => {
        return <Col>{params?.quantity}</Col>;
      },
    },
    {
      title: "Sales amount",
      dataIndex: "sales_amount",
      key: "sales_amount",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params?.total).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },
  ];

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    setCurrentPage(currPage);
    await getItemWiseSalesReportApi(params);
  };

  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };

  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">Item wise sales report</Text>
      <Col style={{ display: "flex", gap: "1rem" }}>
        <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
          width={"400px"}
          getLibraryApi={getItemWiseSalesReportApi}
          handleSearchedValue={handleSearchedValue}
          isLoading={isFetching}
        />
        <Col className="action-sub-container">
          <CustomSelect
            placeholder={"Date"}
            width={"150px"}
            options={dateOptions}
            handleChange={handleChange}
            value={dateFilter}
          />
          {dateFilter === "Custom" && (
            <DateRangePicker
              onChange={handleChangeRangePicker}
              format={"DD-MMM-YYYY"}
            />
          )}
        </Col>
        <CustomSelect
          placeholder={"Export"}
          width={"150px"}
          options={options}
          handleChange={handleExportApi}
          value={isFetching ? <LoadingOutlined /> : "EXPORT"}
          disabled={isFetching ? true : false}
          loading={isFetching}
        />
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        //   tableExpandable={defaultExpandable}
        // tableDataSource={tableDataSource || []}
        tableDataSource={itemWiseSalesReportData?.data?.results}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        }}
        isLoading={itemWiseSalesReportData?.data?.results?.length && isFetching}
        scroll={{ x: "100%" }}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={
          !isFetching &&
          itemWiseSalesReportData?.data?.pagination?.total_entries
        }
      />
    </Col>
  );
};

export default SalesReport;

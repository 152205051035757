import React, { useEffect, useState } from "react";
import { Button, Col, Input, List, Modal, Typography } from "antd";
import { Upload, message } from "antd";
import "./AddCategoriesModal.css";
import { ReactComponent as FileIcon } from "../../../assets/icons/file-upload.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/RemoveImageIcon.svg";
import { ReactComponent as TableInfo } from "../../../assets/icons/TableInfo.svg";
import { bytesToSize } from "../../../commonFuntions/CommonUtilFunctions";
const { Text } = Typography;
const AddCategoriesModal = ({ openAddFile, setOpenAddFile, handleUploadFile,title}) => {
  const [inputValue, setInputValue] = useState("");
  const [info, setInfo] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [handleError, setHandleError] = useState({
    input: false,
    dragger: false,
  });
  // const handleFileChange = (info) => {
  //   let fileList = [...info.fileList];
  //   fileList = fileList.slice(-1);
  //   fileList = fileList.filter((file) => {
  //     if (file.type.includes("image")) {
  //       return true;
  //     } else {
  //       message.error(`${file.name} is not a valid image file.`);
  //       return false;
  //     }
  //   });
  //   setInfo(info);
  //   setFileList(fileList);
  // };
useEffect(()=>{
  setHandleError({
    input: false,
    dragger: false,
  });
  setInputValue("");
  setFileList([]);
  setInfo(null);
},[openAddFile])
  const handleFileChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.filter((file) => {
      const isAllowedType =
      
        file.type.includes("image") ||
        file.type.includes("image/jpeg") ||
        file.type.includes("image/png") 

      if (!isAllowedType) {
        message.error(`${file.name} is not a valid file type.`);
      }

      return isAllowedType;
    });

    setInfo(info);
    setFileList(fileList);
  };
  const handleInputChange = (e) => {
    const newValue = e.target.value;

    setInputValue(newValue);
  };
  const handleOk = () => {
    setOpenAddFile(false);
  };
  const handleSave = () => {
    const file = info?.fileList[0];

    const fileUrl = file?.originFileObj;
    setHandleError({
      input: inputValue ? false : true,
      dragger: fileUrl ? false : true,
    });
    if(inputValue &&fileUrl){

      handleUploadFile(inputValue, fileUrl);

    }
  };
  const handleCancel = () => {
    setHandleError({
      input: false,
      dragger: false,
    });
    setInputValue("");
    setFileList([]);
    setInfo(null);
    setOpenAddFile(false);
  };
  const handleRemoveFile = (file) => {
    // Remove the file from the fileList
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    // handleInputChange("upload_receipt", [...newFileList]);
    setInfo(null);
    setFileList(newFileList);
    // messageApi.open({
    //   type: "success",
    //   content: "File removed successfully",
    // });
  };
  const customFileListRender = (fileList) => {
    return (
      <List
        dataSource={fileList}
        style={{
          marginTop: "10px",
        }}
        renderItem={(item) => {
          return (
            <>
              <Col
                style={{
                  height: "60px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 16px",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <Col
                  style={{ display: "flex", gap: "30px", alignItems: "center" }}
                >
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Text
                      style={{
                        color: "#0a243f",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {item?.name}
                    </Text>
                  </Col>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Text
                    style={{
                      color: "#0a243f",
                      fontSize: "14px",
                      fontWeight: 400,
                      opacity: 0.6,
                    }}
                  >
                    {bytesToSize(item?.size)}
                  </Text>
                  <RemoveIcon onClick={() => handleRemoveFile(item)} />
                </Col>
              </Col>
            </>
          );
        }}
      />
    );
  };
  return (
    <>
      <Modal
        title={title}
        open={openAddFile}
        // open={true}
        className="addFile-modal"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <Col className="addFile-wrapper">
            <Button key="back" className="cancel-btn" onClick={handleCancel}>
              CANCEL
            </Button>
            <Button
              key="submit"
              type="primary"
              className="submit-btn"
              onClick={handleSave}
            >
              SAVE
            </Button>
          </Col>
        }
      >
        <Col className="addFile-body">
          
          <Col>
            <Text>Category</Text>
            <Input
              type="text"
              placeholder="File name"
              value={inputValue}
              className="addFile-input"
              onChange={handleInputChange}
              style={{ borderColor: handleError?.input ? "#f0483e" : "#000" }}
            />
            {handleError?.input && (
              <Text style={{ color: "#f0483e" }}>File name is required ! </Text>
            )}
          </Col>
          <Col>
            <Col style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Text>Upload file</Text>
              {handleError?.dragger ? <TableInfo /> : ""}
            </Col>
            <Upload.Dragger
              fileList={fileList}
              beforeUpload={() => false}
              onChange={(info) => handleFileChange(info)}
              showUploadList={false}
            >
              <Col>
                <Col
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  <FileIcon />
                  <p className="ant-upload-drag-icon">
                    {/* <img src={fileUpload} alt="" /> */}
                  </p>
                  <p className="ant-upload-text">
                  Drag image here or
                    <span
                      style={{
                        color: "#2973f0",
                        marginLeft: "12px",
                      }}
                    >
                      browse
                    </span>
                  </p>
                  <p
                    className="ant-upload-hint"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    (jpeg, png - upto 4 mb)
                  </p>
                </Col>
              </Col>
            </Upload.Dragger>
            <Col>
              {fileList?.length !== 0 && (
                <Col style={{ padding: "0px 24px" }}>
                  {customFileListRender(fileList)}
                </Col>
              )}
            </Col>
          </Col>
        </Col>
      </Modal>
    </>
  );
};
export default AddCategoriesModal;

import React, { useState } from "react"; // ,{ useState }
import "./Categories.css";
import { Avatar, Button, Col, Dropdown, Menu, Switch, Typography } from "antd";
import SearchInput from "../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../components/CustomTableWithPagination/CustomTableWithPagination";
import {
  DashOutlined,
  // CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { ReactComponent as DefaultIcon } from "../../assets/icons/default.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-right.svg";
import StatusModal from "../../components/Modal/StatusModal/StatusModal";
import AddCategoriesModal from "./AddCategoriesModal/AddCategoriesModal";
import { useNavigate } from "react-router";

// import dayjs from "dayjs";
const { Text } = Typography;
const options = [
  {
    key: 1,
    value: "Excel",
    label: "Excel",
  },
  {
    key: 2,
    value: "CSV",
    label: "CSV",
  },
  {
    key: 3,
    value: "PDF",
    label: "PDF",
  },
  {
    key: 4,
    value: "Print",
    label: "Print",
  },
];

// const dateOptions = [
//   {
//     key: 1,
//     value: "Today",
//     label: "Today",
//   },
//   {
//     key: 2,
//     value: "Yesterday",
//     label: "Yesterday",
//   },
//   {
//     key: 3,
//     value: "last_7_day",
//     label: "Last 7 day",
//   },
//   {
//     key: 4,
//     value: "last_30_day",
//     label: "Last 30 day",
//   },
//   {
//     key: 5,
//     value: "last_60_day",
//     label: "Last 60 day",
//   },
//   {
//     key: 6,
//     value: "Custom",
//     label: "Custom",
//   },
// ];
const tableDataSource = [
  {
    key: 1,
    category_name: "Building material",
  },
  {
    key: 2,
    category_name: "Bathroom and plumbing",
  },
  {
    key: 3,
    category_name: "Hardware",
  },
];
const Categories = () => {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);
  const navigate = useNavigate();
  const onChange = (checked) => {
    setShowStatusModal(true);
  };
  const categoryModalOpen = () => {
    setOpenAddFile(true);
  };
  const handleNavigate = (data) => {
    navigate(`/admin/categories/${data}`);
  };
  const columns = [
    {
      key: "icon",
      dataIndex: "icon",
      fixed: "left",
      width: "50px",
      render: (params, record) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  <>
                    <Menu.Item
                      //   onClick={() => onEdit(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">Edit</Text>
                      </Col>
                    </Menu.Item>
                    <Menu.Item
                      //   onClick={() => handleDeleteModalOpen(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">Delete</Text>
                      </Col>
                    </Menu.Item>
                  </>
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined
              //   onClick={() => _onRowClick(record)}
              />
            </Dropdown>
          </>
        );
      },
      // height: "60px",
    },
    {
      title: "Category name",
      dataIndex: "category_name",
      key: "category_name",
      fixed: "left",
      width: "250px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record.parentKey ? "60px" : "0px",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  //   src={`${apiBaseUrl}${record.profile_picture}`}
                  alt="Avatar"
                  icon={<DefaultIcon width={20} height={20} />}
                />
              </Col>
              <Col
                // onClick={() => handleUserDetailsNavigation(record)}
                style={{ fontWeight: 500, cursor: "pointer" }}
              >
                {record.category_name}
              </Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "Category Description",
      dataIndex: "category_description",
      key: "category_description",
      width: "250px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "150px",
      render: (params, record) => {
        return (
          <Col style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Text style={{ fontWeight: 500, fontSize: "16px" }}>Active</Text>
            <Switch defaultChecked onChange={onChange} />
          </Col>
        );
      },
    },

    {
      title: "Order Acceptance",
      dataIndex: "order_acceptance",
      key: "order_acceptance",
      width: "190px",
      render: () => {
        return (
          <Col
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {/* <CloseOutlined style={{color:"red",fontSize:"18px "}}/> */}
            <CheckOutlined style={{ color: "green", fontSize: "18px" }} />
          </Col>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "200px",
      render: (parmas, record) => {
        return (
          <Col style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Text style={{ color: "#2973f0", fontWeight: 500 }}>
              9 subcategories
            </Text>
            <ArrowIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigate(record?.category_name)}
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">Categories</Text>
      <Col className="categories-heading_wrapper">
        <Col style={{ display: "flex", gap: "1rem" }}>
          <SearchInput
            size={"large"}
            placeholder={"Search by customer name, mobile no, email"}
            //   getLibraryApi={getWalletTransferDetailsApi}
            width={"400px"}
            //   isLoading={isFetching}
          />

          <CustomSelect
            placeholder={"Download"}
            width={"100px"}
            height={"46px"}
            options={options}
            //   handleChange={handleChange}
            value={"EXPORT"}
          />
        </Col>
        <Button type="primary" className="cat-btn" onClick={categoryModalOpen}>
          Add new category
        </Button>
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        //   tableExpandable={defaultExpandable}
        tableDataSource={tableDataSource || []}
        // tableDataSource={getWalletData?.data?.results}
        tableColumns={columns}
        // locale={{
        //   emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        // }}
        // isLoading={getWalletData?.data?.results.length && isFetching}
        scroll={{ x: "100%" }}
        // currentPage={currentPage}
        // handlePageChange={handlePageChange}
        itemsPerPage={30}
        // totalEntries={
        //   !isFetching && getWalletData?.data?.pagination?.total_entries
        // }
      />
      <StatusModal
        open={showStatusModal}
        setOpen={setShowStatusModal}
        // handleOk={onActivate}
        // data={productTableData}
      />
      <AddCategoriesModal
        openAddFile={openAddFile}
        setOpenAddFile={setOpenAddFile}
        // handleUploadFile={handleUploadFile}
        title={"Add new category"}
      />
    </Col>
  );
};

export default Categories;

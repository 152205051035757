import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Typography, DatePicker, Row } from "antd";
import "./ChooseVehicleModal.css";
import { ReactComponent as NoteIcon } from "../../../../../assets/icons/pointer.svg";
import { ReactComponent as NoteIIcon } from "../../../../../assets/icons/pointerI.svg";
import CustomDropdown from "../AddPaymentModal/CustomDropdown";
import ChooseAddressModal from "./ChooseAddressModal/ChooseAddressModal";
import { TimeDropdownData } from "../../../../ManageOrders/OrderDetails/OrderConstant/Constant";
import dayjs from "dayjs";
const { Text } = Typography;

const logType = [
  {
    key: "manual",
    value: "Manual",
  },
];
const ChooseVehicleModal = ({
  isModalOpen,
  setIsModalOpen,
  handleOk,
  vendorAddressData,
  pickupAddressValue,
  setPickupAddressValue,
  vendorAddressapiSuccess,
  updateOrderStatusLoading,
  DeliveryDateValue,
  SelectedTime,
  setDeliveryDateValue,
  setSelectedTime,
}) => {
  const [logisticsTypeVisible, setLogisticsTypeVisible] = useState(false);
  const [logisticsTypeSelected, setLogisticsTypeSelected] = useState("Manual");
  const [pickupAddressModal, setPickupAddressModal] = useState(false);
  const [value, setValue] = useState();
  const [date, setDate] = useState("");

  const onChange = (date, dateString) => {
    const currentDate = dayjs(date).format("YYYY-MM-DD");
    setDeliveryDateValue(date);
    setDate(currentDate);
  };

  const logisticsPartnerTypeVisible = (flag) => {
    setLogisticsTypeVisible(flag);
  };

  const handleCancel = () => {
    setValue();
    setIsModalOpen(false);
    setPickupAddressValue({});
  };
  const handLogisticsClick = (selectedValue) => {
    setLogisticsTypeSelected(selectedValue);
    setLogisticsTypeVisible(false);
  };
  const handTimeClick = (selectedValue) => {
    setSelectedTime(selectedValue);
  };

  const handleAddressChange = () => {
    setPickupAddressModal(true);
  };

  useEffect(() => {
    if (vendorAddressData?.data) {
      setPickupAddressValue(vendorAddressData?.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorAddressData, vendorAddressapiSuccess]);

  return (
    <>
      <Modal
        style={{ top: "5px" }}
        title="Choose vehicle for shipment"
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={
          <Col className="addFile-wrapper">
            <Button
              key="back"
              className="vehicle-cancel-btn"
              onClick={handleCancel}
            >
              CANCEL
            </Button>
            <Button
              key="submit"
              type="primary"
              className="vehicle-submit-btn"
              onClick={handleOk}
              disabled={pickupAddressValue?.id ? false : true}
              loading={updateOrderStatusLoading}
            >
              ASSIGN AND ACCEPT ORDER
            </Button>
          </Col>
        }
      >
        <Col>
          <Col className="pickup-address-wrapper">
            {vendorAddressData?.data && (
              <Col className="choose-vehicle-address">
                <Text className="choose-vehicle-header">Pickup address</Text>
                {vendorAddressData?.data?.length ? (
                  <>
                    {" "}
                    <Text style={{ fontSize: "1rem" }}>
                      {pickupAddressValue?.name ||
                        vendorAddressData?.data[0]?.name ||
                        ""}
                    </Text>
                    <Col>
                      <Text style={{ fontSize: "1rem" }}>
                        {pickupAddressValue?.address ||
                          vendorAddressData?.data[0]?.address ||
                          ""}
                      </Text>{" "}
                      ,
                      <Text style={{ fontSize: "1rem" }}>
                        {pickupAddressValue?.address_line_2 ||
                          vendorAddressData?.data[0]?.address_line_2 ||
                          ""}
                      </Text>
                    </Col>
                    <Col>
                      <Text style={{ fontSize: "1rem" }}>
                        {pickupAddressValue?.city ||
                          vendorAddressData?.data[0]?.city ||
                          ""}
                      </Text>{" "}
                      ,
                      <Text style={{ fontSize: "1rem" }}>
                        {pickupAddressValue?.state ||
                          vendorAddressData?.data[0]?.state ||
                          ""}
                      </Text>{" "}
                      ,
                      <Text style={{ fontSize: "1rem" }}>
                        {pickupAddressValue?.pincode ||
                          vendorAddressData?.data[0]?.pincode ||
                          ""}
                      </Text>
                    </Col>
                    <Text style={{ fontSize: "1rem" }}>
                      {pickupAddressValue?.phone_number ||
                        vendorAddressData?.data[0]?.phone_number ||
                        ""}
                    </Text>
                  </>
                ) : (
                  "No Address Found"
                )}
              </Col>
            )}
            {vendorAddressData?.data?.length > 1 && (
              <Text
                className="choose-vehicle-change"
                onClick={handleAddressChange}
              >
                CHANGE
              </Text>
            )}
          </Col>
          <Col className="choose-vehicle-logistics">
            <Text className="logistics-partner-txt">
              Delivery date and time <span style={{ color: "#bf2600" }}>*</span>
            </Text>
            <Row>
              <Col
                className="dropdown-wrapper1"
                style={{ width: "40%", marginRight: "10px" }}
              >
                <DatePicker
                  format={"DD-MMM-YYYY"}
                  style={{
                    height: "46px",
                    width: "100%",
                    borderRadius: "8px",
                    border: "solid 1px #bac7d5",
                    borderColor: date ? "#0a243f" : "#dedede",
                  }}
                  value={DeliveryDateValue}
                  onChange={onChange}
                  
                />
              </Col>
              <Col className="dropdown-wrapper" style={{ width: "57%" }}>
                <CustomDropdown
                  options={TimeDropdownData}
                  handleOptionClick={handTimeClick}
                  selectedText={SelectedTime}
                  className={"DeliverySlotClass"}
                />
              </Col>
            </Row>
          </Col>
          <Col className="choose-vehicle-logistics">
            <Text className="logistics-partner-txt">Logistics partner</Text>
            <Col className="dropdown-wrapper" style={{ width: "50%" }}>
              <CustomDropdown
                handleOptionClick={handLogisticsClick}
                visible={logisticsTypeVisible}
                handleVisibleChange={logisticsPartnerTypeVisible}
                selectedText={logisticsTypeSelected}
                options={logType}
              />
            </Col>
          </Col>
          <Col className="assign-vehicle">
            <Text className="choose-vehicle-header">
              How to assign a vehicle
            </Text>
            <Col className="note-align">
              <NoteIcon className="icon-style" />

              <Text className="styled-txt">
                Choose the smallest and minimum number of vehicles required to
                load all your materials.
              </Text>
            </Col>
            <Col className="note-align">
              <NoteIIcon />

              <Text className="styled-txt">
                Help the vehicle driver to load the materials for safe transit.
              </Text>
            </Col>
          </Col>
        </Col>
        <ChooseAddressModal
          setPickupAddressValue={setPickupAddressValue}
          isModalOpen={pickupAddressModal}
          setIsModalOpen={setPickupAddressModal}
          vendorAddressData={vendorAddressData}
          value={value}
          setValue={setValue}
        />
      </Modal>
    </>
  );
};
export default ChooseVehicleModal;

import { Avatar, Col, Dropdown, Menu, Typography } from "antd";
import React from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ReactComponent as TableInfo } from "../../../../../assets/icons/TableInfo.svg";
import { ReactComponent as DefaultIcon } from "../../../../../assets/icons/default.svg";
import { baseUrl } from "../../../../../commonUtils/commonUtils";
const { Text } = Typography;
const AddVehicleCustomDropdown = ({
  visible,
  handleVisibleChange,
  selectedText,
  options,
  handleOptionClick,
}) => {
  const dropdownContent = (
    <Menu style={{ width: "400px" }}>
      {options?.map((option) => (
        <Menu.Item
          key={option?.id}
          className="styled-menu"
          onClick={() => handleOptionClick(option?.vechicle_type)}
        >
          <Col className="text-icon-align">
            <Avatar
              shape="square"
              src={`${baseUrl}${option?.vechicle_image}`}
              icon={<DefaultIcon />}
            />
            <Text className="val-text"> {option?.vechicle_type}</Text>
          </Col>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <Dropdown
        overlay={dropdownContent}
        style={{ fontWeight: 500, color: "#0a243f" }}
        trigger={["click"]}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <Col
          style={{
            fontWeight: 500,
            color: "#0a243f",
            width: "100%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // marginBottom:"10px"
          }}
        >
          {selectedText}
          {selectedText === "SELECT VEHICLE" ? (
            <TableInfo style={{ width: "20px", height: "20px" }} />
          ) : (
            ""
          )}
          {visible ? <UpOutlined /> : <DownOutlined />}
        </Col>
      </Dropdown>
    </>
  );
};

export default AddVehicleCustomDropdown;

import { Col, Typography } from "antd";
import React from "react";
import "./OrderAddress.css";
import EditAddressModal from "../../../../components/Modal/EditAddressModal/EditAddressModal";
import { ReactComponent as MobCreditWhiteIcon } from "../../../../assets/icons/mobCreditWhiteIcon.svg";
const { Text } = Typography;
const OrderAddress = (props) => {
  const {
    title,
    addressDetails,
    // func,
    addressType,
    addressID,
    deliveryAddress,
    orderId,
    order,
    editCheck,
    mobCredit = false,
    mob_credit_check,
  } = props;
  const name = addressDetails?.name;
  const address = addressDetails?.address_line_1;
  const address_line_2 = addressDetails?.address_line_2;
  const number = addressDetails?.phone_number;
  const gst = addressDetails?.gst_number;
  const city = addressDetails?.city;
  const pincode = addressDetails?.pincode;
  const state = addressDetails?.state;
  return (
    <Col className="address-wrapper">
      <Col className="header">
        <Text className="title">{title}</Text>
        {/* <Text className="edit-btnn" onClick={func}>
          Edit
        </Text> */}
        {!editCheck && !mobCredit && (
          <EditAddressModal
            address={addressType}
            editState={""}
            addressID={addressID}
            deliveryAddress={deliveryAddress}
            orderId={orderId}
            order={order}
            mob_credit_check={mob_credit_check}
          />
        )}
      </Col>
      {addressDetails?.name ? (
        <Col className="body">
          <Text style={{ fontSize: "16px", display: "flex", gap: "5px" }}>
            {name}{" "}
            {addressType === "Billing" && mobCredit && (
              <>
                <MobCreditWhiteIcon />
              </>
            )}
          </Text>

          {addressType === "Billing" && gst && (
            <Text className="gst">{`GST NO: ${gst}`}</Text>
          )}
          {address && <Text style={{ fontSize: "16px" }}>{address},</Text>}
          {address_line_2 && (
            <Text style={{ fontSize: "16px" }}>{address_line_2},</Text>
          )}
          <Text style={{ fontSize: "16px" }}>
            {city}, {state}, {pincode}
          </Text>
          <Text style={{ fontSize: "16px" }}>+91 {number}</Text>
        </Col>
      ) : (
        <>
          <Text
            style={{
              fontSize: "16px",
              display: "block",
              padding: "12px 24px 16px 24px",
            }}
          >
            No address added
          </Text>
        </>
      )}
      {addressType === "Billing" && mobCredit && (
        <>
          <Col className="bottom-banner">
            Billing address can’t be edited as the payment is done through
            mobCREDIT
          </Col>
        </>
      )}
    </Col>
  );
};

export default OrderAddress;

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Navigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";

const PrivateRoute = (props) => {
  const { children } = props;
  const isAuthenticated = localStorage.getItem("accessToken") !== null;
  return isAuthenticated ? (
    <MainLayout>
      <Content>{children}</Content>
    </MainLayout>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;

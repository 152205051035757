import React, { useEffect, useState } from "react";
import { Col, Form, Skeleton, Tabs, Typography } from "antd";
import "./EditMobLibProduct.css";
import BasicDetails from "./EditChildren/BasicDetails";
import VariationInfo from "./EditChildren/VariationInfo";
import BasicInfo from "./EditChildren/BasicInfo";
import { useDispatch, useSelector } from "react-redux";
import { manageEditProductDataSelector } from "../../../redux/slices/manageLibrary/selector";
import ButtonImageUpload from "../../../components/Draggers/ButtonImageUpload";
import ComplianceAndBatteryInfo from "./EditChildren/ComplianceAndBatteryInfo";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLazyGetEditProductDetailQuery,
  useUpdateProductDetailsMutation,
} from "../../../apis/manageLibrary";
import { getEditProductData } from "../../../redux/slices/manageLibrary/action";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import ActionContainer from "./ActionContainer/ActionContainer";
const EditMobLibProduct = () => {
  const { Text } = Typography;
  const { TabPane } = Tabs;
  const params = useParams();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editProductData = useSelector(manageEditProductDataSelector);

  const [
    getEditProductApi,
    { data: editProductDetails, isFetching: isFetchingEditProductDetails },
  ] = useLazyGetEditProductDetailQuery();
  const [updateApi, { isLoading: isUpdateLoading }] =
    useUpdateProductDetailsMutation();

  useEffect(() => {
    if (editProductDetails?.status) {
      dispatch(getEditProductData(editProductDetails?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProductDetails]);
  useEffect(() => {
    getEditProductApi(params?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleEditFormData = async (val) => {
    const data = {
      ...val,
      sub_category_id: val?.sub_category_id,
      category: val?.category[0] || val?.category,
      sub_category_id_2: val?.sub_category_id_2,
    };
    const response = await updateApi({ id: params?.id, data });
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setTimeout(() => {
        navigate("/admin/products");
      }, 800);
      dispatch(getEditProductData({}));
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };

  const handleChildFieldChange = (inputValues) => {
    setFormData({ ...formData, ...inputValues });
    form.setFieldsValue(inputValues);
  };
  const handleCancel = () => {
    navigate("/admin/products");
    dispatch(getEditProductData({}));
  };
  return (
    <Col className="wrapper">
      <Form form={form} onFinish={handleEditFormData}>
        <Col className="headerWrapper">
          <Col>
            <Text className="heading">Edit item</Text>
          </Col>
          <ActionContainer
            position={"top"}
            handleCancel={handleCancel}
            loadingButton={isUpdateLoading}
          />
        </Col>
        <Col className="contentWrapper">
          <Col className="leftContent">
            <Tabs className="tab-container" defaultActiveKey="1">
              <TabPane className="tab-pane" tab="Basic details" key="1">
                <BasicDetails
                  handleChildFieldChange={handleChildFieldChange}
                  data={editProductData}
                  isFetching={isFetchingEditProductDetails}
                />
              </TabPane>
              <TabPane className="tab-pane" tab="Variation info" key="2">
                <VariationInfo
                  handleChildFieldChange={handleChildFieldChange}
                  data={editProductData}
                  isFetching={isFetchingEditProductDetails}
                />
              </TabPane>
              <TabPane className="tab-pane" tab="Basic info" key="3">
                <BasicInfo
                  handleChildFieldChange={handleChildFieldChange}
                  data={editProductData}
                  isFetching={isFetchingEditProductDetails}
                />
              </TabPane>
              <TabPane
                className="tab-pane"
                tab="Compliance and battery info"
                key="4"
              >
                <ComplianceAndBatteryInfo
                  handleChildFieldChange={handleChildFieldChange}
                  data={editProductData}
                  isFetching={isFetchingEditProductDetails}
                />
              </TabPane>
            </Tabs>
          </Col>
          <Col className="rightContent">
            <Col className="rightSubContent">
              {isFetchingEditProductDetails ? (
                <>
                  <Skeleton active />
                </>
              ) : (
                <ButtonImageUpload data={editProductData} />
              )}
            </Col>
          </Col>
        </Col>
        <ActionContainer
          position={"bottom"}
          handleCancel={handleCancel}
          loadingButton={isUpdateLoading}
        />
      </Form>
    </Col>
  );
};

export default EditMobLibProduct;

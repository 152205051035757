import { useState } from "react";
import { saveAs } from "file-saver";
import {
  Row,
  Col,
  Button,
  Menu,
  Dropdown,
  Divider,
  Typography,
  Tooltip,
} from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copyIcon.svg";
import { EyeOutlined } from "@ant-design/icons";
import { ReactComponent as SaveIcon } from "../../../../assets/icons/save.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/redDelete.svg";
import { handlePdfDownload } from "../../../../commonFuntions/CommonUtilFunctions";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../NotificationToast/NotificationToast";
import { baseUrl } from "../../../../commonUtils/commonUtils";
const { Text } = Typography;

const DropdownComponent = ({
  title,
  data,
  _onClick = () => {},
  subOrderData,
  deleteSuborderFileApi,
}) => {
  const [, setSelectedItem] = useState(null);
  const [showVisible, setShowVisible] = useState(false);
  const handleMenuClick = (e) => {
    setSelectedItem(data[e.key]);
    setShowVisible(false);
  };

  const copyLinkToClipboardRow = async (params) => {
    try {
      await navigator.clipboard.writeText(params);
      showSuccessToast("Link copied to clipboard successfully");
    } catch (err) {
      showErrorToast("Failed to copy link to clipboard", err);
    }
  };

  const handlePreview = (data) => {
    handlePdfDownload(data?.file);
  };

  const handleDownload = (data) => {
    const url = data?.file?.split(".pdf");
    saveAs(`${baseUrl}${url[0] + ".pdf"}`);
  };

  const handleDelete = async (data) => {
    const response = await deleteSuborderFileApi({
      suborder_file_id: data?.id,
    });
    if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    } else {
      showSuccessToast(response?.data?.message);
    }
  };
  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{ maxHeight: "250px", overflow: "auto" }}
    >
      {/* {title === "Invoice" && proformaInvoice && (
        <Col className="dropdown-component-header">Proforma Invoice</Col>
      )} */}
      {(title === "Invoice" ||
        title === "Proforma Invoice" ||
        title === "files attached") &&
        data?.map((item, index) => (
          <>
            <Menu.Item key={item?.id}>
              <Row
                style={{
                  minWidth: "480px",
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: 500 }}>{item?.file_name}</span>
                  <span style={{ color: "#6c7c8c" }}>{item?.subTitle}</span>
                </Col>
                <Col style={{ display: "flex", gap: "12px" }}>
                  {title !== "files attached" && (
                    <Tooltip title="Copy url" color={"#fff"} key={"#2973f0"}>
                      <Button
                        onClick={() => copyLinkToClipboardRow(item?.short_url)}
                        style={{
                          padding: "7px",
                          backgroundColor: "#f5f5f5",
                          border: "none",
                          fontWeight: 500,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        icon={
                          <CopyIcon style={{ color: "rgb(41, 115, 240)" }} />
                        }
                      ></Button>
                    </Tooltip>
                  )}
                  {title === "files attached" && (
                    <Button
                      onClick={() => handleDelete(item)}
                      style={{
                        padding: "7px",
                        backgroundColor: "#f5f5f5",
                        border: "none",
                        fontWeight: 500,
                      }}
                      icon={<DeleteIcon />}
                    ></Button>
                  )}

                  <Button
                    onClick={() => handlePreview(item)}
                    style={{
                      backgroundColor: "#f5f5f5",
                      border: "none",
                      color: "#2873f0",
                      fontWeight: 500,
                    }}
                    icon={<EyeOutlined />}
                  ></Button>
                  <Button
                    onClick={() => handleDownload(item)}
                    style={{
                      backgroundColor: "#f5f5f5",
                      border: "none",
                      fontWeight: 500,
                    }}
                    icon={<SaveIcon />}
                  ></Button>
                </Col>
              </Row>
              {index !== data.length - 1 && <Divider style={{ margin: 0 }} />}
            </Menu.Item>
          </>
        ))}
      {/* {title === "Invoice" && status === "Delivered" && (
        <Col className="dropdown-component-header">Invoices</Col>
      )} */}
      {/* {(status === "Delivered" || title === "files attached") &&
        data?.map((item, index) => (
          <>
            <Menu.Item key={item?.id}>
              <Row
                style={{
                  minWidth: "480px",
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: 500 }}>{item?.file_name}</span>
                  <span style={{ color: "#6c7c8c" }}>{item?.subTitle}</span>
                </Col>
                <Col style={{ display: "flex", gap: "12px" }}>
                  {title === "files attached" && (
                    <Button
                      onClick={() => handleDelete(item)}
                      style={{
                        padding: "7px",
                        backgroundColor: "#f5f5f5",
                        border: "none",
                        fontWeight: 500,
                      }}
                      icon={<DeleteIcon />}
                    ></Button>
                  )}

                  <Button
                    onClick={() => handlePreview(item)}
                    style={{
                      backgroundColor: "#f5f5f5",
                      border: "none",
                      color: "#2873f0",
                      fontWeight: 500,
                    }}
                    icon={<EyeOutlined />}
                  ></Button>
                  <Button
                    onClick={() => handleDownload(item)}
                    style={{
                      backgroundColor: "#f5f5f5",
                      border: "none",
                      fontWeight: 500,
                    }}
                    icon={<SaveIcon />}
                  ></Button>
                </Col>
              </Row>
              {index !== data.length - 1 && <Divider style={{ margin: 0 }} />}
            </Menu.Item>
          </>
        ))} */}
      {title === "files attached" && (
        <Menu.Item
          onClick={() => _onClick(subOrderData)}
          key="footer"
          style={{
            // backgroundColor: "#f0f0f0",
            fontWeight: "bold",
            padding: "1rem",
            borderTop: "1px solid #f0f0f0",
          }}
        >
          {data?.length < 3 && (
            <Col
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <Col
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "16px",
                  backgroundColor: "#015fe5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PlusIcon />
              </Col>
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#015fe5",
                  paddingTop: "4px",
                }}
              >
                Add file
              </Text>
            </Col>
          )}
        </Menu.Item>
      )}
    </Menu>
  );
  const handleDropdownVisibleChange = (visible) => {
    setShowVisible(visible);
  };
  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomLeft"
      overlay={menu}
      open={showVisible}
      onOpenChange={handleDropdownVisibleChange}
    >
      {title === "files attached" ? (
        data?.length ? (
          <Text
            style={{
              color: "#2973f0",
              cursor: "pointer",
              position: "relative",
              fontWeight: 500,
            }}
          >
            {` ${data?.length} ${title}`}
            {showVisible ? (
              <UpOutlined style={{ paddingLeft: "6px" }} />
            ) : (
              <DownOutlined
                style={{ paddingLeft: "6px", color: "rgb(41, 115, 240)" }}
              />
            )}
          </Text>
        ) : (
          <Text
            style={{
              color: "#2973f0",
              cursor: "pointer",
              position: "relative",
              fontWeight: 500,
            }}
          >
            Add file
          </Text>
        )
      ) : (
        <Text
          style={{
            color: "#2973f0",
            cursor: "pointer",
            position: "relative",
            fontWeight: 500,
          }}
        >
          {title}
          {showVisible ? (
            <UpOutlined style={{ paddingLeft: "6px" }} />
          ) : (
            <DownOutlined
              style={{ paddingLeft: "6px", color: "rgb(41, 115, 240)" }}
            />
          )}
        </Text>
      )}
    </Dropdown>
  );
};

export default DropdownComponent;

import React, { useEffect, useState } from "react";
import "./OrderDetails.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Skeleton, Typography } from "antd";
import OrderSummary from "./OrderSummary/OrderSummary";
import OrderAddress from "./OrderAddress/OrderAddress";
import OrderItem from "./OrderItems/OrderItem";

import {
  useAddOrderPaymentMutation,
  useAddVehicleMutation,
  // useCancelOrderDetailsMutation,
  useDeleteSuborderFileMutation,
  useLazyGetOrderDetailQuery,
  // useLazyGetSubOrderDetailQuery,
  useLazyGetVehiclesDetailQuery,
  useUpdateOrderStatusMutation,
  useUpdateVehiclesDetailMutation,
} from "../../../apis/ManageOrders";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editOrderDetailSelector,
  vehicleDetailSelector,
} from "../../../redux/slices/manageOrders/selector";
import {
  getEditOrderDetail,
  getVehiclerDetail,
} from "../../../redux/slices/manageOrders/action";
import { dateFormat } from "../../../commonFuntions/CommonUtilFunctions";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert.svg";
import { ReactComponent as CustmerViewVipIcon } from "../../../assets/icons/custmerViewVip.svg";
import { ReactComponent as ProProfileIcon } from "../../../assets/icons/proProfileIcon.svg";

// import ChooseVehicleModal from "./OrderModals/ChooseVehicleModal/ChooseVehicleModal";
import DeliveryAddressModal from "./OrderModals/AddressModal/DeliveryAddressModal";
import BillingAddressModal from "./OrderModals/AddressModal/BillingAddressModal";
import {
  getBillingAddress,
  getDeliveryAddress,
  getRFQUserById,
  updateAddressFlag,
} from "../../../redux/slices/createQuote/action";
import {
  addressFlagSelector,
  billingAddressSelector,
  deliveryAddressSelector,
} from "../../../redux/slices/createQuote/selector";
import AddVehicleModal from "./ViewDetails/AddVehicleModal/AddVehicleModal";
import BillingAddress from "../../../components/Modal/EditAddressModal/AddAddress/BillingAddress";
import { rupifiStatusCheck } from "../../../commonUtils/commonUtils";
import MobCreditCard from "../../../components/CustomContainer/CustomProFileSection/MobCreditCard";
// import {
//   showErrorToast,
//   showSuccessToast,
// } from "../../../NotificationToast/NotificationToast";
const { Text } = Typography;
const initialFormData = {
  nameCompany: "",
  mobileNo: "",
  gst: "",
  addressI: "",
  addressII: "",
  pincode: "",
  city: "",
  state: "",
  email: "",
  address_tag: "",
  id: "",
};
const initialVehicleFormData = {
  waybillno: "",
  customer_logistics_ref_no: "",
  assigned_date: "",
  assigned_time: "",
  driver_name: "",
  driver_phone_number: "",
  vechicle_number: "",
  sub_order: "",
  vehicle: "",
  add_way_bill: "",
  reach_pickup_location_date: "",
  reach_pickup_location_time: "",
  out_for_delivery_location_date: "",
  out_for_delivery_location_time: "",
};
const OrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const orderId = params?.order_id;
  const addressFlag = useSelector(addressFlagSelector);
  const editOrderData = useSelector(editOrderDetailSelector);
  const delivery_address = useSelector(deliveryAddressSelector);
  const billing_address = useSelector(billingAddressSelector);
  const vehicleDetailData = useSelector(vehicleDetailSelector);
  const [
    getOrderDetailApi,
    { data: editData, isFetching: isFetchingEditData },
  ] = useLazyGetOrderDetailQuery();
  const [getVehiclesDetailApi, { data: vehicleData }] =
    useLazyGetVehiclesDetailQuery();
  // const [getSubOrderDetailApi, { data: editSubData }] =
  // useLazyGetSubOrderDetailQuery();
  const [
    orderPaymentApi,
    { isSuccess: successAddOrderPayment, isLoading: isFetching },
  ] = useAddOrderPaymentMutation();
  const [
    updateOrderStatusApi,
    {
      isSuccess: successUpdateOrderStatusApi,
      isLoading: updateOrderStatusLoading,
    },
  ] = useUpdateOrderStatusMutation();
  // const [cancelOrderDetailsApi, { isSuccess: successCancelOrderApi }] =
  //   useCancelOrderDetailsMutation();
  const [deleteSuborderFileApi, { isSuccess: deleteSubOrderFileSuccess }] =
    useDeleteSuborderFileMutation();
  const [addVehicleApi, { isSuccess: successAddVehicleApi }] =
    useAddVehicleMutation();
  // const [ { data: editSubData }] =
  // useLazyGetSubOrderDetailQuery();
  const [updateVehicleApi] = useUpdateVehiclesDetailMutation();
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [
    ,
    // isModalOpen
    setIsModalOpen,
  ] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [remarksError, setRemarksError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [datePikerEmpty, setDatePickerEmpty] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [updateVehicleModalOpen, setUpdateVehicleModalOpen] = useState(false);
  const [vehicleUpdateData, setVehicleUpdateData] = useState({});

  // const [
  //   vehicleUpdateData,
  //    setVehicleUpdateData] = useState({});

  const [
    editDataVal,
    // setEditDataVal
  ] = useState({});
  const [billingFormData, setBillingFormData] = useState(initialFormData);
  const [formData, setFormData] = useState(initialFormData);
  const [billingModal, setBillingModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [addVehicleModalOpen, setAddVehicleModalOpen] = useState(false);
  const [addVehicleFormData, setAddVehicleFormData] = useState(
    initialVehicleFormData
  );
  // const showMobCreditIcon = (editOrderData?.all_addresses || []).some(
  //   (item) => item?.mob_credit
  // );
  const handlePayment = () => {
    setPaymentModal(true);
    setAmountError(false);
    setRemarksError(false);
    setDateError(false);
  };
  const handleCustomerView = (id) => {
    navigate(`/admin/customer/customerDetails/${id}`, {
      state: { id: `/admin/orders/order-details/${editData?.data?.id}` },
    });
  };
  const handleAddVehicleModal = (id) => {
    setAddVehicleFormData({
      ...addVehicleFormData,
      sub_order: id,
    });
    setDatePickerEmpty(true);
    setValidationErrors({});
    setVehicleUpdateData({});
    setAddVehicleModalOpen(true);
  };
  const editAddressModal = (val, index) => {
    setIsModalOpen(false);
    // setEditDataVal(deliveryAddress[index]);
    if (editData) {
      setFormData({
        nameCompany: editData?.data?.address?.delivery_addreess?.name || "",
        mobileNo:
          editData?.data?.address?.delivery_addreess?.phone_number || "",
        gst: editData?.data?.address?.delivery_addreess?.gst_number || "",
        addressI:
          editData?.data?.address?.delivery_addreess?.address_line_1 || "",
        addressII:
          editData?.data?.address?.delivery_addreess?.address_line_2 || "",
        google_map_link:
          editData?.data?.address?.delivery_addreess?.address_line_2 || "",
        pincode: editData?.data?.address?.delivery_addreess?.pincode || "",
        city: editData?.data?.address?.delivery_addreess?.city || "",
        state: editData?.data?.address?.delivery_addreess?.state || "",
        email: editData?.data?.address?.delivery_addreess?.email || "",
        id: editData?.data?.address?.delivery_addreess?.id || "",
      });
    }

    setDeliveryModal(true);
  };
  const editBillingModal = () => {
    setBillingFormData({
      nameCompany: editData?.data?.address?.delivery_addreess?.name || "",
      mobileNo: editData?.data?.address?.delivery_addreess?.phone_number || "",
      gst: editData?.data?.address?.delivery_addreess?.gst_number || "",
      addressI:
        editData?.data?.address?.delivery_addreess?.address_line_1 || "",
      addressII:
        editData?.data?.address?.delivery_addreess?.address_line_2 || "",
      google_map_link:
        editData?.data?.address?.delivery_addreess?.address_line_2 || "",
      pincode: editData?.data?.address?.delivery_addreess?.pincode || "",
      city: editData?.data?.address?.delivery_addreess?.city || "",
      state: editData?.data?.address?.delivery_addreess?.state || "",
      email: editData?.data?.address?.delivery_addreess?.email || "",
      id: editData?.data?.address?.delivery_addreess?.id || "",
    });
    setBillingModal(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleRefetchApi = () => {
    getOrderDetailApi(orderId);
    getVehiclesDetailApi();
  };
  useEffect(() => {
    getOrderDetailApi(orderId);
    // getSubOrderDetailApi(orderId);

    dispatch(updateAddressFlag(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    successAddOrderPayment,
    successUpdateOrderStatusApi,
    addressFlag,
    // successCancelOrderApi,
    deleteSubOrderFileSuccess,
    successAddVehicleApi,
    params,
  ]);
  useEffect(() => {
    getVehiclesDetailApi();
    if (editData?.status) {
      dispatch(getEditOrderDetail(editData?.data));
      dispatch(getBillingAddress(editData?.data?.address?.billing_address));
      dispatch(getDeliveryAddress(editData?.data?.address?.delivery_addreess));
      dispatch(getRFQUserById(editData?.data));
    }
    if (vehicleData?.status) {
      dispatch(getVehiclerDetail(vehicleData?.data));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, vehicleData]);

  const handleBackNavigate = () => {
    dispatch(getEditOrderDetail({}));
    dispatch(getDeliveryAddress({}));
    dispatch(getBillingAddress({}));
    navigate("/admin/orders");
  };
  const handleNavigateToRFQDetails = (id) => {
    navigate(`/admin/rfq/quotes/${id}`, { state: { id } });
  };

  // const redirectToEditOrder = () => {
  //   navigate(
  //     `/admin/orders/update-order/${orderId}/${editOrderData?.quote_id}`
  //   );
  // };
  // const cancelOrderDetails = async () => {
  //   const response = await cancelOrderDetailsApi({
  //     order_id: orderId,
  //   });
  //   if (response?.data?.status === true) {
  //     showSuccessToast(response?.data?.message);
  //   } else {
  //     showErrorToast(response?.error?.data?.message);
  //   }
  // };
  const handleAddGst = () => {
    setBillingModal(true);
    setBillingFormData({
      nameCompany: billing_address.name || "",
      mobileNo: billing_address.phone_number || "",
      gst: billing_address.gst_number || "",
      addressI: billing_address.address_line_1 || "",
      addressII: billing_address.address_line_2 || "",
      google_map_link: billing_address.address_line_2 || "",
      pincode: billing_address.pincode || "",
      city: billing_address.city || "",
      state: billing_address.state || "",
      email: billing_address.email || "",
      address_tag: billing_address.address_tag || "",
      id: billing_address.id || "",
    });
  };
  return (
    <Col className="wrapper">
      <Button
        className="btn"
        icon={<ArrowLeftOutlined />}
        onClick={handleBackNavigate}
      >
        BACK
      </Button>
      <Col className="detail-wrapper">
        {isFetchingEditData ? (
          <>
            <Skeleton active style={{ width: "50%" }} />
          </>
        ) : (
          <>
            <Col className="order-del">
              <Text className="header-text">Order Details</Text>
              <Text className="header-textI">
                Order ID: {editOrderData?.order_id || "N/A"}
              </Text>
              <Text className="header-textI">
                Placed on:{" "}
                {editOrderData?.created_at
                  ? dateFormat(editOrderData?.created_at)
                  : "N/A"}
              </Text>
              {editOrderData?.is_direct_order === false && (
                <>
                  {editOrderData?.rfq_details?.rfq_id && (
                    <Text className="header-textI">
                      RFQ No:{" "}
                      <Text
                        style={{ color: "#2973f0", cursor: "pointer" }}
                        onClick={() =>
                          handleNavigateToRFQDetails(
                            editOrderData?.rfq_details?.rfq_id
                          )
                        }
                      >
                        {editOrderData?.rfq_details?.rfq_id}
                      </Text>
                    </Text>
                  )}
                </>
              )}
            </Col>
          </>
        )}
        <Col className="btn-wrapper">
          <Link
            to={`/admin/orders/update-order/${orderId}/${editOrderData?.quote_id}`}
            target="_blank"
          >
            <Button className="edit-btn">EDIT ORDER</Button>
          </Link>
          {
            // <Button className="cncl-btn" onClick={cancelOrderDetails}>
            //   CANCEL ORDER
            // </Button>
          }
        </Col>
      </Col>
      <Col className="body-wrapper">
        <Col className="left-body">
          {isFetchingEditData ? (
            <>
              <Skeleton.Button active block />
            </>
          ) : (
            <>
              {editOrderData?.pending_amount > 0 && (
                <Col className="detail-notify">
                  <AlertIcon />
                  <Col>
                    Payment is pending for this order. Please add the payment
                    details{" "}
                    <Text
                      onClick={handlePayment}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      here
                    </Text>
                  </Col>
                </Col>
              )}
            </>
          )}
          {isFetchingEditData ? (
            <>
              <Skeleton active paragraph={{ rows: 12 }} />
            </>
          ) : (
            <OrderSummary
              paymentModal={paymentModal}
              setPaymentModal={setPaymentModal}
              handlePayment={handlePayment}
              data={editOrderData}
              paymentSummary={editData?.data?.payment_history}
              orderPaymentApi={orderPaymentApi}
              amountError={amountError}
              setAmountError={setAmountError}
              remarksError={remarksError}
              setRemarksError={setRemarksError}
              dateError={dateError}
              setDateError={setDateError}
              isFetching={isFetching}
            />
          )}
          <Col className="item-container">
            <Text className="item-text">
              {`Order contains items from ${
                editOrderData?.suborders?.length || "N/A"
              } sellers`}
            </Text>
            {isFetchingEditData ? (
              <>
                <Skeleton active paragraph={{ rows: 7 }} />
              </>
            ) : (
              <OrderItem
                updateOrderStatusApi={updateOrderStatusApi}
                itemList={editOrderData?.suborders}
                handleAddVehicleModal={handleAddVehicleModal}
                handleRefetchApi={handleRefetchApi}
                deleteSuborderFileApi={deleteSuborderFileApi}
                updateOrderStatusLoading={updateOrderStatusLoading}
              />
            )}
          </Col>
        </Col>
        <Col className="profile-wrapper">
          {isFetchingEditData ? (
            <>
              <Skeleton avatar active paragraph={{ rows: 1 }} />
            </>
          ) : (
            <>
              {rupifiStatusCheck.includes(
                editOrderData?.user_details?.rupifiDetails?.account_status
              ) && (
                <Col style={{ position: "absolute", left: 0, top: "-32px" }}>
                  <CustmerViewVipIcon />
                </Col>
              )}
              <Col
                className="profile"
                style={{
                  borderRadius: rupifiStatusCheck.includes(
                    editOrderData?.user_details?.rupifiDetails?.account_status
                  )
                    ? "0px 16px 16px 16px"
                    : "16px",
                }}
              >
                <Col className="profile-name">
                  <Avatar
                    style={{
                      border:
                        editOrderData?.user_details?.rupifiDetails
                          ?.account_status === "ACTIVE"
                          ? "2px solid #fecb00"
                          : "1px solid transparent",
                    }}
                  />
                  <Col className="contact">
                    <Text
                      style={{
                        fontWeight: 500,
                        fontSize: "1rem",
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      {editOrderData?.user_details?.full_name}
                      {editOrderData?.user_details?.is_professional && (
                        <ProProfileIcon />
                      )}
                    </Text>
                    <Text style={{ fontWeight: 500 }}>
                      {editOrderData?.user_details?.phone_number}
                    </Text>
                  </Col>
                </Col>
                <Text
                  onClick={() =>
                    handleCustomerView(
                      editOrderData?.user_details?.phone_number
                    )
                  }
                  style={{
                    color: "#2973f0",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  Customer view
                </Text>
              </Col>
              {rupifiStatusCheck.includes(
                editOrderData?.user_details?.rupifiDetails?.account_status
              ) && (
                <Col>
                  <MobCreditCard
                    ruipiCheck={
                      editOrderData?.user_details?.rupifiDetails?.account_status==="ACTIVE"
                    }
                    approved_balance={
                      editOrderData?.user_details?.rupifiDetails?.current_limit
                    }
                    available_balance={
                      editOrderData?.user_details?.rupifiDetails?.balance
                    }
                  />
                </Col>
              )}
            </>
          )}
          {isFetchingEditData ? (
            <>
              <Skeleton avatar active paragraph={{ rows: 1 }} />
            </>
          ) : (
            <OrderAddress
              title={"Delivery address"}
              addressType={"Delivery"}
              addressID={editOrderData?.address?.delivery_addreess?.id}
              addressDetails={delivery_address}
              // addressDetails={editOrderData?.address?.delivery_addreess}
              deliveryAddress={editOrderData?.all_addresses}
              func={editAddressModal}
              orderId={orderId}
              order={true}
            />
          )}
          {isFetchingEditData ? (
            <>
              <Skeleton active paragraph={{ rows: 1 }} />
            </>
          ) : (
            <OrderAddress
              title={"Billing address"}
              addressType={"Billing"}
              addressID={editOrderData?.address?.billing_address?.id}
              addressDetails={billing_address}
              deliveryAddress={editOrderData?.all_addresses}
              // addressDetails={editOrderData?.address?.billing_address}
              func={editBillingModal}
              orderId={orderId}
              order={true}
              mobCredit={billing_address?.mob_credit}
              mob_credit_check={
                editOrderData?.user_details?.rupifiDetails?.account_status
              }
            />
          )}
          <DeliveryAddressModal
            deliveryModal={deliveryModal}
            setIsModalOpen={setIsModalOpen}
            setDeliveryModal={setDeliveryModal}
            editData={editDataVal}
            initialFormData={initialFormData}
            formData={formData}
            setFormData={setFormData}
            addressListModal={showModal}
          />
          <BillingAddressModal
            initialFormData={initialFormData}
            billingFormData={billingFormData}
            setBillingFormData={setBillingFormData}
            billingModal={billingModal}
            setBillingModal={setBillingModal}
            setIsModalOpen={setIsModalOpen}
            editData={editData}
            addressListModal={showModal}
          />

          <AddVehicleModal
            setIsModalOpen={setAddVehicleModalOpen}
            isModalOpen={addVehicleModalOpen}
            title="Add vehicle"
            vehicleDetailData={vehicleDetailData}
            addVehicleFormData={addVehicleFormData}
            setAddVehicleFormData={setAddVehicleFormData}
            // vehicleUpdateData={vehicleUpdateData}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            // editSubData={editSubData}
            addVehicleApi={addVehicleApi}
            datePikerEmpty={datePikerEmpty}
            setDatePickerEmpty={setDatePickerEmpty}
          />

          <AddVehicleModal
            setIsModalOpen={setUpdateVehicleModalOpen}
            isModalOpen={updateVehicleModalOpen}
            title="Update vehicle"
            vehicleDetailData={vehicleData?.data}
            addVehicleFormData={addVehicleFormData}
            setAddVehicleFormData={setAddVehicleFormData}
            // editSubData={editSubData}
            addVehicleApi={updateVehicleApi}
            vehicleUpdateData={vehicleUpdateData}
            datePikerEmpty={datePikerEmpty}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
          />

          {/* <AddVehicleModal
        setIsModalOpen={setAddVehicleModalOpen}
        isModalOpen={addVehicleModalOpen}
        title="Add vehicle"
        vehicleDetailData={vehicleData?.data}
        addVehicleFormData={addVehicleFormData}
        setAddVehicleFormData={setAddVehicleFormData}


        editSubData={editSubData}



        addVehicleApi={addVehicleApi}




        datePikerEmpty={datePikerEmpty}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      /> */}

          {isFetchingEditData ? (
            <>
              <Skeleton active paragraph={{ rows: 1 }} />
            </>
          ) : (
            <>
              {!billing_address?.gst_number && (
                <Col className="add-gst">
                  <Text>Want to add GST?</Text>
                  <Text className="gst-btn" onClick={() => handleAddGst()}>
                    Add GST
                  </Text>
                </Col>
              )}
            </>
          )}
          <BillingAddress
            initialFormData={initialFormData}
            billingFormData={billingFormData}
            setBillingFormData={setBillingFormData}
            billingModal={billingModal}
            setBillingModal={setBillingModal}
            setIsModalOpen={setIsModalOpen}
            editData={billing_address}
            addressListModal={showModal}
          />
        </Col>
      </Col>
    </Col>
  );
};

export default OrderDetails;

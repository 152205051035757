import React from "react";
import { Col, Typography } from "antd";
import "../contentcard.css";
import { dateFormat } from "../../../commonFuntions/CommonUtilFunctions";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-right.svg";

export const RFQRequests = (props) => {
  const { data } = props;
  const { Text } = Typography;
  return (
    <>
      <Col className="rejected-card-container">
        {data?.map((val, idx) => (
          <Col key={idx} className="rejected-card-data-container">
            <Col className="rejected-card-data-sub-container">
              <Col className="rejected-card-data-sub-id-container">
                <Text className="rejected-card-data-sub-id-text">
                  {val?.rfq_id ? val?.rfq_id : "N/A"}
                </Text>
                <Text className="rejected-card-data-sub-name-text">
                  {dateFormat(val?.created_at)}
                </Text>
              </Col>
              <Col className="rfq-requests-card-content-container">
                <Col className="rejected-card-amount-sub-container">
                  <Text className="rejected-card-amount-sub-text">
                    {val?.rfq_created_by?.full_name || "N/A"}
                  </Text>
                  <Text className="rejected-card-amount-sub-text-item">
                    {val?.rfq_created_by?.phone_number || "N/A"}
                  </Text>
                </Col>
                <Col className="rejected-card-status-container">
                  <Col className="rfq-status-container">{val?.rfq_status}</Col>
                  <Link
                    to={`/admin/rfq/quotes/${val?.rfq_id}`}
                    target="_blank"
                    // onClick={() => handleNavigateToRFQDetails(params)}
                  >
                    <ArrowIcon style={{ color: "blue" }} />
                  </Link>
                </Col>
              </Col>
            </Col>
          </Col>
        ))}
      </Col>
    </>
  );
};

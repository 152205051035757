import React from "react";
import { Button, Checkbox, Col, Modal, Typography } from "antd";
import "./AmountCreditModal.css";
const { Text } = Typography;
const AmountCreditModal = ({
  isModalOpen,
  setIsModalOpen,
  handleOk,
  getData,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
 
  return (
    <Modal
      title="Are you sure?"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Col
          key="footer"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "28px",
            // borderTop: "1px solid #dedede",
          }}
        >
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              height: "48px",
              fontWeight: 500,
              fontSize: "14px",
              width: "120px",
            }}
          >
            CANCEL
          </Button>
          <Button
            key="save"
            type="primary"
            onClick={handleOk}
            style={{
              height: "48px",
              fontWeight: 500,
              fontSize: "14px",
              backgroundColor: "#0354a3",
            }}
          >
            YES, TRANSFERRED
          </Button>
        </Col>,
      ]}
    >
      <>
        <Col className="amountCreditModal-containerI">
          <Text className="amountCreditModal-textI">
            {` Has ₹ ${getData?.data?.amount} been credited to customer bank account?`}
          </Text>
        </Col>
        <Col className="amountCreditModal-containerII">
          <Text className="amountCreditModal-textII">
            How do you want to share with the customer?
          </Text>
          <Col>
            <Checkbox
              className="amountCreditModal-checkbox"
              // onChange={onChange}
            >{`Send mail to ${getData?.data?.mob_user?.email}`}</Checkbox>
            <Checkbox
              className="amountCreditModal-checkbox"
              // onChange={onChange}
            >{`Send text message to ${getData?.data?.mob_user?.phone_number}`}</Checkbox>
          </Col>
        </Col>
      </>
    </Modal>
  );
};

export default AmountCreditModal;

import React, { useState } from "react";
import "./UpdatePricing.css";
import { Button, Col, List, Row, Typography, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import FileDragger from "../../../components/Draggers/FileDragger";
import fileUpload from "../../../assets/icons/file-upload.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/RemoveImageIcon.svg";
import stats from "../../../assets/icons/storeIcon.svg";
import MobLibraryCard from "../../ManageMobLibrary/MobLibraryCard";
import {
  bytesToSize,
  // handleDownload,
  // handlePdfDownload,
  handlePublicFolderDownload,
} from "../../../commonFuntions/CommonUtilFunctions";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import { useUploadSellerCataloguePricingFileMutation } from "../../../apis/sellerCatalogue";
import { sellerViewStatusSelector } from "../../../redux/slices/sellerCatalogue/selector";
import { useSelector } from "react-redux";

const { Text } = Typography;
const UpdatePricing = () => {
  const navigate = useNavigate();
  const { seller_id } = useParams();
  const [messageApi] = message.useMessage();
  const [fileList, setFileList] = useState([]);
  // const [uploadedFileData, setUploadedFileData] = useState([]);
  const [addPricingFile, { data: fileObjData, isLoading }] =
    useUploadSellerCataloguePricingFileMutation();
  const sellerStatus = useSelector(sellerViewStatusSelector);
  const uploadFileText = [
    "Upload the new items in product template",
    "Items will be added to library",
    "Upload status will be visible on this page",
    "Upload the images for the new items added from MOB library page",
  ];

  const uploadedFile = (fileObj) => {
    const newFileList = [fileObj.file];
    setFileList(newFileList);
  };

  const handleBack = () => {
    navigate(`/admin/sellers/catalogue/${seller_id}`);
  };
  const customFileListRender = (fileList) => {
    return (
      <List
        dataSource={fileList}
        renderItem={(item) => (
          <Col
            style={{
              height: "50px",
              backgroundColor: "#f2f2f2",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 16px",
              borderRadius: "8px",
            }}
          >
            <Text
              style={{ color: "#0a243f", fontSize: "14px", fontWeight: 500 }}
            >
              {item.name}
            </Text>
            <Col>
              <Text
                style={{
                  color: "#0a243f",
                  fontSize: "14px",
                  fontWeight: 400,
                  opacity: 0.6,
                  marginRight: "36px",
                }}
              >
                {bytesToSize(item.size)}
              </Text>

              <RemoveIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleRemoveFile(item)}
              />
            </Col>
          </Col>
        )}
      />
    );
  };
  const handleRemoveFile = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    messageApi.open({
      type: "success",
      content: "File removed successfully",
    });
    setFileList(newFileList);
  };
  const handleUploadFile = async () => {
    showInfoToast("Uploading the products!");
    const params = { file: fileList[0] };
    const fileDataResponse = await addPricingFile(params);
    if (fileDataResponse?.data?.status === true) {
      // setUploadedFileData(fileDataResponse?.data?.data?.errors);
      showSuccessToast(fileDataResponse?.data?.message);
      setFileList([]);
    } else {
      showErrorToast(fileDataResponse?.data?.message || "Server ");
      // setUploadedFileData({});
    }
  };
  return (
    <>
      <Col style={{ padding: "2rem" }}>
        <Row>
          <Col>
            <Button
              style={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "14px",
                borderRadius: " 8px",
                border: "solid 1.5px #dedede",
              }}
              size="large"
              onClick={handleBack}
            >
              <ArrowLeftOutlined />
              BACK TO CATALOGUE
            </Button>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "32px",
            marginBottom: "30px",
          }}
        >
          <Col>
            <Text
              style={{
                fontWeight: 500,
                fontSize: "24px",
              }}
            >
              Update pricing
            </Text>
            <Text
              style={{
                marginTop: "5px",
                fontSize: "14px",
                display: "block",
                fontWeight: 400,
                color: "#0a243f",
              }}
            >
              Seller: {seller_id}{" "}
              {sellerStatus?.vendor_name
                ? `(${sellerStatus?.vendor_name})`
                : ""}
            </Text>
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            // gap: "50px",
          }}
        >
          <FileDragger
            uploadedFile={uploadedFile}
            fileUpload={fileUpload}
            fileList={fileList}
            customFileListRender={customFileListRender}
            handleFile={handleUploadFile}
            isLoading={isLoading}
            type="UPLOAD"
          />
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              width: "35%",
            }}
          >
            <Col
              className="dont-have-template"
              style={{
                backgroundColor: "#f8e6b6",
                height: "170px",
                width: "100%",
                borderRadius: "8px",
                padding: "24px",
              }}
            >
              <Col
                style={{
                  marginBottom: "12px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Don’t have the Seller catalogue template?
                </Text>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "70px",
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "65%",
                  }}
                >
                  <Text>
                    Filter the items in Catalogue and export it as Excel format
                  </Text>
                  <Button
                    style={{
                      width: "60%",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 500,
                      marginTop: "18px",
                    }}
                    onClick={() =>
                      handlePublicFolderDownload("/Pricing-Sheet-Example.xlsx")
                    }
                  >
                    <DownloadOutlined />
                    Get template
                  </Button>
                </Col>
                <Col style={{ marginRight: "10px" }}>
                  <img
                    src={stats}
                    alt="stats"
                    style={{
                      position: "relative",
                      left: "24px",
                      height: "70px",
                      width: "70px",
                      bottom: 5,
                    }}
                  />
                </Col>
              </Col>
            </Col>

            <Col
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                width: "100%",
              }}
            >
              <Col
                style={{
                  padding: "24px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#0a243f",
                  }}
                >
                  How does it work?
                </Text>
                <List
                  bordered={false}
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    paddingLeft: "13px",
                    lineHeight: "1.71",
                    fontWeight: "700",
                  }}
                  dataSource={uploadFileText}
                  renderItem={(item) => (
                    <List.Item style={{ padding: 0, borderBottom: "none" }}>
                      <List.Item.Meta
                        avatar={
                          <Col
                            style={{
                              width: "5px",
                              height: "5px",
                              backgroundColor: "#000",
                              borderRadius: "50%",
                              marginTop: "10px",
                            }}
                          />
                        }
                        title={
                          <Col
                            style={{
                              fontWeight: 400,
                              padding: 0,
                              color: "#0a243f",
                            }}
                          >
                            {item}
                          </Col>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Col>
          </Col>
          {fileObjData?.status && (
            <MobLibraryCard
              productData={fileObjData?.data?.errors?.errorList}
              productRecords={fileObjData?.data?.errors}
              showCards={true}
            />
          )}
        </Row>
      </Col>
    </>
  );
};

export default UpdatePricing;

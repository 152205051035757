import React, { useState } from "react"; // ,{ useState }
import "./ManageState.css";
import { Button, Col, Dropdown, Menu,  Typography } from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {
  DashOutlined,
} from "@ant-design/icons";

import ManageStateModal from "./ManageStateModal";

const { Text } = Typography;
const options = [
  {
    key: 1,
    value: "Excel",
    label: "Excel",
  },
  {
    key: 2,
    value: "CSV",
    label: "CSV",
  },
  {
    key: 3,
    value: "PDF",
    label: "PDF",
  },
  {
    key: 4,
    value: "Print",
    label: "Print",
  },
];


const tableDataSource = [
  {
    key: 1,
    state_code: "KA",
    state_name:"Karnataka"
  },
  {
    key: 2,
    state_code: "KA",
    state_name:"Karnataka"
  },
  {
    key: 3,
    state_code: "KA" ,
    state_name:"Karnataka"
  },
];
const ManageState = () => {
const [isAddStateModalOpen, setIsAddStateModalOpen] = useState(false);

  const addStateModalOpen = () => {
    setIsAddStateModalOpen(true)  };
 
  const columns = [
    {
      key: "icon",
      dataIndex: "icon",
      width: "50px",

      fixed:"left",
      render: (params, record) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  <>
                
                    <Menu.Item
                      //   onClick={() => handleDeleteModalOpen(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">Delete</Text>
                      </Col>
                    </Menu.Item>
                  </>
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined
              //   onClick={() => _onRowClick(record)}
              />
            </Dropdown>
          </>
        );
      },
      // height: "60px",
    },
    {
      title: "State code",
      dataIndex: "state_code",
      key: "state_code",
      width: "150px",
      fixed:"left",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
               
              }}
            >
              
              <Col
                // onClick={() => handleUserDetailsNavigation(record)}
                style={{ fontWeight: 500, cursor: "pointer" }}
              >
                {record.state_code}
              </Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "State name",
      dataIndex: "state_name",
      key: "state_name",
      width: "350px",
      render:(params,record)=>{
        return(
            <Col
            style={{ fontWeight: 500, cursor: "pointer" }}
          >
            {record.state_name}
          </Col>
        )
      }
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "100px",
      render: () => {
        return (
          <Col style={{ display: "flex", gap: "1rem", alignItems: "flex-end"}}>
            <Text style={{ color: "#2973f0", fontWeight: 500 }}>
              Edit
            </Text>
           
          </Col>
        );
      },
    },
  ];

  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">Manage state</Text>
      <Col className="categories-heading_wrapper">
        <Col style={{ display: "flex", gap: "1rem" }}>
          <SearchInput
            size={"large"}
            placeholder={"Search by customer name, mobile no, email"}
            //   getLibraryApi={getWalletTransferDetailsApi}
            width={"400px"}
            //   isLoading={isFetching}
          />

          <CustomSelect
            placeholder={"Download"}
            width={"100px"}
            height={"46px"}
            options={options}
            //   handleChange={handleChange}
            value={"EXPORT"}
          />
        </Col>
        <Button type="primary" className="cat-btn" onClick={addStateModalOpen}>
        Add state
        </Button>
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        //   tableExpandable={defaultExpandable}
        tableDataSource={tableDataSource || []}
        // tableDataSource={getWalletData?.data?.results}
        tableColumns={columns}
        // locale={{
        //   emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        // }}
        // isLoading={getWalletData?.data?.results.length && isFetching}
        scroll={{ x: "100%" }}
        // currentPage={currentPage}
        // handlePageChange={handlePageChange}
        itemsPerPage={30}
        // totalEntries={
        //   !isFetching && getWalletData?.data?.pagination?.total_entries
        // }
      />
    
    <ManageStateModal isModalOpen={isAddStateModalOpen} setIsModalOpen={setIsAddStateModalOpen}/>
    </Col>
  );
};

export default ManageState;

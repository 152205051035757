import { Col, Skeleton, Typography } from "antd";
import React from "react";
import EditContainerProduct from "../../../../ManageMobLibrary/EditMobLib/EditChildren/EditDetails/EditContainerProduct";
import ActionContainer from "../../../../ManageMobLibrary/EditMobLib/ActionContainer/ActionContainer";
import "./SellerDetails.css";
// import ButtonImageUpload from "../../../../../components/Draggers/ButtonImageUpload";
import { stateOptions ,cityOptions} from "../../../../../commonUtils/commonUtils";
import SellerImageButton from "../../../../../components/Draggers/SellerDisplayImage/SellerImageButton";

const SellerDetails = (props) => {
  const { Text } = Typography;
  const {
    handleChildFieldChange,
    data,
    handleCancel,
    // eslint-disable-next-line 
    seller_id,
    checkBoxList = [],

    
    isFetching,
    loadingButton,
  } = props;
  const itemDetails = [
    // {
    //   name: "bmp_id",
    //   toolTip: false,
    //   label: "BMP ID",
    //   type: "Input",
    //   placeholder: "Enter ID",
    //   required: true,
    //   value: data?.bmp_id || "",
    //   disabled: seller_id ? true : false,
    // },
    {
      name: "vendor_name",
      label: "Name of Company/Shop/Owner(as in the PAN)",
      type: "Input",
      placeholder: "Enter Name",
      toolTip: false,
      value: data?.vendor_name || "",
      required: true,
    },
    {
      name: "email",
      label: "Email Address",
      type: "Input",
      placeholder: "Enter email Address",
      toolTip: false,
      required: false,
      value: data?.email || "",
    },
    {
      name: "phone_number",
      label: "Phone Number",
      type: "Input",
      placeholder: "Enter Business Mobile Number",
      toolTip: false,
      required: true,
      value: data?.phone_number || "",
    },
    {
      name: "google_map_link",
      label: "Google map link",
      type: "Input",
      placeholder: "Enter Google map link",
      toolTip: false,
      required: false,
      value: data?.address?.google_map_link || "",
    },
    {
      name: "mail_address",
      label: "Full Address For Mailling",
      type: "Input",
      placeholder: "Enter Mailling Address",
      toolTip: false,
      required: false,
      value: data?.address?.address || "",
    },
    {
      name: "location",
      label: "Location",
      type: "Input",
      placeholder: "Enter Location",
      toolTip: false,
      required: false,
      value: data?.address?.address_line_2 ||data?.location || "",
    },
    {
      name: "state",
      label: "State",
      type: "Select",
      placeholder: "Select State",
      toolTip: false,
      required: true,
      value: data?.address?.state ||data?.state || "",

      options: stateOptions,
    },
    // {
    //   name: "city",
    //   label: "City",
    //   type: "Input",
    //   placeholder: "Enter City",
    //   toolTip: false,
    //   required: true,
    //   value: data?.address?.city || "",
    // },
    {
      name: "city",
      label: "City",
      type: "Select",
      placeholder: "Select City",
      toolTip: false,
      required: true,
      options:cityOptions,
      value: data?.address?.city || data?.city|| "",
    },
    {
      name: "pincode",
      label: "Pincode",
      type: "Input",
      placeholder: "Enter Pincode",
      toolTip: false,
      required: false,
      value: data?.address?.pincode ||data?.pincode|| "",
    },
    {
      name: "person_full_name",
      label: "Key Person Full Name",
      type: "Input",
      placeholder: "Enter full name",
      toolTip: false,
      value: data?.person_full_name || "",
    },
    {
      name: "shop_opening_time",
      label: "Shop Opening Time",
      type: "TimePicker",
      placeholder: "Enter Opening Time",
      toolTip: false,
      value: data?.shop_opening_time || "",
    },
    {
      name: "shop_closing_time",
      label: "Shop Closing Time",
      type: "TimePicker",
      placeholder: "Enter Closing Time",
      toolTip: false,
      value: data?.shop_closing_time || "",
    },
    {
      name: "website",
      label: "Website (if available)",
      type: "Input",
      placeholder: "Enter Website",
      toolTip: false,
      value: data?.website || "",
      // disabled: seller_id ? true : false,
    },
    {
      name: "shop_act_registration_number",
      label: "Shop Act Registration Number",
      type: "Input",
      placeholder: "Enter Registration Number",
      toolTip: false,
      value: data?.shop_act_registration_number || "",
    },
    {
      name: "gst_number",
      label: "GST Number",
      type: "Input",
      placeholder: "Enter GST Number",
      toolTip: false,
      required: true,
      value: data?.gst_number || "",
    },
    {
      name: "adhar_card_number",
      label: "Aadhar Card Number",
      type: "Input",
      placeholder: "Enter Aadhar Number",
      toolTip: false,
      value: data?.adhar_card_number || "",
    },
    {
      name: "pan_card_number",
      label: "PAN Card Number",
      type: "Input",
      placeholder: "Enter PAN Number",
      toolTip: false,
      value: data?.pan_card_number || "",
    },
    {
      name: "remarks",
      label: "Any Personal Remarks (Max 250 chat)",
      type: "TextArea",
      placeholder: "Enter PAN Number",
      toolTip: false,
      value: data?.remarks || "",
    },
    {
      name: "seller_group",
      label: "Seller Group",
      type: "Select",
      placeholder: "Select Seller Group",
      toolTip: false,
      required: false,
      value: data?.seller_group || "",
      options: [
        {
          key: 1,
          label: "Karnataka",
          value: "Karnataka",
        },
        {
          key: 2,
          label: "Tamil Nadu",
          value: "Tamil Nadu",
        },
      ],
    },
    {
      name: "type_of_materials",
      label: "Type of materials",
      type: "Select_Checkbox",
      placeholder: "Select Seller Group",
      toolTip: false,
      // required: true,
      // options: data?.types_of_material || checkBoxList || [],
      options: checkBoxList.map((item) => {
        
          return {
            ...item,
            checked: data?.types_of_material?.filter((item) => item?.isAdded === true).some((material) => material.id === item.id) ?? false,
          };
       
      }),
      // disabled: seller_id ? true : false,
      // options: [
      //   {
      //     key: 1,
      //     label: "Karnataka",
      //     value: "Karnataka",
      //   },
      //   {
      //     key: 2,
      //     label: "Tamil Nadu",
      //     value: "Tamil Nadu",
      //   },
      // ],
    },
    // {
    //   name: "seller_display_image",
    //   label: "Seller display image",
    //   type: "Select",
    //   placeholder: "Select Seller Group",
    //   toolTip: false,
    //   required: false,
    //   options: [
    //     {
    //       key: 1,
    //       label: "Karnataka",
    //       value: "Karnataka",
    //     },
    //     {
    //       key: 2,
    //       label: "Tamil Nadu",
    //       value: "Tamil Nadu",
    //     },
    //   ],
    // },
  ];

  const bankDetails = [
    {
      name: "bank_name",
      toolTip: false,
      label: "Bank Name",
      type: "Input",
      placeholder: "Enter Bank Name",
      value: data?.bank_details?.bank_name || "",
      required: false,
    },
    {
      name: "bank_branch",
      label: "Bank Branch",
      type: "Input",
      placeholder: "Enter Bank Branch",
      toolTip: false,
      value: data?.bank_details?.bank_branch || "",
      required: false,
      //   disabled: true,
    },
    {
      name: "account_holder",
      label: "Name of The Account Holder",
      type: "Input",
      placeholder: "Enter Name",
      toolTip: false,
      required: false,
      value: data?.bank_details?.name_of_account_holder || "",
    },
    {
      name: "bank_account_number",
      label: "Bank Account Number",
      type: "Input",
      placeholder: "Enter Bank Account Number",
      toolTip: false,
      required: false,
      value: data?.bank_details?.bank_account_number || "",
    },
    {
      name: "bank_ifsc",
      label: "Bank IFSC",
      type: "Input",
      placeholder: "Enter Bank IFSC",
      toolTip: false,
      required: false,
      value: data?.bank_details?.bank_ifsc || "",
    },
    {
      name: "bank_account_type",
      label: "Bank Account Type",
      type: "Select",
      placeholder: "Enter Bank Account Type",
      toolTip: false,
      required: false,
      value: data?.bank_details?.bank_account_type || "",
      options: [
        {
          key: "Saving",
          label: "Saving",
          value: "Saving",
        },
        {
          key: "Current",
          label: "Current",
          value: "Current",
        },
        {
          key: "Other",
          label: "Other",
          value: "Other",
        },
      ],
    },
  ];
  return (
    <>
      <Col className="seller-details-main">
        <Col className="seller-details-main-left">
          <EditContainerProduct
            title={""}
            formData={itemDetails}
            handleChildFieldChange={handleChildFieldChange}
            isFetching={isFetching}
          />
          <EditContainerProduct
            title={"Bank Details"}
            formData={bankDetails}
            handleChildFieldChange={handleChildFieldChange}
            isFetching={isFetching}
          />
        </Col>
        <Col className="seller-details-main-right">
          <Col>
            <Text className="seller-display-image-text">
              Seller display image
            </Text>
            <Col className="rightSubContent" style={{ marginTop: "20px" }}>
              {isFetching ? (
                <>
                  <Skeleton active />
                </>
              ) : (
                <SellerImageButton
                  data={data}
                  name={"seller_display_image"}
                  handleChildFieldChange={handleChildFieldChange}
                />
              )}
            </Col>
          </Col>
        </Col>
      </Col>
      <ActionContainer
        position={"bottom"}
        handleCancel={handleCancel}
        loadingButton={loadingButton}
      />
    </>
  );
};

export default SellerDetails;

import React, { useEffect, useState } from "react";
import {
  Typography,
  // Select,
  // Table,
  Row,
  Col,
  // Dropdown,
  // Space,
  Tag,
  Tooltip,
  // Menu,
} from "antd";
import dayjs from "dayjs";
import "./RFQList.css";
import {
  LoadingOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import {
  colorMapping,
  dateFormat,
  handlePdfDownload,
} from "../../../commonFuntions/CommonUtilFunctions";
import { Link, useNavigate } from "react-router-dom";
import {
  useLazyGetRFQListExcelQuery,
  useLazyGetRFQListQuery,
} from "../../../apis/rfqList";
import { useDispatch, useSelector } from "react-redux";
import { getRFQList } from "../../../redux/slices/rfqList/action";
import { rfqListSelector } from "../../../redux/slices/rfqList/selector";
import { ReactComponent as FileIcon } from "../../../assets/icons/file.svg";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import { LayoutWrapper } from "../../../components/LayoutWrapper/LayoutWrapper";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import { saveAs } from "file-saver";
import NoDataFound from "../../../components/EmptyTable/NoDataFound";

const { Title, Text } = Typography;

const RFQList = () => {
  // const [, setSearchText] = useState("");
  const [dateFilter, setDateFilter] = useState();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedValue, setSearchedValue] = useState({});
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [sorterState, setSorterState] = useState({
    rfq_id: "",
    created_at: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rfqListArray = useSelector(rfqListSelector);
  // const { data: apiData } = useGetRFQListQuery();
  const [rfqListApi, { data: apiData, isFetching: isLoading }] =
    useLazyGetRFQListQuery();
  const [
    excelApi,
    {
      isFetching: exlLoading,
      //  data: excelApiData
    },
  ] = useLazyGetRFQListExcelQuery();
  useEffect(() => {
    if (apiData) {
      dispatch(getRFQList(apiData?.data?.results));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);
  useEffect(() => {
    rfqListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rfqListArray) {
      let arr = [];
      rfqListArray.map((item, index) => {
        let obj = {
          key: index,
          id: item.rfq_id,
          date: item.created_at,
          name: item.rfq_created_by.full_name,
          product: item.product_details.mob_sku,
          mobileNo: item.rfq_created_by.phone_number,
          email: item.rfq_created_by.email,
          remarks: item.rfq_remarks,
          totalQuotes: item.rfq_quotes.length,
          status: item.rfq_status,
          orderNo: item.orderNo,
          view: item.rfq_file,
          order_details: item.order_details,
        };
        return arr.push(obj);
      });
      setTableData(arr);
    }
  }, [rfqListArray]);
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const handleNavigateToRFQDetails = (id) => {
    navigate(`/admin/rfq/quotes/${id}`, { state: { id } });
  };
  // const handleCreateOrder = (record) => {
  //   const id = record?.id;
  //   navigate(`/admin/orders/create-order`, {
  //     state: { id },
  //   });
  // };
  const options = [
    {
      key: 1,
      value: "Today",
      label: "Today",
    },
    {
      key: 2,
      value: "Yesterday",
      label: "Yesterday",
    },
    {
      key: 3,
      value: "last_7_day",
      label: "Last 7 day",
    },
    {
      key: 4,
      value: "last_30_day",
      label: "Last 30 day",
    },
    {
      key: 5,
      value: "last_60_day",
      label: "Last 60 day",
    },
    {
      key: 6,
      value: "Custom",
      label: "Custom",
    },
    {
      key: 7,
      value: "clear_date",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear Date</Col>
      ),
    },
  ];
  const sorterChange = (item) => {
    let key = item;
    setSorterState({
      // ...sorterState,
      [key.replace("-", "")]: item,
    });
    rfqListApi({
      sort_by: item,
      ...(searchedValue?.search && { search: searchedValue?.search }),
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
      page: 1,
    });
  };
  const columns = [
    // {
    //   title: (
    //     <PlusOutlined
    //       style={{
    //         backgroundColor: "#fff",
    //         borderRadius: "50%",
    //         padding: "3px",
    //       }}
    //     />
    //   ),
    //   key: "icon",
    //   render: (param, record) => (
    //     <>
    //       {record?.status !== "Order Created" &&
    //         record?.status !== "Order Converted" && (
    //           <Dropdown
    //             overlay={
    //               <Menu>
    //                 <>
    //                   <Menu.Item
    //                     // onClick={() => onEdit(record)}
    //                     style={{ padding: "7px 12px" }}
    //                   >
    //                     <Col className="container-list">
    //                       <Text className="select-text">Upload Quote</Text>
    //                     </Col>
    //                   </Menu.Item>
    //                   <Menu.Item
    //                     onClick={() => handleCreateOrder(record)}
    //                     style={{
    //                       padding: "7px 12px",
    //                     }}
    //                   >
    //                     <Col className="container-list">
    //                       <Text className="select-text">Create Order</Text>
    //                     </Col>
    //                   </Menu.Item>
    //                 </>
    //               </Menu>
    //             }
    //             trigger={"click"}
    //           >
    //             <DashOutlined />
    //           </Dropdown>
    //         )}
    //     </>
    //   ),
    //   fixed: "left",
    //   width: "50px",
    //   height: "60px",
    // },

    {
      // title: "RFQ Id",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">RFQ Id</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.rfq_id === "rfq_id" ? "" : "grey",
                }}
                onClick={() => sorterChange("rfq_id")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color: sorterState?.rfq_id === "-rfq_id" ? "" : "grey",
                }}
                onClick={() => sorterChange("-rfq_id")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      dataIndex: "id",
      width: "200px",
      className: "rfq-id-class",
      fixed: "left",

      render: (params) => {
        return (
          <>
            <Link
              to={`/admin/rfq/quotes/${params}`}
              target="_blank"
              // onClick={() => handleNavigateToRFQDetails(params)}
            >
              {params}
            </Link>
            {/* <Col onClick={() => handleNavigateToRFQDetails(params)}>
              {params}
            </Col> */}
          </>
        );
      },

      // sorter: (a, b) => a.id.localeCompare(b.id),
      // sortDirections: ["ascend", "descend"]  ,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (params) => {
        return (
          <Tag
            style={{ padding: "8px" }}
            className={`tag-font-style ${colorMapping[params]}`}
          >
            {params}
          </Tag>
        );
      },
      width: "150px",
    },
    {
      // title: "Date",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Date</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.created_at === "created_at" ? "" : "grey",
                }}
                onClick={() => sorterChange("created_at")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color:
                    sorterState?.created_at === "-created_at" ? "" : "grey",
                }}
                onClick={() => sorterChange("-created_at")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      dataIndex: "date",
      render: (params) => {
        return <Col>{dateFormat(params)}</Col>;
      },
      width: "200px",
    },
    {
      title: "Product",
      dataIndex: "product",
      width: "150px",
      className: "rfq-id-class",
      render: (params, record) => {
        return (
          <>
            {params && (
              <Col onClick={() => handleNavigateToRFQDetails(record?.id)}>
                {params ? `SKU: ${params}` : ""}
              </Col>
            )}
            {record?.view && (
              <Col
                style={{ cursor: "pointer" }}
                onClick={() => handlePdfDownload(record?.view)}
              >
                {record?.view && <FileIcon />} View
              </Col>
            )}
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "350px",
    },
    {
      title: "Business mobile",
      dataIndex: "mobileNo",
      width: "200px",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "350px",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "300px",
      render: (params) => {
        return (
          <>
            <Tooltip title={params} className="rfq-table-remarks" color="#fff">
              <Text style={{ cursor: "pointer" }}>
                {params?.length > 100
                  ? `${params?.slice(0, 60)}...`
                  : params || ""}
              </Text>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Total quotes",
      dataIndex: "totalQuotes",
      align: "center",
      width: "150px",
    },
    {
      title: "Order No",
      dataIndex: "orderNo",
      width: "180px",
      render: (params, record) => {
        return (
          <>
            <Link
              to={`/admin/orders/order-details/${record?.order_details?.id}`}
              // onClick={() => handleNavigateToRFQDetails(params)}
            >
              {record?.order_details?.order_id || "N/A"}
            </Link>
            {/* <Col onClick={() => handleNavigateToRFQDetails(params)}>
              {params}
            </Col> */}
          </>
        );
      },
    },
    // {
    //   title: "View",
    //   dataIndex: "view",
    //   align: "center",
    //   width: "150px",
    //   render: (params) => {
    //     return (
    //       <Col
    //         style={{ cursor: "pointer" }}
    //         onClick={() => handlePdfDownload(params)}
    //       >
    //         {params && <FileIcon />}
    //       </Col>
    //     );
    //   },
    // },
  ];
  // const handleExportApi = () => {
  //   // let brandArray = [];
  //   // let categoriesArray = [];
  //   // brands.map((item) => brandArray.push(item.label));
  //   // categories.map((item) => categoriesArray.push(item.label));
  //   // excelApi({
  //   // id: seller_id,
  //   // params: {
  //   //   ...(brandArray.length && { brands: brandArray }),
  //   //   ...(categoriesArray.length && { category: categoriesArray }),
  //   //   ...(searchedValue.search && { search: searchedValue.search }),
  //   // },
  //   // });

  //   // if (excelApiData?.data?.url) {
  //   //   handlePdfDownload(excelApiData?.data?.url);
  //   // }
  // };
  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        rfqListApi({
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        rfqListApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }
      setCurrentPage(1);
    }
    setDateFilter(value);
  };
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      rfqListApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
        ...(sorterState.created_at && { sort_by: sorterState.created_at }),
      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };
  // const handleSearch = (value) => {
  //   setSearchText(value);
  // };

  // const handleProvinceChange = (value) => {
  //   setCities(cityData[value]);
  //   setSecondCity(cityData[value][0]);
  // };
  // const onSecondCityChange = (value) => {
  //   setSecondCity(value);
  // };

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    setCurrentPage(currPage);
    await rfqListApi(params);
  };
  const exportOptions = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];

  const handleExportApi = async () => {
    let response = await excelApi({
      ...(searchedValue.search && { search: searchedValue.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    });
    if (response?.status === "fulfilled") {
      // handlePdfDownload(response?.data?.data?.url);
      saveAs(response?.data?.data?.url);
    }

    // excelApi();
  };
  return (
    <LayoutWrapper>
      <Title
        level={4}
        style={{
          margin: "0 0  32px 0",
          color: "#0a243f",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 500,
        }}
      >
        RFQ'S
      </Title>
      <Row style={{ marginBottom: "20px" }} className="action-container">
        <Col className="action-sub-container">
          <SearchInput
            size={"medium"}
            placeholder={"Search customer name,mobile.no,email"}
            width={"400px"}
            getLibraryApi={rfqListApi}
            handleSearchedValue={handleSearchedValue}
            isLoading={isLoading}
          />

          <CustomSelect
            placeholder={"Date"}
            width={"150px"}
            options={options}
            handleChange={handleChange}
            value={dateFilter}
          />
          {dateFilter === "Custom" && (
            <DateRangePicker
              onChange={handleChangeRangePicker}
              format={"DD-MMM-YYYY"}
            />
          )}

          <CustomSelect
            placeholder={"Export"}
            width={"150px"}
            options={exportOptions}
            handleChange={handleExportApi}
            value={exlLoading ? <LoadingOutlined /> : "Export"}
            disabled={exlLoading ? true : false}
            loading={exlLoading}
          />
        </Col>
      </Row>

      {/* <Table
        className="rfq-list-table"
        dataSource={tableData}
        columns={columns}
        scroll={{ x: "100%" }}
        pagination={{ pageSize: 7 }}
        locale={{
          emptyText: isLoading ? <TableSkeleton /> : "",
        }}
      /> */}
      <CustomTableWithPagination
        className="rfq-list-table"
        tableDataSource={tableData || []}
        tableColumns={columns}
        locale={{
          emptyText: isLoading ? (
            <TableSkeleton length={30} />
          ) : (
            <NoDataFound text={"No RFQ'S found!"} />
          ),
        }}
        scroll={{ x: "100%" }}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={!isLoading && apiData?.data?.pagination?.total_entries}
        isLoading={tableData.length && isLoading}
      />
    </LayoutWrapper>
  );
};

export default RFQList;

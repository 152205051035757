import {Button, Col, Typography, Row} from "antd";
import React, {useEffect, useState} from "react";
import {ArrowLeftOutlined} from "@ant-design/icons";
import "./MobCreditView.css";
import {ReactComponent as MobCREDITAvailableIcon} from "../../../assets/icons/mobCREDITAvailable.svg";
import {ReactComponent as CurrencyIcon} from "../../../assets/icons/CurrencyIcon.svg";
// import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
// import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {Link, useNavigate, useParams, useLocation} from "react-router-dom";
import {useLazyGetMobCreditTransactionDetailsQuery} from "../../../apis/wallet";
import {
  dateFormat,
  removeLastWord,
} from "../../../commonFuntions/CommonUtilFunctions";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import NoDataFound from "../../../components/EmptyTable/NoDataFound";
const {Text} = Typography;
// const options = [
//   {
//     key: 1,
//     value: "Excel",
//     label: "Excel",
//   },
//   {
//     key: 2,
//     value: "CSV",
//     label: "CSV",
//   },
//   {
//     key: 3,
//     value: "PDF",
//     label: "PDF",
//   },
//   {
//     key: 4,
//     value: "Print",
//     label: "Print",
//   },
// ];
const MobCreditView = () => {
  const param = useParams();
  const locationData = useLocation();
  const navigate = useNavigate();
  const [
    searchedValue,
    //  setSearchedValue
  ] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  // const navigate = useNavigate();
  // const handleViewPage = (record) => {
  //   navigate(`/admin/customer/walletbankTransferView/${record?.id}`);
  // };
  const [getWalletTransferDetailsApi, {data: getWalletData, isFetching}] =
    useLazyGetMobCreditTransactionDetailsQuery();

  useEffect(() => {
    getWalletTransferDetailsApi({phone_number: param?.phone_number});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const handleSearchedValue = (params) => {
  //   setSearchedValue(params);
  //   setCurrentPage(1);
  // };
  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      phone_number: param?.phone_number,
      ...(searchedValue && {search: searchedValue?.search}),
    };
    setCurrentPage(currPage);
    await getWalletTransferDetailsApi(params);
  };
  const columns = [
    {
      title: "DESCRIPTION",
      dataIndex: "DESCRIPTION",
      key: "DESCRIPTION",
      fixed: "left",
      render: (_, record) => {
        return (
          <Col className="description-container">
            <Text className="description-container-heading">
              {removeLastWord(record?.remarks)}{" "}
              <Link
                className="order-id-text"
                to={`/admin/orders/order-details/${record?.order?.id}`}
                target="_blank">
                {record?.order?.order_id}
              </Link>
            </Text>
            <Text className="debitedOn">
              Debited on {dateFormat(record?.created_at)}{" "}
            </Text>
          </Col>
        );
      },
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount",
      fixed: "left",
      width: "250px",
      render: (params) => {
        return (
          <Text className="amount-container-heading">
            ₹ {(params || 0).toFixed(2)}
          </Text>
        );
      },
    },
  ];

  // const handleChange = (value) => {
  //   console.log("Download data in vormate : ", value);
  // };
  const handleBackNavigate = () => {
    navigate(`/admin/customer/customerDetails/${param?.phone_number}`);
  };
  return (
    <Col className="walletBank-container">
      <Button
        className="btn"
        icon={<ArrowLeftOutlined />}
        onClick={handleBackNavigate}>
        BACK
      </Button>
      <Text className="customer_title">mobCREDIT</Text>
      <Row className="mob-credit-balance-container">
        <Row className="mob-credit-balance-sub-container">
          <Row className="mob-credit-balance-sub-image-container">
            <MobCREDITAvailableIcon />
          </Row>

          <Row className="mob-credit-balance-sub-text-container">
            <Text className="mob-credit-balance-sub-text-container-balance">
              ₹ {(locationData?.state?.balance || 0)?.toFixed(2)}
            </Text>
            <Text className="mob-credit-balance-sub-text-container-text">
              Available balance
            </Text>
          </Row>
        </Row>

        <Row className="mob-credit-balance-sub-container">
          <Row className="mob-credit-balance-sub-image-container">
            <CurrencyIcon />
          </Row>

          <Row className="mob-credit-balance-sub-text-container">
            <Text className="mob-credit-balance-sub-text-container-balance">
              ₹ {(locationData?.state?.current_limit || 0).toFixed(2) || " -"}
            </Text>
            <Text className="mob-credit-balance-sub-text-container-text">
              Active
            </Text>
          </Row>
        </Row>
      </Row>

      <Text className="customer_title">MOB credit used</Text>
      {/* <Col style={{display: "flex", gap: "1rem"}}> */}
      {/* <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
          getLibraryApi={getWalletTransferDetailsApi}
          handleSearchedValue={handleSearchedValue}
          width={"400px"}
          isLoading={isFetching}
          id={param?.phone_number}
        /> */}
      {/* <CustomSelect
          placeholder={"Download"}
          width={"100px"}
          height={"46px"}
          options={options}
          handleChange={handleChange}
          value={"EXPORT"}
        /> */}
      {/* </Col> */}
      <CustomTableWithPagination
        className="mob-credit-table"
        //   tableExpandable={defaultExpandable}
        //   tableDataSource={tableDataSource || []}
        tableDataSource={getWalletData?.data?.results}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? (
            <TableSkeleton length={10} />
          ) : (
            <NoDataFound text={"No transaction found!"} />
          ),
        }}
        isLoading={getWalletData?.data?.results?.length && isFetching}
        scroll={{x: "100%"}}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={
          !isFetching && getWalletData?.data?.pagination?.total_entries
        }
      />
    </Col>
  );
};

export default MobCreditView;

import React, {useEffect} from "react";
import {Col, Row} from "antd";
import StatusCard from "./StatusCard";
import ContentCard from "./ContentCard";
import DashBoardTable from "./DashBoardTable";
// import { ReactComponent as TwoWheelerIcon } from "../../assets/icons/2wheeler.svg";
// import { ReactComponent as ThreeWheelerIcon } from "../../assets/icons/3wheeler.svg";
// import { ReactComponent as ExchangeIcon } from "../../assets/icons/exchange.svg";
// import { ReactComponent as ReturnIcon } from "../../assets/icons/return.svg";
import {useLazyGetDashboardQuery} from "../../apis/dashboard";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import {useNavigate} from "react-router-dom";
import CardSkeleton from "../../components/skeleton/CardSkeleton";
import {StatusCardSkeleton} from "../../components/skeleton/StatusCardSkeleton";

const Dashboard = () => {
  const [dashboardApi, {data: dashboardData, isFetching}] =
    useLazyGetDashboardQuery();
  const navigate = useNavigate();
  useEffect(() => {
    dashboardApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRfqRequestNavigate = () => {
    navigate("/admin/rfq");
  };
  const handleServiceRequestNavigate = () => {
    navigate("/admin/report/service-request-report");
  };

  return (
    <>
      <Col className="dashboard-main-container">
        <Row className="dashboard-main-row-container">
          {isFetching ? (
            <StatusCardSkeleton />
          ) : (
            <StatusCard
              heading="Orders for fulfillment"
              value={(dashboardData?.data?.order_for_fullfillment || 0).toFixed(
                0
              )}
              status="Combined"
            />
          )}
          {isFetching ? (
            <StatusCardSkeleton />
          ) : (
            <StatusCard
              heading="Cancellations"
              value={(dashboardData?.data?.order_cancelled || 0).toFixed(0)}
              status="This month"
            />
          )}
          {isFetching ? (
            <StatusCardSkeleton />
          ) : (
            <StatusCard
              heading="Avg suborder size"
              value={(
                dashboardData?.data?.average_suborder_size?.avg_suborders || 0
              ).toFixed(2)}
              status="This month"
            />
          )}
          {isFetching ? (
            <StatusCardSkeleton />
          ) : (
            <StatusCard
              heading="Total sales amount"
              value={(dashboardData?.data?.total_sales_amount || 0).toFixed(2)}
              status="This month"
            />
          )}
        </Row>
        <Row className="dashboard-main-row-card-container">
          <Col className="dashboard-card-align">
            {/* <ContentCard
              heading="Vehicle request"
              data={vehicleRequest}
              length={vehicleRequest.length}
            /> */}
            {/* <ContentCard
              heading="Rejected by seller"
              data={rejectedBySeller}
              length={rejectedBySeller.length}
            /> */}
          </Col>
          <Col className="dashboard-card-align">
            {/* <ContentCard
              heading="Return/ exchange"
              data={returnExchange}
              length={returnExchange.length}
            /> */}
            {isFetching ? (
              <>
                <CardSkeleton />
              </>
            ) : (
              <ContentCard
                heading="RFQ requests"
                data={dashboardData?.data?.rfq_request}
                length={dashboardData?.data?.rfq_request?.length}
                isFetching={isFetching}
                handleNavigate={handleRfqRequestNavigate}
              />
            )}

            {isFetching ? (
              <>
                <CardSkeleton />
              </>
            ) : (
              <ContentCard
                heading="Accepted RFQ’s for order"
                data={dashboardData?.data?.rfq_accepted}
                length={dashboardData?.data?.rfq_accepted?.length}
                isFetching={isFetching}
                handleNavigate={handleRfqRequestNavigate}
              />
            )}
          </Col>
          <Col className="dashboard-card-align">
            {isFetching ? (
              <>
                <CardSkeleton />
              </>
            ) : (
              <ContentCard
                heading="Service request"
                data={dashboardData?.data?.service_requests}
                length={dashboardData?.data?.service_requests?.length}
                isFetching={isFetching}
                handleNavigate={handleServiceRequestNavigate}
              />
            )}
          </Col>
          <Col className="dashboard-card-align">
            {/* <ContentCard
              heading="Item issues"
              data={itemIssues}
              length={itemIssues.length}
            /> */}
          </Col>
          {isFetching ? (
            <Col className="skeleton-wrapper-container">
              <TableSkeleton length={6} />
            </Col>
          ) : (
            <DashBoardTable orderData={dashboardData?.data?.orders} />
          )}
        </Row>
        {/* <CreateQuote /> */}
      </Col>
    </>
  );
};

export default Dashboard;

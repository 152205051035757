import React from "react";
import "./ActionContainer.css";
import { Button, Col, Form } from "antd";

const ActionContainer = (props) => {
  const { position, handleCancel, loadingButton } = props;
  return (
    <>
      <Form.Item>
        <Col
          className={
            position === "top"
              ? "top-action-container"
              : "bottom-action-container"
          }
        >
          <Button className="cancelBtn" onClick={handleCancel}>
            CANCEL
          </Button>
          <Button
            loading={loadingButton}
            className="saveBtn"
            type="primary"
            htmlType="submit"
          >
            SAVE
          </Button>
        </Col>
      </Form.Item>
    </>
  );
};
export default ActionContainer;

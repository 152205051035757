import React from "react";
// import "./createOrderModal.css";
import { Col, Typography, Checkbox } from "antd";
import { MainModal } from "../../../../../components/Modal/MainModal/MainModal";
import { ModalFooter } from "../../../../../components/Modal/MainModal/ModalFooter";
import { dateFormat } from "../../../../../commonFuntions/CommonUtilFunctions";
import { ReactComponent as WalletIcon } from "../../../../../assets/icons/creditWallet.svg";
import { ReactComponent as RedRightArrowIcon } from "../../../../../assets/icons/redrightarrow.svg";

const { Text, Title } = Typography;

export const CancelOrderModal = (props) => {
  const {
    title,
    open,
    handleCloseModal,
    handleCancel,
    handleSave,
    cancelText,
    saveText,
    data,
    checkboxState,
    setCheckboxState,
  } = props;
  const footer = [
    <ModalFooter
      cancelText={cancelText}
      saveText={saveText}
      handleCancel={handleCancel}
      handleSave={handleSave}
      saveButtonCss={"cancel-order-button"}
    />,
  ];
  const handleCheckboxChange = (e, type) => {
    const targetValue = e.target.checked;
    setCheckboxState({ ...checkboxState, [type]: targetValue });
  };
  return (
    <>
      <MainModal
        title={title}
        open={open}
        handleCloseModal={handleCloseModal}
        footer={footer}
      >
        <Col style={{ height: "450px" }} className="create-modal-container">
          {/* <Col className="create-modal-sub-container">
            <Text className="create-modal-recommed-text">Are you sure?,</Text>
            <Text className="create-modal-recommed-text-2">
              {" "}
              to Cancel this order,
            </Text>
          </Col> */}
          <Col
            style={{
              display: "flex",
              border: "1px solid #dedede",
              borderRadius: "8px",
              height: "100px",
              marginBottom: "20px",
              position: "relative",
            }}
          >
            <RedRightArrowIcon
              width={"20px"}
              height={"20px"}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 100,
              }}
            />

            <Col
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "14px",
              }}
            >
              <Text
                style={{ color: "#0a243f", fontWeight: 500, fontSize: "16px" }}
              >
                Paid amount
              </Text>
              <Text
                style={{ color: "#0a243f", fontWeight: 500, fontSize: "16px" }}
              >
                ₹ {(data?.paid_amount || 0).toFixed(2)}
              </Text>
            </Col>
            <Col
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-end",
                backgroundColor: "#ffebe6",
                padding: "14px 14px 14px 0",
              }}
            >
              <Text
                style={{ color: "#bf2600", fontWeight: 500, fontSize: "16px" }}
              >
                If cancelled
              </Text>
              <Text
                style={{
                  color: "#0a243f",
                  fontWeight: 500,
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                will be added to MOB wallet{" "}
                <WalletIcon width={"20px"} height={"20px"} />
              </Text>
            </Col>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "120px",
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#f5f5f5",
              color: "#2973f0",
              marginBottom: "30px",
            }}
          >
            <Col
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Col
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Text
                  style={{
                    color: "#0a243f",
                    fontWeight: 500,
                  }}
                >{`Sub ID: ${data?.sub_order_id}`}</Text>
                <Text style={{ color: "#0a243f", fontSize: "14px" }}>
                  {data?.user_details?.full_name}{" "}
                  {`(+91 ${data?.user_details?.phone_number})`}
                </Text>
              </Col>
              <Text style={{ color: "#6c7c8c" }}>
                {data?.created_at && dateFormat(data?.created_at)}
              </Text>
            </Col>
            <Col
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <Col style={{ display: "flex", gap: "10px" }}>
                <Text style={{ color: "#6c7c8c" }}>Items: </Text>
                <Text style={{ color: "#0a243f", fontWeight: 500 }}>
                  {data?.items?.length || 0}
                </Text>
              </Col>
              <Col style={{ display: "flex", gap: "10px" }}>
                <Text style={{ color: "#6c7c8c" }}>Total: </Text>
                <Text style={{ color: "#0a243f", fontWeight: 500 }}>
                  ₹ {data?.sub_total ? Number(data?.sub_total)?.toFixed(2) : 0}
                </Text>
              </Col>
            </Col>
          </Col>
          <Col>
            <Title
              level={5}
              style={{
                color: "#0a243f",
                margin: 0,
                marginBottom: "20px",
                fontWeight: 500,
              }}
            >
              How do you want to share with the customer?
            </Title>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {data?.user_details?.email && (
                <Checkbox
                  onChange={(e) => handleCheckboxChange(e, "email")}
                  checked={checkboxState?.email}
                  style={{
                    color: "#0a243f",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {`Send mail to ${data?.user_details?.email}`}
                </Checkbox>
              )}

              {data?.user_details?.phone_number && (
                <Checkbox
                  onChange={(e) => handleCheckboxChange(e, "phone_number")}
                  checked={checkboxState?.phone_number}
                  style={{
                    color: "#0a243f",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {`Send text message to ${data?.user_details?.phone_number}`}
                </Checkbox>
              )}
            </Col>
          </Col>
        </Col>
      </MainModal>
          
    </>
  );
};

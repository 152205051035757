import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "./baseQuery";

export const manageOrdersAPI = createApi({
  reducerPath: "manageOrdersAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getExistingOrdertTable: builder.query({
      query: (params) => {
        return {
          url: `api/orders/order/`,
          params,
        };
      },
    }),
    getOrderDetail: builder.query({
      query: (id) => {
        return {
          url: `api/orders/order/${id}/get_order_details/`,
        };
      },
    }),
    updateOrderStatus: builder.mutation({
      query: ({id, params}) => ({
        url: `api/orders/order/${id}/update_suborder_details/`,
        method: "PATCH",
        body: params,
      }),
    }),
    cancelOrderDetails: builder.mutation({
      query: (id) => ({
        url: `api/orders/order/cancel_order/`,
        method: "PATCH",
        body: id,
      }),
    }),
    updateOrderDetailsAddress: builder.mutation({
      query: (params) => ({
        url: `api/orders/order/update_order_address/`,
        method: "PATCH",
        body: params,
      }),
    }),
    addOrderPayment: builder.mutation({    
      query: (paymentData) => ({
        url: "api/orders/order/create_payment_history/",
        method: "POST",
        body: paymentData,
      }),
    }),
    convertToOrderAPI: builder.mutation({
      query: (convertData) => ({
        url: "api/orders/rfq/convert_to_order/",
        method: "POST",
        body: convertData,
      }),
    }),
    getSubOrderDetail: builder.query({
      query: (id) => {
        return {
          url: `api/orders/order/${id}/get_suborder_details/`,
        };
      },
    }),

    getVehiclesDetail: builder.query({
      query: () => {
        return {
          url: `/api/orders/vehicles/`,
        };
      },
    }),
    updateVehiclesDetail: builder.mutation({
      query: (params) => {
        const formData = new FormData();

        formData.append("vehicle_id", params.vehicle_id);
        formData.append("waybillno", params.waybillno);
        formData.append(
          "customer_logistics_ref_no",
          params.customer_logistics_ref_no
        );
        formData.append("assigned_date", params.assigned_date);
        formData.append("assigned_time", params.assigned_time);
        formData.append("driver_name", params.driver_name);
        formData.append("driver_phone_number", params.driver_phone_number);
        formData.append("vechicle_number", params.vechicle_number);
        formData.append("sub_order", params.sub_order);
        formData.append("vehicle", params.vehicle);
        formData.append("add_way_bill", params.add_way_bill);
        return {
          url: "api/orders/order/update_suborder_vehicle/",
          method: "PATCH",
          body: formData,
        };
      },
    }),
    addVehicle: builder.mutation({
      query: (params, file) => {
        const formData = new FormData();
        formData.append("waybillno", params.waybillno);
        formData.append(
          "customer_logistics_ref_no",
          params.customer_logistics_ref_no
        );
        formData.append("assigned_date", params.assigned_date);
        formData.append("assigned_time", params.assigned_time);
        formData.append("driver_name", params.driver_name);
        formData.append("driver_phone_number", params.driver_phone_number);
        formData.append("vechicle_number", params.vechicle_number);
        formData.append("sub_order", params.sub_order);
        formData.append("vehicle", params.vehicle);
        formData.append("add_way_bill", params.add_way_bill);
        return {
          url: "api/orders/order/assign_suborder_vehicle/",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteSuborderVehicle: builder.mutation({
      query: (deleteId) => ({
        url: `api/orders/order/delete_suborder_vehicle/`,
        method: "DELETE",
        body: deleteId,
      }),
    }),
    deleteSuborderFile: builder.mutation({
      query: (deleteId) => ({
        url: `api/orders/order/delete_suborder_file/`,
        method: "DELETE",
        body: deleteId,
      }),
    }),
    getVendorAddress: builder.query({
      query: (params) => {
        return {
          url: `accounts/mob_vendor/get_vendor_address/`,
          params,
        };
      },
    }),
    getOrdersListExcel: builder.query({
      query: (params) => {
        return {
          url: `/api/orders/order/download_order_details/`,
          params,
        };
      },
    }),
  }),
});

export const {
  useLazyGetExistingOrdertTableQuery,
  useLazyGetVendorAddressQuery,
  useLazyGetOrderDetailQuery,
  useLazyGetVehiclesDetailQuery,
  useLazyGetSubOrderDetailQuery,
  useUpdateOrderStatusMutation,
  useUpdateVehiclesDetailMutation,
  useAddOrderPaymentMutation,
  useConvertToOrderAPIMutation,
  useAddVehicleMutation,
  useDeleteSuborderVehicleMutation,
  useDeleteSuborderFileMutation,
  useCancelOrderDetailsMutation,
  useUpdateOrderDetailsAddressMutation,
  useLazyGetOrdersListExcelQuery,
} = manageOrdersAPI;

export default manageOrdersAPI;

export const statusData = [
  {
    title: "Waiting",
    key: 0,
  },
  {
    title: "Ready for Pickup",
    key: 1,
  },
  {
    title: "Out for delivery",
    key: 2,
  },
  {
    title: "Delivered",
    key: 3,
  },
  {
    title: "Cancelled",
    key: 4,
  },
  //   {
  //     title: "Rejected by seller",
  //     key: 5,
  //   },
  //   {
  //     title: "Return request",
  //     key: 6,
  //   },
];
export const proData = [
  {
    title: "PRO Active",
    key: 0,
  },
  {
    title: "PRO Inactive",
    key: 1,
  },
];

export const TimeDropdownData = [
  
  {
    value: "Early morning 6am - 9am",
    label: "Early morning",
    time: "6am - 9am",
  },
  { value: "Morning 9am - 12pm", label: "Morning", time: "9am - 12pm" },
  { value: "Afternoon 12pm - 3pm", label: "Afternoon", time: "12pm - 3pm" },
  { value: "Evening 3pm - 6:30pm", label: "Evening", time: "3pm - 6:30pm" },
  {
    value: "Late evening 6:30pm - 10pm",
    label: "Late evening",
    time: "30pm - 10pm",
  },
  { value: "Night 10pm - 6am", label: "Night", time: "10pm - 6am" },
];
import React, { useState } from "react";
import SearchInput from "../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../components/SelectableDropdown/CustomSelect/CustomSelect";
import { Button, Col } from "antd";
import { saveAs } from "file-saver";

import {
  showErrorToast,
  showSuccessToast,
} from "../../NotificationToast/NotificationToast";
import { baseUrl } from "../../commonUtils/commonUtils";
const options = [
  {
    key: 1,
    value: "Excel",
    label: "Excel",
  },
];
const CustomerHeader = ({
  handleAddCustomer,
  getAllCustomersApi,
  isFetching,
  downloadCustomersApi,
  handleSearchedValue,
}) => {
  const [exportSearch, setExportSearch] = useState("");
  const parentSetterState = (val) => {
    setExportSearch(val);
  };

  const handleChange = async (value) => {
    const response = await downloadCustomersApi(exportSearch);
    if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    } else {
      const responseData = response?.data;
      saveAs(`${baseUrl}${responseData?.data?.url}`);
      showSuccessToast(responseData?.message);
    }
  };
  return (
    <Col
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Col style={{ display: "flex", gap: "1rem" }}>
        <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
          getLibraryApi={getAllCustomersApi}
          width={"400px"}
          isLoading={isFetching}
          parentSetterState={parentSetterState}
          handleSearchedValue={handleSearchedValue}
        />
        <CustomSelect
          placeholder={"Download"}
          width={"100px"}
          height={"46px"}
          options={options}
          handleChange={handleChange}
          value={"EXPORT"}
        />
      </Col>
      <Button
        type="primary"
        className="add_customer_btn"
        onClick={handleAddCustomer}
      >
        Add customer
      </Button>
    </Col>
  );
};

export default CustomerHeader;

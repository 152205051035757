import React from "react";
import { Row } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Bell } from "../../assets/icons/bell.svg";
import { ReactComponent as Help } from "../../assets/icons/help.svg";
import {
  CenterContainer,
  HeaderContainer,
  LeftContainer,
  OpenCloseButton,
  ProfileContainer,
  RightContainer,
  ViewText,
} from "./NavbarStyled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { sellerRoutes } from "../Sidebar/menuItems";
import { useDispatch, useSelector } from "react-redux";
import { sellerViewStatusSelector } from "../../redux/slices/sellerCatalogue/selector";
import { getViewStatus } from "../../redux/slices/sellerCatalogue/action";
import ProfileDropdown from "./ProfileDropDown/ProfileDropdown";
import { MainSearchInput } from "../MainSearchInput/MainSearchInput";

const Navbar = ({ setCollapsed, collapsed }) => {
  const { pathname } = useLocation();
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sellerStatus = useSelector(sellerViewStatusSelector);
  const url_parts = pathname.split("/");
  const new_url = url_parts.slice(0, url_parts.length - 1).join("/");
  const seller_route_check = sellerRoutes.includes(new_url);

  const handleNavigate = () => {
    if (seller_route_check) {
      navigate(`/admin/sellers/seller-view/${sellerStatus?.bmp_id}`);
    }
    dispatch(getViewStatus({}));
  };
  const handleNavigateDashboard = () => {
    const currentPath = window.location.pathname;
    const dashboardPath = "/admin/dashboard";
    if (currentPath === dashboardPath) {
      window.location.reload();
    } else {
      navigate(dashboardPath);
    }
  };

  return (
    <HeaderContainer
      style={{
        backgroundColor: seller_route_check ? "#42919b" : "#0354a3",
      }}
    >
      <Row>
        <LeftContainer span={8}>
          <OpenCloseButton onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </OpenCloseButton>

          <Logo
            style={{ cursor: "pointer" }}
            onClick={handleNavigateDashboard}
          />
          <ViewText
            style={{
              cursor: seller_route_check ? "pointer" : "text",
            }}
            onClick={() => handleNavigate()}
          >
            {seller_route_check
              ? `${sellerStatus?.bmp_id || param?.seller_id} 
                 ${
                   sellerStatus?.vendor_name
                     ? `(${sellerStatus?.vendor_name})`
                     : ""
                 }`
              : "ADMIN VIEW"}
          </ViewText>
        </LeftContainer>
        <CenterContainer span={8}>
          <MainSearchInput />
        </CenterContainer>
        <RightContainer span={8}>
          <Bell />
          <Help />
          <ProfileContainer>
            <ProfileDropdown />
          </ProfileContainer>
        </RightContainer>
      </Row>
    </HeaderContainer>
  );
};

export default Navbar;

import React from "react";
import "./AmountSelectedCard.css";
import { Col, Typography } from "antd";
const { Text } = Typography;
const AmountSelectedCard = ({ getData }) => {
  return (
    <Col className="amountSelectedCard-container">
      <Col style={{ display: "flex", justifyContent: "space-between" }}>
        <Text
          style={{
            fontWeight: 500,
          }}
        >
          AMOUNT SELECTED
        </Text>
        {/* <Text style={{ color: "#2973f0" }}>Edit</Text> */}
      </Col>
      <Text
        style={{ fontSize: "20px", fontWeight: 500 }}
      >{`₹ ${getData?.data?.amount}`}</Text>
      <Text style={{ fontWeight: 500 }}>COMMENTS</Text>
      <Text>{getData?.data?.remarks ? getData?.data?.remarks : "-"}</Text>
    </Col>
  );
};

export default AmountSelectedCard;

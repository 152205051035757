import React, { useEffect, useState } from "react";
import "./Customer.css";
import { Avatar, Col, Dropdown, Menu, Typography } from "antd";
import CustomerHeader from "./CustomerHeader";
import {
  // PlusOutlined,
  DashOutlined,
} from "@ant-design/icons";
import CustomTableWithPagination from "../../components/CustomTableWithPagination/CustomTableWithPagination";
import { ReactComponent as DefaultIcon } from "../../assets/icons/default.svg";
import {
  Link,
  //  useNavigate
} from "react-router-dom";
import AddCustomerModal from "./AddCustomerModal/AddCustomerModal";
import {
  useCreateCustomerMutation,
  useDeleteCustomerMutation,
  useLazyGetCustomersQuery,
  useLazyGetDownloadCustomersQuery,
  useUpdateCustomerMutation,
} from "../../apis/customer";

// import { baseQuery } from "../../apis/baseQuery";
import { baseUrl } from "../../commonUtils/commonUtils";
import { dateFormat } from "../../commonFuntions/CommonUtilFunctions";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import ManageDeleteModal from "../../components/Modal/DeleteModal/ManageDeleteModal/ManageDeleteModal";
import {
  showErrorToast,
  showSuccessToast,
} from "../../NotificationToast/NotificationToast";
import NoDataFound from "../../components/EmptyTable/NoDataFound";
const { Text } = Typography;
const initialFormData = {
  full_name: "",
  phone_number: "",
  gst_number: "",
  address_line_1: "",
  address_line_2: "",
  pincode: "",
  city: "",
  email: "",
  id: "",
  date_of_birth: "",
  state: "",
};

const Customer = () => {
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [searchedValue, setSearchedValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const [
    ,
    // isModalOpen
    setIsModalOpen,
  ] = useState(false);
  const [editData, setEditData] = useState({});
  const [formData, setFormData] = useState(initialFormData);
  const [
    getAllCustomersApi,
    {
      data: tableData,
      //  isLoading,
      isFetching,
    },
  ] = useLazyGetCustomersQuery();
  const [
    downloadCustomersApi,
    // { data }
  ] = useLazyGetDownloadCustomersQuery();
  const [createCustomerApi, { isSuccess: createCustomerSuccess, isLoading }] =
    useCreateCustomerMutation();
  const [updateCustomerApi, { isSuccess: updateCustomerSuccess }] =
    useUpdateCustomerMutation();
  const [deleteCustomerApi, { isSuccess: deleteCustomerSuccess }] =
    useDeleteCustomerMutation();
  const onDelete = async () => {
    const response = await deleteCustomerApi({ user_id: selectedRowData?.id });
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setIsDeleteModalOpen(false);
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const onEdit = (data) => {
    setEditData({
      full_name: data?.full_name,
      phone_number: data?.phone_number,
      gst_number: data?.gst_number,
      address_line_1: data?.address_line_1,
      address_line_2: data?.address_line_2,
      pincode: data?.pincode,
      city: data?.city,
      email: data?.email,
      id: data?.id,
      date_of_birth: data?.date_of_birth,
    });
    setEditCustomerModal(true);
  };
  const handleAddCustomer = () => {
    setAddCustomerModal(true);
    setFormData(initialFormData);
  };

  // const navigate = useNavigate();
  // const handleUserDetailsNavigation = (record) => {
  //   navigate(`/admin/customer/customerDetails/${record?.phone_number}`, {
  //     state: { id: `/admin/customer` },
  //   });
  // };
  const handleDeleteModalOpen = (data) => {
    setSelectedRowData(data);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
  };
  useEffect(() => {
    getAllCustomersApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCustomerSuccess, deleteCustomerSuccess, updateCustomerSuccess]);
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
    };
    setCurrentPage(currPage);
    await getAllCustomersApi(params);
  };

  const columns = [
    {
      // title: (
      //   <PlusOutlined
      //     style={{
      //       backgroundColor: "#fff",
      //       borderRadius: "50%",
      //       padding: "3px",
      //     }}
      //   />
      // ),
      key: "icon",
      dataIndex: "icon",
      fixed: "left",
      width: "50px",
      render: (params, record) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  <>
                    <Menu.Item
                      onClick={() => onEdit(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">Edit</Text>
                      </Col>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => handleDeleteModalOpen(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">Delete</Text>
                      </Col>
                    </Menu.Item>
                  </>
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined
              //   onClick={() => _onRowClick(record)}
              />
            </Dropdown>
          </>
        );
      },
      // height: "60px",
    },
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      fixed: "left",
      width: "300px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record.parentKey ? "60px" : "0px",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={`${baseUrl}${record.profile_picture}`}
                  alt="Avatar"
                  icon={<DefaultIcon width={20} height={20} />}
                />
              </Col>
              <Link
                to={`/admin/customer/customerDetails/${record?.phone_number}`}
                target="_blank"
                // onClick={() => handleUserDetailsNavigation(record)}
                style={{ fontWeight: 500, color: "#2973f0", cursor: "pointer" }}
              >
                {record.full_name}
              </Link>
            </Col>
          </>
        );
      },
    },
    {
      title: "Business mobile",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "150px",
      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleUserDetailsNavigation(record)}
            to={`/admin/customer/customerDetails/${record?.phone_number}`}
            target="_blank"
            style={{ fontWeight: 500, color: "#2973f0", cursor: "pointer" }}
          >
            {params}
          </Link>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "300px",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "300px",
    },
    {
      title: "Wallet Amount",
      dataIndex: "wallet_amount",
      key: "wallet_amount",
      width: "150px",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      width: "300px",
    },
    {
      title: "Registered date",
      dataIndex: "date_joined",
      key: "date_joined",
      width: "200px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{parmas && dateFormat(parmas)}</Text>
          </Col>
        );
      },
    },
    {
      title: "DOB",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      width: "200px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{parmas && dateFormat(parmas)}</Text>
          </Col>
        );
      },
    },
    {
      title: "GSTIN",
      dataIndex: "gst_number",
      key: "gst_number",
      width: "200px",
    },
    {
      title: "Total Orders",
      dataIndex: "total_orders",
      key: "total_orders",
      width: "150px",
    },
  ];
  return (
    <Col className="customer-container">
      <Text className="customer_title">Customers</Text>
      <CustomerHeader
        handleAddCustomer={handleAddCustomer}
        getAllCustomersApi={getAllCustomersApi}
        isFetching={isFetching}
        downloadCustomersApi={downloadCustomersApi}
        handleSearchedValue={handleSearchedValue}
      />
      <CustomTableWithPagination
        className="order-list-table"
        //   tableExpandable={defaultExpandable}
        //   tableDataSource={tableDataSource || []}
        tableDataSource={tableData?.data?.results}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? (
            <TableSkeleton length={10} />
          ) : (
            <NoDataFound text={"No customers found!"} />
          ),
        }}
        isLoading={tableData?.data?.results.length && isFetching}
        scroll={{ x: "100%" }}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={!isFetching && tableData?.data?.pagination?.total_entries}
      />
      <AddCustomerModal
        addCustomerModal={addCustomerModal}
        setIsModalOpen={setIsModalOpen}
        setAddCustomerModal={setAddCustomerModal}
        title={"Add new customer"}
        initialFormData={initialFormData}
        formData={formData}
        setFormData={setFormData}
        addressListModal={showModal}
        createCustomerApi={createCustomerApi}
        isLoading={isLoading}
      />
      <AddCustomerModal
        addCustomerModal={editCustomerModal}
        setIsModalOpen={setIsModalOpen}
        setAddCustomerModal={setEditCustomerModal}
        editData={editData}
        initialFormData={initialFormData}
        title={"Edit customer"}
        formData={formData}
        setFormData={setFormData}
        addressListModal={showModal}
        isLoading={isLoading}
        createCustomerApi={updateCustomerApi}
      />
      <ManageDeleteModal
        handleOk={handleDeleteModalCancel}
        open={isDeleteModalOpen}
        handleSave={onDelete}
        data={selectedRowData?.full_name}
      />
    </Col>
  );
};

export default Customer;

import React, { useEffect, useState } from "react";
import "./AddNewSeller.css";
import { Col, Form, Tabs, Typography } from "antd";
import SellerDetails from "./SellerDetails/SellerDetails";
import { PickupDetails } from "./PickupDetails/PickupDetails";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateSellerVenderMutation,
  useLazyGetSellerAddVendorDetailsQuery,
  useLazyGetSellerEditVendorDetailsQuery,
  useUpdateSellerVenderMutation,
} from "../../../../apis/sellerCatalogue";
import { getEditSellerVenderDetail } from "../../../../redux/slices/sellerCatalogue/action";
import { useDispatch, useSelector } from "react-redux";
import { editSellerVenderDetailsSelector } from "../../../../redux/slices/sellerCatalogue/selector";
// import ActionContainer from "../../../ManageMobLibrary/EditMobLib/ActionContainer/ActionContainer";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../NotificationToast/NotificationToast";

// const editProductData = {};
export const AddNewSeller = () => {
  const { Text } = Typography;
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editSellerData = useSelector(editSellerVenderDetailsSelector);
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [
    getEditVenderDetailsApi,
    { data: editVenderDetails, isFetching: isFetchingEditVenderDetails },
  ] = useLazyGetSellerEditVendorDetailsQuery();
  const [getAddVenderDetailsApi, { data: addVenderDetails }] =

    useLazyGetSellerAddVendorDetailsQuery();

  const [createSellerVenderApi, { isLoading: isCreateLoading }] =
    useCreateSellerVenderMutation();
  const [updateSellerVenderApi, { isLoading: isUpdateLoading }] =
    useUpdateSellerVenderMutation();

  useEffect(() => {
    if (params?.seller_id) {
      getEditVenderDetailsApi({ bmp_id: params?.seller_id });
    } else {
      // getAddVenderDetailsApi();
    }
    getAddVenderDetailsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (editVenderDetails?.status) {
      dispatch(getEditSellerVenderDetail(editVenderDetails?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editVenderDetails]);
  // const onChange = (key) => {
  // };
  const handleEditFormData = async (val) => {
    const data = {
      vendor_name: val?.vendor_name || "",
      bmp_id: params?.seller_id
        ? editVenderDetails?.data?.id
        : val?.bmp_id || "",
      email: val?.email || "",
      phone_number: val?.phone_number || "",
      address: val?.mail_address || "",
      location: val?.location || "",
      state: val?.state || "",
      city: val?.city || "",
      pincode: val?.pincode || "",
      person_full_name: val?.person_full_name || "",
      shop_opening_time: val?.shop_opening_time || "",
      shop_closing_time: val?.shop_closing_time || "",
      website: val?.website || "",
      shop_act_registration_number: val?.shop_act_registration_number || "",
      gst_number: val?.gst_number || "",
      adhar_card_number: val?.adhar_card_number || "",
      pan_card_number: val?.pan_card_number || "",
      remarks: val?.remarks || "",
      seller_group: val?.seller_group || "",
      google_map_link: val?.google_map_link || "",
      types_of_material: val?.type_of_materials || [],
      seller_display_image: formData?.seller_display_image || "",
      bank_details: {
        bank_name: val?.bank_name || "",
        bank_branch: val?.bank_branch || "",
        name_of_account_holder: val?.account_holder || "",
        bank_account_number: val?.bank_account_number || "",
        bank_ifsc: val?.bank_ifsc || "",
        bank_account_type: val?.bank_account_type || "",
      },
    };
    let response;
    if (params?.seller_id) {
      response = await updateSellerVenderApi(data);
    } else {
      response = await createSellerVenderApi(data);
    }

    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setTimeout(() => {
        navigate("/admin/sellers");
      }, 800);
      dispatch(getEditSellerVenderDetail({}));
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };

  const handleChildFieldChange = (inputValues) => {
    setFormData({ ...formData, ...inputValues });
    form.setFieldsValue(inputValues);
  };

  const handleCancel = () => {
    navigate("/admin/sellers");
    dispatch(getEditSellerVenderDetail({}));
  };
  return (
    <>
      <Col className="wrapper">
        <Form form={form} onFinish={handleEditFormData}>
          <Col className="headerWrapper">
            <Col>
              <Text className="heading">Manage Seller</Text>
            </Col>
            {/* <ActionContainer position={"top"} handleCancel={handleCancel} /> */}
          </Col>
          <Col className="contentWrapper">
            <Col className="leftContent left-seller-basic-details">
              <Tabs
                className="tab-container"
                defaultActiveKey="1"
                // onChange={onChange}
              >
                <TabPane
                  className="tab-pane"
                  tab="Seller Basic details"
                  key="1"
                >
                  <SellerDetails
                    handleChildFieldChange={handleChildFieldChange}
                    data={editSellerData}
                    handleCancel={handleCancel}
                    seller_id={params?.seller_id}
                    checkBoxList={addVenderDetails?.data}
                    isFetching={isFetchingEditVenderDetails}
                    loadingButton={isCreateLoading || isUpdateLoading}
                  />
                </TabPane>
                {params?.seller_id && (
                  <TabPane className="tab-pane" tab="Pickup Details" key="2">
                    <PickupDetails
                      // handleChildFieldChange={handleChildFieldChange}
                      // data={editProductData}
                      seller_id={params?.seller_id}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Col>
        </Form>
      </Col>
    </>
  );
};

import React from "react";
import { Button, Col, Form, Input, Modal } from "antd";
const ManageStateModal = ({ isModalOpen, setIsModalOpen }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    // setIsModalOpen(false);
    form.submit()
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields()
  };
  


  return (
    <>
      <Modal
        title="Add new state"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Col
            key="footer"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "28px",
            }}
          >
            <Button
              key="cancel"
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                width: "120px",
              }}
            >
              CANCEL
            </Button>
            <Button
              key="save"
              type="primary"
              onClick={handleOk}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                width: "200px",
                backgroundColor: "#0354a3",
              }}
            >
              ADD STATE
            </Button>
          </Col>,
        ]}
      ><Col style={{ padding: "0.5rem 2rem ",minHeight:"400px" }}>
       <Form
            form={form}
            name="passwordChange"
            autoComplete="off"
            style={{
              // maxWidth: 500,
            }}
            layout="vertical"
            >
            <Form.Item
              label={
                <Col className="change-password-text">State code</Col>
              }
              name="stateCode"
              rules={[
                {
                  required: true,
                  message: "Please enter your state code",
                },
              ]}
              >
              <Input type="text" className="change-password-input" />
            </Form.Item>

            <Form.Item
              label={<Col className="change-password-text">State name</Col>}
              name="stateName"
              rules={[
                {
                  required: true,
                  message: "Please enter your state name",
                },
                
              ]}
              >
              <Input type="text" className="change-password-input" />
            </Form.Item>

           
          
          </Form>
              </Col>
      </Modal>
    </>
  );
};
export default ManageStateModal;

import React from "react";
import { Upload, message, Typography, Col, List } from "antd";
import { ReactComponent as FileIcon } from "../../../../../../assets/icons/file (2) (1).svg";

const { Text } = Typography;

const PdfDragger = ({
  setAddVehicleFormData,
  addVehicleFormData,
  pdfList,
  setPdfList,
}) => {
  const checkPDFFile = (file) => {
    setAddVehicleFormData({
      ...addVehicleFormData,
      add_way_bill: file,
    });
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      message.error("You can only upload PDF files!");
      return;
    }
    setPdfList([file]);
    return isPDF;
  };

  return (
    <Col>
      <Upload.Dragger
        accept=".pdf"
        beforeUpload={checkPDFFile}
        showUploadList={false}
        multiple={false}
        customRequest={({ onSuccess, file }) => {
          setTimeout(() => {
            onSuccess("ok");
          }, 0);
        }}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <FileIcon />
          <Col style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Text style={{ fontSize: "16px", fontWeight: 500 }}>
              Add waybill <Text style={{ color: "#2973f0" }}>browse</Text>
            </Text>
            <Text style={{ color: "#0a243f", opacity: 0.6 }}>
              only pdf upto 10 mb
            </Text>
          </Col>
        </Col>
      </Upload.Dragger>

      {pdfList.length > 0 && (
        <List
          dataSource={pdfList}
          renderItem={(item) => (
            <List.Item>
              <a
                href={URL.createObjectURL(item)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.name}
              </a>
            </List.Item>
          )}
        />
      )}
    </Col>
  );
};

export default PdfDragger;

import {
  // useEffect,
  useState,
} from "react";
import {Row, Col, Menu, Dropdown, Divider, Typography} from "antd";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import "./StatusDropDown.css";
import {statusColorMapping} from "../../../../commonFuntions/CommonUtilFunctions";
// import {setSelectedStatus} from "../../../../redux/slices/componentState/action";
// import {
//   useDispatch,
//   //  useSelector
// } from "react-redux";
// import {selectedStatusSelector} from "../../../../redux/slices/componentState/selector";
const {Text} = Typography;

const StatusDropDown = ({
  data,
  status,
  handleAPI = () => {},
  index,
  dropdownCheck,
}) => {
  // const dispatch = useDispatch();
  const [showVisible, setShowVisible] = useState(false);
  // const selectedStatus = useSelector(selectedStatusSelector);
  // const [selectedItem, setSelectedItem] = useState("");
  // useEffect(() => {
  //   if (status) {
  //     dispatch(setSelectedStatus(status));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [status]);
  const handleMenuClick = (e) => {
    // setSelectedItem(data[e.key].title);
    // dispatch(setSelectedStatus(data[e.key].title));
    setShowVisible(false);
    handleAPI(data[e.key].title, index);
  };

  const menu = (
    <Menu onClick={handleMenuClick} style={{}}>
      {data.map((item, index) => (
        <>
          <Menu.Item key={item.key}>
            <Row className="menu-container">
              <Col style={{display: "flex", flexDirection: "column"}}>
                <Text
                  className={`${statusColorMapping[item?.title]} menu-text`}>
                  {item.title}
                </Text>
              </Col>
            </Row>
            {index !== data.length - 1 && <Divider style={{margin: 0}} />}
          </Menu.Item>
        </>
      ))}
    </Menu>
  );
  const handleDropdownVisibleChange = (visible) => {
    setShowVisible(visible);
  };
  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomLeft"
      overlay={menu}
      open={dropdownCheck ? showVisible : false}
      onOpenChange={handleDropdownVisibleChange}>
      <Col
        className="drop-down-container"
        style={{height: dropdownCheck ? "36px" : "auto"}}>
        <Text
          className={`${statusColorMapping[status]} ${
            dropdownCheck ? "label-text-pro" : "label-text"
          }`}>
          {status}
        </Text>
        {dropdownCheck && (
          <Text
            className="arrow-container"
            style={{
              width: dropdownCheck ? "40px" : "45px",
              padding: dropdownCheck ? "8px 5px" : "9px",
            }}>
            {showVisible ? (
              <UpOutlined style={{paddingLeft: "6px"}} />
            ) : (
              <DownOutlined style={{paddingLeft: "6px"}} />
            )}
          </Text>
        )}
      </Col>
    </Dropdown>
  );
};

export default StatusDropDown;

import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  message,
  // Upload,
  List,
} from "antd";
import {
  ArrowLeftOutlined,
  //  UploadOutlined
} from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import fileUpload from "../../assets/icons/file-upload.svg";
import stats from "../../assets/icons/stats.svg";
import { useNavigate } from "react-router-dom";
import MobLibraryCard from "./MobLibraryCard";
import { ReactComponent as RemoveIcon } from "../../assets/icons/RemoveImageIcon.svg";
import "./uploadSUK.css";
import { useAddNewFileMutation } from "../../apis/manageLibrary";
import FileDragger from "../../components/Draggers/FileDragger";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../NotificationToast/NotificationToast";
import {
  bytesToSize,
  handlePublicFolderDownload,
} from "../../commonFuntions/CommonUtilFunctions";

const UpdateSKU = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [
    updateFileApi,
    {
      data: fileObjData,
      // error,
      //  isSuccess,
      isLoading,
    },
  ] = useAddNewFileMutation();
  const { Text } = Typography;

  const updateFileText = [
    "Upload the edited details in product template",
    "Items will be updated in library",
    "Update status will be visible on this page",
    "Upload the images for the new items added from MOB library page",
  ];

  // const props = {
  //   name: "file",
  //   multiple: true,
  //   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  //   onChange(info) {
  //     const { status } = info.file;
  //     if (status !== "uploading") {
  //     }
  //     if (status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   onDrop(e) {
  //   },
  // };
  const handleBack = () => {
    navigate("/admin/products");
  };

  const [fileList, setFileList] = useState([]);
  const [
    ,
    // uploadedFileData
    setUploadedFileData,
  ] = useState({});

  const uploadedFile = (fileObj) => {
    // const messageMapping = {
    //   done: { type: "success", content: "File uploaded successfully!" },
    //   uploading: { type: "info", content: "Uploading the file!" },
    //   error: { type: "error", content: "Unable to upload the file!" },
    // };

    // messageApi.open({
    //   type: messageMapping[fileObj.file.status].type,
    //   content: messageMapping[fileObj.file.status].content,
    // });
    // if (fileObj.file.status === "done") {
    // File is successfully uploaded
    const newFileList = [fileObj.file];
    setFileList(newFileList);
    // }
  };
  // const handlebeforeUpload = () => {
  //   if (fileList.length >= 1) {
  //     // Reject the file if the maximum file count is exceeded
  //     messageApi.open({
  //       type: "error",
  //       content: "Maximum file count of 1 reached.",
  //     });
  //     return false;
  //   }
  //   return false;
  // };

  const customFileListRender = (fileList) => {
    return (
      <List
        dataSource={fileList}
        renderItem={(item) => (
          <Col
            style={{
              height: "50px",
              backgroundColor: "#f2f2f2",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 16px",
              borderRadius: "8px",
            }}
          >
            <Text
              style={{ color: "#0a243f", fontSize: "14px", fontWeight: 500 }}
            >
              {item.name}
            </Text>
            <Col>
              <Text
                style={{
                  color: "#0a243f",
                  fontSize: "14px",
                  fontWeight: 400,
                  opacity: 0.6,
                  marginRight: "36px",
                }}
              >
                {bytesToSize(item.size)}
              </Text>

              <RemoveIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleRemoveFile(item)}
              />
            </Col>
          </Col>
        )}
      />
    );
  };

  const handleRemoveFile = (file) => {
    // Remove the file from the fileList
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    messageApi.open({
      type: "success",
      content: "File removed successfully",
    });
    setFileList(newFileList);
  };

  const handleUpdateFile = async () => {
    showInfoToast("Updating the products!");

    const params = { file: fileList[0], is_update: true };

    const fileDataResponse = await updateFileApi(params);
    if (fileDataResponse?.data?.status === true) {
      setUploadedFileData(fileDataResponse?.data?.data?.errors);
      showSuccessToast(fileDataResponse?.data?.message);
      setFileList([]);
    } else {
      showErrorToast(
        fileDataResponse?.data?.message || fileDataResponse?.error?.error
      );
      setUploadedFileData({});
    }
  };

  const handleGoManageLibrary = () => {
    navigate("/admin/products", { state: { modal: true } });
  };

  return (
    <Col style={{ padding: "32px" }}>
      {contextHolder}
      <Row>
        <Col>
          <Button
            style={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "14px",
              borderRadius: " 8px",
              border: "solid 1.5px #dedede",
            }}
            size="large"
            onClick={handleBack}
          >
            <ArrowLeftOutlined />
            BACK TO MOB LIBRARY
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "32px",
          marginBottom: "30px",
        }}
      >
        <Col
          style={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "24px",
            }}
          >
            Update SKU
          </Text>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: 500,
              fontFamily: "Inter",
              color: "#2973f0",
              cursor: "pointer",
            }}
            onClick={handleGoManageLibrary}
          >
            CHANGE
          </Text>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          // gap: "50px",
        }}
      >
        <FileDragger
          uploadedFile={uploadedFile}
          fileUpload={fileUpload}
          fileList={fileList}
          customFileListRender={customFileListRender}
          handleFile={handleUpdateFile}
          isLoading={isLoading}
          type="UPDATE"
        />
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            width: "35%",
          }}
        >
          <Col
            className="dont-have-template"
            style={{
              backgroundColor: "#f8e6b6",
              height: "120px",
              width: "100%",
              borderRadius: "8px",
              padding: "24px",
            }}
          >
            <Col
              style={{
                marginBottom: "12px",
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Dont have a template?
              </Text>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "70px",
              }}
            >
              <Button
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
                onClick={() =>
                  handlePublicFolderDownload("/Listing-Sheet-Example.xlsx")
                }
              >
                <DownloadOutlined />
                Get template
              </Button>
              <img
                src={stats}
                alt="stats"
                style={{
                  position: "relative",
                  top: -11,
                  left: "24px",
                }}
              />
            </Col>
          </Col>

          <Col
            style={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              // height: "224px",
              width: "100%",
            }}
          >
            <Col
              style={{
                padding: "24px",
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#0a243f",
                }}
              >
                How does it work?
              </Text>
              <List
                style={{
                  fontSize: "14px",
                  paddingLeft: "13px",
                  lineHeight: "1.71",
                  fontWeight: "700",
                }}
                dataSource={updateFileText}
                renderItem={(item) => (
                  <List.Item
                    className="custom-list-item"
                    style={{
                      border: "none",
                      padding: 0,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Text style={{ fontWeight: "500" }}>{item}</Text>
                  </List.Item>
                )}
              />
            </Col>
          </Col>
        </Col>
        {fileObjData?.status && (
          <MobLibraryCard
            productData={fileObjData?.data?.errors?.errorList}
            productRecords={fileObjData?.data?.errors}
            showCards={true}
            updateSKU
          />
        )}
      </Row>
    </Col>
  );
};

export default UpdateSKU;

import React, { useEffect, useState } from "react";
import { Col, Typography } from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
// import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {
  //   useLazyGetDownloadServiceRequestReportsQuery,
  useLazyGetSellerSubscriptionDataQuery,
} from "../../../apis/reports";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
// import {LoadingOutlined} from "@ant-design/icons";
// import {saveAs} from "file-saver";
import {
//   colorMapping,
  dateFormat,
} from "../../../commonFuntions/CommonUtilFunctions";
import { Link } from "react-router-dom";

const { Text } = Typography;
// const options = [
//   {
//     key: 1,
//     value: "Export",
//     label: "Export",
//   },
// ];
const MobCreditSubscriptionReport = () => {
  const [searchedValue, setSearchedValue] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  const [
    getCustomerOrderReportApi,
    { data: customerOrderReportData, isFetching },
  ] = useLazyGetSellerSubscriptionDataQuery();
  //   const [getDownloadCustomerDetailsReportapi, {isFetching: downloadReport}] =
  //     useLazyGetDownloadServiceRequestReportsQuery();
  useEffect(() => {
    getCustomerOrderReportApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "mob_vendor",
      key: "mob_vendor",
      width: "230px",
      fixed: "left",
      render: (params, record) => {
        return <Text>{params?.vendor_name}</Text>;
      },
    },
    {
      title: "BMP ID",
      dataIndex: "mob_vendor",
      key: "mob_vendor",
      width: "150px",
      render: (params, record) => {
        return (
          <Link
            to={`/admin/sellers/add-seller/${params?.bmp_id}`}
            target="_blank"
            style={{ fontWeight: 400, color: "#2973f0", cursor: "pointer" }}
          >
            {params?.bmp_id || "N/A"}
          </Link>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: "200px",
      render: (params, record) => {
        return (
          <Col style={{ fontWeight: 400 }}>
            <Text>{params ? dateFormat(params) : "N/A"}</Text>
          </Col>
        );
      },
    },
    {
      title: "Business GSTIN certificate",
      dataIndex: "gstin_certificate",
      key: "gstin_certificate",
      width: "200px",
      align: "center",
      render: (params, record) => {
        return (
          <a
            style={{ padding: "8px" }}
            className=""
            href={params}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>
        );
      },
    },

    {
      title: "Business PAN card",
      dataIndex: "business_pan_card",
      key: "business_pan_card",
      width: "200px",
      align: "center",
      render: (params, record) => {
        return (
          <a
            style={{ padding: "8px" }}
            className=""
            href={params}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>
        );
      },
    },
    {
      title: "Owner/ Partner/ Director’s Aadhar",
      dataIndex: "",
      key: "Aadhar",
      width: "200px",
      align: "center",
      render: (parmas, record) => {
        return (
          <>
            {record?.adhar_card && (
              <a
                style={{ padding: "8px" }}
                className=""
                href={record?.adhar_card}
                target="_blank"
                rel="noreferrer"
              >
                View Upload 1
              </a>
            )}
            {record?.adhar_card_2 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.adhar_card_2}
                  target="_blank"
                  rel="noreferrer"
                >
                 View Upload 2
                </a>
              </>
            )}
            {record?.adhar_card_3 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.adhar_card_3}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 3
                </a>
              </>
            )}
            {record?.adhar_card_4 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.adhar_card_4}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 4
                </a>
              </>
            )}
            {record?.adhar_card_5 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.adhar_card_5}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 5
                </a>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Owner/ Partner/ Director’s PAN card",
      dataIndex: "",
      key: "Partner",
      width: "200px",
      align: "center",
      render: (parmas, record) => {
        return (
          <>
            {record?.owner_pan_card && (
              <a
                style={{ padding: "8px" }}
                className=""
                href={record?.owner_pan_card}
                target="_blank"
                rel="noreferrer"
              >
                View Upload 1
              </a>
            )}
            {record?.owner_pan_card_2 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.owner_pan_card_2}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 2
                </a>
              </>
            )}
            {record?.owner_pan_card_3 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.owner_pan_card_3}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 3
                </a>
              </>
            )}
            {record?.owner_pan_card_4 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.owner_pan_card_4}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 4
                </a>
              </>
            )}
            {record?.owner_pan_card_5 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.owner_pan_card_5}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 5
                </a>
              </>
            )}
          </>
        );
      },
    },
  ];

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
    };
    setCurrentPage(currPage);
    await getCustomerOrderReportApi(params);
  };

  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  //   const handleExportApi = async () => {
  //     console.log("dgdgh");
  //     let response = await getDownloadCustomerDetailsReportapi({
  //       ...(searchedValue.search && {search: searchedValue.search}),
  //       //   download: true,
  //     });
  //     if (response?.status === "fulfilled") {
  //       saveAs(response?.data?.data?.url);
  //     }
  //   };

  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">mobCREDIT subscription</Text>
      <Col style={{ display: "flex", gap: "1rem" }}>
        <SearchInput
          size={"large"}
          placeholder={"Search by seller name, mobile no, email"}
          width={"400px"}
          getLibraryApi={getCustomerOrderReportApi}
          handleSearchedValue={handleSearchedValue}
          isLoading={isFetching}
        />

        {/* <CustomSelect
          placeholder={"Export"}
          width={"150px"}
          options={options}
          handleChange={handleExportApi}
          value={downloadReport ? <LoadingOutlined /> : "EXPORT"}
          disabled={downloadReport ? true : false}
          loading={downloadReport}
        /> */}
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        tableDataSource={customerOrderReportData?.data?.results}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        }}
        isLoading={customerOrderReportData?.data?.results.length && isFetching}
        scroll={{ x: "100%" }}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={
          !isFetching &&
          customerOrderReportData?.data?.pagination?.total_entries
        }
      />
    </Col>
  );
};

export default MobCreditSubscriptionReport;

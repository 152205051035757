import React, { useEffect, useState } from "react";
import {
  //  Avatar, Dropdown, Menu,
  Col,
  Tag,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {
  useLazyGetDownloadOrderReportsQuery,
  useLazyGetOrderReportsQuery,
} from "../../../apis/reports";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";

import dayjs from "dayjs";
import { saveAs } from "file-saver";
import {
  // colorMapping,
  statusColorMapping,
} from "../../../commonFuntions/CommonUtilFunctions";
import { Link } from "react-router-dom";
// import { dateFormat } from "../../../commonFuntions/CommonUtilFunctions";
const { Text } = Typography;
const options = [
  {
    key: 1,
    value: "Export",
    label: "Export",
  },
];

const dateOptions = [
  {
    key: 1,
    value: "Today",
    label: "Today",
  },
  {
    key: 2,
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    key: 3,
    value: "last_7_day",
    label: "Last 7 day",
  },
  {
    key: 4,
    value: "last_30_day",
    label: "Last 30 day",
  },
  {
    key: 5,
    value: "last_60_day",
    label: "Last 60 day",
  },
  {
    key: 6,
    value: "Custom",
    label: "Custom",
  },
];

const OrderReport = () => {
  const [dateFilter, setDateFilter] = useState();
  const [searchedValue, setSearchedValue] = useState({});
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [currentPage, setCurrentPage] = useState(1);

  const [getOrderReportApi, { data: orderReportData, isFetching }] =
    useLazyGetOrderReportsQuery();

  const [getDownloadOrderReportsApi, { isFetching: FetchingReports }] =
    useLazyGetDownloadOrderReportsQuery();
  useEffect(() => {
    getOrderReportApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        getOrderReportApi({
          ...(searchedValue?.search && { search: searchedValue?.search }),
          // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
          // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        getOrderReportApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(searchedValue?.search && { search: searchedValue?.search }),
          // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
          // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }
      setCurrentPage(1);
    }
    setDateFilter(value);
  };
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      getOrderReportApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
        // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };

  const handleExportApi = async () => {
    console.log("dgdgh");
    let response = await getDownloadOrderReportsApi({
      ...(searchedValue.search && { search: searchedValue.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      download: true,
    });
    if (response?.status === "fulfilled") {
      // handlePdfDownload(response?.data?.data?.url);
      saveAs(response?.data?.data?.url);
    }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: "250px",
      fixed: "left",
      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleDetailNav(record)}
            to={`/admin/orders/order-details/${record?.order?.id}`}
            target="_blank"
            className="dashboard-table-orderid"
          >
            {params}
          </Link>
        );
      },
    },
    {
      title: "Sub Order ID",
      dataIndex: "suborder_id",
      key: "suborder_id",
      width: "250px",
      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleDetailNav(record)}
            to={`/admin/orders/view-details/${record?.suborder?.id}`}
            target="_blank"
            className="dashboard-table-orderid"
          >
            {params}
          </Link>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: "250px",
      // render: (params, record) => {
      //   return <Col>{params ? dateFormat(params) : "N/A"}</Col>;
      // },
    },
    {
      title: "Customer Mobile",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "200px",
    },
    {
      title: "Customer Email Id",
      dataIndex: "email",
      key: "email",
      width: "300px",
    },
    {
      title: "Mode Of Customer",
      dataIndex: "mode_of_customer",
      key: "mode_of_customer",
      width: "200px",
    },
    {
      title: "Customer Address",
      dataIndex: "customer_address",
      key: "customer_address",
      width: "300px",
    },
    {
      title: "Customer City",
      dataIndex: "customer_city",
      key: "customer_city",
      width: "150px",
    },
    {
      title: "Customer Pincode",
      dataIndex: "customer_pincode",
      key: "customer_pincode",
      width: "250px",
    },
    {
      title: "BMP Id",
      dataIndex: "bmp_id",
      key: "bmp_id",
      width: "150px",
    },
    {
      title: "Seller Name",
      dataIndex: "seller_name",
      key: "seller_name",
      width: "150px",
    },
    {
      title: "Seller Address",
      dataIndex: "seller_address",
      key: "seller_address",
      width: "350px",
    },
    {
      title: "Seller City",
      dataIndex: "seller_city",
      key: "seller_city",
      width: "150px",
    },
    {
      title: "Seller Pincode",
      dataIndex: "seller_pincode",
      key: "seller_pincode",
      width: "150px",
    },

    {
      title: "Order Quantity",
      dataIndex: "order_quantity",
      key: "order_quantity",
      width: "150px",
    },
    {
      title: "Order Amount",
      dataIndex: "order_amount",
      key: "order_amount",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      key: "order_status",
      width: "150px",

      render: (params, record) => {
        return (
          <Tag
            style={{ padding: "8px" }}
            className={`tag-font-style ${statusColorMapping[params]}`}
          >
            {params}
          </Tag>
        );
      },
    },
    {
      title: "Discount Amount",
      dataIndex: "discount_amount",
      key: "discount_amount",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "Net Amount",
      dataIndex: "net_amount",
      key: "net_amount",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "Coupon Applied",
      dataIndex: "is_coupon_applied",
      key: "is_coupon_applied",
      width: "250px",
    },
    {
      title: "Coupon Code",
      dataIndex: "coupon_code",
      key: "coupon_code",
      width: "150px",
    },
    {
      title: "Coupon Amount",
      dataIndex: "coupon_amount",
      key: "coupon_amount",
      width: "150px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      width: "250px",
    },
    {
      title: "Payment Received (Based on order no)",
      dataIndex: "payment_received",
      key: "payment_received",
      width: "200px",
    },
    {
      title: "Delivery Note",
      dataIndex: "delivery_notes",
      key: "delivery_notes",
      width: "250px",
    },
    {
      title: "RFQ RefNo",
      dataIndex: "rfqNo",
      key: "rfqNo",
      width: "200px",
      render: (params, record) => {
        return (
          <Link
            // onClick={() => handleDetailNav(record)}
            to={`/admin/rfq/quotes/${record?.rfq_details?.rfq_id}`}
            target="_blank"
            className="dashboard-table-orderid"
          >
            {record?.rfq_details?.rfq_id}
          </Link>
        );
      },
    },

    {
      title: "Delivered Date",
      dataIndex: "order_date",
      key: "order_date",
      width: "250px",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      width: "200px",
    },

    {
      title: "Customer GSTIN",
      dataIndex: "gstIn",
      key: "gstIn",
      width: "200px",
    },

    {
      title: "Invoice No",
      dataIndex: "invoice_no",
      key: "invoice_no",
      width: "300px",
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      width: "250px",
    },

    {
      title: "Delivery charges",
      dataIndex: "delivery_charges",
      key: "delivery_charges",
      width: "150px",
    },

    {
      title: "Payment Mode",
      dataIndex: "payment_mode",
      key: "payment_mode",
      width: "250px",
    },
    {
      title: "Payment Ref No",
      dataIndex: "payment_ref_no",
      key: "payment_ref_no",
      width: "250px",
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      width: "250px",
    },

    {
      title: "Refund Amount",
      dataIndex: "payment_date",
      key: "payment_date",
      width: "250px",
      render: (params, record) => {
        return <Col>{`₹ ${parseFloat(params).toFixed(2)}`}</Col>;
      },
    },

    // {
    //   title: "Pickup State",
    //   dataIndex: "pickup_state",
    //   key: "pickup_state",
    //   width: "150px",
    // },

    // {
    //   title: "Payment Amount",
    //   dataIndex: "payment_amount",
    //   key: "payment_amount",
    //   width: "250px",
    //   render: (params, record) => {
    //     return <Col>{₹ ${parseFloat(params).toFixed(2)}}</Col>;
    //   },
    // },

    // {
    //   title: "Delivery Slot",
    //   dataIndex: "delivery_slot",
    //   key: "delivery_slot",
    //   width: "250px",
    // },
  ];

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    setCurrentPage(currPage);
    await getOrderReportApi(params);
  };

  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };

  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">Order Report</Text>
      <Col style={{ display: "flex", gap: "1rem" }}>
        <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
          width={"400px"}
          getLibraryApi={getOrderReportApi}
          handleSearchedValue={handleSearchedValue}
          isLoading={isFetching}
        />
        <Col className="action-sub-container">
          <CustomSelect
            placeholder={"Date"}
            width={"150px"}
            options={dateOptions}
            handleChange={handleChange}
            value={dateFilter}
          />
          {dateFilter === "Custom" && (
            <DateRangePicker
              onChange={handleChangeRangePicker}
              format={"DD-MMM-YYYY"}
            />
          )}
        </Col>
        <CustomSelect
          placeholder={"Export"}
          width={"150px"}
          options={options}
          handleChange={handleExportApi}
          value={FetchingReports ? <LoadingOutlined /> : "EXPORT"}
          disabled={FetchingReports ? true : false}
          loading={FetchingReports}
        />
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        //   tableExpandable={defaultExpandable}
        // tableDataSource={tableDataSource || []}
        tableDataSource={orderReportData?.data?.results}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        }}
        isLoading={orderReportData?.data?.results?.length && isFetching}
        scroll={{ x: "100%" }}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={
          !isFetching && orderReportData?.data?.pagination?.total_entries
        }
      />
    </Col>
  );
};

export default OrderReport;

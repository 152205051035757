import React, { useState } from "react";
import { Layout } from "antd";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Header/Navbar";
import { sellerRoutes } from "../components/Sidebar/menuItems";
import { useLocation } from "react-router-dom";
import SellerBanner from "../components/Header/SellerBanner";
const { Content } = Layout;

const MainLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  let url_parts = pathname.split("/");
  let new_url = url_parts.slice(0, url_parts.length - 1).join("/");
  return (
    <Layout style={{ background: "#fff" }}>
      {/* Header */}
      <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />
      {sellerRoutes.includes(new_url) && <SellerBanner />}
      <Layout style={{ background: "#fff" }}>
        {/* Sidebar */}
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />

        {/* Content */}
        <Layout>
          <Content
            style={{
              background: "#fff",
              // padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;

import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "./baseQuery";

export const reportsAPI = createApi({
  reducerPath: "reportsAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getCancelledOrderReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/get_cancelled_order_reports/`,
          params,
        };
      },
    }),

    getSellerSalesReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/get_seller_sales_reports/`,
          params,
        };
      },
    }),
    getOrderReports: builder.query({
      query: (params) => {
        return {
          url: `/api/reports/get_order_reports/`,
          params,
        };
      },
    }),
    getCategoryWiseReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/get_category_wise_reports/`,
          params,
        };
      },
    }),
    getCustomerDetails: builder.query({
      query: (params) => {
        return {
          url: `api/reports/get_customer_details/`,
          params,
        };
      },
    }),
    getServiceRequestData: builder.query({
      query: (params) => {
        return {
          url: `api/reports/get_service_report/`,
          params,
        };
      },
    }),
    getInvoiceReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/get_invoice_report/`,
          params,
        };
      },
    }),
    getItemWiseSalesReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/get_item_wise_sales_reports/`,
          params,
        };
      },
    }),
    getDownloadCancelledOrderReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/download_cancelled_order_reports/`,
          params,
        };
      },
    }),
    getDownloadSellerSalesReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/download_seller_sales_reports/`,
          params,
        };
      },
    }),
    getDownloadServiceRequestReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/download_service_report/`,
          params,
        };
      },
    }),
    getDownloadOrderReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/download_order_reports/`,
          params,
        };
      },
    }),
    getDownloadCustomerDetailsReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/download_customer_details/`,
          params,
        };
      },
    }),
    getDownloadMobPROReports: builder.query({
      query: (params) => {
        return {
          url: `/accounts/mob_customer/download_professional_customers/`,
          params,
        };
      },
    }),
    getDownloadInvoiceReports: builder.query({
      query: (params) => {
        return {
          url: `api/reports/download_invoice_report/`,
          params,
        };
      },
    }),
    getMobCreditReports: builder.query({
      query: (params) => {
        return {
          url: `/api/reports/get_mob_credit_reports/`,
          params,
        };
      },
    }),
    getMobPROReports: builder.query({
      query: (params) => {
        return {
          url: `/accounts/mob_customer/get_professional_customers/`,
          params,
        };
      },
    }),
    getSellerSubscriptionData: builder.query({
      query: (params) => {
        return {
          url: `/api/reports/get_seller_documents/`,
          params,
        };
      },
    }),
  }),
});

export const {
  useLazyGetCancelledOrderReportsQuery,
  useLazyGetSellerSalesReportsQuery,
  useLazyGetOrderReportsQuery,
  useLazyGetCategoryWiseReportsQuery,
  useLazyGetCustomerDetailsQuery,
  useLazyGetInvoiceReportsQuery,
  useLazyGetItemWiseSalesReportsQuery,
  useLazyGetDownloadCancelledOrderReportsQuery,
  useLazyGetDownloadSellerSalesReportsQuery,
  useLazyGetDownloadOrderReportsQuery,
  useLazyGetDownloadCustomerDetailsReportsQuery,
  useLazyGetDownloadInvoiceReportsQuery,
  useLazyGetMobCreditReportsQuery,
  useLazyGetMobPROReportsQuery,
  useLazyGetDownloadMobPROReportsQuery,
  useLazyGetServiceRequestDataQuery,
  useLazyGetDownloadServiceRequestReportsQuery,
  useLazyGetSellerSubscriptionDataQuery
} = reportsAPI;

export default reportsAPI;

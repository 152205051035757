import React from "react";
import { Col, Tag, Typography } from "antd";
import "./SubOrderComponent.css";
import { statusColorMapping } from "../../commonFuntions/CommonUtilFunctions";
import { ReactComponent as FastDelivery } from "../../assets/icons/fast-delivery.svg";
export const SubOrderComponent = (props) => {
  const { record } = props;
  const { Text } = Typography;

  return (
    <Col className={"subOrderStyle"}>
      <Col className={"subIdName"}>
        <Text className={"subDataId subId"}>{`Sub ID: ${
          record?.suborder_id || record?.id || record?.subId
        }`}</Text>
        <Text className={"subDataId"}>
          {record?.bmp_id && `(${record?.bmp_id}) `}

          {`${record?.vendor_name || record?.itemName}`}
        </Text>
      </Col>
      <Col>
      {record?.is_delayed && (
      <Col className="delayFlag"> <Text style={{color:"#bf2600",fontweight: 600}}><FastDelivery style={{ paddingTop: "5px" , width: "20px" ,height:"15px" }} /> Delayed {record?.is_delayed}</Text></Col>
      )}
      </Col>
      <Col className={"subStatusTotal"}>
        <Tag
          className={`subDataStatus  ${
            statusColorMapping[record?.status || record?.subStatus]
          }`}
        >
          {record.status || record?.subStatus}
        </Tag>
        <Text className={"subDataId"}>{`₹ ${
          record.total?.toFixed(2) || record?.subTotal
        }`}</Text>
      </Col>
    </Col>
  );
};

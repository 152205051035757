import {
  //  Button,
  Col,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import "./WalletBankTransfer.css";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import dayjs from "dayjs";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import { useNavigate } from "react-router-dom";
import { useLazyGetWalletToBankTransferDetailsQuery } from "../../../apis/wallet";
import { dateFormat } from "../../../commonFuntions/CommonUtilFunctions";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import NoDataFound from "../../../components/EmptyTable/NoDataFound";
const { Text } = Typography;
const options = [
  {
    key: 1,
    value: "Excel",
    label: "Excel",
  },
  {
    key: 2,
    value: "CSV",
    label: "CSV",
  },
  {
    key: 3,
    value: "PDF",
    label: "PDF",
  },
  {
    key: 4,
    value: "Print",
    label: "Print",
  },
];

const dateOptions = [
  {
    key: 1,
    value: "Today",
    label: "Today",
  },
  {
    key: 2,
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    key: 3,
    value: "last_7_day",
    label: "Last 7 day",
  },
  {
    key: 4,
    value: "last_30_day",
    label: "Last 30 day",
  },
  {
    key: 5,
    value: "last_60_day",
    label: "Last 60 day",
  },
  {
    key: 6,
    value: "Custom",
    label: "Custom",
  },
  {
    key: 7,
    value: "clear_date",
    label: <Col style={{ color: "#006644", fontWeight: 500 }}>Clear Date</Col>,
  },
];
const WalletBankTransfer = () => {
  const [dateFilter, setDateFilter] = useState();
  const [searchedValue, setSearchedValue] = useState({});
  const [
    ,// dateSearch
    // setDateSearch,
  ] = useState({
    start_date: "",
    end_date: "",
  });
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const handleViewPage = (record) => {
    navigate(`/admin/customer/walletbankTransferView/${record?.id}`);
  };
  const [getWalletTransferDetailsApi, { data: getWalletData, isFetching }] =
    useLazyGetWalletToBankTransferDetailsQuery();

  useEffect(() => {
    getWalletTransferDetailsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
    };
    setCurrentPage(currPage);
    await getWalletTransferDetailsApi(params);
  };
  const columns = [
    {
      title: "Request id",
      dataIndex: "request_id",
      key: "request_id",
      fixed: "left",
      width: "250px",
      render: (params, record) => {
        return (
          <Text className="wallet-bank-styledText" style={{ fontWeight: 500 }}>
            {params}
          </Text>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date_requested",
      key: "date",
      width: "250px",
      render: (params, record) => {
        return (
          <Text className="wallet-bank-styledText">
            {params && dateFormat(params)}
          </Text>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "250px",
      render: (params, record) => {
        return (
          <Text className="wallet-bank-styledText">
            {record?.mob_user?.full_name}
          </Text>
        );
      },
    },
    {
      title: "Business mobile",
      dataIndex: "mobile_no",
      key: "mobile_no",
      width: "200px",
      render: (params, record) => {
        return (
          <Text className="wallet-bank-styledText">
            {record?.mob_user?.phone_number}
          </Text>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "150px",
      render: (params, record) => {
        return <Text className="wallet-bank-styledText">{params}</Text>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "200px",
      render: (params, record) => {
        return (
          <Text
            style={{
              padding: "8px 16px",
              borderRadius: "8px",
              fontWeight: 500,
              backgroundColor: params === "Requested" ? "#e8e8e8" : "#abf5d1",
              color: params === "Requested" ? "#0a243f" : "#006644",
            }}
          >
            {params}
          </Text>
        );
      },
    },
    {
      title: "Total quotes",
      dataIndex: "total_quotes",
      key: "total_quotes",
      width: "150px",
      render: (params, record) => {
        return (
          <Text
            className="wallet-bank-styledText"
            onClick={() => handleViewPage(record)}
            style={{ color: "#2973f0", cursor: "pointer" }}
          >
            {" "}
            View
          </Text>
        );
      },
    },
  ];
  // const handleChangeRangePicker = (dates, dateStrings) => {
  //   if (dates && dates.length === 2) {
  //     const startDateFormatted = dates[0].format("YYYY-MM-DD");
  //     const endDateFormatted = dates[1].format("YYYY-MM-DD");
  //     setDateSearch({
  //       start_date: startDateFormatted,
  //       end_date: endDateFormatted,
  //       // bmp_id: bmp_id,
  //     });
  //   }
  // };

  const handleDateChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        getWalletTransferDetailsApi({
          ...(searchedValue?.search && { search: searchedValue?.search }),
          page: 1,
        });
      } else {
        getWalletTransferDetailsApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(searchedValue?.search && { search: searchedValue?.search }),
          page: 1,
        });
      }
      setCurrentPage(1);
    }
    setDateFilter(value);
  };

  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      getWalletTransferDetailsApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };

  // const handleDateChange = (value) => {
  //   let formattedDate = null;

  //   switch (value) {
  //     case "Today":
  //       formattedDate = dayjs().format("YYYY-MM-DD");
  //       break;
  //     case "Yesterday":
  //       formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
  //       break;
  //     case "last_7_day":
  //       formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
  //       break;
  //     case "last_30_day":
  //       formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
  //       break;
  //     case "last_60_day":
  //       formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
  //       break;
  //     case "Custom":
  //       formattedDate = "Custom";
  //       break;
  //     default:
  //       break;
  //   }

  //   const currentDate = dayjs().format("YYYY-MM-DD");
  //   setDateSearch({
  //     start_date: formattedDate,
  //     end_date: currentDate,
  //     //   bmp_id: bmp_id,
  //   });
  //   setDateFilter(value);
  // };
  // const handleSearchOnDate = () => {
  //   // getSettlementByIDAPI(dateSearch);
  // };
  const handleChange = (value) => {
    console.log("Download data in vormate : ", value);
  };
  return (
    <Col className="walletBank-container">
      <Text className="customer_title">Wallet transfer</Text>
      <Col style={{ display: "flex", gap: "1rem" }}>
        <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
          getLibraryApi={getWalletTransferDetailsApi}
          handleSearchedValue={handleSearchedValue}
          width={"400px"}
          isLoading={isFetching}
        />
        <Col className="action-sub-container">
          <CustomSelect
            placeholder={"Date"}
            width={"150px"}
            options={dateOptions}
            handleChange={handleDateChange}
            value={dateFilter}
          />
          {dateFilter === "Custom" && (
            <DateRangePicker
              onChange={handleChangeRangePicker}
              format={"DD-MMM-YYYY"}
            />
          )}
          {/* <Button
            size="large"
            style={{
              backgroundColor: "#0354a3",
              color: "#fff",
              fontWeight: 500,
              height: "48px",
            }}
            onClick={() => handleSearchOnDate()}
          >
            Search
          </Button> */}
        </Col>
        <CustomSelect
          placeholder={"Download"}
          width={"100px"}
          height={"46px"}
          options={options}
          handleChange={handleChange}
          value={"EXPORT"}
        />
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        //   tableExpandable={defaultExpandable}
        //   tableDataSource={tableDataSource || []}
        tableDataSource={getWalletData?.data?.results}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? (
            <TableSkeleton length={10} />
          ) : (
            <NoDataFound text={"No transaction found!"} />
          ),
        }}
        isLoading={getWalletData?.data?.results.length && isFetching}
        scroll={{ x: "100%" }}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={
          !isFetching && getWalletData?.data?.pagination?.total_entries
        }
      />
    </Col>
  );
};

export default WalletBankTransfer;

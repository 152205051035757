import React from "react";
import { Button, Typography, Col } from "antd";
import "./createMobAddressBanner.css";

const { Text } = Typography;
const CreateMobAddressBanner = (props) => {
  const { handleClick } = props;
  return (
    <>
      <Col className="create-address-container">
        <Col className="create-address-sub-container">
          <Text className="create-address-sub-container-text-1">
            Please add mobCREDIT address to create quote
          </Text>
          <Text className="create-address-sub-container-text-2">
            Please reach out to your manager if you face any issues
          </Text>
        </Col>
        <Button className="create-address-button" onClick={handleClick}>
          ADD MOBCREDIT ADDRESS
        </Button>
      </Col>
    </>
  );
};

export default CreateMobAddressBanner;

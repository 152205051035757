import React from "react";
import "../contentcard.css";
import { Col, Typography } from "antd";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-right.svg";

export const VehicleRequest = (props) => {
  const { data } = props;
  const { Text } = Typography;
  return (
    <>
      <Col className="vehicle-card-container">
        {data?.map((val, idx) => (
          <Col key={idx} className="vehicle-sub-card-container">
            <Col className="vehicle-sub-row-card-container">
              <Col className="vehicle-sub-row-2-card-container">
                <Text className="vehicle-sub-row-2-card-container-text">{`Sub ID: ${val.id}`}</Text>
                <Text className="vehicle-sub-row-2-card-container-text-date">
                  {val.date}
                </Text>
              </Col>
              <Col className="vehicle-sub-card-status-container">
                <Col className="vehicle-sub-sub-card-status-container">
                  {val.status}
                </Col>
                <ArrowIcon />
              </Col>
            </Col>

            {val?.data?.map((item, idxx) => (
              <Col
                className="vehicle-sub-sub-card-status-container-1"
                key={idx}
              >
                <Col className="vehicle-sub-sub-card-status-container-image">
                  <Col className="vehicle-sub-sub-card-status-container-image-container">
                    {item.image}
                  </Col>
                  <Text style={{ fontWeight: 500 }}>{item.name}</Text>
                </Col>

                <Col>
                  <Text className="vehicle-sub-sub-card-status-container-image-text">{`Weight: ${item.weight}`}</Text>
                </Col>
              </Col>
            ))}
          </Col>
        ))}
      </Col>
    </>
  );
};

import React from "react";
import {
  Button,
  Col,
  Typography,
  // Select,
  Avatar,
  Skeleton,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UserDetail from "./UserDetail";
// import SettlementCard from "./SettlementCard";
import warning from "../../assets/icons/warning.svg";
import { ReactComponent as SettlementIcon } from "../../assets/icons/Settlement.svg";
import { ReactComponent as DisputeIcon } from "../../assets/icons/Dispute.svg";
import { ReactComponent as AssetsIcon } from "../../assets/icons/Assets.svg";
// import { useNavigate } from "react-router-dom";
import SettelmentSearchDropdown from "../../components/DropDown/settelmentSearchDropdown/SettelmentSearchDropdown";

const { Text } = Typography;
const SettlementHeader = (props) => {
  const {
    data,
    handleBackNavigate,
    searchDropdownData,
    getSettlementSearchApi,
    // handleHitApi,
    seller_name,
    handleChangeParam,
    isFetchingVendorData,
  } = props;
  const isNegative = data?.amount_pending < 0;
  // const navigate = useNavigate();
  // const handleChange = (value) => {
  // };

  return (
    <>
      <Col className="settleHeaderWrapper">
        <Button
          className="settleBtn"
          icon={<ArrowLeftOutlined />}
          onClick={handleBackNavigate}
        >
          BACK
        </Button>
        <Text className="settleStatement">
          Seller (BMP) pending settlements
        </Text>
        <SettelmentSearchDropdown
          size={"large"}
          placeholder={"Type here..."}
          width={"400px"}
          getLibraryApi={getSettlementSearchApi}
          list={searchDropdownData}
          // handleSearchedValue = {}
          // editValue={editQuoteData?.user?.full_name}
          // disabled={editCondition}
          defaultValue={seller_name}
          handleSetSelector={handleChangeParam}
          // showModal={handleShowAddCustomerModal}
          // editCondition={editCondition}
          // handleRemoveSelector={handleRemoveSelector}
        />
        {isFetchingVendorData ? (
          <>
            <Skeleton active paragraph={{ rows: 2 }} />
          </>
        ) : (
          <UserDetail data={data?.bank_details} />
        )}
        {/* <Col className="settleCardWrapper">
          <SettlementCard />
          <SettlementCard />
          <SettlementCard />
        </Col> */}
        {isFetchingVendorData ? (
          <>
            <Col style={{ display: "flex", gap: "20px" }}>
              <Skeleton active paragraph={{ rows: 4 }} />
              <Skeleton active paragraph={{ rows: 4 }} />
              <Skeleton active paragraph={{ rows: 4 }} />
            </Col>
          </>
        ) : (
          <>
            <Col className="end-container-seller-view">
              <Col
                className={`tag-main-container ${
                  isNegative && "negative-tag-text-container"
                }`}
                style={{
                  backgroundColor: isNegative
                    ? "rgba(234, 234, 234, 1)"
                    : "rgba(255, 255, 255, 0.2)",
                  border: "none",
                }}
              >
                <Col
                  className={`icon-main-container ${
                    isNegative && "negative-icon-main-container"
                  }`}
                >
                  <SettlementIcon />
                </Col>
                <Col className={`tag-text-container`}>
                  <Text
                    className={`tag-text-heading`}
                    style={{ color: isNegative ? "#0a243f" : "#fff" }}
                  >
                    Settlement amount pending
                  </Text>
                  <Text
                    className="tag-text-number"
                    style={{ color: isNegative ? "#bf2600" : "#fff" }}
                  >
                    ₹ {(data?.amount_pending || 0).toFixed(2)}
                  </Text>

                  <Text className="negative-text" style={{ color: "#fff" }}>
                    This is the amount pending to be paid to the seller
                    {/* This negative amount is because of returns and exchange
                    after the settlement was done */}
                  </Text>

                  {/* <Text
                        className="footer-container-text"
                        style={{ color: "#fff" }}
                      >
                        Revenue: ₹ {(data?.revenue || 0).toFixed(2)}
                      </Text>
                      <Text
                        className="footer-container-text"
                        style={{ color: "#fff" }}
                      >
                        Tax: ₹ {(data?.tax || 0).toFixed(2)}
                      </Text> */}
                </Col>
              </Col>
              <Col
                className="dispute-container"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  border: "none",
                }}
              >
                <Col className="icon-background-4">
                  <DisputeIcon />
                </Col>
                <Col className="tag-text-container">
                  <Text className="tag-text-heading" style={{ color: "#fff" }}>
                    To be resolved
                  </Text>
                  <Text className="tag-text-number" style={{ color: "#fff" }}>
                    ₹ {(data?.to_be_resolved || 0).toFixed(2)}
                  </Text>
                  <Text
                    className="footer-container-text"
                    style={{ color: "#fff" }}
                  >
                    This is the amount for orders to be resolved. It will be
                    released immediately thereafter.
                  </Text>
                </Col>
              </Col>
              <Col
                className="assets-container"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  border: "none",
                }}
              >
                <Col className="icon-background-5">
                  <AssetsIcon />
                </Col>
                <Col className="tag-text-container">
                  <Text className="tag-text-heading" style={{ color: "#fff" }}>
                    Payment settled
                  </Text>
                  <Text className="tag-text-number" style={{ color: "#fff" }}>
                    ₹ {(data?.payment_settled || 0).toFixed(2)}
                  </Text>
                  <Text
                    className="footer-container-text"
                    style={{ color: "#fff" }}
                  >
                    This is the amount that has been paid to seller by MOB for
                    orders
                    {/* This is the amount that has been paid to you by MOB for orders */}
                  </Text>
                </Col>
              </Col>
            </Col>
            {data?.amount_pending < 0 && (
              <Col className="extra-payment">
                <Avatar
                  style={{ marginRight: "15px" }}
                  shape="square"
                  src={warning}
                />{" "}
                This seller have recieved and extra payment of ₹{" "}
                {Math.abs(data?.amount_pending || 0).toFixed(2)}
              </Col>
            )}
          </>
        )}
      </Col>
    </>
  );
};

export default SettlementHeader;

import { useState } from "react";
import {
  Row,
  Col,
  Button,
  Menu,
  Dropdown,
  Divider,
  Typography,
  Tooltip,
} from "antd";
import { DownOutlined, UpOutlined, EyeOutlined } from "@ant-design/icons";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copyIcon.svg";

import { ReactComponent as SaveIcon } from "../../../../assets/icons/save.svg";
import "./QuotationRequiredDropdown.css";
import { saveAs } from "file-saver";
import { baseUrl } from "../../../../commonUtils/commonUtils";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../NotificationToast/NotificationToast";

const { Text } = Typography;
// const data = [
//   {
//     title: "BENG02 - Backyard decor",
//     price: "₹ 31250",
//     icon: <SaveIcon />,
//     key: 0,
//   },

//   {
//     title: "BENG02 - Backyard decor",
//     price: "₹ 31250",
//     icon: <SaveIcon />,
//     key: 1,
//   },
// ];

const QuotationRequiredDropdown = (props) => {
  const {
    title,
    //  name = true,
    handlePdfDownload,
    data,
  } = props;
  // const text = name?.split("/");
  // let data = [
  //   {
  //     title: text[text.length - 1],
  //     // price: "₹ 31250",
  //     icon: <SaveIcon />,
  //     key: 0,
  //   },
  // ];
  const [, setSelectedItem] = useState(null);
  const [showVisible, setShowVisible] = useState(false);
  const handleMenuClick = (e) => {
    setSelectedItem(data[e.key]);
  };
  const handlePdf = (item) => {
    saveAs(`${baseUrl}${item?.file}`);
    setShowVisible(false);
  };
  const handlePreview = (data) => {
    handlePdfDownload(data?.file);
  };
  const copyLinkToClipboardRow = async (params) => {
    try {
      await navigator.clipboard.writeText(params);
      showSuccessToast("Link copied to clipboard successfully");
    } catch (err) {
      showErrorToast("Failed to copy link to clipboard", err);
    }
  };
  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{ maxHeight: "250px", overflow: "auto" }}
    >
      {data?.map((item, index) => (
        <>
          <Menu.Item key={item?.id}>
            <Row
              style={{
                minWidth: "480px",
                padding: "1rem",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Col style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: 500 }}>{item?.file_name}</span>
              </Col>
              <Col style={{ display: "flex", gap: "12px" }}>
                <Tooltip title="Copy url" color={"#fff"} key={"#2973f0"}>
                  <Button
                    style={{
                      backgroundColor: "#f5f5f5",
                      border: "none",
                      color: "#2873f0",
                      fontWeight: 500,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => copyLinkToClipboardRow(item?.short_url)}
                    icon={<CopyIcon />}
                  ></Button>
                </Tooltip>
                <Button
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "none",
                    color: "#2873f0",
                    fontWeight: 500,
                  }}
                  icon={<EyeOutlined onClick={() => handlePreview(item)} />}
                ></Button>
                <Button
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "none",
                    fontWeight: 500,
                  }}
                  icon={<SaveIcon />}
                  onClick={() => handlePdf(item)}
                >
                  pdf
                </Button>
              </Col>
            </Row>
            {index !== data?.length - 1 && <Divider style={{ margin: 0 }} />}
          </Menu.Item>
        </>
      ))}
    </Menu>
  );
  const handleDropdownVisibleChange = (visible) => {
    setShowVisible(visible);
  };
  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomLeft"
      overlay={menu}
      open={showVisible}
      onOpenChange={handleDropdownVisibleChange}
    >
      <Text
        style={{
          color: "#2973f0",
          cursor: "pointer",
          position: "relative",
        }}
      >
        {title}
        {showVisible ? (
          <UpOutlined style={{ paddingLeft: "6px" }} />
        ) : (
          <DownOutlined style={{ paddingLeft: "6px", color: "#2873f0" }} />
        )}
      </Text>
    </Dropdown>
  );
};

export default QuotationRequiredDropdown;

import { Col } from "antd";
import React from "react";
import "./BasicDetails.css";
import EditContainerProduct from "./EditDetails/EditContainerProduct";
import { dropDownData } from "../../../../commonUtils/commonUtils";

const VariationInfo = (props) => {
  const { handleChildFieldChange, data, isFetching } = props;
  const variationInformation = [
    {
      name: "parent_sku",
      toolTip: false,
      label: "Parent SKU",
      type: "Input",
      // disabled: true,
      placeholder: "Enter Parent SKU",
      value: data?.parent_sku || "",
    },
    {
      name: "variation_theme",
      label: "Variation theme",
      type: "Select",
      // disabled: true,
      value: data?.variation_theme || "",
      placeholder: "Enter Variation theme",
      toolTip: false,
      options: dropDownData?.variation_theme,
    },

    {
      name: "variation_type",
      label: "Variation type",
      type: "Select",
      // disabled: true,
      value: data?.variation_type || "",
      placeholder: "Enter Variation type",
      toolTip: false,
      options: dropDownData?.variation_type,
    },
    {
      name: "item_width",
      label: "Item width",
      type: "Input",
      placeholder: "Enter Item width",
      toolTip: false,
      value: data?.item_width || "",
      reg: true,
    },
    {
      name: "item_width_unit_of_measure",
      label: "Width unit of measure",
      type: "Select",
      value: data?.item_width_unit_of_measure || "",
      placeholder: "Enter Width unit of measure",
      toolTip: false,
      options: dropDownData?.item_width_unit_of_measure,
    },
    {
      name: "item_weight",
      label: "Item weight",
      type: "Input",
      placeholder: "Enter Item weight",
      toolTip: false,
      value: data?.item_weight || "",
      reg: true,
    },
    {
      name: "item_weight_unit_of_measure",
      label: "Weight unit of measure",
      type: "Select",
      value: data?.item_weight_unit_of_measure || "",
      placeholder: "Enter Weight unit of measure",
      toolTip: false,
      options: dropDownData?.item_weight_unit_of_measure,
    },
    {
      name: "unit_count_per_product",
      label: "Unit count per product",
      type: "Input",
      placeholder: "Enter Unit count per product",
      toolTip: false,
      value: data?.unit_count_per_product || "",
    },
    {
      name: "color_names",
      label: "Color name",
      type: "Input",
      placeholder: "Enter Color name",
      toolTip: false,
      value: data?.color_names || "",
    },
    {
      name: "size_name",
      label: "Size name",
      type: "Input",
      placeholder: "Enter Size name",
      toolTip: false,
      value: data?.size_name || "",
    },
    {
      name: "primary_material_type",
      label: "Primary material type",
      type: "Select",
      value: data?.primary_material_type || "",
      placeholder: "Enter Primary material type",
      toolTip: false,
      options: dropDownData?.primary_material_type,
    },
    {
      name: "pattern_name",
      label: "Pattern name",
      type: "Select",
      placeholder: "Enter Pattern name",
      toolTip: false,
      value: data?.pattern_name || "",
      options: dropDownData?.pattern_name,
    },
    {
      name: "item_height",
      label: "Item height",
      type: "Input",
      placeholder: "Enter Item height",
      toolTip: false,
      value: data?.item_height || "",
      reg: true,
    },
    {
      name: "item_height_unit_of_measure",
      label: "Height unit of measure",
      type: "Select",
      value: data?.item_height_unit_of_measure || "",
      placeholder: "Enter Height unit of measure",
      toolTip: false,
      options: dropDownData?.item_height_unit_of_measure,
    },
    {
      name: "item_length",
      label: "Item length",
      type: "Input",
      placeholder: "Enter Item length",
      toolTip: false,
      value: data?.item_length || "",
      reg: true,
    },
    {
      name: "item_length_unit_of_measure",
      label: "Length unit of measure",
      type: "Select",
      value: data?.item_length_unit_of_measure || "",
      placeholder: "Enter Length unit of measure",
      toolTip: false,
      options: dropDownData?.item_length_unit_of_measure,
    },
    {
      name: "item_volume",
      label: "Item volume",
      type: "Input",
      placeholder: "Enter Item volume",
      toolTip: false,
      value: data?.item_volume || "",
      reg: true,
    },
    {
      name: "item_volume_unit_of_measure",
      label: "Volume unit of measure",
      type: "Select",
      value: data?.item_volume_unit_of_measure || "",
      placeholder: "Enter Volume unit of measure",
      toolTip: false,
      options: dropDownData?.item_volume_unit_of_measure,
    },
  ];
  return (
    <Col>
      {/* <Form> */}
      <EditContainerProduct
        tittle={"Variation information"}
        formData={variationInformation}
        handleChildFieldChange={handleChildFieldChange}
        isFetching={isFetching}
      />

      {/* </Form> */}
    </Col>
  );
};

export default VariationInfo;

import React, {useEffect, useState} from "react";
import {Col, Input} from "antd";
import "./CancelledFloatInput.css";
import {
  // useDispatch,
  useSelector,
} from "react-redux";
// import {getBmpPrice} from "../../../redux/slices/createQuote/action";
import {bmpPriceSelector} from "../../../redux/slices/createQuote/selector";

const CancelledFloatInput = (props) => {
  const [focus, setFocus] = useState(false);
  // const dispatch = useDispatch();
  let {
    label,
    value,
    placeholder,
    type,
    required,
    setAddDeliveryNotes,
    tableIndex,
    editCondition,
    disableAll,
  } = props;
  const bmpData = useSelector(bmpPriceSelector);

  // const deliveryNotes = () => {
  //   dispatch(
  //     getBmpPrice({
  //       ...bmpData[tableIndex],
  //       quote_delivery_notes: value,
  //       index: tableIndex,
  //       // quote_delivery_dates: bmpPrice[tableIndex].quote_delivery_dates,
  //       // quote_delivery_slot: bmpPrice[tableIndex].quote_delivery_slot,
  //     })
  //   );
  //   setAddDeliveryNotes(value);
  // };
  useEffect(() => {
    if (editCondition && bmpData[tableIndex]?.quote_delivery_notes) {
      setAddDeliveryNotes(bmpData[tableIndex]?.quote_delivery_notes);
      setFocus(true);
    } else {
      setAddDeliveryNotes("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCondition]);
  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <Col
      className="float-label"
      //   onBlur={() => setFocus(false)}
      //   onFocus={() => setFocus(true)}
    >
      <Input
        disabled={disableAll}
        // onChange={props.onChange}
        className="toggle_input"
        type={type}
        defaultValue={
          editCondition ? bmpData[tableIndex]?.quote_delivery_notes : value
        }
        // onPressEnter={deliveryNotes}
        // onBlur={deliveryNotes}
        style={{
          borderColor: "#f3f2ef",
          backgroundColor: "#f3f2ef",
          color: "#0a243f",
        }}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </Col>
  );
};

export default CancelledFloatInput;

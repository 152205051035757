import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";

export const publishQuoteAPI = createApi({
  reducerPath: "publishQuoteAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    publishQuoteById: builder.mutation({
      query: (status) => ({
        url: "/api/orders/rfq/update_quote/",
        method: "PATCH",
        body: status,
      }),
    }),
  }),
});

export const { usePublishQuoteByIdMutation } = publishQuoteAPI;

export default publishQuoteAPI;

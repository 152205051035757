import {
  Avatar,
  Card,
  Col,
  Row,
  Skeleton,
  Typography,
  Form,
  Input,
  DatePicker,
  Upload,
  Button,
  // message,
} from "antd";
import React, { useEffect, useState } from "react";

import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import fileUpload from "../../../assets/icons/file-upload.svg";

import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import "./WalletbankTransferView.css";
import AmountSelectedCard from "./AmountSelectedCard/AmountSelectedCard";
import AmountCreditModal from "./AmountCreditModal/AmountCreditModal";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  useLazyGetWalletToBankTransferDetailsByIdQuery,
  useUpdateWalletToBankTransferMutation,
} from "../../../apis/wallet";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import { dateFormat } from "../../../commonFuntions/CommonUtilFunctions";
const { Text, Title } = Typography;
const { Dragger } = Upload;
// const { TextArea } = Input;
const WalletbankTransferView = () => {
  const navigate = useNavigate();
  dayjs.extend(customParseFormat);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [formValue, setFormValue] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getWalletToBankTransferDetailsByIdApi, { data: getData }] =
    useLazyGetWalletToBankTransferDetailsByIdQuery();
  const [
    updateWalletToBankTransferApi,
    { isSuccess: onSuccessWalletToBbankTransfer },
  ] = useUpdateWalletToBankTransferMutation();
  const handleBackNavigate = () => {
    navigate(-1);
  };
  const parms = useParams();
  const [form] = Form.useForm();
  useEffect(() => {
    if (getData?.data?.transaction_number) {
      form.setFieldsValue({
        input: getData?.data?.transaction_number,
        date: dayjs(getData?.data?.transaction_date || ""),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);
  const onFinish = (values) => {
    setFormValue(values);
  };
  const onValuesChange = (_, allValues) => {
    const isInputEmpty = !allValues.input;
    const isDateNotSelected = !allValues.date;
    const isUploadNotSelected =
      !allValues.upload || allValues.upload.length === 0;

    setIsButtonDisabled(
      isInputEmpty || isDateNotSelected || isUploadNotSelected
    );
  };

  useEffect(() => {
    if (parms) {
      getWalletToBankTransferDetailsByIdApi(parms?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parms, onSuccessWalletToBbankTransfer]);
  const handleModalTransfer = () => {
    form.submit();
    setIsModalOpen(true);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    const fileList = e.fileList.slice(-1);
    return fileList;
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    showUploadList: true,
    accept: "image/*",
    beforeUpload: () => false,
  };


  const handleFormSubmit = async () => {
    const newDateValue = dayjs(formValue.date).format("YYYY-MM-DD");
    const params = {
      transaction_number: formValue.input,
      transaction_date: newDateValue,
      file: formValue.upload[0]?.originFileObj,
      id: parms?.id,
      remarks: "No",
    };
    const response = await updateWalletToBankTransferApi(params);

    if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    } else {
      const responseData = response?.data;
      showSuccessToast(responseData?.message);
      form.resetFields();
      setIsModalOpen(false);
    }
  };
  const handleCustomerView = (id) => {
    navigate(`/admin/customer/customerDetails/${id}`, {
      state: { id: `/admin/customer/walletbankTransferView/${parms?.id}` },
    });
  };
  return (
    <Col className="walletbankTransferView-container">
      <Button
        className="btn"
        icon={<ArrowLeftOutlined />}
        onClick={handleBackNavigate}
      >
        BACK
      </Button>

      <Col
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Col style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          <Col className="walletbankTransferView-header-align">
            <Text className="view-header-text">Transfer details</Text>

            <Text
              style={{
                padding: "8px 16px",
                borderRadius: "8px",
                fontWeight: 500,
                backgroundColor:
                  getData?.data?.status === "Requested" ? "#e8e8e8" : "#abf5d1",
                color:
                  getData?.data?.status === "Requested" ? "#0a243f" : "#006644",
              }}
            >
              {getData?.data?.status}
            </Text>
          </Col>
          <Text className="view-date-text">
            {getData?.data?.transaction_date &&
              dateFormat(getData?.data?.transaction_date)}
          </Text>
        </Col>
        {getData?.data?.status === "Requested" && (
          <Col style={{ display: "flex", gap: "1rem" }}>
            <Button className="view-reject-btn">REJECT</Button>
            <Button
              className="view-transferred-btn"
              disabled={isButtonDisabled}
              onClick={handleModalTransfer}
            >
              MONEY TRANSFERRED
            </Button>
          </Col>
        )}
      </Col>

      <Col style={{ width: "100%", display: "flex", gap: "1.5rem" }}>
        <Col
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {getData?.data?.status === "Requested" && (
            <Col className="request-details-notify">
              {` ₹ ${getData?.data?.amount} wallet amount has been deducted from customer account after
            requesting.`}
            </Col>
          )}
          {getData?.data?.status === "Transferred" && (
            <Col className="transfer-details-notify">
              Money has been transferred from customer wallet to bank. It will
              take 5-7 working days to reflect in their account
            </Col>
          )}

          <AmountSelectedCard getData={getData} />

          <Col className="view-uploader-form">
            <Form
              form={form}
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
                date: dayjs(),
              }}
              onFinish={onFinish}
              onValuesChange={onValuesChange}
            >
              <Form.Item
                label="Transaction number"
                name="input"
                rules={[
                  {
                    required: true,
                    message: "Please input something!",
                  },
                ]}
              >
                <Input
                  style={{ height: "46px" }}
                  className="wallet-amount-input"
                  disabled={
                    getData?.data?.status === "Transferred" ? true : false
                  }
                />
              </Form.Item>

              <Form.Item
                label="Date"
                name="date"
                rules={[
                  {
                    required: true,
                    message: "Please select a date!",
                  },
                ]}
              >
                <DatePicker
                  style={{ height: "46px", width: "100%" }}
                  className="wallet-amount-input"
                  format="DD-MMM-YYYY"
                  disabled={
                    getData?.data?.status === "Transferred" ? true : false
                  }
                />
              </Form.Item>

              {getData?.data?.status === "Requested" && (
                <Form.Item
                  label="Upload"
                  name="upload"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: "Please select a image!",
                    },
                  ]}
                >
                  <Dragger {...uploadProps}>
                    <Col>
                      <Col
                        style={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        <p className="ant-upload-drag-icon">
                          <img src={fileUpload} alt="" />
                        </p>
                        <p className="ant-upload-text">
                          Drop your files here or{" "}
                          <span
                            style={{
                              color: "#2973f0",
                              marginLeft: "12px",
                            }}
                          >
                            browse
                          </span>
                        </p>
                        <p
                          className="ant-upload-hint"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Supported formats(svg,jpeg,png)
                        </p>
                      </Col>
                    </Col>
                  </Dragger>
                </Form.Item>
              )}
            </Form>
          </Col>

          {getData?.data?.status === "Transferred" && (
            <Col className="request-details-notify">
              {` ₹ ${getData?.data?.amount} wallet amount has been deducted from customer account after
            requesting.`}
            </Col>
          )}
        </Col>

        <Col style={{ width: "30%" }}>
          {false ? (
            <Skeleton
              active
              avatar
              paragraph={{
                rows: 4,
              }}
            />
          ) : (
            <Card
              style={{
                width: "100%",
                minHeight: "160px",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Row>
                <Col span={6}>
                  <Avatar size={50} icon={<UserOutlined />} />
                </Col>
                <Col
                  span={18}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                  }}
                >
                  <Title level={5} style={{ fontWeight: 500, margin: 0 }}>
                    {getData?.data?.mob_user?.full_name}
                  </Title>
                  <Text style={{ fontWeight: 500 }}>
                    {getData?.data?.mob_user?.phone_number}{" "}
                  </Text>
                  <Text style={{ fontWeight: 500 }}>
                    {getData?.data?.mob_user?.email}{" "}
                  </Text>
                  <Text
                    style={{
                      fontWeight: 500,
                      color: "#2973f0",
                      paddingTop: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleCustomerView(getData?.data?.mob_user?.phone_number)
                    }
                  >
                    Go to customer view
                  </Text>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Col>
      <AmountCreditModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleOk={handleFormSubmit}
        getData={getData}
      />
    </Col>
  );
};

export default WalletbankTransferView;

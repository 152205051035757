import React, { useEffect, useState } from "react";
import {
  // PlusOutlined,
  // DownOutlined,
  CloseOutlined,
  DashOutlined,
  // LoadingOutlined,
} from "@ant-design/icons";
import { Col, Typography, Row, Button, Dropdown, Avatar, Menu } from "antd";
import "./customswitch.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UploadItemsModal from "../../components/Modal/UploadItemsModal";
import { DownArrow } from "../../components/Icons/DownArrow";
import { UpArrow } from "../../components/Icons/UpArrow";
import {
  useActivateTableMutation,
  useDeleteProductRowMutation,
  useLazyGetFlushCategoriesQuery,
  useLazyGetLibraryExcelQuery,
  // useDeleteTableImageMutation,
  useLazyGetLibraryTableQuery,
} from "../../apis/manageLibrary";
import { useDispatch, useSelector } from "react-redux";
import {
  getEditProductData,
  getTableRowData,
  getmanageLibraryTableData,
} from "../../redux/slices/manageLibrary/action";
import { manageLibraryTableSelector } from "../../redux/slices/manageLibrary/selector";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import SearchDropdown from "../../components/DropDown/SearchDropdown";
import SearchInput from "../../components/Input/SearchInputs/SearchInput";
import { ReactComponent as DefaultIcon } from "../../assets/icons/default.svg";
import {
  showErrorToast,
  showSuccessToast,
} from "../../NotificationToast/NotificationToast";
import CustomTableWithPagination from "../../components/CustomTableWithPagination/CustomTableWithPagination";
import { baseUrl } from "../../commonUtils/commonUtils";
// import CustomSelect from "../../components/SelectableDropdown/CustomSelect/CustomSelect";
import ManageDeleteModal from "../../components/Modal/DeleteModal/ManageDeleteModal/ManageDeleteModal";
import { handlePdfDownload } from "../../commonFuntions/CommonUtilFunctions";
import StatusModal from "../../components/Modal/StatusModal/StatusModal";
import NoDataFound from "../../components/EmptyTable/NoDataFound";
const handleTableData = (params) => {
  let initialArrVal = [];
  let parentSubOrder = [];

  params?.results?.products?.map((item, index) => {
    let obj = {
      key: item.id,
      // icon: <Col className="table-icon">...</Col>,
      productName: item.product_name,
      image: item.images[0] ? `${baseUrl}${item.images[0]?.image}` : "",
      status: (
        <Col
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Col style={{ fontWeight: 500 }}>Active</Col>
        </Col>
      ),
      mobsku: item.mob_sku,
      brand: item.brand_name,
      sellingprice: (
        <Col style={{ textAlign: "right" }}>{`₹ ${
          item.your_selling_price ? item.your_selling_price : 0
        }`}</Col>
      ),
      mrp: (
        <Col style={{ textAlign: "right" }}>{`₹ ${
          item.mrp ? item.mrp : 0
        }`}</Col>
      ),
      uom: item.unit_of_measure,
      category: item.category,
      subCategory: item.sub_category_id,
      subsubCategory: item.sub_category_id_2,
      expandRow: false,
      subproducts: item.subproducts,
      is_active: item.is_active,
    };
    initialArrVal.push(obj);
    // parentSubOrder.push(obj);
    if (item?.subproducts?.length) {
      item?.subproducts?.map((element, index) => {
        parentSubOrder.push({
          parentKey: item.id,
          key: element.id,
          // icon: <Col className="table-icon">...</Col>,
          productName: element.product_name,
          image: element.images[0]
            ? `${baseUrl}${element.images[0]?.image}`
            : "",
          status: (
            <Col
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <Col style={{ fontWeight: 500 }}>Active</Col>
            </Col>
          ),
          mobsku: element.mob_sku,
          brand: element.brand_name,
          sellingprice: (
            <Col style={{ textAlign: "right" }}>{`₹ ${
              element?.your_selling_price ? element?.your_selling_price : 0
            }`}</Col>
          ),
          mrp: (
            <Col style={{ textAlign: "right" }}>{`₹ ${
              element?.mrp ? element?.mrp : 0
            }`}</Col>
          ),
          uom: element.unit_of_measure,
          category: element.category,
          subCategory: element.sub_category_id,
          subsubCategory: element?.sub_category_id_2,
          is_active: item.is_active,
        });
        return null;
      });
    }
    return null;
  });

  return { initialArrVal, parentSubOrder };
};

const ManageMobLib = () => {
  const { Text } = Typography;
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const [
    ,
    // subCategoryLength
    setSubCategoryLength,
  ] = useState(0);

  const [subProducts, setSubProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productTableData, setProductTableData] = useState({});
  const [searchedValue, setSearchedValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [
    ,
    // activeRowItem
    setActiveRowItem,
  ] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableSelector = useSelector(manageLibraryTableSelector);

  const [
    libraryTableApi,
    {
      data: tableData,
      isFetching: isLoading,
      // isSuccess
    },
  ] = useLazyGetLibraryTableQuery();
  const [getFlushCategoriesApi, { isFetching }] =
    useLazyGetFlushCategoriesQuery();
  const [
    ,
    // loaderr
    setLoaderr,
  ] = useState(false);
  const [
    // excelApi,
    {
      // isFetching: exlLoading,
      data: excelApiData,
    },
  ] = useLazyGetLibraryExcelQuery();
  const [activateTableRowApi] = useActivateTableMutation();
  const [deleteTableRowApi] = useDeleteProductRowMutation();

  const handleSearchedValue = (params) => {
    if (params) {
      setCurrentPage(1);
      setSearchedValue(params);
    }
  };
  const handleDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };
  // const handleExportApi = () => {
  //   let brandArray = [];
  //   let categoriesArray = [];
  //   brands.map((item) => brandArray.push(item.label));
  //   categories.map((item) => categoriesArray.push(item.label));
  //   excelApi({
  //     ...(brandArray.length && { brands: brandArray }),
  //     ...(categoriesArray.length && { category: categoriesArray }),
  //     ...(searchedValue.search && { search: searchedValue.search }),
  //   });

  //   if (excelApiData?.data?.url) {
  //     handlePdfDownload(excelApiData?.data?.url);
  //   }

  //   // excelApi();
  // };
  useEffect(() => {
    if (excelApiData?.data?.url) {
      handlePdfDownload(excelApiData?.data?.url);
    }
  }, [excelApiData]);
  useEffect(() => {
    libraryTableApi();
    dispatch(getEditProductData({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isLoading) {
      setLoaderr(isLoading);
    }
  }, [isLoading]);
  useEffect(() => {
    if (location?.state?.modal) {
      setIsModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleClearFilter = () => {
    setCategories([]);
    setBrands([]);
    libraryTableApi();
    setCurrentPage(1);
  };

  const handleFilterLibrary = () => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item.label));
    categories.map((item) => categoriesArray.push(item.label));
    libraryTableApi({
      ...(brandArray.length && { brands: brandArray }),
      ...(categoriesArray.length && { category: categoriesArray }),
      page: 1,
    });
    setCurrentPage(1);
  };
  const handleFilterSelect = (label) => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item?.label));
    categories.map((item) => categoriesArray.push(item?.label));
    if (label === "Categories") {
      libraryTableApi({
        ...(brandArray.length && { brands: brandArray }),
        page: 1,
      });
    } else if (label === "Brands") {
      libraryTableApi({
        ...(categoriesArray.length && { category: categoriesArray }),
        page: 1,
      });
    }
    setCurrentPage(1);
  };
  console.log(excelApiData, "excelApiData");
  const _onRowClick = (data) => {
    setProductTableData(data);
    setActiveRowItem(data?.is_active);
    dispatch(getTableRowData(data));
  };

  const handleRowClick = (record, open) => {
    // let currentTableData = [...tableDataSource];
    let currentTableData = tableDataSource.filter((item) => !item.parentKey);
    let parentIndex = "";
    let subOrderList = subProducts.filter(
      (item) => item.parentKey === record.key
    );
    currentTableData = currentTableData.map((table, index) => {
      if (table.key === record.key) {
        parentIndex = index;
        return { ...table, expandRow: open === "up" ? true : false };
      } else {
        return { ...table, expandRow: false };
      }
    });

    if (open === "up") {
      currentTableData.splice(parentIndex + 1, 0, ...subOrderList);
      setSubCategoryLength(subOrderList.length || 0);
    } else {
      // currentTableData.splice(parentIndex + 1, subOrderList.length);
      setSubCategoryLength(0);
    }

    setTableDataSource(currentTableData);
  };

  useEffect(() => {
    if (tableData?.status === true) {
      dispatch(getmanageLibraryTableData(tableData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  useEffect(() => {
    if (tableSelector) {
      const data = tableSelector ? handleTableData(tableSelector) : "";
      setTableDataSource(data?.initialArrVal || []);
      setSubProducts(data?.parentSubOrder || []);
    }
  }, [tableSelector]);

  // const onEdit = () => {
  //   navigate(`/admin/products/update-product/${productTableData?.key}`, {
  //     state: { id: productTableData?.key },
  //   });
  // };

  const onActivate = async () => {
    const params = {
      product_id: productTableData?.key,
      is_active: !productTableData?.is_active,
    };
    const response = await activateTableRowApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      libraryTableApi();
      setShowStatusModal(false);
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };

  const onDelete = async () => {
    const params = { product_id: productTableData?.key };
    const response = await deleteTableRowApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setIsDeleteModalOpen(false);
      libraryTableApi();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };

  const columns = [
    {
      // title: (
      //   <PlusOutlined
      //     style={{
      //       backgroundColor: "#fff",
      //       borderRadius: "50%",
      //       padding: "3px",
      //     }}
      //   />
      // ),
      key: "icon",
      dataIndex: "icon",
      fixed: "left",
      width: "50px",
      render: (params, record) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  {/* <> */}
                  <Menu.Item
                    // onClick={onEdit}
                    style={{ padding: "7px 12px" }}
                  >
                    <Link
                      to={`/admin/products/update-product/${productTableData?.key}`}
                      target="_blank"
                    >
                      <Col className="container-list">
                        <Text className="select-text">Edit</Text>
                      </Col>
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => setShowStatusModal(true)}
                    style={{ padding: "7px 12px" }}
                  >
                    <Col className="container-list">
                      <Text className="select-text">
                        {record?.is_active ? "Deactivate" : "Activate"}
                      </Text>
                    </Col>
                  </Menu.Item>
                  <Menu.Item
                    onClick={handleDeleteModalOpen}
                    style={{
                      padding: "7px 12px",
                    }}
                  >
                    <Col className="container-list">
                      <Text
                        className="select-text"
                        style={{ color: "#bf2600" }}
                      >
                        Delete
                      </Text>
                    </Col>
                  </Menu.Item>
                  {/* </> */}
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined onClick={() => _onRowClick(record)} />
            </Dropdown>
          </>
        );
      },
      // height: "60px",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      fixed: "left",
      width: "393px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record.parentKey ? "60px" : "0px",
              }}
            >
              {!(record?.parentKey || !record?.subproducts?.length) && (
                <Col>
                  {record.expandRow ? (
                    <UpArrow
                      className={"cursor"}
                      onClick={() => handleRowClick(record, "down")}
                    />
                  ) : (
                    <DownArrow
                      className={"cursor"}
                      onClick={() => handleRowClick(record, "up")}
                    />
                  )}
                </Col>
              )}
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={record.image}
                  alt="Avatar"
                  icon={<DefaultIcon width={20} height={20} />}
                />
              </Col>
              <Col style={{ fontWeight: 500 }}>{record.productName}</Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "120px",
      render: (params, record) => {
        return <>{record?.is_active ? "Active" : "Inactive"}</>;
      },
    },
    {
      title: "MOBSKU",
      dataIndex: "mobsku",
      key: "mobsku",
      width: "150px",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: "150px",
    },
    // {
    //   title: "Selling Price",
    //   dataIndex: "sellingprice",
    //   key: "sellingprice",
    //   width: "120px",
    // },
    {
      title: <Col style={{ textAlign: "right" }}>MRP</Col>,
      dataIndex: "mrp",
      key: "mrp",
      width: "120px",
    },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
      width: "150px",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: "200px",
    },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      width: "350px",
    },
    {
      title: "Sub Sub Category",
      dataIndex: "subsubCategory",
      key: "subsubCategory",
      width: "350px",
    },
  ];
  const uploadImage = (id) => {
    navigate("/admin/products/upload-images");
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handlePageChange = async (currPage) => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item.label));
    categories.map((item) => categoriesArray.push(item.label));
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(brandArray.length && { brands: brandArray }),
      ...(categoriesArray.length && { category: categoriesArray }),
    };
    setCurrentPage(currPage);
    await libraryTableApi(params);
  };
  // const startItemIndex = (currentPage - 1) * 10;
  // const endItemIndex = startItemIndex + 10;
  // const currentData = tableDataSource.slice(startItemIndex, endItemIndex);
  // const options = [
  //   {
  //     key: 1,
  //     value: "Excel",
  //     label: "Excel",
  //   },
  // ];
  const handleFlushCategories = async () => {
    const response = await getFlushCategoriesApi();
    console.log(
      "🚀 ~ file: ManageMobLib.js:573 ~ handleFlushCategories ~ response:",
      response
    );
  };
  return (
    <Col style={{ padding: "2rem" }}>
      <Row>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Text
            style={{
              fontSize: "20px",
              fontFamily: "Inter",
              fontWeight: 500,
              lineHeight: 1.6,
            }}
          >
            Manage Mob Library
          </Text>
          <Text>{tableSelector?.pagination?.total_entries || 0} items</Text>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Col
          style={{
            display: "flex",
            gap: "16px",
          }}
        >
          <SearchInput
            size={"large"}
            placeholder={"search by name, description, SKU"}
            getLibraryApi={libraryTableApi}
            width={"400px"}
            handleSearchedValue={handleSearchedValue}
            isLoading={isLoading}
          />
          {/* <Button style={{ fontWeight: 500, height: "48px" }} size="large">
            Export <DownOutlined />
          </Button> */}
          {/* <CustomSelect
            placeholder={"Export"}
            width={"150px"}
            options={options}
            handleChange={handleExportApi}
            value={exlLoading ? <LoadingOutlined /> : "Export"}
            disabled={exlLoading ? true : false}
            loading={exlLoading}
          /> */}
        </Col>
        <Col
          style={{
            display: "flex",
            gap: "16px",
          }}
        >
          <Button
            size="large"
            style={{
              backgroundColor: "#0354a3",
              color: "#fff",
              fontWeight: 500,
              height: "48px",
            }}
            loading={isFetching}
            onClick={handleFlushCategories}
          >
            Flush categories{" "}
          </Button>
          <Button
            size="large"
            style={{ fontWeight: 500, height: "48px" }}
            onClick={uploadImage}
          >
            Upload images
          </Button>
          <Button
            size="large"
            style={{
              backgroundColor: "#0354a3",
              color: "#fff",
              fontWeight: 500,
              height: "48px",
            }}
            onClick={showModal}
          >
            Upload items
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          marginBottom: "20px",
        }}
      >
        <Col
          style={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            height: "60px",
          }}
        >
          <SearchDropdown
            list={tableSelector?.results?.categories}
            label={"Categories"}
            placeholder={"Search categories"}
            selectedItems={categories}
            setSelectedItems={setCategories}
            handleFilterLibrary={handleFilterLibrary}
            handleFilterSelect={handleFilterSelect}
          />
          <SearchDropdown
            list={tableSelector?.results?.brands}
            label={"Brands"}
            placeholder={"Search brands"}
            selectedItems={brands}
            setSelectedItems={setBrands}
            handleFilterLibrary={handleFilterLibrary}
            handleFilterSelect={handleFilterSelect}
          />
          {/* <Select
            defaultValue="Sort"
            style={{
              width: 120,
              height: "48px",
            }}
            // onChange={handleChange}
            options={[]}
          /> */}
          {(brands?.length !== 0 || categories?.length !== 0) && (
            <Button
              style={{
                backgroundColor: "#f2f2f2",
                borderRadius: "30px",
                height: "48px",
                fontWeight: 500,
                border: "none",
              }}
              onClick={handleClearFilter}
            >
              <CloseOutlined /> Clear filters
            </Button>
          )}
        </Col>
      </Row>
      {/* <Table
        className="mob-list-table"
         // expandable={defaultExpandable}
         dataSource={currentData || tableDataSource || []}
        // pagination={{ pageSize: 10 + subCategoryLength }}
        pagination={false}
        columns={columns}
        locale={{
          emptyText: isLoading ? <TableSkeleton length={10} /> : "",
        }}
        scroll={{ x: "100%" }}
      /> */}
      <CustomTableWithPagination
        className="mob-list-table"
        tableDataSource={tableDataSource || []}
        tableColumns={columns}
        locale={{
          emptyText: isLoading ? (
            <TableSkeleton length={30} />
          ) : (
            <NoDataFound text={"No products found!"} />
          ),
        }}
        scroll={{ x: "100%" }}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={!isLoading && tableData?.data?.pagination?.total_entries}
        isLoading={isLoading}
        //  loaderr={loaderr}
      />
      <UploadItemsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        showModal={showModal}
      />
      <ManageDeleteModal
        handleOk={handleDeleteModalCancel}
        open={isDeleteModalOpen}
        handleSave={onDelete}
        data={productTableData?.productName}
      />
      <StatusModal
        open={showStatusModal}
        setOpen={setShowStatusModal}
        handleOk={onActivate}
        data={productTableData}
      />
    </Col>
  );
};

export default ManageMobLib;

import React, { useState } from "react";
import { Row, Col, Button, Typography, List } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import // message,
// Upload
"antd";
// import { ReactComponent as Camera } from "../../assets/icons/camera.svg";
import { useNavigate } from "react-router-dom";
import ImageDragger from "../../components/Draggers/ImageDragger";
import { useUploadImagesMutation } from "../../apis/manageLibrary";
import MobLibraryCard from "./MobLibraryCard";

const { Text } = Typography;
const UploadImage = () => {
  const navigate = useNavigate();
  const [uploadedAllImages, setUploadedAllImages] = useState({});
  const [uploadImageApi, { data: imageList, isLoading }] =
    useUploadImagesMutation();
  const handleBack = () => {
    navigate(-1);
  };
  const items = [
    "Supported formats(.png,.jpeg,.jpg)",
    "Maximum 100 files upload at the time",
    "Maximum file size: 100kb(per image)",
    "Image size should be 450 x 350",
  ];

  // const customRequest =async({file, onSuccess, onError, onProgress,ss,uu })=>{
  // }
  // function base64ToImage(base64Url) {
  //   const img = new Image();
  //   img.src = base64Url;
  //   return img;
  // }

  return (
    <Col style={{ padding: "2rem" }}>
      <Row>
        <Col>
          <Button
            style={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "14px",
              borderRadius: " 8px",
              border: "solid 1.5px #dedede",
            }}
            onClick={handleBack}
            size="large"
          >
            <ArrowLeftOutlined />
            BACK TO MOB LIBRARY
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "32px",
          marginBottom: "30px",
        }}
      >
        <Col
          style={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "24px",
            }}
          >
            Upload image
          </Text>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ImageDragger
          uploadImageApi={uploadImageApi}
          isLoading={isLoading}
          imageList={imageList}
          multipleImages={true}
          setUploadedAllImages={setUploadedAllImages}
        />
        {/* <Col style={{ width: "60%" }}>
          <Dragger
            onDrop={(e) =>}
            {...props}
            listType="picture"
            // customRequest={customRequest}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              border: "1px solid #dedede",
              borderRadius: "8px",
              boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
              maxHeight: "310px",
            }}
          >
            <Col
              style={{
                border: "2px dashed #dedede",
                margin: "24px 24px 50px 24px",
                padding: "32px",
                borderRadius: "8px",
              }}
            >
              <Col
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <Camera />
                <Text
                  style={{
                    display: "block",
                    fontWeight: 500,
                    fontSize: "16px",
                  }}
                >
                  Drag your images here or
                  <Text
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#2973f0",
                      marginLeft: "12px",
                    }}
                  >
                    browse
                  </Text>
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#0a243f",
                    opacity: 0.6,
                  }}
                >
                  Supported formats(.png,.jpeg,.jpg)
                </Text>
              </Col>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                size="large"
                style={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#fff",
                  backgroundColor: "#9da7b2",
                  marginRight: "24px",
                  borderRadius: "8px",
                }}
              >
                UPLOAD ALL IMAGES
              </Button>
            </Col>
          </Dragger>
        </Col> */}

        <Col
          // className="dont-have-template"
          style={{
            // minheight: "180px",
            width: "35%",
          }}
        >
          <Col
            style={{
              borderRadius: "8px",
              padding: "24px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#0a243f",
              }}
            >
              Image requirements
            </Text>
            <List
              bordered={false}
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                paddingLeft: "13px",
                lineHeight: "1.71",
                fontWeight: "700",
              }}
              dataSource={items}
              renderItem={(item, index) => (
                <List.Item style={{ padding: 0, borderBottom: "none" }}>
                  <List.Item.Meta
                    avatar={
                      <Col
                        style={{
                          width: "5px",
                          height: "5px",
                          borderRadius: "50%",
                          marginTop: "10px",
                          backgroundColor: index === 1 ? "red" : "#000",
                        }}
                      />
                    }
                    title={
                      <Col
                        style={{
                          fontWeight: "500",
                          padding: 0,
                          color: index === 1 ? "red" : "#000",
                        }}
                      >
                        {item}
                      </Col>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Col>
      </Row>
      {imageList?.status && (
        <MobLibraryCard productData={imageList?.data?.errors} />
      )}
      {uploadedAllImages?.status && (
        <MobLibraryCard productData={uploadedAllImages?.data?.errors} />
      )}
    </Col>
  );
};

export default UploadImage;

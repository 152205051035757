import React, { useCallback, useEffect, useState } from "react";
import "./MainSearchInput.css";
// import { SearchInput } from "../Header/NavbarStyled";
import { ReactComponent as Search } from "../../assets/icons/search-3.svg";
// import { ReactComponent as DefaultIcon } from "../../assets/icons/default.svg";

import {
  AutoComplete,
  //  Avatar,
  Col,
  Input,
  Typography,
} from "antd";
import { debounce } from "lodash";
import { useLazyGetMainSearchQuery } from "../../apis/dashboard";
// import { baseUrl } from "../../commonUtils/commonUtils";
import { useNavigate } from "react-router-dom";
import { string_dot } from "../../commonFuntions/CommonUtilFunctions";
import { useDispatch, useSelector } from "react-redux";
import { main_search_listSelector } from "../../redux/slices/loginAuth/selector";
import { getMain_search_list } from "../../redux/slices/loginAuth/action";
import TableSkeleton from "../skeleton/TableSkeleton";

export const MainSearchInput = () => {
  const { Text } = Typography;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getAutoCompleteSearchApi, { data: dataList, isFetching }] =
    useLazyGetMainSearchQuery();
  const reduxList = useSelector(main_search_listSelector);
  const [searchText, setSearchText] = useState("");
  const customersList = reduxList?.customers;
  const sellerList = reduxList?.mob_vendors;
  const ordersList = reduxList?.orders;
  const rfqList = reduxList?.rfqs;
  const handleTrigger = (params) => {
    getAutoCompleteSearchApi(params);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTextDebouncedTrigger = useCallback(
    debounce(handleTrigger, 1000),
    []
  );
  const handleSearch = (value) => {
    if (value === "") {
      searchTextDebouncedTrigger({
        search: "",
      });
    }
    if (value?.length >= 3) {
      searchTextDebouncedTrigger({
        search: value,
      });
    }
    setSearchText(value);
  };

  useEffect(() => {
    if (searchText?.length === "") {
      dispatch(getMain_search_list([]));
    }
    if (searchText?.length >= 3) {
      dispatch(getMain_search_list(dataList?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, dataList]);
  const handleSelect = (value) => {
    const item = JSON.parse(value);
    const bmp_id_present = "bmp_id" in item;
    const phone_number_present = "phone_number" in item;
    const order_id_present = "order_id" in item;
    const rfq_id_present = "rfq_id" in item;

    if (bmp_id_present) {
      navigate(`/admin/sellers/seller-view/${item?.bmp_id}`);
    }
    if (phone_number_present) {
      navigate(`/admin/customer/customerDetails/${item?.phone_number}`);
    }
    if (order_id_present) {
      navigate(`/admin/orders/order-details/${item?.id}`);
    }
    if (rfq_id_present) {
      navigate(`/admin/rfq/quotes/${item?.rfq_id}`);
    }
    dispatch(getMain_search_list([]));
    setSearchText("");
  };
  const renderTitle = (title, key) => {
    return (
      <span key={key} style={{ fontWeight: 500, fontSize: "14px" }}>
        {title}
      </span>
    );
  };
  const renderItem = (itemList) => {
    if (!Array.isArray(itemList)) {
      return "No Data";
    }

    return itemList?.map((obj, index) => {
      return {
        value: JSON.stringify(obj),
        label: (
          <Col
            key={obj?.id}
            style={{
              height: "56px",
              padding: "12px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text>
              {string_dot(obj?.full_name || "", 40) ||
                string_dot(obj?.order_id || "", 40) ||
                string_dot(obj?.rfq_id || "", 40) ||
                `(${obj?.bmp_id}) ${string_dot(obj?.vendor_name || "", 30)}`}
            </Text>
            <Text>{obj?.phone_number || ""}</Text>
          </Col>
        ),
      };
    });
  };

  const options = [];
  if (customersList?.length >= 1) {
    options.push({
      label: renderTitle("Customers", 1),
      options: renderItem(customersList),
    });
  }
  if (sellerList?.length >= 1) {
    options.push({
      label: renderTitle("Seller", 2),
      options: renderItem(sellerList),
    });
  }
  if (ordersList?.length >= 1) {
    options.push({
      label: renderTitle("Orders", 3),
      options: renderItem(ordersList),
    });
  }
  if (rfqList?.length >= 1) {
    options.push({
      label: renderTitle("Rfqs", 4),
      options: renderItem(rfqList),
    });
  }
  return (
    <>
      <AutoComplete
        className="main-auto-complete"
        style={{
          width: "100%",
          height: "100%",
        }}
        onSearch={handleSearch}
        onSelect={handleSelect}
        value={searchText}
        options={options}
        dropdownRender={(menu) => (
          <Col>{isFetching ? <TableSkeleton length={1} /> : menu}</Col>
        )}
      >
        <Input
          className="main-auto-complete-input"
          placeholder="Search customer/ seller/ phone/ order/ rfq"
          suffix={<Search />}
        />
      </AutoComplete>
    </>
  );
};

import { Col, Modal, Radio, Typography } from "antd";
import React from "react";
const { Text } = Typography;
// const deliveryAddress = [
//   {
//     id: 1,
//     name: "Backyard decor sector 1",
//     address_line_1:
//       "66138 Legros Mission Suite 804 Eden Plain Apt. 613, Behind starbucks Chennai",
//     pincode: "600009",
//   },
//   {
//     id: 2,
//     name: "Backyard decor sector 2",
//     address_line_1:
//       "66138 Legros Mission Suite 804 Eden Plain Apt. 613, Behind starbucks Chennai",
//     pincode: "600009",
//   },
// ];
const ChooseAddressModal = ({
  isModalOpen,
  setIsModalOpen,
  setPickupAddressValue,
  vendorAddressData,
  value,
  setValue,
}) => {
  const onChange = (e) => {
    setValue(e.target.value);
    setPickupAddressValue(e.target.value);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        title="Pickup address"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        {" "}
        <Col
          style={{
            // height: "300px",
            padding: "12px 30px",
            width: "100%",
          }}
        >
          <Radio.Group
            onChange={onChange}
            value={value}
            style={{ width: "100%" }}
          >
            {vendorAddressData?.data?.map((option, index) => (
              <>
                <Col
                  key={option.id}
                  style={{
                    width: "100%",
                    //   height: "80px",
                    padding: "14px 16px",
                    borderRadius: "16px",
                    border: `2px solid ${
                      value === option ? "#0a243f" : "#f1f1f2"
                    }`,
                    background: `${value === option ? "#f1f1f2" : "#fff"}`,
                    marginBottom: "12px",
                  }}
                >
                  <Radio value={option} style={{ width: "100%" }}>
                    <Col
                      style={{
                        paddingLeft: "16px",
                        width: "100%",
                      }}
                    >
                      <Col
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            color: "#0a243f",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          {option.name}
                        </Text>
                      </Col>

                      <Col>
                        <Text
                          style={{
                            color: "#0a243f",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {option.address}
                        </Text>{" "}
                        ,
                        <Text
                          style={{
                            color: "#0a243f",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {option.address_line_2}
                        </Text>
                      </Col>
                      <Col>
                        <Text
                          style={{
                            color: "#0a243f",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {option.city}
                        </Text>{" "}
                        ,{" "}
                        <Text
                          style={{
                            color: "#0a243f",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {option.state}
                        </Text>{" "}
                        ,{" "}
                        <Text
                          style={{
                            color: "#0a243f",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {option.pincode}
                        </Text>
                      </Col>
                      <Col>
                        <Text
                          style={{
                            color: "#0a243f",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {option.phone_number}
                        </Text>
                      </Col>
                    </Col>
                  </Radio>
                </Col>
              </>
            ))}
          </Radio.Group>
        </Col>
      </Modal>
    </>
  );
};

export default ChooseAddressModal;

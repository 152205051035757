import {
  Col,
  // Typography
} from "antd";
import React from "react";
import "./BasicDetails.css";
import EditContainerProduct from "./EditDetails/EditContainerProduct";
import { dropDownData } from "../../../../commonUtils/commonUtils";

const BasicInfo = (props) => {
  const { handleChildFieldChange, data, isFetching } = props;
  // const { Text } = Typography;
  const basicInformation = [
    {
      name: "item_imported",
      label: "Item imported",
      type: "Select",
      value: data?.item_imported || "",
      placeholder: "Enter Item imported",
      toolTip: false,
      options: [
        {
          key: "YES",
          label: "YES",
          value: "YES",
        },
        {
          key: "NO",
          label: "NO",
          value: "NO",
        },
      ],
    },
    {
      name: "importer_contact",
      label: "Importer contact",
      type: "Input",
      placeholder: "Enter Importer contact",
      toolTip: false,
      value: data?.importer_contact || "",
      reg: true,
      regNum: true,
    },
    {
      name: "manufacturer_part_number",
      label: "Manufacturer part number",
      type: "Input",
      placeholder: "Enter Manufacturer part number",
      toolTip: false,
      value: data?.manufacturer_part_number || "",
    },
    {
      name: "intended_product_use_with",
      label: "Intended product use with",
      type: "Input",
      placeholder: "Enter Intended product use with",
      toolTip: false,
      value: data?.intended_product_use_with || "",
    },
    {
      name: "model_year",
      label: "Model year",
      type: "Input",
      placeholder: "Enter Model year",
      toolTip: false,
      value: data?.model_year || "",
      reg: true,
    },
    {
      name: "search_keywords",
      label: "Search keywords",
      type: "Input",
      placeholder: "Enter Search keywords",
      toolTip: false,
      value: data?.search_keywords || "",
    },
    {
      name: "plug_type",
      label: "Plug type",
      type: "Select",
      value: data?.plug_type || "",
      placeholder: "Enter Plug type",
      toolTip: false,
      options: dropDownData?.plug_type,
    },
    {
      name: "special_features",
      label: "Special features",
      type: "Select",
      value: data?.special_features || "",
      placeholder: "Enter Special features",
      toolTip: false,
      options: dropDownData?.special_features,
    },
    {
      name: "finish_type",
      label: "Finish type",
      type: "Select",
      value: data?.finish_type || "",
      placeholder: "Enter Finish type",
      toolTip: false,
      options: dropDownData?.finish_type,
    },
    {
      name: "shipping_weight",
      label: "Shipping weight",
      type: "Input",
      placeholder: "Enter Shipping weight",
      toolTip: false,
      value: data?.shipping_weight || "",
      reg: true,
    },
    // {
    //   name: "item_weight_unit_of_measure",
    //   label: "Weight unit of measure",
    //   type: "Select",
    //   value: data?.item_weight_unit_of_measure || "",
    //   placeholder: "Enter Weight unit of measure",
    //   toolTip: false,
    //   options: [
    //     {
    //       key: 1,
    //       label: "ONE",
    //       value: "ONE",
    //     },
    //     {
    //       key: 2,
    //       label: "TWO",
    //       value: "TWO",
    //     },
    //   ],
    // },
    {
      name: "is_product_expirable",
      label: "Is product expirable",
      type: "Select",
      value: data?.is_product_expirable || "",
      placeholder: "Enter Is product expirable",
      toolTip: false,
      options: [
        {
          key: "YES",
          label: "YES",
          value: "YES",
        },
        {
          key: "NO",
          label: "NO",
          value: "NO",
        },
      ],
    },
    {
      name: "model_number",
      label: "Model number",
      type: "Input",
      placeholder: "Enter Model number",
      toolTip: false,
      value: data?.model_number || "",
    },
    {
      name: "manufacturer",
      label: "Manufacturer",
      type: "Input",
      placeholder: "Enter Manufacturer",
      toolTip: false,
      value: data?.manufacturer || "",
    },
    {
      name: "power_source_type",
      label: "Power source type",
      type: "Select",
      value: data?.power_source_type || "",
      placeholder: "Enter Power source type",
      toolTip: false,
      options: dropDownData?.power_source_type,
    },
    {
      name: "included_components",
      label: "Included components",
      type: "Select",
      value: data?.included_components || "",
      placeholder: "Enter Included components",
      toolTip: false,
      options: dropDownData?.included_components,
    },
    {
      name: "manufacturer_contact_details",
      label: "Manufacturer contact no",
      type: "TextArea",
      placeholder: "Enter Manufacturer contact no",
      toolTip: false,
      value: data?.manufacturer_contact_details || "",
    },
    {
      name: "item_type_name",
      label: "Item type name",
      type: "Input",
      placeholder: "Enter Item type name",
      toolTip: false,
      value: data?.item_type_name || "",
    },
    {
      name: "number_of_packages",
      label: "Number of packages",
      type: "Input",
      placeholder: "Enter Number of packages",
      toolTip: false,
      value: data?.number_of_packages || "",
      reg: true,
    },
    {
      name: "unit_count_type",
      label: "Unit count type",
      type: "Select",
      value: data?.unit_count_type || "",
      placeholder: "Enter Unit count type",
      toolTip: false,
      options: dropDownData?.unit_count_type,
    },
    {
      name: "country_of_origin",
      label: "Country of origin",
      type: "Select",
      value: data?.country_of_origin || "",
      placeholder: "Enter Country of origin",
      toolTip: false,
      options: dropDownData?.country,
    },
    {
      name: "condition_type",
      label: "Condition type",
      type: "Select",
      value: data?.condition_type || "",
      placeholder: "Enter Condition type",
      toolTip: false,
      options: dropDownData?.conditon_type,
    },
    {
      name: "currency",
      label: "Currency",
      type: "Select",
      value: data?.currency || "",
      placeholder: "Enter Currency",
      toolTip: false,
      options: dropDownData?.currency,
    },
  ];
  return (
    <Col>
      {/* <Form> */}
      <EditContainerProduct
        tittle={"Basic information"}
        formData={basicInformation}
        handleChildFieldChange={handleChildFieldChange}
        isFetching={isFetching}
      />

      {/* </Form> */}
    </Col>
  );
};

export default BasicInfo;

import React from "react";
import { ReactComponent as AddToCatalogueIcon } from "../../../assets/icons/addToCatalogue.svg";
import { Button, Col, Modal } from "antd";
import "./AddToCatalogueModal.css";
const AddedToCatalogueModal = (props) => {
  const { item, open, handleOk } = props;

  return (
    <Modal
      //   style={{ maxHeight: "80vh", overflowY: "auto" }}
      className="add-image"
      open={open}
      onOk={handleOk}
      onCancel={() => handleOk()}
      footer={[
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            borderRadius: "0px 0px 16px 16px",
            height: "96px",
            backgroundColor: "#fff",
          }}
        >
          <Button
            onClick={() => handleOk()}
            style={{
              height: "48px",
              fontWeight: 500,
              fontSize: "14px",
              maxWidth: "120px",
              width: "100%",
            }}
          >
            CANCEL
          </Button>
        </Col>,
      ]}
    >
      <Col className="icon-container">
        <AddToCatalogueIcon className="catalogue-icon" />
      </Col>
      <Col className="item-added">{`${
        item || 0
      } items added to your catalogue!`}</Col>
      <Col className="text">
        {"You can view these items in Catalogue and Inventory & price section"}
      </Col>
    </Modal>
  );
};

export default AddedToCatalogueModal;
